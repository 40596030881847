import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {  // Provides a hook for centralized exception handling



  handleError(error: any) {
    if (error?.qa?.split('-')[0] === 'ResizeObserver loop limit exceeded ') {
      console.warn('Ignored: ResizeObserver loop limit exceeded');
      return false;
    } 
    if (error instanceof HttpErrorResponse) {
    } else {
      console.log(error);
      if (environment.devModeEnabled) {
        const errStr = error.message + '';
        alert(errStr);
      }
    }
  }
}
