import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/authenticate/auth.service';
import { DataService } from '@app/services/shared/storage/data.service';
import { environment } from 'environments/environment';
@Component({
    selector: 'app-footer',
    templateUrl: 'footer.component.html',
    styleUrls: ['footer.component.scss']
})

export class FooterComponent {

    showEULAPolicy = false;
    showCookiePolicy = false;
    versionNumber;
    constructor(private dataService: DataService, private authService: AuthService, private router: Router) {


        if (this.router.url != '/general-exception') {
            this.showCookiePolicy = false;
            this.showEULAPolicy = false;
        }

        this.versionNumber = environment.AppVersion;
        this.acceptEulaPolicy();
        this.dimissCookiePolicy();
    }

    dimissCookiePolicy() {

            this.showCookiePolicy = false;
            const element1 = document.getElementById('removelay1');
            element1.classList.remove('z-ind');
            const element2 = document.getElementById('removelay2');
            element2.classList.remove('overlay');
            const element3 = document.getElementById('removelay3');
            element3.classList.remove('overlay');
    }

    acceptEulaPolicy() {
        this.showEULAPolicy = false;
    }

    decline() {
        this.authService.logout();
    }

    ngDoCheck(){
        if (this.dataService.closeFooter){
            if (this.showCookiePolicy == true && this.showEULAPolicy == true){
            const element1 = document.getElementById('removelay1');
            element1.classList.remove('z-ind');
            const element2 = document.getElementById('removelay2');
            element2.classList.remove('overlay');
            const element3 = document.getElementById('removelay3');
            element3.classList.remove('overlay');
            }
            this.showCookiePolicy = false;
            this.showEULAPolicy = false;
        }
    }
}
