import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { log } from 'console';

@Component({
  selector: 'app-create-new-pds',
  templateUrl: './create-new-pds.component.html',
  styleUrls: ['./create-new-pds.component.scss'],
})
export class CreateNewPDSComponent {
  isLoading = false;
  saveButtonDisable = false;
  pdsValue: any = null;
  pdsError = true;
  submit = false;
  isExits = false;
  pdslengthError = false;
  pdsIdAdded = false;

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public pdsData,
    private dialogRef: MatDialogRef<CreateNewPDSComponent>,
    public snackBar: MatSnackBar,
    public translate: TranslateService
  ) {}

  close() {
    this.dialogRef.close();
  }
  addPDS() {
    this.submit = true;
    if (!this.pdsError && !this.isExits) {
  //    this.pdsData.pdsIdList.unshift(this.pdsValue);
      this.pdsIdAdded = true;
      this.snackBar.open(this.translate.instant('pdsIdsuccess'), '', {
        duration: 4000,
        panelClass: ['status-snack-bar'],
      });
    
      this.dialogRef.close({value:this.pdsValue,flag:this.pdsIdAdded});
    }
  }
  valueChange(event) {
    const regex = /^[a-zA-Z0-9]+$/;
    this.checkForPdsLength(event);
    if (event.value === '') {
      this.pdsError = true;
      this.isExits = false;
    } else {
      if (!this.pdsError) {
        let isExits = false;
        this.pdsData.pdsIdList.forEach((el) => {
          if (el.trim().toLowerCase() === event.value.trim().toLowerCase()) {
            isExits = true;
          }
        });
        if (isExits) {
          this.pdsError = false;
          this.isExits = true;
        } else {
          this.pdsError = false;
          this.isExits = false;
        }
      }
    }
    if (event.value.match(regex) && !this.pdsError) {
      this.pdsError = false;
    } else {
      this.pdsError = true;
    }
  }

  checkForPdsLength(event) {
    if (event.value.length >= 2 && event.value.length <= 10) {
      this.pdslengthError = false;
      this.pdsError = false;
    } else {
      this.pdslengthError = true;
      this.pdsError = true;
    }
  }
}
