import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import _, { isNull } from "lodash";

import * as constants from "@app/services/shared/app-constants";
import { TableDescription } from "@app/services/models/misc/table-description";
import { WGRole } from "@app/services/models/roles";
import { DataService } from "@app/services/shared/storage/data.service";
import { DuplicateServiceOption } from "@app/services/models/misc/general";
import { Utility } from "@app/common/utility/utils"

const popupLabelList = [
  "modifier",
  "smcsQty",
  "jobLocation",
  "workApplication",
  "jobCondition",
  "cabType",
];

const smcsModifiers = [
  "SMCSComponent",
  "SMCSCabType",
  "SMCSJobCondition",
  "SMCSJobLocation",
  "SMCSModifier",
  "SMCSQuantity",
  "SMCSWorkApplication",
];

@Component({
  selector: "app-duplicate-service-options",
  templateUrl: "./duplicate-service-options.component.html",
  styleUrls: ["./duplicate-service-options.component.scss"],
})
export class DuplicateServiceOptionsComponent implements OnInit {
  @Input() duplicateServiceOptionsList = [];
  @Output() selectedServiceOptions = new EventEmitter();
  displayedColumns = [];
  isMobOrTab: boolean = false;
  popupData = {};
  tableColumns = [
    {
      label: "",
      value: "RadioHeader",
      keyPath: "",
      show: false,
      cssClass: "",
      width: "0.5rem",
      titleWidth: "3rem",
    },
    {
      label: "SMCS Codes",
      value: "smcsCodes",
      keyPath: "SMCSComponent",
      show: true,
      cssClass: "col-smcs-code",
      width: "6rem",
      marginLeft: "4.4rem",
      titleWidth: "8rem",
    },
    {
      label: "Job Codes",
      value: "jobCodes",
      keyPath: "JobCode",
      show: true,
      cssClass: "col-job-code",
      width: "6rem",
      titleWidth: "7.6rem"
    },
    {
      label: "SMCS Desc.",
      value: "smcsDesc",
      keyPath: "SMCSComponentDescription",
      show: true,
      cssClass: "col-smcs-desc",
      width: "8rem",
      titleWidth: "10.4rem",
    },
    {
      label: "Intervals",
      value: "intervals",
      keyPath: "interval",
      show: true,
      cssClass: "col-intervals",
      width: "7rem",
      titleWidth: "8.8rem",
    },
    {
      label: "Effectivity",
      value: "effectivity",
      keyPath: "effectivity",
      show: true,
      cssClass: "col-effectivity",
      width: "6rem",
      titleWidth: "8rem",
    },
    {
      label: "Labor Hours",
      value: "laborHours",
      keyPath: "LaborHours",
      show: true,
      cssClass: "col-laborHours",
      width: "6rem",
      titleWidth: "7.8rem",
    },
    {
      label: "Business Group Code",
      value: "businessGroupCode",
      keyPath: "BusinessGroupCode",
      show: true,
      cssClass: "col-businessGroupCode",
      width: "6rem",
      titleWidth: "8rem"
    }
  ];
  
  utiity = new Utility();

  constructor(
    private activeModal: NgbActiveModal,
    public translate: TranslateService,
    private dataService: DataService
  ) {
    
  }

  ngOnInit(): void {
    this.setDisplayedColumns();
  }

  setDisplayedColumns = (): void => {
    this.tableColumns.forEach((column) => {
      this.displayedColumns.push(column.value);
    });
  };

  getSmcsColData = (element) => {
    let smcsColData: string = "";

    smcsModifiers.forEach((currentModifier) => {
      if (!_.isEmpty(currentModifier)) {
        smcsColData += element[currentModifier] + " ";
      }
    });
    return smcsColData;
  };

  getPopupLabelList = () => {
    return popupLabelList;
  };

  isNull = (data): boolean => {
    return data == null;
  };

  getDuplicateSOTableDesc = () => {
    let tableDesc = TableDescription.ListDuplicateSO;
    return tableDesc;
  };

  isMobileOrTablet = () => {
    if (
      constants.mobOrTabCheckReg1.test(navigator.userAgent) ||
      constants.mobOrTabCheckReg2.test(navigator.userAgent.substring(0, 4))
    ) {
      this.isMobOrTab = true;
    }
  };

  showpopover = (currrentRowData, popupComponent) => {
    this.popupData = currrentRowData;
    if (!this.isMobOrTab) {
      popupComponent.open();
    } else {
      popupComponent.close();
    }
  };

  closePopup = (popupComponent) => {
    popupComponent.close();
  };

  getInterval = (element): string => {
    let firstIntervalData = element?.FirstInterval,
      nextIntervalData = element?.NextInterval;

    if (isNull(firstIntervalData) && isNull(nextIntervalData)) return "-";

    let intTranslated = this.translate.instant("INT");
    let hrsTranslated = this.translate.instant("HRS");
    firstIntervalData = isNull(firstIntervalData) ? "-" : firstIntervalData;
    nextIntervalData = isNull(nextIntervalData) ? "-" : nextIntervalData;

    return (
      intTranslated +
      " - " +
      firstIntervalData +
      ", " +
      nextIntervalData +
      " - " +
      hrsTranslated
    );
  };

  getEffectivity = (element): string => {
    let beginSerialNum = isNull(element.BeginSerialNumber)
      ? ""
      : element.BeginSerialNumber;
    let endSerialNum = isNull(element.EndSerialNumber)
      ? ""
      : element.EndSerialNumber;
    let serialNum = beginSerialNum + " - " + endSerialNum;
    return serialNum;
  };

  isIntervalCol = (column): boolean => {
    return column.value == "intervals";
  };

  isEffectivity = (column): boolean => {
    return column.value == "effectivity";
  };

  isRadioColumn = (column): boolean => {
    return column.value == "RadioHeader";
  };

  isSmcsColumn = (column): boolean => {
    return column.value == "smcsCodes";
  };
  isSmcsDescColumn = (column): boolean => {
    return column.value == "smcsDesc";
  };
  isBuisnessJobCodesColumn = (column): boolean => {
    return column.value == "businessGroupCode";
  };

  closeModal = (): void => {
    this.activeModal.close();
  };

  isChangedRow = (currentRowIndex, selectedRowIndex): boolean => {
    return currentRowIndex == selectedRowIndex;
  };

  selectedRow = (tableIndex, selectedRowIndex): void => {
    let tableLen = this.duplicateServiceOptionsList[tableIndex].length;
    for (let i = 0; i < tableLen; i++)
      if (this.isChangedRow(i, selectedRowIndex))
        this.duplicateServiceOptionsList[tableIndex][
          selectedRowIndex
        ].IsSelected = true;
      else this.duplicateServiceOptionsList[tableIndex][i].IsSelected = false;
  };

  isSelectedRow = (tableIndex, rowIndex) => {
    return this.duplicateServiceOptionsList[tableIndex][rowIndex]?.IsSelected;
  };

  saveServiceOptions = () => {
    this.selectedServiceOptions.emit(this.duplicateServiceOptionsList);
    this.closeModal();
  };

  getSmcsDesc(parameter: DuplicateServiceOption): string {
    return this.utiity.getSmcsDesc(parameter);
  }
}
