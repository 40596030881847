import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SubjectService } from '@app/services/data/subject.service';
import { EstimatePricingService } from '@app/services/estimate-pricing/estimate-pricing.service';
import { EstimateType } from '@app/services/models/misc/general';
import * as constants from '@app/services/shared/app-constants';
import { DataService } from '@app/services/shared/storage/data.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfirmAlertComponent } from '@app/components/confirm-alert/confirm-alert.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-product-selection-settings',
  templateUrl: './product-selection.component.html',
  styleUrls: ['./product-selection.component.scss'],
})
export class ProductSelectionComponent implements OnInit, OnDestroy {
  selectProductForm: FormGroup;
  productSelected = false;
  productData = null;
  submit = false;
  prefixFlag = true;
  isSubmitted = false;
  validForms = [];
  progressPercent;
  subscription: Subscription;
  saveButtonDisable = true;
  isLoading = false;
  formLoaders = {
    manufacturer: false,
    family: false,
    model: false,
    prefix: false,
    range: false,
    serial: false,
  };
  searchText = '';
  prefixError = false;
  manufacturerList = [];
  manufacturerLists: any;
  familyList = [];
  modelList: Array<any>;
  serialRangeList: Array<any>;
  serialPrefixList: Array<any>;
  isAPIError = false;
  isNotFound = false;
  equipmentType = EstimateType.Engine;
  private getManufacturerSubscribe: any;
  private getFamilySubscribe: any;
  private getModalSubscribe: any;
  private getSerialNumberSubscribe: any;
  searchProductError = false;
  productSelectionError = false;
  errorLists = [];
  showErrorList = false;
  showSearchError = false;
  placeHolderValue = this.translate.instant('lbl_search_prefix');
  selectedManufacturer: any = '';
  loadManufacturerError: any = false;
  loadManufacturer: any = false;
  failureList = [];
  loadManufacturerApiError: any = false;
  disableSearch: any = true;
  columns = [
    {
      label: 'Manufacturer',
      value: 'Manufacturer',
      keyPath: 'Manufacturer.Name',
      show: true,
      cssClass: 'col-Manufacturer',
      width: 150,
    },
    {
      label: 'family',
      value: 'family',
      keyPath: 'Family.Name',
      show: true,
      cssClass: 'col-family',
      width: 170,
    },
    {
      label: 'model',
      value: 'model',
      keyPath: 'Model.SalesModel',
      show: true,
      cssClass: 'col-model',
      width: 100,
    },
    {
      label: 'serialPrefix',
      value: 'serialPrefix',
      keyPath: 'PrefixAndSerialNumbers.Prefix',
      show: true,
      cssClass: 'col-serialPrefix',
      width: 120,
    },
    {
      label: 'serialRange',
      value: 'serialRange',
      keyPath: 'PrefixAndSerialNumbers.Ranges[0].Low',
      show: true,
      cssClass: 'col-serialRange',
      width: 150,
    },
  ];
  displayedColumns = [];
  selectedProductIndex = 0;
  selectedProduct;

  @Output() selectedProductData = new EventEmitter();
  selectMultipleProduct = false;
  multipleData: any;
  prefixArray: any;

  constructor(
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public estimatePricingService: EstimatePricingService,
    private dialogRef: MatDialogRef<ProductSelectionComponent>,
    private dataService: DataService,
    private ref: ChangeDetectorRef,
    private modalService: NgbModal,
    private subjectService: SubjectService,
    public snackBar: MatSnackBar
  ) {
    this.dataService.dataSource = 'AllDataDealerPreferred';
    this.dataService.dataOrigin = 'ServiceOptionsCollaborator';
    this.dataService.equipmentType = 'Engine';
    this.dataService.dealerCode = this.dataService.organizationCode;
    // TBD - Temporarily hard code the single dealer code as we dont have selection
    this.formLoaders.manufacturer = true;
    this.selectProductForm = this.formBuilder.group({
      manufacturer: [{ value: '', disabled: true }, [Validators.required]],
      family: [{ value: '', disabled: true }, [Validators.required]],
      model: [{ value: '', disabled: true }, [Validators.required]],
      serialPrefix: [{ value: '', disabled: true }, [Validators.required]],
      serialRange: [{ value: '', disabled: true }, [Validators.required]],
    });

    this.getManufacturerSubscribe = this.estimatePricingService
      .getManufacturerData()
      .subscribe(
        (data) => {
          if (data && data.IsSuccess) {
            data = data.Manufacturers;
            this.manufacturerList = data;
            this.selectProductForm.get('manufacturer').enable();
            this.selectProductForm.get('family').enable();
            this.selectProductForm.controls.manufacturer.setValue(
              this.manufacturerList[0]
            );
            this.formLoaders.manufacturer = false;
            this.formLoaders.family = true;
            this.isAPIError = false;
            this.getFamilyList();
          } else {
            this.productSelectionError = true;
            this.errorLists = [];
            this.errorLists = data.Failures;
            this.formLoaders.manufacturer = false;
            this.setScrollToError('errorDiv');
            this.handleErrorMessage(data);
          }
        },
        (err) => {
          this.isAPIError = true;
          this.formLoaders.manufacturer = false;
        }
      );
  }

  getFamilyList() {
    this.saveButtonDisable = true;
    this.getFamilySubscribe = this.estimatePricingService
      .getFamilyData(this.manufacturerList[0].Identifier, this.equipmentType)
      .subscribe(
        (familyData) => {
          if (familyData && familyData.IsSuccess) {
            familyData = familyData.FamilyInfo;
            this.familyList = familyData;
            this.isAPIError = false;
            setTimeout(() => {
              this.setFamilyValue();
            }, 500);
          } else {
            this.productSelectionError = true;
            this.errorLists = [];
            this.errorLists = familyData.Failures;
            this.formLoaders.family = false;
            this.setScrollToError('errorDiv');
            this.handleErrorMessage(familyData);
          }
        },
        (err) => {
          this.isAPIError = true;
          this.formLoaders.family = false;
        }
      );
  }

  get family() {
    return this.selectProductForm.get('family');
  }

  get model() {
    return this.selectProductForm.get('model');
  }

  get serialPrefix() {
    return this.selectProductForm.get('serialPrefix');
  }

  get serialRange() {
    return this.selectProductForm.get('serialRange');
  }

  valueChangeManufacturer(manufacturer) {
    this.saveButtonDisable = true;
    this.formLoaders.family = true;
    this.searchProductError = false;
    this.showErrorList = false;
    this.productSelectionError = false;
    this.searchText = null;
    this.model.setValue('', {
      onlySelf: false,
    });
    this.family.setValue('', {
      onlySelf: false,
    });
    this.serialPrefix.setValue('', {
      onlySelf: false,
    });
    this.serialRange.setValue('', {
      onlySelf: false,
    });

    this.modelList = [];
    this.familyList = [];
    this.serialRangeList = [];
    this.serialPrefixList = [];
    this.selectProductForm.get('model').disable();
    this.selectProductForm.get('serialRange').disable();
    this.selectProductForm.get('serialPrefix').disable();
    this.selectProductForm.get('family').enable();
    this.getFamilySubscribe = this.estimatePricingService
      .getFamilyData(manufacturer.Identifier, this.equipmentType)
      .subscribe(
        (data) => {
          if (data && data.IsSuccess) {
            data = data.FamilyInfo;
            this.familyList = data;
            this.isAPIError = false;
            setTimeout(() => {
              this.setFamilyValue();
            }, 500);
          } else {
            this.productSelectionError = true;
            this.errorLists = [];
            this.errorLists = data.Failures;
            this.formLoaders.family = false;
            this.setScrollToError('errorDiv');
            this.handleErrorMessage(data);
          }
        },
        (err) => {
          this.isAPIError = true;
          this.formLoaders.family = false;
        }
      );

    if (manufacturer.Name !== 'CATERPILLAR') {
      this.prefixFlag = false;
      this.progressPercent = 33.34;
      this.serialPrefix.clearValidators();
      this.serialRange.clearValidators();
      this.serialPrefix.setErrors(null);
      this.serialRange.setErrors(null);
    } else {
      this.prefixFlag = true;
      this.progressPercent = 20;
      this.serialPrefix.setValidators([Validators.required]);
      this.serialRange.setValidators([Validators.required]);
      this.serialPrefix.setErrors({ required: true });
      this.serialRange.setErrors({ required: true });
    }
  }

  setFamilyValue() {
    if (this.familyList.length === 1) {
      this.selectProductForm.controls.family.setValue(this.familyList[0]);
      this.valueChangeFamily(this.familyList[0]);
    }
    this.formLoaders.family = false;
  }

  valueChangeFamily(family) {
    this.saveButtonDisable = true;
    this.formLoaders.model = true;
    this.searchProductError = false;
    this.showErrorList = false;
    this.productSelectionError = false;
    this.searchText = null;
    this.model.setValue('', {
      onlySelf: false,
    });
    this.serialPrefix.setValue('', {
      onlySelf: false,
    });
    this.serialRange.setValue('', {
      onlySelf: false,
    });

    this.modelList = [];
    this.serialRangeList = [];
    this.serialPrefixList = [];
    this.selectProductForm.get('serialRange').disable();
    this.selectProductForm.get('serialPrefix').disable();
    this.selectProductForm.get('model').enable();
    if (family === '') {
      this.formLoaders.model = false;
      return;
    }
    this.getModalSubscribe = this.estimatePricingService
      .getModalData(
        this.selectProductForm.value.manufacturer.Identifier,
        family.Identifier,
        this.equipmentType
      )
      .subscribe(
        (data) => {
          if (data && data.IsSuccess) {
            data = data.ModelInfo;
            this.modelList = data;
            this.isAPIError = false;
            setTimeout(() => {
              if (this.modelList.length === 1) {
                this.selectProductForm.controls.model.setValue(
                  this.modelList[0].SalesModel
                );
                this.valueChangeModel(this.modelList[0].SalesModel);
              }
              this.formLoaders.model = false;
            }, 500);
          } else {
            this.productSelectionError = true;
            this.errorLists = [];
            this.errorLists = data.Failures;
            this.formLoaders.model = false;
            this.setScrollToError('errorDiv');
            this.handleErrorMessage(data);
          }
        },
        (err) => {
          this.isAPIError = true;
          this.formLoaders.model = false;
        }
      );
  }

  valueChangeModel(model) {
    this.saveButtonDisable = true;
    this.formLoaders.prefix = true;
    this.searchProductError = false;
    this.showErrorList = false;
    this.productSelectionError = false;
    this.searchText = null;
    this.serialPrefix.setValue('', {
      onlySelf: false,
    });
    this.serialRange.setValue('', {
      onlySelf: false,
    });

    this.serialRangeList = [];
    this.serialPrefixList = [];
    this.selectProductForm.get('serialRange').disable();
    this.selectProductForm.get('serialPrefix').enable();
    if (model === '') {
      this.formLoaders.prefix = false;
      return;
    }

    this.getSerialNumberSubscribe = this.estimatePricingService
      .getSerialNumberData(
        this.selectProductForm.value.manufacturer.Identifier,
        this.selectProductForm.value.family.Identifier,
        model,
        this.equipmentType
      )
      .subscribe(
        (data) => {
          if (data && data.IsSuccess) {
            data = data.SerialNumberInfo;
            this.serialPrefixList = data;
            this.isAPIError = false;
            this.prefixArray = [];
            this.serialPrefixList.forEach((elem) => {
              this.prefixArray.push(elem.Prefix);
            });
            this.prefixArray = new Set(this.prefixArray);
            this.prefixArray = Array.from(this.prefixArray);
            setTimeout(() => {
              if (this.prefixArray.length === 1) {
                this.selectProductForm.controls.serialPrefix.setValue(
                  this.serialPrefixList[0].Prefix
                );
                this.valueChangeserialPrefix(this.serialPrefixList[0].Prefix);
              }
              this.formLoaders.prefix = false;
            }, 500);
          } else {
            this.productSelectionError = true;
            this.errorLists = [];
            this.errorLists = data.Failures;
            this.formLoaders.prefix = false;
            this.setScrollToError('errorDiv');
            this.handleErrorMessage(data);
          }
        },
        (err) => {
          this.isAPIError = true;
          this.formLoaders.prefix = false;
        }
      );
  }
  valueChangeserialPrefix(prefix) {
    this.saveButtonDisable = true;
    this.searchProductError = false;
    this.showErrorList = false;
    this.productSelectionError = false;
    this.searchText = null;
    this.serialRange.setValue('', {
      onlySelf: false,
    });
    this.serialRangeList = [];
    this.formLoaders.range = true;
    this.selectProductForm.get('serialRange').enable();

    if (prefix === '') {
      this.formLoaders.range = false;
      return;
    }

    this.serialPrefixList.forEach((item) => {
      if (item.Prefix === prefix) {
        this.serialRangeList.push(item.Range.Low + '-' + item.Range.High);
      }
    });
    setTimeout(() => {
      this.formLoaders.range = false;
    }, 500);
  }
  valueChangeserialRange(e) {
    this.saveButtonDisable = false;
    this.searchProductError = false;
    this.showErrorList = false;
    this.productSelectionError = false;
    this.searchText = null;
  }
  submitForm() {
    let value = '';
    const formValidObj = {
      formName: 'Engine Performance Product Selection',
      formContent: 'User Selected Value',
      formContent2: null,
      formStatus: 'success',
    };

    Object.keys(this.selectProductForm.value).forEach((key, index, array) => {
      if (this.selectProductForm.value[key] !== null) {
       value = index < array.length - 1
          ?  value + 'User Entered Value | '
          :  value + 'User Entered Value';
      }
    });
    formValidObj.formContent2 = value;
    if (this.selectProductForm.valid && !this.selectProductForm.disabled) {
      const data = this.selectProductForm.value;
      data.rangeList = this.serialRangeList;
      data.flag = true;
      this.selectedProductData.emit(data);
      this.subjectService.setFormChangesStream({
        isFormSubmitted: true,
        formValue: formValidObj,
      });
    } else {
      this.submit = true;
    }
  }

  close() {
    this.dialogRef.close();
  }

  updateStatus() {
    const percent = this.validForms.length;
    this.progressPercent = percent * 33.34;
  }

  ngOnInit() {
    this.progressPercent = 20;
    this.loadManufacturers();
  }

  ngOnDestroy() {
    if (this.getManufacturerSubscribe) {
      this.getManufacturerSubscribe.unsubscribe();
    }
    if (this.getFamilySubscribe) {
      this.getFamilySubscribe.unsubscribe();
    }
    if (this.getModalSubscribe) {
      this.getModalSubscribe.unsubscribe();
    }
    if (this.getSerialNumberSubscribe) {
      this.getSerialNumberSubscribe.unsubscribe();
    }
  }

  equipmentTypeChanged() {
    this.valueChangeManufacturer(this.manufacturerList[0]);
  }

  search(event) {
    this.isLoading = true;
    this.isAPIError = false;
    this.isNotFound = false;
    this.searchProductError = false;
    this.showErrorList = false;
    this.productSelectionError = false;
    event.preventDefault();
    this.serialRangeList = [];
    this.resetProductSelection();

    const isCatEquipment: any = {
      isCatEquipment:
        this.selectedManufacturer.Identifier === 'AA' ? true : false,
      selectedManufacturerCode: this.selectedManufacturer.Identifier,
    };
    this.estimatePricingService
      .getProductSearchData(this.searchText, isCatEquipment)
      .subscribe(
        (data) => {
          if (data.IsSuccess) {
            if (data.ProductInfo && data.ProductInfo.length > 0) {
              if (data.ProductInfo.length > 1) {
                this.ref.detectChanges();
                data = data.ProductInfo;
                this.multipleData = data;
                this.isLoading = false;
                this.displayedColumns.push('radio');
                this.multipleData.forEach((item) => {
                  item.selectedSerialRange =
                    item.PrefixAndSerialNumbers.Range.Low +
                    '-' +
                    item.PrefixAndSerialNumbers.Range.High;
                });
                this.setDisplayedColumns();
                this.selectedProduct = this.multipleData[0];
                this.selectMultipleProduct = true;
              } else {
                data = data.ProductInfo[0];
                data.selectedSerialRange =
                  data.PrefixAndSerialNumbers.Range.Low +
                  '-' +
                  data.PrefixAndSerialNumbers.Range.High;
                this.productdata(data);
              }
            }
          } else {
            this.isLoading = false;
            this.searchProductError = true;
            this.errorLists = [];
            this.errorLists = data.Failures;
            this.handleErrorMessage(data);
          }
        },
        (err) => {
          this.checkForErrorStatus(err);
          this.isLoading = false;
        }
      );
  }

  checkForErrorStatus(err) {
    if (err.status === 500 || err.status === 503) {
      this.isNotFound = true;
    } else {
      this.isAPIError = true;
    }
  }

  productSearchChange() {
    this.isAPIError = false;
    this.isNotFound = false;
    this.searchProductError = false;
    this.showErrorList = false;
    this.productSelectionError = false;
    const val = this.searchText;
    if (val === '' || val === null) {
      this.prefixError = true;
    } else {
      if (val.match(constants.numberAplhabetsRegex)) {
        this.prefixError = false;
      } else {
        this.prefixError = true;
      }
    }
  }

  productdata(data) {
    if (
      data.Manufacturer === null &&
      data.Family === null &&
      data.Model === null &&
      data.PrefixAndSerialNumbers === null
    ) {
      this.isNotFound = true;
      this.isLoading = false;
      this.isAPIError = false;
      return;
    }
    const tempRange = [];
    tempRange.push(
      data.PrefixAndSerialNumbers.Range.Low +
        '-' +
        data.PrefixAndSerialNumbers.Range.High
    );
    const product = {
      manufacturer: {
        Name: data.Manufacturer.Name.toString(),
        Identifier: data.Manufacturer.Identifier.toString(),
      },
      family: { Identifier: data.Family.Identifier, Name: data.Family.Name },
      model: data.Model.SalesModel,
      serialPrefix: data.PrefixAndSerialNumbers.Prefix,
      serialRange:
        data.PrefixAndSerialNumbers.Range.Low +
        '-' +
        data.PrefixAndSerialNumbers.Range.High,
      rangeList: tempRange,
    };
    this.productData = product;
    this.serialRangeList = product.rangeList;
    this.productSelected = true;
    this.saveButtonDisable = false;
    this.progressPercent = 100;
    this.isAPIError = false;
    this.isLoading = false;
  }

  changeProduct() {
    this.searchText = null;
    this.productSelected = false;
    this.saveButtonDisable = true;
    this.searchProductError = false;
    this.showErrorList = false;
    this.productSelectionError = false;
    this.selectedProductIndex = 0;
    this.selectedProduct = '';
    this.multipleData = [];
    this.selectMultipleProduct = false;
    this.displayedColumns = [];
  }

  valueChange(event) {
    this.productData.serialRange = event.value;
    this.searchText = null;
  }

  selectProduct() {
    if (this.productSelected) {
      this.dialogRef.close(this.productData);
    } else {
      this.dialogRef.close(this.selectProductForm.value);
    }
  }
  setScrollToError(id) {
    setTimeout(() => {
      const elmnt = document.getElementById(id) as HTMLInputElement;
      if (elmnt) {
        elmnt.scrollIntoView();
      }
    }, 50);
  }
  showSearchErrorClick() {
    this.showErrorList = true;
    if (this.productSelectionError) {
      this.setScrollToError('errorSubDiv');
    }
  }
  resetProductSelection() {
    this.productSelectionError = false;
    this.model.setValue('', {
      onlySelf: false,
    });
    this.serialPrefix.setValue('', {
      onlySelf: false,
    });
    this.serialRange.setValue('', {
      onlySelf: false,
    });

    this.selectProductForm.controls.family.setValue('');
    this.modelList = [];
    this.serialRangeList = [];
    this.serialPrefixList = [];
    this.selectProductForm.get('serialRange').disable();
    this.selectProductForm.get('serialPrefix').disable();
    this.selectProductForm.get('model').disable();
  }
  setDisplayedColumns() {
    this.columns.forEach((column, index) => {
      this.displayedColumns.push(column.value);
    });
  }

  selectedRow(element, i) {
    this.selectedProductIndex = i;
    this.selectedProduct = element;
  }

  selectData() {
    this.selectMultipleProduct = false;
    this.productdata(this.selectedProduct);
  }

  changeManufacturerValue(manufacturerValue) {
    this.isAPIError = false;
    this.showSearchError = false;
    this.isNotFound = false;
    this.placeHolderValue = manufacturerValue.Identifier !== 'AA'
      ? this.translate.instant('lbl_search_number')
      : this.placeHolderValue = this.translate.instant('lbl_search_prefix');
    this.searchError();
  }

  searchError() {
    if (!this.searchText) {
      this.disableSearch = true;
    } else if (
      this.prefixError ||
      !this.selectedManufacturer ||
      this.loadManufacturerError
    ) {
      this.disableSearch = true;
    } else if (this.searchText) {
      if (
        (this.searchText.length === 3 || this.searchText.length === 8) &&
        this.selectedManufacturer
      ) {
        this.disableSearch = false;
      } else {
        this.disableSearch = true;
      }
    }
  }

  loadManufacturers() {
    this.selectedManufacturer = '';
    this.manufacturerLists = [];
    this.loadManufacturer = true;
    this.getManufacturerSubscribe = this.estimatePricingService
      .getManufacturerData()
      .subscribe(
        (data) => {
          if (data && data.IsSuccess) {
            this.loadManufacturer = false;
            data = data.Manufacturers;
            data = this.sortData(data);
            this.manufacturerLists = data;
            this.selectedManufacturer = this.manufacturerLists.filter(
              (element) => {
                if (element.Identifier === 'AA') {
                  return element;
                }
              }
            );
            this.selectedManufacturer = this.selectedManufacturer[0];
          } else {
            this.selectedManufacturer = '';
            this.failureList = [];
            this.failureList = data.Failures;
            this.loadManufacturer = false;
            this.loadManufacturerError = true;
            this.handleErrorMessage(data);
          }
          this.searchError();
        },
        (err) => {
          this.loadManufacturerApiError = true;
          this.loadManufacturer = false;
          this.selectedManufacturer = '';
        }
      );
  }

  checkSerial(): boolean {
    if (this.searchText != null) {
      if (this.searchText.length === 3 || this.searchText.length === 8) {
        if (this.prefixError) {
          return true;
        }
        return false;
      }
    }

    return true;
  }

  sortData(data) {
    if (data.length > 0) {
      const sortData = data.filter((element) => {
        return element.Identifier === 'AA';
      });
      data.sort(function(a, b) {
        const textA = a.Name.toUpperCase();
        const textB = b.Name.toUpperCase();
        let result;
        if(textA < textB){
          result = -1;
        }else if(textA > textB ){
          result = 1;
        }else{
          result = 0;
        }
        return result;
      });
      data = data.filter((ele) => ele.Identifier !== 'AA');
      data.unshift(sortData[0]);
      return data;
    }
  }

  handleErrorMessage = (response) => {
    let hasMultipleErrors = (response.FeedbackItems && response.FeedbackItems.length > 1) ? true: false;
    if(hasMultipleErrors) {
      let errorMessage = this.translate.instant('serverErr');
      this.showSnackBar(errorMessage);
    }
    else {
      if(response.FeedbackItems && response.FeedbackItems.length > 0){
        let errorMessage = response.FeedbackItems[0].Message;
        this.showErrorMsg(errorMessage);
      }
    }
  }

  showErrorMsg = (msg) => {
    let modalProps: {} = { 
      centered: true, 
      windowClass: 'confirm-modal', 
      backdropClass: 'confirm-modal-backdrop', 
      backdrop: 'static',
      keyboard: false
    }
    const modalRef = this.modalService.open(ConfirmAlertComponent, modalProps);
    modalRef.componentInstance.isMessage = true;
    modalRef.componentInstance.noCloseIcon = true;
    modalRef.componentInstance.timeFrame = true;
    modalRef.componentInstance.alertMessage = msg;
    modalRef.componentInstance.buttons = [
      {
        text: this.translate.instant('confirmOk'),
        cssClass: ['cat-btn-primary'],
        handler: (modal) => {
          modal.close(false);
        }
      }
    ];
  }
  
  showSnackBar = (message) => {
    this.snackBar.open(message, '', {
      duration: 4000,
      panelClass: ['status-snack-bar'],
    });
  }
}
