import { Component } from '@angular/core';
import { CcModalRef } from '@app/components/modal';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent  {

  constructor(public dialogRef: CcModalRef<PrivacyPolicyComponent>) { }


  onClose() {
    this.dialogRef.close();
  }

}
