import { Component, Input, OnInit } from '@angular/core';
import * as constants from '@app/services/shared/app-constants';

@Component({
  selector: 'app-hover',
  templateUrl: './hover.component.html',
  styleUrls: ['./hover.component.scss']
})
export class HoverComponent implements OnInit {

  @Input() item: any;
  @Input() isMobOrTab = false;


  ngOnInit() {
    this.isMobileOrTablet();
  }
  isMobileOrTablet() {
    // tslint:disable-next-line: max-line-length
    if (constants.mobOrTabCheckReg1.test(navigator.userAgent)
      // tslint:disable-next-line: max-line-length
      || constants.mobOrTabCheckReg2.test(navigator.userAgent.substr(0, 4))) {
      this.isMobOrTab = true;
    }
  }
}
