import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Utility } from "../utility/utils";

import { DataService } from "@app/services/shared/storage/data.service";

@Component({
  selector: "app-labor-selections-display",
  templateUrl: "./labor-selections-display.component.html",
  styleUrls: ["./labor-selections-display.component.scss"],
})
export class LaborSelectionsDisplayComponent implements OnInit {
  @Input() laborData;
  @Input() selectedData;
  @Input() hideHeader = false;
  @Output() selectedLaborEmitter = new EventEmitter();
  includeLaborData = true;
  selectedConfigurationIndex = 0;
  columns = [
    {
      label: "laborCode",
      value: "laborCode",
      keyPath: "LaborCode",
      show: true,
      cssClass: "col-application",
      width: 100,
    },
    {
      label: "storeCode",
      value: "storeCode",
      keyPath: "StoreCode",
      show: true,
      cssClass: "col-engineArrangement",
      width: 100,
    },
    {
      label: "costCenter",
      value: "costCenter",
      keyPath: "CostCenter",
      show: true,
      cssClass: "col-priceArrangement",
      width: 100,
    },
    {
      label: "custClass",
      value: "custClass",
      keyPath: "CustomerClass",
      show: true,
      cssClass: "col-fuelType",
      width: 120,
    },
    {
      label: "custNum",
      value: "custNum",
      keyPath: "CustomerNumber",
      show: true,
      cssClass: "col-pdsId",
      width: 150,
    },
  ];

  sell = {
    label: "sell",
    value: "sell",
    currency: "LaborRate.Sell.CurrencyCode",
    keyPath: "LaborRate.Sell.Amount",
    show: true,
    cssClass: "col-pdsId",
    width: 80,
  };
  cost = {
    label: "cost",
    value: "cost",
    currency: "LaborRate.Cost.CurrencyCode",
    keyPath: "LaborRate.Cost.Amount",
    show: true,
    cssClass: "col-pdsId",
    width: 80,
  };
  displayedColumns = [];
  selectedProductIndex = 0;
  selectedProduct;
  utility = new Utility();
  constructor(
    public activeModal: NgbActiveModal,
    public dataService: DataService
  ) {}

  ngOnInit(): void {
    this.displayedColumns.push("radio");
    if (this.hideHeader) {
      this.columns.push(this.sell);
      this.columns.push(this.cost);
    }
    this.columns = !this.dataService.isUserOrgIntermediate
      ? this.columns
      : this.utility.spliceArrayAtIndex(this.columns, [6]);
    this.setDisplayedColumns();
    if (this.selectedData) {
      if (this.laborData.indexOf(this.selectedData) > -1) {
        this.selectedConfigurationIndex = this.laborData.indexOf(
          this.selectedData
        );
        this.selectedProduct = this.selectedData;
      }
    } else {
      this.selectedProduct = this.laborData[0];
    }
  }

  close() {
    this.activeModal.close();
  }

  setDisplayedColumns() {
    this.columns.forEach((column, index) => {
      this.displayedColumns.push(column.value);
    });
  }

  selectedRow(event, i) {
    this.selectedConfigurationIndex = i;
    this.selectedProduct = event;
    if (this.hideHeader) {
      this.selectedLaborEmitter.emit({ value: this.selectedProduct });
    }
  }

  selectData(event) {
    this.activeModal.close(this.selectedProduct);
  }
}
