import { Component, Input, OnInit } from '@angular/core';
import { Column } from '@app/services/models/misc/general';
import { WorkgroupService } from '@app/services/workgroup/workgroup.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-select-engine-specification',
  templateUrl: './select-engine-specification.component.html',
  styleUrls: ['./select-engine-specification.component.scss']
})
export class SelectEngineSpecificationComponent {
  selectedEngineConfiguration: any;
  @Input() data: any;
  columns: Column[] = [
    {
      label: 'arrangementNumber', value: 'arrangementNumber',
      keyPath: 'Arrangement', show: true, cssClass: 'col-engineArrangement', width: 130, sortActive: false
    },
    {
      label: 'power_Rating', value: 'power_Rating',
      keyPath: 'PowerRatingKW', show: true, cssClass: 'col-powerSpeed', width: 130, sortActive: false
    },
    {
      label: 'price_List', value: 'price_List',
      keyPath: 'PriceList', show: true, cssClass: 'col-priceArrangement',
      width: 130, sortActive: false
    },
    {
      label: 'speed_Rating', value: 'speed_Rating',
      keyPath: 'SpeedRatingRPM', show: true, cssClass: 'col-powerSpeed', width: 130, sortActive: false
    },
    {
      label: 'testSpecNumber', value: 'testSpecNumber',
      keyPath: 'TestSpecification', show: true, cssClass: 'col-OT', width: 80, sortActive: false
    }
  ]
  isLoading: boolean;

  constructor(public ngbModal: NgbActiveModal,private workGroupService:WorkgroupService) { }

  ngOnInit(){
    this.selectedEngineConfiguration = this.data[0];
  }

  checkboxValueChanged(element: any) {
    this.selectedEngineConfiguration = element;
  }

  async save() {
    this.isLoading = true;
    let configurationData = await this.loadEngineApiCall();
    this.isLoading = false;
    this.selectedEngineConfiguration.engineData = configurationData;
    
    this.ngbModal.close(this.selectedEngineConfiguration);
  }

  close() {
    this.ngbModal.dismiss();
  }

  loadEngineApiCall(){
   return new Promise(res=>{
    this.workGroupService.loadEnginePerf(this.selectedEngineConfiguration.PriceList,this.selectedEngineConfiguration.Arrangement,this.selectedEngineConfiguration.SpeedRating).subscribe((data)=>{
      res(data);
     })
   }) 
  }

}

