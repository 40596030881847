import { Injectable } from '@angular/core';
import { RestService } from '@app/services/rest/rest.service';
import * as ServiceMappings from '@app/services/shared/app-constants';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class WorkgroupService {
  currencyCode: any;
  currencyCodeQE: any;
  currencyList: any;
  isRegionSettingsAvailable = false;
  isCatOrganization = false;
  unitOfMeasure;
  isQuickEstimatorSettings = false;
  isWorkgroupLoadOver = false;
  isBannerAlign = false;
  constructor(private restService: RestService) { }

  private laborDetails = new BehaviorSubject({});
  workgroupLaborDetails = this.laborDetails.asObservable();

  private fluidPricesData = new BehaviorSubject({});
  workgroupfluidPricesData = this.fluidPricesData.asObservable();

  private smcsDetails = new BehaviorSubject({});
  workgroupSMCSDetails = this.smcsDetails.asObservable();

  private travelDetails = new BehaviorSubject({});
  workgroupTravelDetails = this.travelDetails.asObservable();

  private generalDetails = new BehaviorSubject({});
  workgroupGeneralDetails = this.generalDetails.asObservable();

  private adjustmentDetails = new BehaviorSubject({});
  workgroupAdjustmentDetails = this.adjustmentDetails.asObservable();

  private perdiemDetails = new BehaviorSubject({});
  workgroupPerdiemDetails = this.perdiemDetails.asObservable();

  private currencyFactor = new BehaviorSubject({});
  currencyFactorDetails = this.currencyFactor.asObservable();

  private QElaborDetails = new BehaviorSubject({});
  workgroupQELaborDetails = this.QElaborDetails.asObservable();

  private QEsmcsDetails = new BehaviorSubject({});
  workgroupQESMCSDetails = this.QEsmcsDetails.asObservable();

  private QEgeneralDetails = new BehaviorSubject({});
  workgroupQEGeneralDetails = this.QEgeneralDetails.asObservable();

  private compleveladjustmentDetails = new BehaviorSubject({});
  workgroupcompleveladjustmentDetails = this.compleveladjustmentDetails.asObservable();

  private ppcleveladjustmentDetails = new BehaviorSubject({});
  PartsProductCodeAdjustmentConfiguration = this.ppcleveladjustmentDetails.asObservable();

  private sosAdjustmentDetails = new BehaviorSubject({});
  workgroupSOSAdjustmentDetails = this.sosAdjustmentDetails.asObservable();

  private workgroupSelection = new BehaviorSubject({});
  workgroupSelectionEnums = this.workgroupSelection.asObservable();

  private regionSetting = new BehaviorSubject({});
  regionSettingDetails = this.regionSetting.asObservable();

  private PartsPricingModifiersDetails = new BehaviorSubject({});
  partsPricingModifiers = this.PartsPricingModifiersDetails.asObservable();

  private miscPriceDetails = new BehaviorSubject({});
  wgMiscPrices = this.miscPriceDetails.asObservable();


  private workgroupSelectionValues = new BehaviorSubject({});
  wgSelectionValueSub = this.workgroupSelectionValues.asObservable();

  private workgroupSettingsDataForBasic = new BehaviorSubject({});
  wgSettingsBasicDataSub = this.workgroupSettingsDataForBasic.asObservable();

  private exclusionDetails = new BehaviorSubject({});
  workgroupExclusionDetails = this.exclusionDetails.asObservable();

  setLaborData(data: any) {
    this.laborDetails.next(data);
  }

  setFluidPricesData(data: any) {
    this.fluidPricesData.next(data);
  }

  setSMCSData(data: any) {
    this.smcsDetails.next(data);
  }

  setTravelDetails(data: any) {
    this.travelDetails.next(data);
  }

  setGeneralDetails(data: any) {
    this.generalDetails.next(data);
  }

  setPerdiemDetails(data: any) {
    this.perdiemDetails.next(data);
  }

  setAdjustmentDetails(data: any) {
    this.adjustmentDetails.next(data);
  }
  setComponentAdjustmentDetails(data: any) {
    this.compleveladjustmentDetails.next(data);
  }
  setPPCAdjustmentDetails(data: any) {
    this.ppcleveladjustmentDetails.next(data);
  }
  setSOSAdjustmentDetails(data: any) {
    this.sosAdjustmentDetails.next(data);
  }
  setCurrencyFactorTable(data: any) {
    this.currencyFactor.next(data);
  }

  setWorkgroupSelectionValue(data: any) {
    this.workgroupSelection.next(data);
  }
  getWorkgroupData(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.getWorkgroupDetails);
  }

  getGlobalWorkgroupData(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.getGlobalWorkgroupDetails);
  }
  saveWorkgroupData(data, isGlobal?): Observable<any> {
    if (isGlobal) {
      return this.restService.httpPost(ServiceMappings.saveWorkGroupGlobal, data);
    }
    else {
      return this.restService.httpPost(ServiceMappings.saveWorkGroup, data);
    }

  }

  copyWorkgroupData(wgId, target, data): Observable<any> {
    return this.restService.httpPost(ServiceMappings.copyWorkGroup + wgId + '&target=' + target, data);
  }

  savePartPrice(data): Observable<any> {
    return this.restService.httpPost(ServiceMappings.savePartPrice, data);
  }

  editPartPrice(data): Observable<any> {
    return this.restService.httpPost(ServiceMappings.editPartPrice, data);
  }

  deleteCustomer(uniqueid): Observable<any> {
    return this.restService.httpPost(ServiceMappings.deleteCustomer + uniqueid, {});
  }

  getPartsData(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.getParts);
  }

  getCustomerData(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.getCustomers);
  }

  addCustomerData(data): Observable<any> {
    return this.restService.httpPost(ServiceMappings.addCustomer, data);
  }

  editCustomerData(data): Observable<any> {
    return this.restService.httpPost(ServiceMappings.editCustomer, data);
  }

  mergeWorkgroupData(data, isGlobal?): Observable<any> {
    if (isGlobal) {
      return this.restService.httpPost(ServiceMappings.mergeWorkGroupGlobal, data);
    }
    else {
      return this.restService.httpPost(ServiceMappings.mergeWorkGroup, data);
    }

  }

  getWorkgroupSelectionValue(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.getWorkgroupSelectionValues);
  }
  getGeneralPreferences(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.getGeneralPreferences);
  }
  saveGeneralPreferences(data): Observable<any> {
    return this.restService.httpPost(ServiceMappings.saveGeneralPreferences, data);
  }
  mergeGeneralPreferences(data): Observable<any> {
    return this.restService.httpPost(ServiceMappings.mergeGeneralPreferences, data);
  }
  addcustomerList(file): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    return this.restService.httpPost(ServiceMappings.addCustomerlist,
      formData);
  }

  // Base currency from WG
  setCurrencyCode(data: any) {
    this.currencyCode = data;
  }
  getCurrencyCode() {
    return this.currencyCode;
  }
  setRegionSettingsDetails(data: any) {
    this.regionSetting.next(data);
  }
  getPricingFileInfo(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.getPartsPricingFile);
  }
  uploadPartsPicingFile(file, notes?, CurrencyCode?): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    if (notes == '' && !CurrencyCode) {
      return this.restService.httpPost(ServiceMappings.uploadPartsPicingFile,
        formData);
    } else if (notes == '' && CurrencyCode) {
      return this.restService.httpPost(ServiceMappings.uploadPartsPicingFile + '?CurrencyCode=' + CurrencyCode,
        formData);
    } else if (notes != '' && !CurrencyCode) {
      return this.restService.httpPost(ServiceMappings.uploadPartsPicingFile + '?notes=' + notes,
        formData);
    }
    else {
      return this.restService.httpPost(ServiceMappings.uploadPartsPicingFile + '?notes=' + notes + '&CurrencyCode=' + CurrencyCode,
        formData);
    }

  }


  setPartsPricingModifiers(data: any) {
    this.PartsPricingModifiersDetails.next(data);
  }
  clearCache(): Observable<any> {
    return this.restService.httpDelete(ServiceMappings.clearCacheData);
  }

  getCompareWgDetails() {
    return this.restService.httpGet(ServiceMappings.compareWG);
  }
  getQuickEstimatorSettings(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.quckEstimatorSettingsGet);
  }

  setQELaborData(data: any) {
    this.QElaborDetails.next(data);
  }

  setQESMCSData(data: any) {
    this.QEsmcsDetails.next(data);
  }

  setQEGeneralDetails(data: any) {
    this.QEgeneralDetails.next(data);
  }

  setQECurrencyCode(data: any) {
    this.currencyCodeQE = data;
  }
  getQECurrencyCode() {
    return this.currencyCodeQE;
  }

  saveQEWorkgroupData(data): Observable<any> {
    return this.restService.httpPost(ServiceMappings.saveQEWorkGroup, data);
  }

  mergeQEWorkgroupData(data): Observable<any> {
    return this.restService.httpPost(ServiceMappings.mergeQEWorkGroup, data);
  }
  
  workgroupPartsParicingInfo(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.workgroupPartsParicingInfo);
  }

  getQEParts(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.qePartsGet);
  }

  getQEPartsDetails(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.qePartsDetails);
  }

  saveQECrossRef(payload): Observable<any> {
    return this.restService.httpPost(ServiceMappings.qeCrossRef, payload);
  }

  saveQEParts(data): Observable<any> {
    return this.restService.httpPost(ServiceMappings.saveqeParts, data);
  }

  mergeQEParts(data): Observable<any> {
    return this.restService.httpPost(ServiceMappings.mergeqeParts, data);
  }

  getGlobalParts(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.getGlobalParts);
  }
  saveGlobalParts(data): Observable<any> {
    return this.restService.httpPost(ServiceMappings.saveGlobalParts, data);
  }
  mergeGlobalParts(data): Observable<any> {
    return this.restService.httpPost(ServiceMappings.mergeGlobalParts, data);
  }
  underCarriage(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.underCarriageLoad);
  }
  underCarriage1(data): Observable<any> {
    return this.restService.httpPost(ServiceMappings.underCarriageSave, data);
  }
  saveWorkgroupComparison(data) {

    return this.restService.httpPost(ServiceMappings.compareSave, data);
  }
  loadMachineVariability(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.machineVariablityLoad);
  }
  saveMachineVariability(data): Observable<any> {
    return this.restService.httpPost(ServiceMappings.machineCariabilitySave, data);
  }
  loadPartsPricingData(data): Observable<any> {
    return this.restService.httpGet(ServiceMappings.loadPartsPricingFile + '?partNumbers=' + data);
  }
  getLaborData(): any {
    return this.laborDetails.getValue();
  }
  setMiscData(data) {
    this.miscPriceDetails.next(data);
  }
  getMiscData(): any {
    return this.miscPriceDetails.getValue();
  }
  setWgSelectionValues(data) {
    this.workgroupSelectionValues.next(data);
  }

  setWgDataForBasic(data) {
    this.workgroupSettingsDataForBasic.next(data);
  }
  getAlerts(): Observable<any>{
    return this.restService.httpGet(ServiceMappings.getAlerts);
  }
  mergeAlerts(data): Observable<any> {
    return this.restService.httpPost(ServiceMappings.mergeAlerts, data);
  }
  saveAlerts(data): Observable<any> {
    return this.restService.httpPost(ServiceMappings.saveAlerts, data);
  }
  loadFre(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.loadFre);
  }
  loadFreService(data): Observable<any> {
    return this.restService.httpPost(ServiceMappings.LoadFreService, data);
  }

  saveFre(data): Observable<any>{
    return this.restService.httpPost(ServiceMappings.saveFre, data)
  }
  setExclusionDetails(data: any) {
    this.exclusionDetails.next(data);
  }
  getExclusionDetails(): any {
    return this.exclusionDetails.getValue();
  }
  getSMCSData() {
    return this.smcsDetails.getValue();
  }
  loadFullFre(data): Observable<any>{
    return this.restService.httpPost(ServiceMappings.loadFullFre, data)
  }
  
  getGlobalPartsRefresh(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.getGlobalPartsRefresh);
  }

  saveParts(payload): Observable<any> {
    return this.restService.httpPost(ServiceMappings.adCrossRef, payload);
  }
  getGlobalSMCSData(): Observable<any> {
     return this.restService.httpGet(ServiceMappings.getSMCS);
  }
  getGlobalSMCSCodeData(smcstype): Observable<any> {
    return this.restService.httpGet(ServiceMappings.getSMCSData+smcstype);
  }
  loadEnginePerf(priceArrangementNum,arrangementNum,speedRating,ratingType?,testSpec?): Observable<any> {
    if(!ratingType && !testSpec){
    return this.restService.httpGet(ServiceMappings.loadEnginePerf + '?priceArrangementNumber=' + priceArrangementNum
     + '&arrangementNumber=' + arrangementNum + '&speedRating=' + speedRating);
    }
    else if(!testSpec){
      return this.restService.httpGet(ServiceMappings.loadEnginePerf + '?priceArrangementNumber=' + priceArrangementNum
      + '&arrangementNumber=' + arrangementNum + '&speedRating=' + speedRating + '&ratingType=' + ratingType);
    }
    else if(!ratingType){
      return this.restService.httpGet(ServiceMappings.loadEnginePerf + '?priceArrangementNumber=' + priceArrangementNum
      + '&arrangementNumber=' + arrangementNum + '&speedRating=' + speedRating + '&testSpec=' + testSpec);
    }
    else{
      return this.restService.httpGet(ServiceMappings.loadEnginePerf + '?priceArrangementNumber=' + priceArrangementNum
      + '&arrangementNumber=' + arrangementNum + '&speedRating=' + speedRating + '&ratingType=' + ratingType + '&testSpec=' + testSpec);
    }
   }
   loadEnginePerfEdit(perfNum,sysMeasure?): Observable<any> {
    return this.restService.httpGet(ServiceMappings.loadEnginePerfEdit + '?performancenumber=' + perfNum + 'systemOfMeasurement=' + sysMeasure)
   }
  
 getSMCSLoadData(): Observable<any> {
  return this.restService.httpGet(ServiceMappings.smcsLoadData);
}
  mergeSMCSData(data): Observable<any> {
    return this.restService.httpPost(ServiceMappings.smcsMergeData, data);
  }
  saveSMCSData(data): Observable<any> {
  return this.restService.httpPost(ServiceMappings.smcsSaveeData, data);
  }
  globalPartsParicingInfo(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.globalPartsParicingInfo);
  }
}
