import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numToYear'
})
export class NumToYearPipe implements PipeTransform {

  transform(value, startYear, convertToActualYear, isStart?): any {
    if (isStart && convertToActualYear) {
      return startYear
    }
    if(convertToActualYear) {
      return startYear + value-1;
    } else {
      return value;
    }
  }

}
