import { Directive, forwardRef, Provider } from '@angular/core';
import { RequiredValidator, NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';

export const INPUT_FIELD_REQUIRED_VALIDATOR: Provider = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => CcInputFieldRequiredValidator),
  multi: true
};

@Directive({
  selector: `cc-input-field[required][formControlName],
  cc-input-field[required][formControl], cc-input-field[required][ngModel]`,
  providers: [INPUT_FIELD_REQUIRED_VALIDATOR]
})
export class CcInputFieldRequiredValidator extends RequiredValidator {
  validate(control: AbstractControl): ValidationErrors | null {
    if (this.required && control.value === null) {
      return {
        required: true
      };
    }
    return null;
  }
}
