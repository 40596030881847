import { CurrencyPipe, DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

import { DataService } from "@app/services/shared/storage/data.service";
import { UnitConversionPipe } from "@app/common/pipes/unit-conversion.pipe";
import { DefinitionTypes, PartsColumnConfig, PartsRowConfig } from "@app/services/models/misc/general";
import { WorkgroupService } from "@app/services/workgroup/workgroup.service";
import { Utility } from "@app/common/utility/utils";

@Pipe({
  name: "formatCell",
  pure: false,
})
export class FormatCellPipe implements PipeTransform {
  utiity = new Utility();
  constructor(
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private dataService: DataService,
    private workgroupService: WorkgroupService,
    private unitConversionPipe: UnitConversionPipe
  ) {}

  transform(value: any, key: any, row: any, unitConversion?, toUTC?: boolean) {
    if (value === undefined && !key.calculate) {
      if (key.keyPath.trim().indexOf("-") !== -1) {
        return this.splitKeys(key, row, value, "-");
      } else if (key.keyPath.trim().indexOf(" ") !== -1) {
        return this.splitKeys(key, row, value, " ");
      } else {
        return this.getValueFromObject(value, key, row);
      }
    } else if (key.currency) {
      if (key.isUnitConvertible) {
        const val = this.unitConversionPipe.transform(
          value,
          key.operation,
          key.factor,
          this.workgroupService.unitOfMeasure
        );
        return this.currencyPipe.transform(val, key.currency);
      }
      return this.currencyPipe.transform(value, key.currency);
    } else if (key.date) {
      return this.dateConverter(value, toUTC);
    } else if (key.calculate) {
      return row[key.calculationKeys[1]] - row[key.calculationKeys[0]];
    }
    if (key.defenitionTypecheck) {
      if (row.DefinitionType === DefinitionTypes.CCR) {
        return null;
      }
    }
    return value;
  }

  getValueFromObject(value, key, row, unit?) {
    const arr = key.keyPath.split(".");
    let obj = row;
    arr.forEach((element) => {
      obj = this.retrieveData(obj, element);
    });
    // TODO - Switch case implementation
    if (typeof obj !== "object") {
      if (key.currency) {
        if (key.isUnitConvertible) {
          const val = this.unitConversionPipe.transform(
            obj,
            key.operator,
            key.factor,
            this.workgroupService.unitOfMeasure
          );
          return this.currencyPipe.transform(val, this.getCurrency(key, row));
        }
        return this.currencyPipe.transform(obj, this.getCurrency(key, row));
      } else if (key.value == "sortOrder" || key.value == "code") {
        return row;
      } else if (key.value == "coreCreditFlag") {
        return this.utiity.formatCoreCreditflag(obj);
      }
      return obj;
    }
    return "-";
  }

  splitKeys(key, row, value, separator) {
    const arr = key.keyPath.split(separator);
    let desc = "";
    arr.forEach((element) => {
      if (desc === "") {
        desc = this.getValueFromObject(value, { keyPath: element.trim() }, row);
      } else {
        desc =
          desc +
          " " +
          separator +
          " " +
          this.getValueFromObject(value, { keyPath: element.trim() }, row);
      }
    });
    return desc;
  }

  dateConverter(date, toUTC?: boolean) {
    if (toUTC) {
      return this.datePipe.transform(
        date,
        this.dataService.userDateFormat,
        "UTC"
      );
    }
    return this.datePipe.transform(date, this.dataService.userDateFormat);
  }

  getCurrency(key, row) {
    const arr = key.currency.split(".");
    let obj = row;
    arr.forEach((element) => {
      obj = this.retrieveData(obj, element);
    });
    if (typeof obj !== "object") {
      return obj;
    }
    return "-";
  }

  getAmount(key, row) {
    const arr = key.keyPath.split(".");
    let obj = row;
    arr.forEach((element) => {
      obj = this.retrieveData(obj, element);
    });
    if (typeof obj !== "object") {
      return obj;
    }
    return "-";
  }

  retrieveData(obj, element) {
    if (obj) {
      obj = obj[element];
    }
    return obj;
  }

  /**
   * @ Accepts 2 arguments
   * @ 1st argument type as object of type PartsRowConfig
   * @ 2nd argument type as object of type PartsColumnConfig
   * @ method to check whether it is fluid or not
  */
  checkIsFluid(element:PartsRowConfig, column: PartsColumnConfig): boolean {
    if (this.transform(element[column.keyPath], column, element)) {
      return true;
    } 
    else {
      return false;
    } 
  }
}
