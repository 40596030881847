import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { MessageBar } from "@cat-digital-workspace/shared-ui-core/message";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { AccessRequestSubmissionComponent } from "@app/access-request-submission/access-request-submission.component";
import { UserService } from "@app/services/misc/user.service";
import { DataService } from "@app/services/shared/storage/data.service";
import { OrgRole, OrgRoleRename } from "@app/services/models/roles";
@Component({
  selector: "app-new-user-landing-page",
  templateUrl: "./new-user-landing-page.component.html",
  styleUrls: ["./new-user-landing-page.component.scss"],
})
export class NewUserLandingPageComponent implements OnInit {
  showBannerMsg = "welcome";
  userdetails: any = [];
  requestsubmitted = false;
  messageRef1: any;
  data: any = [];
  count = 0;
  orgIntermediate = OrgRole.Intermediate;
  orgIntermediateRename = OrgRoleRename.Intermediate;

  @Output() overWriteFactors = new EventEmitter();
  messageRef: any;
  protected closeMessage = new Subject<boolean>();
  modalRef: any;

  constructor(
    public dataService: DataService,
    public modalService: NgbModal,
    private translate: TranslateService,
    private messageBar: MessageBar,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.dataService.showLoading = true;
    this.getuserListRequest();
  }
  getuserListRequest() {
    this.userService.getUserListRequests().subscribe((response) => {
      if (this.dataService.orgRole?.toLowerCase() !== "none") {
        this.router.navigateByUrl("/home");
      } else if (response == null || response == "") {
        this.dataService.showLoading = false;
      } else {
        let i = response.length - 1;
        this.userdetails = response[i];
        if (
          response[i].PreferredOrganizationRole !== "" &&
          response[i].Notes !== "" &&
          response[i].Status?.toLowerCase() == "created"
        ) {
          this.requestsubmitted = true;
          const messageText = this.translate.instant("requestlevelSubmitted");
          const actionObj = [];
          this.showprogressBanner(messageText, actionObj);
          this.dataService.showLoading = false;
        } else {
          this.requestsubmitted = false;
          this.dataService.showLoading = false;
        }
      }
    });
  }
  ngDoCheck() {
    if (this.count !== 1) {
      if (this.data.FeedBack?.IsSuccess) {
        this.count = 1;
        this.dataService.showLoading = true;
        this.getuserListRequest();
      }
    }
  }
  requestAccess() {
    this.modalRef = this.modalService.open(AccessRequestSubmissionComponent, {
      centered: true,
      backdrop: "static",
      windowClass: "confirm-modal",
    });
    this.modalRef.componentInstance.data = this.data;
    this.modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      this.data = receivedEntry;
    });
  }
  showprogressBanner(messageText, actionObj) {
    document
      .getElementById("status-message-container")
      .classList.remove("d-none");
    const configObj: any = {
      hostType: "inline",
      hostSelectorId: "status-message-container",
      horizontalPosition: "center",
      verticalPosition: "top",
    };
    this.messageRef1 = this.messageBar.open(
      messageText,
      "info",
      actionObj,
      configObj,
      false
    );
    if (this.messageRef1) {
      this.messageRef1
        .afterClosed()
        .pipe(takeUntil(this.closeMessage))
        .subscribe((ref) => {
          document
            .getElementById("status-message-container")
            .classList.add("d-none");
          this.closeMessage.next(true);
          this.closeMessage.complete();
        });
    }
  }
}
