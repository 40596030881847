import { ChangeDetectorRef, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GloabalSettingsService } from '@app/services/global-settings/gloabal-settings.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ConfirmAlertComponent } from '@app/components/confirm-alert/confirm-alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-global-upload-engine-performance',
  templateUrl: './global-upload-engine-performance.component.html',
  styleUrls: ['./global-upload-engine-performance.component.scss']
})
export class GlobalUploadEnginePerformanceComponent {

  constructor(
      private ref: ChangeDetectorRef,
      private globalService: GloabalSettingsService,
      private snackBar: MatSnackBar,
      private translate: TranslateService,
      public activeModal: NgbActiveModal,
      private modalService: NgbModal
    ) { }
    showdragZone = true;
    files: File[] = [];
    errorMessage = '';
    alert = false;
    isLoading = false;
    saveButtonDisable = true;


  onSelect(event) {
    if (_.find(event.rejectedFiles, { reason: 'no_multiple' })) {
      this.alert = false;
      this.showdragZone = false;
    } else if (_.find(event.rejectedFiles, { reason: 'type' })) {
      this.showdragZone = false;
      this.alert = true;
    } else {
      if (event.addedFiles){
      this.files.push(...event.addedFiles);
      this.showdragZone = false;
      this.saveButtonDisable = false;
      }
    }

  }
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.showdragZone = true;
    this.alert = false;
    this.errorMessage = '';
    this.saveButtonDisable = true;
  }
  submitForm(event) {
    this.isLoading = true;
    this.globalService.uploadEngineperformance(this.files[0], 'Global').subscribe(data => {
      if(data.IsSuccess) {
        this.snackBar.open(this.translate.instant('engineperformanceUploadSuccess'), '', {
          duration: 4000,
          panelClass: ['status-snack-bar']
        });
        this.showdragZone = true;
        this.alert = false;
        this.saveButtonDisable = true;
        this.isLoading = false;
        this.errorMessage = '';
        this.files = [];
        this.activeModal.close(data);
      }
      else {
        this.handleErrorMessage(data);
      }
    }, err => {
      this.isLoading = false;
      this.errorMessage = err.error;
    });
  }

  close() {
    this.activeModal.close();
  }

  handleErrorMessage = (response) => {
    let hasMultipleErrors = (response.FeedbackItems.length > 1) ? true: false;
    if(hasMultipleErrors) {
      let errorMessage = this.translate.instant('serverErr');
      this.showSnackBar(errorMessage);
    }
    else {
      let errorMessage = response.FeedbackItems[0].Message;
      this.showErrorMsg(errorMessage);
    }
  }

  showErrorMsg(msg) {
    let modalProps: {} = { 
      centered: true, 
      windowClass: 'confirm-modal', 
      backdropClass: 'confirm-modal-backdrop', 
      backdrop: 'static',
      keyboard: false
    }
    const modalRef = this.modalService.open(ConfirmAlertComponent, modalProps);
    modalRef.componentInstance.isMessage = true;
    modalRef.componentInstance.noCloseIcon = true;
    modalRef.componentInstance.timeFrame = true;
    modalRef.componentInstance.alertMessage = msg;
    modalRef.componentInstance.buttons = [
      {
        text: this.translate.instant('confirmOk'),
        cssClass: ['cat-btn-primary'],
        handler: (modal) => {
          modal.close(false);
        }
      }
    ];
  }

  showSnackBar(message) {
    this.snackBar.open(message, '', {
      duration: 4000,
      panelClass: ['status-snack-bar'],
    });
  }

}
