import { CurrencyPipe, DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '@app/services/shared/storage/data.service';


@Pipe({
  name: 'unitConversion'
})
export class UnitConversionPipe implements PipeTransform {

  constructor(private currencyPipe: CurrencyPipe,
              private datePipe: DatePipe,
              private dataService: DataService) { }

  transform(value: any, operator: any, factor: any, unit?) {
    if (unit === 'US Standard') {
      switch (operator) {
        case 'multiply': return value * factor;
        case 'divide': return value / factor;
        default: return value;
      }
    } else {
      return value;
    }
  }

}
