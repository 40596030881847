import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { ConfirmAlertComponent } from "@app/components/confirm-alert/confirm-alert.component";
import { UserService } from "@app/services/misc/user.service";
import { DataService } from "@app/services/shared/storage/data.service";
import { OrgRole, OrgRoleRename } from "@app/services/models/roles";

import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { takeUntil } from "rxjs/operators";
import { MessageBar } from "@cat-digital-workspace/shared-ui-core/message";
import { Subject } from "rxjs";

@Component({
  selector: "app-access-request-submission",
  templateUrl: "./access-request-submission.component.html",
  styleUrls: ["./access-request-submission.component.scss"],
})
export class AccessRequestSubmissionComponent implements OnInit {
  selectedAccessVal = "";
  preferenceData = {
    PreferredOrganizationRole: "",
    Notes: "",
  };
  organizationRoles: any = [];
  requestData = true;
  requestedElevatedIn24Hours: any;
  isRequestElevated: any;
  messageRef1: any;
  @Input() public data;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  protected closeMessage = new Subject<boolean>();

  constructor(
    public activeModal: NgbActiveModal,
    public dataService: DataService,
    private userService: UserService,
    public translate: TranslateService,
    private modalService: NgbModal,
    private messageBar: MessageBar
  ) {}

  ngOnInit(): void {
    this.getlevels();

    this.organizationRoles = this.getlevels();
  }
  ngDoCheck() {
    if (
      this.preferenceData.PreferredOrganizationRole !== "" &&
      this.preferenceData.Notes !== ""
    ) {
      this.requestData = false;
    }
  }
  getlevels() {
    const orgs = [];
    this.userService.getUserAccessLevel().subscribe((data) => {
      if (data.KeyValues) {
        let roles = data.KeyValues.OrganizationAffiliationRoleEnum;
        this.data = this.dataService;
        for (const role of roles) {
          if (role.toLowerCase() !== "none") {
            orgs.push({
              label:
                role == OrgRole.Intermediate
                  ? OrgRoleRename.Intermediate
                  : role,
              value: role,
            });
          }
        }
        this.organizationRoles = orgs;
      }
    });
  }
  close() {
    this.activeModal.close(false);
  }
  selectedAccess(evt) {
    this.preferenceData.PreferredOrganizationRole =
      evt.currentSelection[0].value;
  }

  postRequestAccess() {
    this.getUserListRequests();
    this.userService
      .userRequestElevation(this.preferenceData)
      .subscribe((data) => {
        this.passEntry.emit(data);
        if (data.FeedBack?.IsSuccess) {
          this.isRequestElevated = true;
          this.openEmailNotificationConfirmPopup();
          this.preferenceData = {
            PreferredOrganizationRole: "",
            Notes: "",
          };
          this.activeModal.close(false);
        } else {
          const actionObj = [];
          const errorMsg = this.translate.instant("access_banner_text");
          this.openSuccess(errorMsg, actionObj);
        }
      });
  }
  openEmailNotificationConfirmPopup() {
    const modalRef = this.modalService.open(ConfirmAlertComponent, {
      centered: true,
      windowClass: "confirm-modal",
      backdropClass: "restore-backdrop",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.alertMessage = this.translate.instant(
      "request_notification"
    );
    modalRef.componentInstance.estimateRelease = true;
    modalRef.componentInstance.buttons = [
      {
        text: this.translate.instant("confirmOk"),
        cssClass: ["cat-btn-primary "],
        handler: (modal) => {
          modalRef.close();
          this.getUserListRequests();
        },
      },
    ];
  }

  openSuccess(messageText, actionObj) {
    document
      .getElementById("info-message-container-profile")
      .classList.remove("d-none");
    const configObj: any = {
      hostType: "inline",
      hostSelectorId: "info-message-container-profile",
      horizontalPosition: "center",
      verticalPosition: "top",
    };
    this.messageRef1 = this.messageBar.open(
      messageText,
      "info",
      actionObj,
      configObj,
      false
    );
    if (this.messageRef1) {
      this.messageRef1
        .afterClosed()
        .pipe(takeUntil(this.closeMessage))
        .subscribe((ref) => {
          document
            .getElementById("info-message-container-profile")
            .classList.add("d-none");
          this.closeMessage.next(true);
          this.closeMessage.complete();
        });
    }
  }

  getUserListRequests() {
    let lastQEResponse: boolean;
    this.userService.getUserListRequests().subscribe(
      (response) => {
        if (response.length > 0) {
          const totalReponseRequest = response.length;
          let createdRequestIndex: number;
          let isRequestExist = false;
          for (let i = totalReponseRequest - 1; i >= 0; i--) {
            if (response[i].Status == "Created") {
              createdRequestIndex = i;
              isRequestExist = true;
              break;
            }
          }
          if (isRequestExist) {
            const timeDiff = this.getTimeDiff(
              response[createdRequestIndex].LastUpdated,
              response[createdRequestIndex].RequestedTime
            );

            if (timeDiff > 86400000) {
              lastQEResponse = false;
              this.handleElevatedLogic(lastQEResponse);
            } else {
              lastQEResponse = true;
              this.handleElevatedLogic(lastQEResponse);
            }
          } else {
            lastQEResponse = false;
            this.handleElevatedLogic(lastQEResponse);
          }
        } else {
          lastQEResponse = false;
          this.handleElevatedLogic(lastQEResponse);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  handleElevatedLogic(isRequestPresent) {
    this.requestedElevatedIn24Hours = isRequestPresent;
    this.isRequestElevated = this.requestedElevatedIn24Hours;
    if (!isRequestPresent) {
      this.isRequestElevated = false;
    } else {
      this.isRequestElevated = true;
    }
  }

  getTimeDiff(lastUpdated, requestedTime) {
    const d = new Date();
    let requestTime = "";
    let requestedYear: number;
    let requestedMonth: number;
    let requestedDate: number;
    let requestedHour: number;
    let requestedMinute: number;
    let requestedSecond: number;

    if (lastUpdated) {
      requestTime = lastUpdated;
    } else {
      requestTime = requestedTime;
    }

    requestedYear = Number.parseInt(requestTime.substring(0, 4));
    requestedMonth = Number.parseInt(requestTime.substring(5, 7));
    requestedDate = Number.parseInt(requestTime.substring(8, 10));
    requestedHour = Number.parseInt(requestTime.substring(11, 13));
    requestedMinute = Number.parseInt(requestTime.substring(14, 16));
    requestedSecond = Number.parseInt(requestTime.substring(17, 19));
    const requestDateTime: any = new Date(
      requestedYear,
      requestedMonth,
      requestedDate,
      requestedHour,
      requestedMinute,
      requestedSecond
    );
    const currentDateTime: any = new Date(
      d.getFullYear(),
      d.getMonth() + 1,
      d.getDate(),
      d.getHours(),
      d.getMinutes(),
      d.getSeconds()
    );
    return currentDateTime - requestDateTime;
  }
}
