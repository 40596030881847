import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableComponent } from '@app/common/data-table/data-table.component';
import { ConfirmAlertComponent } from '@app/components/confirm-alert/confirm-alert.component';
import { SubjectService } from '@app/services/data/subject.service';
import { EstimatePricingService } from '@app/services/estimate-pricing/estimate-pricing.service';
import { DataService } from '@app/services/shared/storage/data.service';
import { EnginePerformanceService } from '@app/services/workgroup/engineperformance.service';
import { WorkgroupService } from '@app/services/workgroup/workgroup.service';
import { AddEditExistingPDSComponent } from '@app/settings/engine-performance/add-edit-engine-performance/add-edit-existing-pds/add-edit-existing-pds.component';
import { AddEditHoursAdjstmentComponent } from '@app/settings/engine-performance/add-edit-engine-performance/add-edit-hours-adjstment/add-edit-hours-adjstment.component';
import { CreateNewPDSComponent } from '@app/settings/engine-performance/add-edit-engine-performance/create-new-pds/create-new-pds.component';
import { ProductSelectionComponent } from '@app/settings/engine-performance/add-edit-engine-performance/product-selection/product-selection.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-edit-engine-performance',
  templateUrl: './add-edit-engine-performance.component.html',
  styleUrls: ['./add-edit-engine-performance.component.scss']
})
export class AddEditEnginePerformanceComponent implements OnInit, OnDestroy {
  tmiData: any;
  valueAdded: boolean;
  selectedData: any;
  duplicateOnTmi = false;
  @Input() pdsIdListData1;
  errorMessage = '';
  saveDisable = false;
  pdsIdListData: any[];
;

  constructor( public dialogRef: MatDialogRef<AddEditEnginePerformanceComponent>, @Inject(MAT_DIALOG_DATA) public permData,
               public fb: FormBuilder, public ref: ChangeDetectorRef, public subjectService: SubjectService, public dialog: MatDialog,
               public workgroupService: WorkgroupService, public translate: TranslateService,
               public estimatePricingService: EstimatePricingService, public snackBar: MatSnackBar,
               public enginePerformanceService: EnginePerformanceService, public dataService: DataService,
               public modalService: NgbModal) {
                this.dataService.dataSource = 'AllDataDealerPreferred';
                this.dataService.dataOrigin = 'ServiceOptionsCollaborator';
                this.dataService.equipmentType = 'Engine';
                this.dataService.dealerCode = this.dataService.organizationCode;

               }
  @ViewChild(DataTableComponent)
  private dataTableComponent: DataTableComponent;
  private enumSubscribe: any;
  private getManufacturerSubscribe: any;
  private getFamilySubscribe: any;
  private getModalSubscribe: any;
  private getSerialNumberSubscribe: any;
  permcDataForm: FormGroup;
  isEdit: any;
  isCopy: any;
  rowData: any;
  rowDataBackup: any;
  fullData: any;
  dataHeader: any;
  tempPds = [];
  engineGroupList = [
    { id: 'D', value: 'Diesel'},
    { id: 'G', value: 'Gas'}
  ];
  engineGroupType: any;
  additionalData = {
    modifedcode0: '',
    modifedcode1: '',
    modifedcode2: ''
  };
  formLoaders = {
    manufacturer: false,
    family: false,
    model: false,
    prefix: false,
    range: false,
    serial: false
  };
  formErrors = {
    manufacturer: false,
    family: false,
    model: false,
    prefix: false,
    range: false,
    serial: false
  };
  modifedcode0ValidD = true;
  modifedcode1ValidD = true;
  modifedcode2ValidD = true;
  modifedcode0ValidG = true;
  modifedcode1ValidG = true;
  modifedcode2ValidG = true;
  isLoading = false;
  isAPIError = false;
  saveButtonDisable = false;
  engineType = [];
  manufacturerList = [];
  familyList = [];
  modelList = [];
  serialPrefixList = [];
  engineTypeobj = {};
  engineTypevalues = [];
  engineModifierEnum = [];
  hoursAdjustmentDetails: any;
  existingPDSDetails: any;
  prefernceNameHoursAdjustment = 'hoursAdjustmentDetails';
  prefernceNameExistingPDS = 'existingPDS';
  columnsExistingPDS: any;
  pdsIdList = [];
  submit = false;
  networkError = false;
  networkErrorMessage: any;
  showFooterButtons = true;
  duplicateEngineperformance = false;
  bsocError = false;
  speedError = false;
  powerError =  false;
  oilError =  false;
  formError = false;
  fuelmassError = false;
  oilChgIntvlError = false;
  sumpsizeError = false;
  selectedPdsid: any;
  fuelTypeList = [];
  IsEditablePDS = false;
  engineConsumptionTypeEnum: any;
  pdsIdFullData: any;
  placeHolderValue = this.translate.instant('lbl_search_prefix');
  selectedManufacturer: any = '';
  loadManufacturerError: any = false;
  loadManufacturer: any = false;
  failureList = [];
  manufacturerLists: any;
  loadManufacturerApiError: any = false;
  searchText = '';
  disableSearch: any = true;
  prefixError = false;
  showSearchError = false;
  isNotFound = false;
  myDataService: any;
  columnsHoursAdjustment = [
      {
      label: 'speedWithUnit', value: 'speedWithUnit', keyPath: 'EngineSpeed',
      show: true, cssClass: 'col-speedWithUnit', width: 100, searchEnabled: true
      },
      {
        label: 'avgLoadFactor', value: 'avgLoadFactor', keyPath: 'AverageLoadFactor',
        show: true, cssClass: 'col-avgLoadFactor', width: 100, searchEnabled: true
      },
      {
        label: 'adjFactor', value: 'adjFactor', keyPath: 'AdjustmentFactor',
        show: true, cssClass: 'col-adjFactor', width: 100, searchEnabled: true
      }
  ];
  columnsExistingPDSDiesl = [
    {
    label: 'engineSpeed', value: 'engineSpeed', keyPath: 'EngineSpeed',
    show: true, cssClass: 'col-engineSpeed', width: 150, searchEnabled: true
    },
    {
      label: 'engineBKW', value: 'engineBKW', keyPath: 'EngineBrakeKiloWatt',
      show: true, cssClass: 'col-engineBKW', width: 150, searchEnabled: true
    },
    {
      label: 'generatorEffincy', value: 'generatorEffincy', keyPath: 'GeneratorEfficiency',
      show: true, cssClass: 'col-generatorEffincy', width: 150, searchEnabled: true
    },
    {
      label: 'bsfcWithUnitDesl', value: 'bsfcWithUnitDesl', keyPath: 'BrakeSpecificFuelConsumption',
      show: true, cssClass: 'col-bsfcWithUnitDesl', width: 150, searchEnabled: true
      },
      {
        label: 'fuelRateDesl', value: 'fuelRateDesl', keyPath: 'FuelRate',
        show: true, cssClass: 'col-fuelRateDesl', width: 150, searchEnabled: true
      },
      {
        label: 'engineEKW', value: 'engineEKW', keyPath: 'EngineElectricalKiloWatt',
        show: true, cssClass: 'col-engineEKW', width: 150, searchEnabled: true
      }
];
columnsExistingPDSGas = [
  {
  label: 'engineSpeed', value: 'engineSpeed', keyPath: 'EngineSpeed',
  show: true, cssClass: 'col-engineSpeed', width: 150, searchEnabled: true
  },
  {
    label: 'engineBKW', value: 'engineBKW', keyPath: 'EngineBrakeKiloWatt',
    show: true, cssClass: 'col-engineBKW', width: 150, searchEnabled: true
  },
  {
    label: 'generatorEffincy', value: 'generatorEffincy', keyPath: 'GeneratorEfficiency',
    show: true, cssClass: 'col-generatorEffincy', width: 150, searchEnabled: true
  },
  {
    label: 'bsfcWithUnitGas', value: 'bsfcWithUnitGas', keyPath: 'BrakeSpecificFuelConsumption',
    show: true, cssClass: 'col-bsfcWithUnitGas', width: 150, searchEnabled: true
    },
    {
      label: 'fuelRateGas', value: 'fuelRateGas', keyPath: 'FuelRate',
      show: true, cssClass: 'col-fuelRateGas', width: 150, searchEnabled: true
    },
    {
      label: 'engineEKW', value: 'engineEKW', keyPath: 'EngineElectricalKiloWatt',
      show: true, cssClass: 'col-engineEKW', width: 150, searchEnabled: true
    }
];
isconsumptionError = false;
isAddtnDataError = false;
public compFilterCtrl: FormControl = new FormControl();
protected onDestroy = new Subject<void>();
parameterType: any;
isFromGlobal = false;
searchedManufacturer: any;
activeDrawerAddNew: NgbModalRef;
productDetailsActiveModal: NgbModalRef;
prefixAPISubscribe: Subscription;
2;
pdsIdList1 = [];
tmiTableData: any;
  ngOnInit() {
   this.enumCall();
   this.loadManufacturers();
   this.fluidPriceCall();
   this.engineTypevalues = [this.translate.instant('EPG'), this.translate.instant('IND'),
    this.translate.instant('MA'), this.translate.instant('MP'), this.translate.instant('PET')];
   if (this.engineType.length > 0) {
      // tslint:disable-next-line: forin
      for (const i in this.engineType) {
        this.engineTypeobj[this.engineType[i]] = this.engineTypevalues[i];
      }
    }
   this.isEdit = this.permData.isEdit;
   this.isCopy = this.permData.isCopy;
   this.rowData = this.permData.currentData;
   this.rowDataBackup = _.clone(this.rowData);
   this.fullData = this.permData.fullData;
   this.parameterType = this.permData.parameterType;
   this.isFromGlobal = this.permData.isFromGlobal;

   this.dataHeader = this.rowData.DataHeader;
    // for speeding up pds ddl
   this.pdsIdFullData = this.permData.pdsIdList;
   this.ppidCheck();

   this.hoursAdjustmentDetails = this.rowData.HoursAdjustmentDetails ? this.rowData.HoursAdjustmentDetails : [];
   this.existingPDSDetails = this.rowData.EnginePerformances ? this.rowData.EnginePerformances : [];
   this.permcDataForm = this.fb.group({
      pdsId: [null, [Validators.required]],
      engineGroup: [null, [Validators.required]],
      engineType: [null, [Validators.required]],
      speed: [null, [Validators.required, Validators.pattern(/^[0-9]*(\.[0-9]+)?$/),Validators.min(10), Validators.max(10000)]],
      power: [null, [Validators.required, Validators.pattern(/^[0-9]*(\.[0-9]+)?$/),Validators.min(10), Validators.max(10000)]],
      bsoc: [null, [Validators.required, Validators.pattern(/^[0-9]*(\.[0-9]+)?$/)]],
      priceAggnt: [null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9]+$/)]],
      aggmtNo: [null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9]+$/)]],
      fuelType: [null, [Validators.required]],
      fuelMass: [null, [Validators.required, Validators.pattern(/^[0-9]*(\.[0-9]+)?$/)]],
      sumpSise: [null, [Validators.required, Validators.pattern(/^[0-9]*(\.[0-9]+)?$/)]],
      oilChgIntvl: [null, [Validators.required, Validators.pattern(/^[0-9]*(\.[0-9]+)?$/)]],
      oilMass: [null, [Validators.required, Validators.pattern(/^[0-9]*(\.[0-9]+)?$/), Validators.min(10), Validators.max(10000)] ],
      lowHeatValue: [null, [Validators.required, Validators.pattern(/^[0-9]*(\.[0-9]+)?$/)]],
      ot: [null],
      model: [null , [Validators.required]],
      serialPrefix: [null , [Validators.required]],
      manufacturer: [null, [Validators.required]]
    });
   if (this.isEdit || this.isCopy) {
    this.editCopyCall();
    }
   this.compFilterCtrl.valueChanges
    .pipe(takeUntil(this.onDestroy))
    .subscribe(() => {
      this.filterMyOptions();
    });
   const element4 = document.getElementById('bodyscroll');
   element4.style.overflow = 'hidden';
   this.subjectService.tmiData$.subscribe(a=>{
    this.tmiData = a;
   })
   if(this.permcDataForm.controls.pdsId.value){
    this.pdsIdList1.push(this.permcDataForm.controls.pdsId.value);
    this.valueAdded = true;
    this.tmiTableData = [this.permData.currentData];
   }
  }
  ngOnDestroy() {
    const element4 = document.getElementById('bodyscroll');
    element4.style.overflow = 'unset';
    if(this.activeDrawerAddNew) {
      this.activeDrawerAddNew.close();
    }
    if(this.productDetailsActiveModal) {
      this.productDetailsActiveModal.close();
    }
    if(this.prefixAPISubscribe) {
      this.prefixAPISubscribe.unsubscribe();
    }

  }
  setValue() {
    this.permcDataForm.controls.pdsId.setValue(this.dataHeader.PdsID);
    this.permcDataForm.controls.engineGroup.setValue(this.dataHeader.EngineGroupID);
    this.permcDataForm.controls.engineType.setValue(this.dataHeader.EngineTypeID);
    this.permcDataForm.controls.model.setValue(this.dataHeader.Model);
    this.permcDataForm.controls.manufacturer.setValue(this.dataHeader.manufacturer);
    this.permcDataForm.controls.serialPrefix.setValue(this.dataHeader.SerialNumberPrefix);
    this.permcDataForm.controls.speed.setValue(this.dataHeader.Speed);
    this.permcDataForm.controls.power.setValue(this.dataHeader.Power);
    this.permcDataForm.controls.bsoc.setValue(this.dataHeader.BrakeSpecificOilConsumption);
    this.permcDataForm.controls.priceAggnt.setValue(this.dataHeader.PriceArrangementNumber);
    this.permcDataForm.controls.aggmtNo.setValue(this.dataHeader.EngineArrangementNumber);
    this.permcDataForm.controls.fuelMass.setValue(this.dataHeader.FuelMass);
    this.permcDataForm.controls.sumpSise.setValue(this.dataHeader.SumpSize);
    this.permcDataForm.controls.oilChgIntvl.setValue(this.dataHeader.OilChangeInterval);
    this.permcDataForm.controls.oilMass.setValue(this.dataHeader.OilMass);
    this.permcDataForm.controls.lowHeatValue.setValue(this.dataHeader.LowHeatValue);
    this.permcDataForm.controls.ot.setValue(this.dataHeader.OTNumber);
  }
  filterMyOptions() {
    const search = this.compFilterCtrl.value.toLowerCase();
    const res = [];
    if (!search) {
     this.pdsIdList  = this.permData.pdsIdList;
    } else {
      this.pdsIdList  = this.permData.pdsIdList;
      let componentsTemp = [];
      let componentsTempNew = [];
      componentsTemp = this.pdsIdList;
      componentsTempNew = componentsTemp.filter((cws) => (cws.toLowerCase().includes(search)) );
    this.pdsIdList = componentsTempNew;
     }

  }
  close() {
    if (!this.isEdit) {
      const element = document.getElementById('common-data-table');
      if (element) {
        element.style.display = 'table';
      }
      if (this.hoursAdjustmentDetails.length === 0) {
        this.hoursAdjustmentDetails = [{test: 'test'}];
      }
      if (this.existingPDSDetails.length === 0) {
        this.existingPDSDetails = [{test: 'test'}];
      }
    } else if (this.isEdit) {
      const element = document.getElementById('common-data-table');
      if (element) {
        element.style.display = 'table';
      }
    }
    this.dialogRef.close();
  }

  valueChange(event, field) {
    this.isconsumptionError = false;
    this.isAddtnDataError = false;
    if (field === 'bsoc') {
      this.bsocCall(event);
    } else if (field === 'speed') {
      this.speedCall(event);
    } else if (field === 'oil') {
      this. oilCall(event);
    } else if (field === 'power') {
    this.powerCall(event);
    } else  if (field === 'fuelMass') {
    this.fuelMassCall(event);
    }  else  if (field === 'oilChgIntvl') {
      this.oilChangeCall(event);
    } else if (field === 'sumpSize') {
   this.sumpCall(event);
    }
  }

  engineGroupChange(event) {
    this.isconsumptionError = false;
    this.isAddtnDataError = false;
    this.engineGroupType = event.value;
    this.modifedcode0ValidD = true;
    this.modifedcode1ValidD = true;
    this.modifedcode2ValidD = true;
    this.modifedcode0ValidG = true;
    this.modifedcode1ValidG = true;
    this.modifedcode2ValidG = true;
    this.additionalData.modifedcode0 = '0';
    this.additionalData.modifedcode1 = '0';
    this.additionalData.modifedcode2 = '0';
    this.hoursAdjustmentDetails = [];
  }

  editHoursAdjustment(event) {
    this.addNewHoursAdjustment(true, event.row);
  }
  deleteHoursAdjustment(index: any) {
      const modalRef = this.modalService.open(ConfirmAlertComponent, { centered: true, windowClass: 'confirm-modal',
       backdropClass: 'confirm-modal-engine', backdrop: 'static' });
      modalRef.componentInstance.alertMessage = this.translate.instant('deleteRecordConfirmationMsg');
      modalRef.componentInstance.delete = true;
      modalRef.componentInstance.buttons = [

        {
          text: this.translate.instant('confirmTextNo'),
          cssClass: ['btn btn-outline-secondary btn-width  btn-mr'],
          handler: (modal) => {
            modal.close(false);
          }
        },
        {
          text: this.translate.instant('confirmTextYes'),
          cssClass: ['btn btn-warning btn-width '],
          handler: (modal) => {
            modal.close(false);
            this.snackBar.open(this.translate.instant('houradjustmentdeleteSuccess'), '', {
              duration: 4000,
              panelClass: ['status-snack-bar']
            });
            this.hoursAdjustmentDetails.splice(this.hoursAdjustmentDetails.indexOf(index.row), 1);
            this.hoursAdjustmentDetails = _.clone(this.hoursAdjustmentDetails);
            this.dataTableComponent.updateTableData();
          }
        }
      ];
    }
  addNewPdS() {
    const obj = {
      isEdit: true,
      pdsIdList: JSON.parse(JSON.stringify(this.pdsIdFullData))
    };
    const dialogRef = this.dialog.open(CreateNewPDSComponent, {
      panelClass: 'custom-mat-dialog',
      data: obj,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.tempPds.push(result.value);
        this.pdsIdList.unshift(result.value);
       
        this.selectedPdsid = result;
        this.permcDataForm.controls.pdsId.setValue(result.value);
        this.IsEditablePDS = true;
      }
    });
  }
  editExistingPDS(event) {
    this.addExistingPDS(true, event.row);
  }
  deleteExistingPDS(index: any) {
    const modalRef = this.modalService.open(ConfirmAlertComponent, { centered: true, windowClass: 'confirm-modal',
    backdropClass: 'confirm-modal-engine', backdrop: 'static' });
    modalRef.componentInstance.alertMessage = this.translate.instant('deleteRecordConfirmationMsg');
    modalRef.componentInstance.delete = true;
    modalRef.componentInstance.buttons = [

      {
        text: this.translate.instant('lblcancel'),
        cssClass: ['btn btn-outline-secondary btn-width btn-mr'],
        handler: (modal) => {
          modal.close(false);
        }
      },
      {
        text: this.translate.instant('Delete'),
        cssClass: ['btn btn-warning btn-width '],
        handler: (modal) => {
          modal.close(false);
          this.snackBar.open(this.translate.instant('existingpdsdeleteSuccess'), '', {
            duration: 4000,
            panelClass: ['status-snack-bar']
          });
          this.existingPDSDetails.splice(this.existingPDSDetails.indexOf(index.row), 1);
          this.existingPDSDetails = _.clone(this.existingPDSDetails);
          this.dataTableComponent.updateTableData();
        }
      }
    ];
  }
  addNewHoursAdjustment(isEdit, rowData?) {
    const obj = {
      fulldata: this.hoursAdjustmentDetails,
      isEdit: false,
      rowData: '',
      model: this.permcDataForm.controls.model.value,
      index: -1
    };
    if (isEdit) {
      obj.rowData = rowData;
      obj.isEdit = true;
      obj.index = this.hoursAdjustmentDetails.indexOf(rowData);
    }
    const dialogRef = this.dialog.open(AddEditHoursAdjstmentComponent, {
      panelClass: 'custom-mat-dialog',
      data: obj,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        if (!isEdit) {
        this.snackBar.open(this.translate.instant('hourAdjSuccess'), '', {
          duration: 4000,
          panelClass: ['status-snack-bar']
        });
        this.hoursAdjustmentDetails.push(data);
        this.hoursAdjustmentDetails = _.clone(this.hoursAdjustmentDetails);
        if(this.dataTableComponent) {
          this.dataTableComponent.updateTableData();
        }
      } else if (isEdit) {
        this.snackBar.open(this.translate.instant('hourAdjeditSuccess'), '', {
          duration: 4000,
          panelClass: ['status-snack-bar']
        });
        this.hoursAdjustmentDetails[this.hoursAdjustmentDetails.indexOf(rowData)] = data;
        this.hoursAdjustmentDetails = _.clone(this.hoursAdjustmentDetails);
        if(this.dataTableComponent) {
          this.dataTableComponent.updateTableData();
        }
      }
      }
    });
  }
  addExistingPDS(isEdit, rowData?) {
    const obj = {
      fulldata: this.existingPDSDetails,
      isEdit: false,
      rowData: '',
      engineGroupType: this.engineGroupType,
      pdsId: this.permcDataForm.controls.pdsId.value,
      index: -1
    };
    if (isEdit) {
      obj.rowData = rowData;
      obj.isEdit = true;
      obj.index = this.existingPDSDetails.indexOf(rowData);
    }
    const dialogRef = this.dialog.open(AddEditExistingPDSComponent, {
      panelClass: 'custom-mat-dialog',
      data: obj,
      disableClose: true

    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        if (!isEdit) {
        this.snackBar.open(this.translate.instant('existingpdsAddSuccess'), '', {
          duration: 4000,
          panelClass: ['status-snack-bar']
        });
        this.existingPDSDetails.push(data);
        this.existingPDSDetails = _.clone(this.existingPDSDetails);
        this.dataTableComponent.updateTableData();
      } else if (isEdit) {
        this.snackBar.open(this.translate.instant('existingpdseditSuccess'), '', {
          duration: 4000,
          panelClass: ['status-snack-bar']
        });
        this.existingPDSDetails[this.existingPDSDetails.indexOf(rowData)] = data;
        this.existingPDSDetails = _.clone(this.existingPDSDetails);
        this.dataTableComponent.updateTableData();
      }
    }
    });
  }

  pdsIdListChange(event) {
    
    this.isconsumptionError = false;
    this.isAddtnDataError = false;
    this.selectedPdsid = event.value;
    
    if(this.isEdit){
      this.selectedData = this.tmiTableData.filter(a=>{
        return a.DataHeader.PdsID == event.value
      })
      this.existingPDSDetails = this.selectedData[0]?.EnginePerformances;
    }
    if (this.existingPDSDetails && this.existingPDSDetails.length === 0) {
      this.tempPds.push(event.value);
    }
    this.valueAdded = true;
  }
  disabler() {
    if (this.existingPDSDetails.length === 0) {
        return false;
      } else if (this.existingPDSDetails.length >= 1) {
        if (this.tempPds.includes(this.selectedPdsid) === true) {
          return false;
        } else {
        return true;
        }
      }
  }

  engineTypeListChange(event) {
  this.isconsumptionError = false;
  this.isAddtnDataError = false;

  }
  additionDatacheck() {
    if (Number(this.additionalData.modifedcode0) === 0 && Number(this.additionalData.modifedcode1) === 0 &&
      Number(this.additionalData.modifedcode2) === 0) {
        this.isconsumptionError = true;
        return false;
      } else {
        this.isconsumptionError = false;
        return true;
      }
  }

  saveData() {
    const value = '';

    const formValidObj = {
      formName: 'Add / Edit Engine Performance - Settings',
      formContent: this.permcDataForm.value.pdsId,
      formContent2: null ,
      formStatus: 'success',
    };
    Object.keys(this.permcDataForm).forEach((key, index, array) => {
      this.permcDataCall(key, index, value, array);
    });
    formValidObj.formContent2 = value;
    this.submit = true;
    if(this.permcDataForm.valid && this.tmiData && !this.isEdit && this.additionDatacheck() && !this.formError && this.validAddtnlDataVldtn()){
      this.callMergeEnginePerformanceAPI();
    }
    else if ( this.permcDataForm.valid && !this.isEdit && !this.isCopy && !this.formError && this.additionDatacheck() && !this.isHoursError()
      && this.validAddtnlDataVldtn()) {
      this.callMergeEnginePerformanceAPI();
    } else if ( this.permcDataForm.valid && this.isEdit && !this.formError && this.additionDatacheck() && !this.isHoursError()
      && this.validAddtnlDataVldtn()) {
        this.validate(formValidObj);
    } else if ( this.permcDataForm.valid && this.isCopy && !this.formError && this.additionDatacheck() && !this.isHoursError()
      && this.validAddtnlDataVldtn()) {

      this.callMergeEnginePerformanceAPI();
    }
    else if(this.permcDataForm.valid && !this.isEdit && this.additionDatacheck() && !this.formError && this.validAddtnlDataVldtn()){
      this.callMergeEnginePerformanceAPI();
    }
    this.errorCall(formValidObj);
  }
  resetDataTable() {
    this.isLoading = false;
    const element = document.getElementById('common-data-table');
    if (element) {
      element.style.display = 'table';
    }
    this.dialogRef.close(this.generateJson());
  }
  moveScrollToErrorDivAndShowError() {
    this.isLoading = false;
    this.duplicateEngineperformance = true;
    this.networkErrorMessage = this.translate.instant('enginePerformanceexistsMsg');
    setTimeout(() => {
      const elmnt = document.getElementById('errorDiv') as HTMLInputElement;
      if (elmnt) {
        elmnt.scrollIntoView();
      }
      }, 50);
  }
  checkDuplteVdltn() {
    let isDuplicate = false;
    let tempBackUp;
    if (this.isEdit) {
      const indexOfCurrentData = this.fullData.indexOf(this.rowData);
      tempBackUp = JSON.parse(JSON.stringify(this.fullData));
      tempBackUp.splice(indexOfCurrentData, 1);
    } else {
      tempBackUp = JSON.parse(JSON.stringify(this.fullData));
    }
    const fullDataMap = new Map(tempBackUp.map(o => [this.getvadtnString(o.DataHeader), o]));
    const compareString = this.getCurrentCompareString(this.permcDataForm.controls);
    if (fullDataMap.get(compareString)) {
      isDuplicate = true;
    }
    return isDuplicate;
  }
  addtnlEngineData(target, value) {
    this.isconsumptionError = false;
    this.isAddtnDataError = false;
    const regex = /^[0-9]*(\.[0-9]+)?$/;
    let error = false;
    if (value === '') {
      this.formError =  true;
    } else {
      if (!value.match(regex)) {
        this.formError =  true;
      } else {
        error = true;
        this.formError =  false;
      }
    }
    this.targetCall(target, error);
  }
  showToolbar() {
    this.showFooterButtons = true;
  }

  hideToolbar() {
    this.showFooterButtons = false;
  }

  generateJson() {
      const engineData = {
          DataHeader: {
            PdsID:  this.permcDataForm.controls.pdsId.value,
            EngineGroupID: this.permcDataForm.controls.engineGroup.value,
            EngineTypeID: this.permcDataForm.controls.engineType.value,
            Speed: this.permcDataForm.controls.speed.value,
            Power: this.permcDataForm.controls.power.value,
            Model: this.permcDataForm.controls.model.value,
            Manufacturer: this.permcDataForm.controls.manufacturer.value,
            SerialNumberPrefix: this.permcDataForm.controls.serialPrefix.value,
            PriceArrangementNumber: this.permcDataForm.controls.priceAggnt.value,
            EngineArrangementNumber: this.permcDataForm.controls.aggmtNo.value,
            OTNumber: this.permcDataForm.controls.ot.value,
            IsCustom: true,
            BrakeSpecificOilConsumption: this.permcDataForm.controls.bsoc.value,
            FuelType: this.permcDataForm.controls.fuelType.value,
            FuelMass: this.permcDataForm.controls.fuelMass.value,
            LowHeatValue: this.permcDataForm.controls.lowHeatValue.value,
            OilMass: this.permcDataForm.controls.oilMass.value,
            SumpSize: this.permcDataForm.controls.sumpSise.value,
            OilChangeInterval: this.permcDataForm.controls.oilChgIntvl.value,
            PowerSpeed: this.permcDataForm.controls.power.value + 'KW' + '@' + this.permcDataForm.controls.speed.value + 'rpm',
            maxConsumptionFuel: '',
            maxConsumptionHour: '',
            FuelOverhauls: [
                {
                    ComponentCode: '1000',
                    Consumption: this.additionalData.modifedcode0,
                    ModifierCode: this.engineModifierEnum[0],
                    ConsumptionType: ''
                },
                {
                    ComponentCode: '1000',
                    Consumption: this.additionalData.modifedcode2,
                    ModifierCode: this.engineModifierEnum[2],
                    ConsumptionType: ''
                },
                {
                    ComponentCode: '1000',
                    Consumption: this.additionalData.modifedcode1,
                    ModifierCode: this.engineModifierEnum[1],
                    ConsumptionType: ''
                }
            ]
          },
          EnginePerformances: this.existingPDSDetails,
          HoursAdjustmentDetails: this.hoursAdjustmentDetails
        };
      if (this.permcDataForm.controls.engineGroup.value === 'G') {
        engineData.DataHeader.maxConsumptionHour = engineData.DataHeader.FuelOverhauls[0].Consumption;
        engineData.DataHeader.FuelOverhauls[0].ConsumptionType = this.engineConsumptionTypeEnum[1];
        engineData.DataHeader.FuelOverhauls[1].ConsumptionType = this.engineConsumptionTypeEnum[1];
        engineData.DataHeader.FuelOverhauls[2].ConsumptionType = this.engineConsumptionTypeEnum[1];
      } else  if (this.permcDataForm.controls.engineGroup.value === 'D') {
        engineData.DataHeader.maxConsumptionFuel = engineData.DataHeader.FuelOverhauls[0].Consumption;
        engineData.DataHeader.FuelOverhauls[0].ConsumptionType = this.engineConsumptionTypeEnum[0];
        engineData.DataHeader.FuelOverhauls[1].ConsumptionType = this.engineConsumptionTypeEnum[0];
        engineData.DataHeader.FuelOverhauls[2].ConsumptionType = this.engineConsumptionTypeEnum[0];
      }
      if (this.isFromGlobal) {
        engineData.DataHeader.IsCustom = false;
      } else {
        engineData.DataHeader.IsCustom = true;
      }
      return {
        EngineBaseData: [engineData]
      };
  }
  getvadtnString(data) {
    const el = JSON.parse(JSON.stringify(data));

    return (el.PdsID.trim().toLowerCase() + el.EngineGroupID.trim().toLowerCase()
      + el.EngineTypeID.trim().toLowerCase() + el.Model.trim().toLowerCase() + el.SerialNumberPrefix.trim().toLowerCase()
      + el.PriceArrangementNumber.trim().toLowerCase() + el.EngineArrangementNumber.trim().toLowerCase()
      + el.FuelType.trim().toLowerCase() + el.OTNumber.trim().toLowerCase() + el.IsCustom.toString());
  }
  getCurrentCompareString(data) {

    if (this.isFromGlobal) {
      return (data.pdsId.value.trim().toLowerCase() + data.engineGroup.value.trim().toLowerCase()
      + data.engineType.value.trim().toLowerCase() + data.model.value.trim().toLowerCase() + data.serialPrefix.value.trim().toLowerCase()
      + data.priceAggnt.value.trim().toLowerCase() + data.aggmtNo.value.trim().toLowerCase()
      + data.fuelType.value.trim().toLowerCase() + data.ot.value.trim().toLowerCase() + 'false');
    } else {
      return (data.pdsId.value.trim().toLowerCase() + data.engineGroup.value.trim().toLowerCase()
      + data.engineType.value.trim().toLowerCase() + data.model.value.trim().toLowerCase() + data.serialPrefix.value.trim().toLowerCase()
      + data.priceAggnt.value.trim().toLowerCase() + data.aggmtNo.value.trim().toLowerCase()
      + data.fuelType.value.trim().toLowerCase() + data.ot.value.trim().toLowerCase() + 'true');
    }
  }


  selectProductPopup(){
    const dialogRef = this.dialog.open(ProductSelectionComponent, {
      panelClass: 'custom-mat-dialog',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(product => {

      if (product && product.model){
        this.permcDataForm.controls.model.patchValue(product.model);
        document.getElementById('model').children[0].classList.add('cc-text-present');
      }
      if (product && product.manufacturer) {
        this.selectedManufacturer = this.manufacturerLists.filter((element) => {
          if (element.Identifier === product.manufacturer.Identifier){
            return element;
          }
        });
        this.selectedManufacturer = this.selectedManufacturer[0];
        this.permcDataForm.controls.manufacturer.setValue(this.selectedManufacturer);
        this.ref.detectChanges();
      }
      if (product && product.serialPrefix){
        this.permcDataForm.controls.serialPrefix.patchValue(product.serialPrefix);
      }
    });
  }
  fuelTypeListChange(event) {
    this.isconsumptionError = false;
    this.isAddtnDataError = false;
  }
  pdsDataSwitch(event) {
    if (event) {
      this.pdsIdList = this.pdsIdFullData;
      if (this.isEdit || this.isCopy) {
        const index = this.pdsIdList.indexOf(this.dataHeader.PdsID);
        if (index >= 0) {
          this.pdsIdList.splice(index, 1);
          this.pdsIdList.unshift(this.dataHeader.PdsID);
        }
      }
    } else {
 this. pdDataCheck();
    } 
  }
  isHoursError() {
    if (this.engineGroupType === 'D') {
      if (!this.modifedcode0ValidD || !this.modifedcode1ValidD || !this.modifedcode2ValidD) {
        return true;
      } else {
        return false;
      }
    } else if (this.engineGroupType === 'G') {
      if (!this.modifedcode0ValidG || !this.modifedcode1ValidG || !this.modifedcode2ValidG) {
        return true;
      } else {
        return false;
      }
    }
  }
  validAddtnlDataVldtn() {
    if (Number(this.additionalData.modifedcode0) === 0) {
      this.isAddtnDataError = false;
      return true;
    } else {
      const major = Number(Number(this.additionalData.modifedcode1) / Number(this.additionalData.modifedcode0));
      const inframe = Number(Number(this.additionalData.modifedcode2) / Number(this.additionalData.modifedcode0));
      if (Number.isInteger(major) && Number.isInteger(inframe)) {
        this.isAddtnDataError = false;
        return true;
      } else {
        this.isAddtnDataError = true;
        return false;
      }
    }
  }

  showServerError() {
    this.isLoading = false;
    this.networkError = true;
    this.networkErrorMessage = this.translate.instant('serverErr');
  }

  callMergeEnginePerformanceAPI() {
    this.isLoading = true;
    this.networkError = false;
    const isDuplicate = this.checkDuplteVdltn();
    if (!isDuplicate) {
      this.duplicateEngineperformance = false;
      this.enginePerformanceService.mergeEnginePerformancedata(this.generateJson(), this.parameterType).subscribe(response => {
        if(response.IsSuccess) {
          this.resetDataTable();
        }
        else {
          this.handleErrorMessage(response);
        }
      }, err => {
        this.showServerError();
      });
    } else if(isDuplicate && this.tmiData){
       this.duplicateOnTmi = true;
       this.errorMessage = this.translate.instant('enginePerformanceexistsMsg');
       this.saveDisable = true;
    }
    else {
      this.moveScrollToErrorDivAndShowError();
    }
  }

  changeManufacturerValue(manufacturerValue) {
    this.isAPIError = false;
    this.showSearchError = false;
    this.isNotFound = false;
    this.placeHolderValue = this.permcDataForm.controls.manufacturer.value.Identifier !== 'AA' ? 
     this.translate.instant('lbl_search_number') :
      this.translate.instant('lbl_search_prefix');
  }

  searchError() {
    if (!this.searchText) {
      this.disableSearch = true;
    }
    else if (this.prefixError ||  !this.selectedManufacturer || this.loadManufacturerError) {
      this.disableSearch = true;
    }
    else if (this.searchText) {
      if ((this.searchText.length === 3 || this.searchText.length === 8) && this.selectedManufacturer) {
        this.disableSearch = false;
      }
      else {
        this.disableSearch = true;
      }
    }
  }

  loadManufacturers() {
    this.selectedManufacturer = '';
    this.manufacturerLists = [];
    this.loadManufacturer = true;
    this.getManufacturerSubscribe = this.estimatePricingService.getManufacturerData().subscribe((data) => {
      if (data && data.IsSuccess) {
        this.loadManufacturer = false;
        data = data.Manufacturers;
        data = this.sortData(data);
        this.manufacturerLists = data;
        this.selectedManufacturer = this.manufacturerLists.filter((element) => {
          if (element.Identifier === 'AA'){
            return element;
          }
        });
        this.selectedManufacturer = this.selectedManufacturer[0];
        this.permcDataForm.controls.manufacturer.setValue(this.selectedManufacturer);
      }
      else {
        this.selectedManufacturer = '';
        this.failureList = [];
        this.failureList = data.Failures;
        this.loadManufacturer = false;
        this.loadManufacturerError = true;
        this.handleErrorMessage(data);
      }
    }, (err) => {
      this.loadManufacturerApiError = true;
      this.loadManufacturer = false;
      this.selectedManufacturer = '';
    });
  }

  checkSerial(): boolean {
    if (this.permcDataForm.invalid) {
      return true;
    }
    if (this.permcDataForm.controls.serialPrefix.value != null) { 
      if (this.permcDataForm.controls.serialPrefix.value.length === 3 || this.permcDataForm.controls.serialPrefix.value.length === 8) {
        if (this.prefixError) {
          return true;
        }
        return false;
      }
    }
    return true;
  }

  sortData(data) {
    if (data.length > 0){
    const sortData = data.filter(element => {
      return (element.Identifier === 'AA');
    });
    data.sort(function(a, b) {
      const textA = a.Name.toUpperCase();
      const textB = b.Name.toUpperCase();
      if(textA < textB) {
        return -1;
      } else if(textA > textB){
        return 1;
      } else {
        return 0;
      }
    });
    data = data.filter(ele => ele.Identifier !== 'AA');
    data.unshift(sortData[0]);
    return data;
    }
  }
  enumCall(){
    this.enumSubscribe = this.workgroupService.workgroupSelectionEnums.subscribe((data: any) => {
      if (data && data.enums && data.enums.KeyValues) {
        this.engineType = data.enums.KeyValues.EngineTypeEnum;
        this.engineModifierEnum = data.enums.KeyValues.EngineModifierEnum;
        this.engineConsumptionTypeEnum = data.enums.KeyValues.EngineConsumptionTypeEnum;
      }
    });
  }
  fluidPriceCall(){
    this.workgroupService.workgroupfluidPricesData.subscribe((data: any) => {
      if (data) {
        this.fuelTypeList = data.filter(el => {
          if (el.FluidType === this.engineConsumptionTypeEnum[0]) {
            return el;
          }
        });
      }
    });
  }
  editCopyCall(){
    this.engineGroupType = this.dataHeader.EngineGroupID;
    this.dataHeader.FuelOverhauls.forEach( el => {
      if (el.ModifierCode === this.engineModifierEnum[0]) {
        this.additionalData.modifedcode0 = el.Consumption;
      } else if (el.ModifierCode === this.engineModifierEnum[1]) {
        this.additionalData.modifedcode1 = el.Consumption;
      } else if (el.ModifierCode === this.engineModifierEnum[2]) {
        this.additionalData.modifedcode2 = el.Consumption;
      }
    });

    this.setValue();
    this.fuelTypeList.forEach( el => {
      if (el.Number.toLowerCase() === this.dataHeader.FuelType.toLowerCase()) {
        this.permcDataForm.controls.fuelType.setValue(el.Number);
      }
    });
    this.selectedPdsid = this.dataHeader.PdsID;
  }
  ppidCheck(){
    if (this.pdsIdFullData && this.pdsIdFullData.length > 200) {
      this.pdsIdList = this.pdsIdFullData.slice(0, Number((this.pdsIdFullData.length / 20 ).toFixed(0)));
      if (this.isEdit || this.isCopy) {
        if (!this.pdsIdList.includes(this.dataHeader.PdsID)) {
          this.pdsIdList.unshift(this.dataHeader.PdsID);
        }
      }
    } else {
      this.pdsIdList = this.pdsIdFullData;
    }
  }
  bsocCall(event){
    if (event.value >= 0 && event.value <= 1000) {
      this.bsocError = false;
      this.formError =  false;
    } else {
      this.bsocError = true;
      this.formError =  true;
    }
  }
  speedCall(event){
    if (event.value >= 10 && event.value <= 100000) {
      this.speedError = false;
      this.formError =  false;
    } else {
      this.speedError = true;
      this.formError =  true;
    }
  }
  oilCall(event){
    if (event.value >= 10 && event.value <= 10000) {
      this.oilError = false;
      this.formError =  false;
    } else {
      this.oilError = true;
      this.formError =  true;
    }
  }
  powerCall(event){
    if (event.value >= 10 && event.value <= 100000) {
      this.powerError = false;
      this.formError =  false;
    } else {
      this.powerError = true;
      this.formError =  true;
    }
  }
  fuelMassCall(event){
    if (event.value >= 0 && event.value <= 1000000) {
      this.fuelmassError = false;
      this.formError =  false;
    } else {
      this.fuelmassError = true;
      this.formError =  true;
    }
  }
  oilChangeCall(event){
    if (event.value >= 0 && event.value <= 160000 ) {
      this.oilChgIntvlError = false;
      this.formError =  false;
    } else {
      this.oilChgIntvlError = true;
      this.formError =  true;
    }
  }
  sumpCall(event){
    if (event.value >= 0 && event.value <= 100000 ) {
      this.sumpsizeError = false;
      this.formError =  false;
    } else {
      this.sumpsizeError = true;
      this.formError =  true;
    }
  }  errorCall(formValidObj){
    if (this.isAddtnDataError){
      formValidObj.formStatus = 'fail - ' + this.translate.instant('addtnlDataError');
    }
    if (this.isconsumptionError){
      formValidObj.formStatus = 'fail - ' + this.translate.instant('consumptionError');
    }

    this.subjectService.setFormChangesStream({isFormSubmitted: true, formValue: formValidObj});
  }
  editPreformanceCall(apiJsonFormat, formData, index){
    this.enginePerformanceService.editEnginePerformancedata(apiJsonFormat, this.parameterType).subscribe(response => {
      if(response.IsSuccess) {
        this.isLoading = false;
        const element = document.getElementById('common-data-table');
        if (element) {
          element.style.display = 'table';
        }
        this.dialogRef.close({data: formData.EngineBaseData[0], index});
      }
      else {
        this.handleErrorMessage(response);
      }
      }, err => {
      this.showServerError();
      });
  }
  permcDataCall(key, index, value, array){
    if (this.permcDataForm[key] !== null){
      if (key === 'pdsId' || key === 'speed' || key === 'power'){
        value = index < array.length - 1 ? value + this.permcDataForm[key] + ' | ' : value + this.permcDataForm[key];
      }
      else{
        value =  index < array.length - 1 ?  value + 'User Entered Value | ' : value + 'User Entered Value';
      }
    }
  }
  duplicateCall(){
    this.duplicateEngineperformance = false;
    const formData = this.generateJson();
    const index = this.fullData.indexOf(this.rowData);
    this.fullData[index] = formData.EngineBaseData[0];
    const apiJsonFormat =  {EngineBaseData: []};
    let temp;
    if (this.isFromGlobal) {
      temp = this.fullData;
    } else {
      temp = this.fullData.filter(el => {
        if (el.DataHeader.IsCustom) {
          return el;
        }
      });
    }

    apiJsonFormat.EngineBaseData = temp;
    this.editPreformanceCall(apiJsonFormat, formData, index);
  }
  validate(formValidObj){
    this.isLoading = true;
    this.networkError = false;
    const isDuplicate = this.checkDuplteVdltn();
    if (!isDuplicate) {
    this. duplicateCall();
    } else {
      this.moveScrollToErrorDivAndShowError();
      formValidObj.formStatus = 'fail - ' + this.translate.instant('enginePerformanceexistsMsg');
    }
  }

  targetCall(target, error){
    if (target === 1) {
      if (this.engineGroupType === 'D') {
        this.modifedcode0ValidD = error;
      } else if (this.engineGroupType === 'G') {
        this.modifedcode0ValidG = error;
      }
    } else if (target === 2) {
    this.setTarget(error);

    } else if (target === 3) {
  this.checkTarget(error);
    }
  }
  setTarget(error){
    if (this.engineGroupType === 'D') {
      this.modifedcode2ValidD = error;
    } else if (this.engineGroupType === 'G') {
      this.modifedcode2ValidG = error;
    }
  }
  checkTarget(error){
    if (this.engineGroupType === 'D') {
      this.modifedcode1ValidD = error;
    } else if (this.engineGroupType === 'G') {
      this.modifedcode1ValidG = error;
    }
  }
  pdDataCheck(){
    if (this.pdsIdFullData && this.pdsIdFullData.length > 200) {
      this.pdsIdList = this.pdsIdFullData.slice(0, Number((this.pdsIdFullData.length / 20 ).toFixed(0)));
      if (this.selectedPdsid && !this.pdsIdList.includes(this.selectedPdsid)) {
        this.pdsIdList.unshift(this.selectedPdsid);
      }
      if (this.isEdit || this.isCopy) {
        if (!this.pdsIdList.includes(this.dataHeader.PdsID)) {
          this.pdsIdList.unshift(this.dataHeader.PdsID);
        }
      }
    } else {
      this.pdsIdList = this.pdsIdFullData;
    }
  }

  handleErrorMessage = (response) => {
    let hasMultipleErrors = (response.FeedbackItems.length > 1) ? true: false;
    if(hasMultipleErrors) {
      let errorMessage = this.translate.instant('serverErr');
      this.showSnackBar(errorMessage);
    }
    else {
      let errorMessage = response.FeedbackItems[0].Message;
      this.showErrorMsg(errorMessage);
    }
  }

  showErrorMsg(msg) {
    let modalProps: {} = { 
      centered: true, 
      windowClass: 'confirm-modal', 
      backdropClass: 'confirm-modal-backdrop', 
      backdrop: 'static',
      keyboard: false
    }
    const modalRef = this.modalService.open(ConfirmAlertComponent, modalProps);
    modalRef.componentInstance.isMessage = true;
    modalRef.componentInstance.noCloseIcon = true;
    modalRef.componentInstance.timeFrame = true;
    modalRef.componentInstance.alertMessage = msg;
    modalRef.componentInstance.buttons = [
      {
        text: this.translate.instant('confirmOk'),
        cssClass: ['cat-btn-primary'],
        handler: (modal) => {
          modal.close(false);
        }
      }
    ];
  }
  showSnackBar(message) {
      this.snackBar.open(message, '', {
        duration: 4000,
        panelClass: ['status-snack-bar'],
      });
  }
}