import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SubjectService } from '@app/services/data/subject.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-customerdisplay',
  templateUrl: './customerdisplay.component.html',
  styleUrls: ['./customerdisplay.component.scss']
})
export class CustomerdisplayComponent implements OnInit {
  @Input() isEdit: boolean;
  @Input() customer: any;
  @Input() customerData: any;
  @Input() customerClass:any;
  @Input() selectedValue;
  columns = [
    {
      label: 'customerName',
      value: 'customerName',
      keyPath: 'CustomerName',
      show: true,
      cssClass: 'col-customerName',
      width: 150,
      searchEnabled: true,
    },
    {
      label: 'custNum',
      value: 'customerNumber',
      keyPath: 'CustomerNumber',
      show: true,
      cssClass: 'col-customerClass',
      width: 150,
      searchEnabled: true,
    },
    {
      label: 'custClass',
      value: 'customerClass',
      keyPath: 'CustomerClass',
      show: true,
      cssClass: 'col-customerClass',
      width: 150,
      searchEnabled: true,
    },
  ];
  displayedColumns = [];
  selectedCustomer;
  selectedConfigurationIndex;
  @Output() selecterEmitter = new EventEmitter;
  hideHeader=false;
  totalLength = 0;
  itemsPerPage = 20;
  dataSource;
  searchBackup=[];
  customerData1=[];
  showCustomer=false
  setpadding=true;
  noData = false;
  constructor(public activeModal: NgbActiveModal,private subjectService: SubjectService,  public ref: ChangeDetectorRef,) { }

  ngOnInit(): void {
    if(this.customerClass){
      this.customerData = this.customerData.filter( data =>{ return this.customerClass == data.CustomerClass})
      this.dataSource = new MatTableDataSource(this.customerData);
      this.totalLength = this.customerData.length;
      this.customerData1=this.customerData.length
    }
    else{
    this.dataSource = new MatTableDataSource(this.customerData);
    this.totalLength = this.customerData.length;
    this.customerData1=this.customerData.length
    }
    if(this.customerData.length===0 || this.customerData==null){
      this.showCustomer=true;
    }
    this.subjectService.eventEmitListener$.subscribe(data => {
      if (this.customerData && this.customerData.length && data && data.pagination && data.pagination.pageName === 'customer-details') {
        const pagedData = this.customerData.slice(data.pagination.startIndex, data.pagination.endIndex + 1);
        this.itemsPerPage = data.pagination.pageSize;
        this.dataSource.data = JSON.parse(JSON.stringify(pagedData));
        this.totalLength = this.customerData.length;
      }
    });
    this.searchBackup=JSON.parse(JSON.stringify(this.customerData))
    this.subjectService.matTableSearchCust$.pipe(
      debounceTime(500)
    ).subscribe(searchTextValue => {
      this.applyFilter(searchTextValue.message);
    });
    this.displayedColumns.push('radio');
    this.setDisplayedColumns();
}

  setDisplayedColumns() {
    this.columns.forEach((column, index) => {

      this.displayedColumns.push(column.value);
    });
  }

  close() {
    this.activeModal.close();
  }

  selectedRow(event, i) {
    this.selectedValue = event;
    this.selectedCustomer = event;
      this.selecterEmitter.emit({ value: this.selectedCustomer })
  }

  selectData(event) {
    this.activeModal.close(this.selectedCustomer);

  }
  onSearch(searchTextValue: string, event: Event) {
    event.stopPropagation();
    this.selectedConfigurationIndex=-1;
    this.subjectService.sendMessage(searchTextValue, 'matTableSearchCust');
  }
  applyFilter(searchTerm) {
    if(this.noData){
      this.dataSource = new MatTableDataSource(this.searchBackup);
    }
    this.noData = false;
    const filteredData = [];
    if (searchTerm && searchTerm.length) {
      this.searchBackup.forEach(row => {
       const processedData = row.CustomerName+row.CustomerNumber+row.CustomerClass;
        if ((processedData.trim().toLowerCase()).includes(searchTerm.trim().toLowerCase())) {
          if (!filteredData.includes(row)) {
            filteredData.push(JSON.parse(JSON.stringify(row)));
          }
        }
      });
      this.customerData = JSON.parse(JSON.stringify(filteredData));

      if(this.customerData.length === 0){
        this.dataSource = [];
        this.noData = true;
      }
    } else {
      this.customerData= JSON.parse(JSON.stringify(this.searchBackup));;
    }
  }
  
  clear(): void {
    this.selectedConfigurationIndex=-1;
}
  ngAfterViewInit() {
    this.ref.detectChanges();
  }
}
