import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '@app/services/shared/storage/data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SubjectService } from '@app/services/data/subject.service';

@Component({
  selector: 'app-workgroup-delete-alert',
  templateUrl: './workgroup-delete-alert.component.html',
  styleUrls: ['./workgroup-delete-alert.component.scss']
})
export class WorkgroupDeleteAlertComponent implements OnInit {
  @Input() alertMessage;
  @Input() workGroupList;
  selectedWorkGroup;
  constructor( public dataService: DataService, private activeModal: NgbActiveModal, public subjectService: SubjectService) { }

  ngOnInit(): void {
    if (this.workGroupList.length > 0){
      this.selectedWorkGroup = this.workGroupList.filter(elem => elem.WorkGroupId == '1')[0];
    }
  }


  wgValueChange(event) {
    this.selectedWorkGroup = event.value;
  }

  save() {
    this.dataService.defaultOrgWG = this.selectedWorkGroup;
    this.dataService.defaultWGRole = this.selectedWorkGroup.Role;
    this.subjectService.sendMessage(this.selectedWorkGroup.Role,'defaultWGRoleChanged');
    this.activeModal.close();
  }
}
