import { Component, OnInit, OnDestroy, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { DataTableComponent } from '@app/common/data-table/data-table.component';
import { ConfirmAlertComponent } from '@app/components/confirm-alert/confirm-alert.component';
import { GlobalUploadEnginePerformanceComponent } from '@app/global-settings/global-settings/global-upload-engine-performance/global-upload-engine-performance.component';
import { SubjectService } from '@app/services/data/subject.service';
import { GloabalSettingsService } from '@app/services/global-settings/gloabal-settings.service';
import { WGRole } from '@app/services/models/roles';
import { DataService } from '@app/services/shared/storage/data.service';
import { EnginePerformanceService } from '@app/services/workgroup/engineperformance.service';
import { WorkgroupService } from '@app/services/workgroup/workgroup.service';
import { AddEditEnginePerformanceComponent } from '@app/settings/engine-performance/add-edit-engine-performance/add-edit-engine-performance.component';
import { ExportAsComponent } from '@app/settings/engine-performance/export-as/export-as.component';
import { ViewEnginePerformanceComponent } from '@app/settings/engine-performance/view-engine-performance/view-engine-performance.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { filter } from 'rxjs/operators';
import { AddEditEnginePerformanceGlobalComponent } from './add-edit-engine-performance-global/add-edit-engine-performance-global.component';
import { log } from 'console';

export const TooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 200,
  hideDelay: 0,
  touchendHideDelay: 1000,
};

@Component({
  selector: 'app-engine-performance',
  templateUrl: './engine-performance.component.html',
  styleUrls: ['./engine-performance.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: TooltipDefaults }
  ]
})
export class EnginePerformanceComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableComponent)
  private dataTableComponent: DataTableComponent;
  showTable = false;
  showButton = true;
  today: number = Date.now();
  private dataSubscribe: any;
  prefernceName = 'EngineperformanceWg';
  showFetch = true;
  dataHeader: any;
  showLoader = true;
  columns = [
    {
      label: 'pdsId', value: 'pdsId', keyPath: 'DataHeader.PdsID', show: true,
      cssClass: 'col-model', width: 80, searchEnabled: true
    },
    {
      label: 'model', value: 'model', keyPath: 'DataHeader.Model', show: true,
      cssClass: 'col-model', width: 80, searchEnabled: true
    },
    {
      label: 'SNPrefix', value: 'SNPrefix', keyPath: 'DataHeader.SerialNumberPrefix', show: true,
      cssClass: 'col-SNPrefix', width: 80, searchEnabled: true
    },
    {
      label: 'application', value: 'application', keyPath: 'DataHeader.EngineTypeID - DataHeader.EngineGroupID',
      show: true, cssClass: 'col-application', width: 140, searchEnabled: true
    },
    {
      label: 'powerSpeed', value: 'powerSpeed', keyPath: 'DataHeader.PowerSpeed', show: true,
      cssClass: 'col-powerSpeed', width: 130, searchEnabled: true
    },
    {
      label: 'priceArrangement', value: 'priceArrangement', keyPath: 'DataHeader.PriceArrangementNumber',
      show: true, cssClass: 'col-priceArrangement', width: 150, searchEnabled: true
    },
    {
      label: 'OT', value: 'OT', keyPath: 'DataHeader.OTNumber', show: true, cssClass: 'col-OT',
      width: 80, searchEnabled: true
    },
    {
      label: 'engineArrangement', value: 'engineArrangement', keyPath: 'DataHeader.EngineArrangementNumber',
      show: true, cssClass: 'col-engineArrangement', width: 160, searchEnabled: true
    },
    {
      label: 'topEndFuel', value: 'topEndFuel', keyPath: 'DataHeader.maxConsumptionFuel', show: true,
      cssClass: 'col-topEndFuel', width: 100, searchEnabled: true
    },
    {
      label: 'topEndHours', value: 'topEndHours', keyPath: 'DataHeader.maxConsumptionHour', show: true,
      cssClass: 'col-topEndHours', width: 110, searchEnabled: true
    },

  ];
  isCustomColum: any = {
    label: 'isCustom', value: 'isCustom', keyPath: 'DataHeader.IsCustom', show: true, checkbox: true,
    searchEnabled: false, width: 100
  };
  @Input() isFromGlobal = false;
  parameterType: any;
  loadParameterType: any;
  moreOptionsWithEdit = [
    { label: 'View', icon: 'fa-eye' },
    { label: 'Edit', icon: 'fa-pencil-square-o' },
    { label: 'Copy', icon: 'fa-clon' }
  ];
  moreOptions = [
    { label: 'View', icon: 'fa-eye' }
  ];
  dis = '';
  searchCharacterInvalid: boolean;
  pdsIdList = [];
  localLang: any;

  tableConfig = {
    stickyHeader: false,
     expandable: false,
     isBulkAction: false,
     isSortEnable: false,
     isBulkActionOpenOnSingleClick: false,
     isBulkActionOverlayClose: false,

   columnConfig: [

   {
     name: 'pdsId',
     title: 'PDS ID',
     width: 20,
     component: null,
     align: 'left',
     priority: 2,
   },
   {
     name: 'model',
     title: 'Model',
     width: 8,
     component: null,
     align: 'left',
     priority: 2,
   },

   {
     name: 'SNPrefix',
     title: 'SN Prefix',
     width: 37,
     component: null,
     align: 'center',
     priority: 2,
   },
   {
     name: 'application',
     title: 'Application',
     width: 30,
     component: null,
     align: 'center',
     priority: 2,
   },
   {
    name: 'powerSpeed',
    title: 'Power Rating @ Speed Rating',
    width: 150,
    component: null,
    align: 'center',
    priority: 2,
  },
  {
    name: 'priceArrangement',
    title: 'Price List',
    width: 35,
    component: null,
    align: 'center',
    priority: 2,
  },
  {
    name: 'OT',
    title: 'Test Spec Number (0T #)',
    width: 120,
    component: null,
    align: 'center',
    priority: 2,
  },
  {
    name: 'engineArrangement',
    title: 'Engine Arrangement #',
    width: 120,
    component: null,
    align: 'center',
    priority: 2,
  }
     ]
 };
 showDeleteIcon=false;
  searchtext: any;
  constructor(private modalService: NgbModal, public translate: TranslateService,
              public activeModal: NgbActiveModal, private subjectService: SubjectService,
              public enginePerformanceService: EnginePerformanceService,
              public snackBar: MatSnackBar, private workgroupService: WorkgroupService,
              private router: Router, public dialog: MatDialog, public dataService: DataService,
              public globalService: GloabalSettingsService, public ref: ChangeDetectorRef) { }
  consumptionType = [];
  data = [];
  powerSpeed: any;
  lastUpdatetime;
  private routerSub: any;
  admin = WGRole.Admin;
  addNewPointerEventsNone = true;
  private enumSubscribe: any;
  isEngineDataLoaded = false; // to avoid multiple call of load api - subject issue
  ngOnInit() {    
    this.localLang = localStorage.getItem('locale') === 'ru';
    if (!this.isFromGlobal) {
      this.columns.push(this.isCustomColum);
    }
    // Reload Customer data when the Workgroup dropdown changed
    this.routerSub = this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd)
    ).subscribe(() => { // Reload the API request only if the WG dropdown is changed
      if (this.router.getCurrentNavigation().extras && this.router.getCurrentNavigation().extras.state) {
        if (this.router.getCurrentNavigation().extras.state.action === 'reload') {
          this.initiateLoadEngine();
        }
      }
    });
    this.initiateLoadEngine();
    this.listenEventChange();
    this.searchtext = this.translate.instant('Search');
  }
  initiateLoadEngine() {
    this.enumSubscribe = this.workgroupService.workgroupSelectionEnums.subscribe((enumValues: any) => {
      if (enumValues && enumValues.enums && enumValues.enums.KeyValues && !this.isEngineDataLoaded) {
        if (!this.isFromGlobal) {
          this.parameterType = enumValues.enums.KeyValues.EnginePerformanceDataTypeEnum[2];
          this.loadParameterType = enumValues.enums.KeyValues.EnginePerformanceDataTypeEnum[0];
          this.loadEngineData();
        } else {
          this.parameterType = enumValues.enums.KeyValues.EnginePerformanceDataTypeEnum[1];
          this.loadParameterType = enumValues.enums.KeyValues.EnginePerformanceDataTypeEnum[1];
          this.loadEngineData();
        }
        if (this.enumSubscribe) {
          this.enumSubscribe.unsubscribe();
        }
      }
    });
  }
  loadEngineData() {
    this.isEngineDataLoaded = true;
    this.dataSubscribe = this.enginePerformanceService.getEnginePerformance(this.loadParameterType).subscribe(data => {
      if (data && data.EngineBaseData) {
        this.data = data.EngineBaseData;
        this.data.forEach(el => {
          const tempData = el.DataHeader.PdsID;
          this.pdsIdList.push(tempData);
        });
        this.showFetch = false;
        this.consumptionType = this.data;
        for (let list of this.consumptionType) {
          
          const consumptionList = list.DataHeader.FuelOverhauls;
          const power = list.DataHeader.Power;
          const speed = list.DataHeader.Speed;
          const powerspeed = power + 'KW' + '@' + speed + 'RPM';
          list.DataHeader.PowerSpeed = powerspeed;
          list.DataHeader.consumptionType = consumptionList[0].ConsumptionType;
          for (const i of consumptionList) {
            this.setModifierCodes(i, list);
          }
          if (list.DataHeader.consumptionType === 'Fuel') {
            list.DataHeader.maxConsumptionFuel = list.DataHeader.modifedcode0;
            list.DataHeader.maxConsumptionHour = '';
          } else {
            list.DataHeader.maxConsumptionFuel = '';
            list.DataHeader.maxConsumptionHour = list.DataHeader.modifedcode0;
          }
        }
        this.data = this.consumptionType;
      } else {
        this.showFetch = false;
      }
      this.addNewPointerEventsNone = false;
    }, err => {
      this.showFetch = false;
      this.addNewPointerEventsNone = false;
    });
  }

  setModifierCodes(i, list) {
    if (i.ModifierCode === 'TE') {
      list.DataHeader.modifedcode0 = i.Consumption;
    } else if (i.ModifierCode === 'IX') {
      list.DataHeader.modifedcode2 = i.Consumption;
    } else {
      list.DataHeader.modifedcode1 = i.Consumption;
    }
  }

  moreClick(data) {
    const modalRef = this.modalService.open(ViewEnginePerformanceComponent, { centered: true, windowClass: 'engine-popup' });
    modalRef.componentInstance.engineDetails = data.engineDetails;
    modalRef.componentInstance.dataHeader = data.DataHeader;
    modalRef.componentInstance.hoursAdjustmentDetails = data.HoursAdjustmentDetails;
    modalRef.componentInstance.enginePerformances = data.EnginePerformances;
    modalRef.componentInstance.isFromGlobal = this.isFromGlobal;

  }

  search(searchTextValue: string, event: Event) {
    event.stopPropagation();
    this.subjectService.sendMessage(searchTextValue, 'matTableSearch');
    if (searchTextValue && searchTextValue.length > 2) {
      this.searchCharacterInvalid = false;
    } else {
      this.searchCharacterInvalid = true;
    }
  }

  searchBlur(searchTextValue: string, event: Event) {
    if (searchTextValue === '') {
      this.searchCharacterInvalid = false;
    }
  }

  ngOnDestroy() {
    if (this.dataSubscribe) {
      this.dataSubscribe.unsubscribe();
    }
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
    if (this.enumSubscribe) {
      this.enumSubscribe.unsubscribe();
    }
  }

  addNewPermceData() {
    const dialogRef = this.dialog.open( this.isFromGlobal ? AddEditEnginePerformanceGlobalComponent : AddEditEnginePerformanceComponent, {
      height: '100%',
      width: '100%',
      maxWidth: '100vw',
      panelClass: 'custom-mat-dialog',
      disableClose: true,
      data: {
        currentData: '',
        fullData: this.data,
        isEdit: false,
        pdsIdList: _.uniq(this.pdsIdList),
        parameterType: this.parameterType,
        isFromGlobal: this.isFromGlobal
      }
    });
    this.subjectService.pdsIdListData1=_.uniq(this.pdsIdList);
    
    dialogRef.componentInstance.pdsIdListData1=_.uniq(this.pdsIdList);

    dialogRef.afterClosed().subscribe(val => {
      this.insertIntoPdsList(val);
    });
  }


  edit(event) {
    if (event) {
      const dialogRef = this.dialog.open( this.isFromGlobal ? AddEditEnginePerformanceGlobalComponent : AddEditEnginePerformanceComponent, {
        height: '100%',
        width: '100%',
        maxWidth: '100vw',
        panelClass: 'custom-mat-dialog',
        disableClose: true,
        data: {
          currentData: event,
          fullData: this.data,
          isEdit: true,
          pdsIdList: _.uniq(this.pdsIdList),
          parameterType: this.parameterType,
          isFromGlobal: this.isFromGlobal
        }
      });

      dialogRef.afterClosed().subscribe(val => {
        if (val) {
          (document.getElementById('engineDataSearch') as HTMLInputElement).value = '';
          this.snackBar.open(this.translate.instant('editEngineperformanceSuccess'), '', {
            duration: 4000,
            panelClass: ['status-snack-bar']
          });
          this.data[val.index] = val.data;
          this.data = _.clone(this.data);
          const index = this.pdsIdList.indexOf(val.data.DataHeader.PdsID);
          if (index < 0) {
            this.pdsIdList.unshift(val.data.DataHeader.PdsID);
          }
          this.dataTableComponent.updateTableData();
        } 
      });
    }
  }
  delete(event) {
    const modalRef = this.modalService.open(ConfirmAlertComponent, { centered: true, windowClass: 'confirm-modal' });
    modalRef.componentInstance.alertMessage = this.translate.instant('deleteRecordConfirmationMsg');
    modalRef.componentInstance.delete = true;
    modalRef.componentInstance.buttons = [

      {
        text: this.translate.instant('lblcancel'),
        cssClass: ['btn btn-outline-secondary btn-width btn-mr'],
        handler: (modal) => {
          modal.close(false);
        }
      },
      {
        text: this.translate.instant('Delete'),
        cssClass: ['btn btn-warning btn-width '],
        handler: (modal) => {
          modal.close(false);
          const fullData = _.clone(this.data);
          this.data.splice(this.data.indexOf(event), 1);
          this.data = _.clone(this.data);
          this.deleteApi(this.data, fullData);
        }
      }
    ];
  }
  moreOptionsFun() {
    if (this.dataService.defaultWGRole !== this.admin) {
      this.showDeleteIcon=false;
      return this.moreOptions;      
    } else {
      this.showDeleteIcon=true;
      return this.moreOptionsWithEdit;     
    }
  }
  deleteApi(data, fullData) {
    const apiJsonFormat = { EngineBaseData: [] };
    let temp;
    if (this.isFromGlobal) {
      temp = data;
    } else {
      temp = data.filter(el => {
        if (el.DataHeader.IsCustom) {
          return el;
        }
      });
    }
    apiJsonFormat.EngineBaseData = temp;
    this.enginePerformanceService.editEnginePerformancedata(apiJsonFormat, this.parameterType).subscribe(response => {
      if(response.IsSuccess) {
        (document.getElementById('engineDataSearch') as HTMLInputElement).value = '';
        this.dataTableComponent.updateTableData();
        this.snackBar.open(this.translate.instant('enginedelSuccess'), '', {
          duration: 4000,
          panelClass: ['status-snack-bar']
        });
      }
      else {
        this.handleErrorMessage(response);
      }
    }, err => {
      this.data = fullData;
      this.dataTableComponent.updateTableData();
      (document.getElementById('engineDataSearch') as HTMLInputElement).value = '';
      this.snackBar.open(this.translate.instant('serverErr'), '', {
        duration: 4000,
        panelClass: ['status-snack-bar']
      });
    });
  }
  copyData(event) {
    if (event) {
      const dialogRef = this.dialog.open( this.isFromGlobal ? AddEditEnginePerformanceGlobalComponent : AddEditEnginePerformanceComponent, {
        height: '100%',
        width: '100%',
        maxWidth: '100vw',
        panelClass: 'custom-mat-dialog',
        disableClose: true,
        data: {
          currentData: event,
          fullData: this.data,
          isCopy: true,
          pdsIdList: _.uniq(this.pdsIdList),
          parameterType: this.parameterType,
          isFromGlobal: this.isFromGlobal
        }
      });

      dialogRef.afterClosed().subscribe(val => {
        this.insertIntoPdsList(val);
      });
    }
  }

  insertIntoPdsList(val) {
    if (val) {
      (document.getElementById('engineDataSearch') as HTMLInputElement).value = '';
      this.snackBar.open(this.translate.instant('engineperformanceSuccess'), '', {
        duration: 4000,
        panelClass: ['status-snack-bar']
      });
      this.data.push(val.EngineBaseData[0]);
      this.data = _.clone(this.data);
      const index = this.pdsIdList.indexOf(val.EngineBaseData[0].DataHeader.PdsID);
      if (index < 0) {
        this.pdsIdList.unshift(val.EngineBaseData[0].DataHeader.PdsID);
      }
      this.dataTableComponent.updateTableData();
    }
  }
  uploadEngineperformance() {
    const modalRef = this.modalService.open(GlobalUploadEnginePerformanceComponent,
       { centered: true, windowClass: 'customer-popup', backdrop: 'static'  });
    modalRef.result.then(data => {
      if (data) {
        (document.getElementById('engineDataSearch') as HTMLInputElement).value = '';
        this.loadEngineData();
      }
    });
  }

  exportEngineperformance() {
    this.globalService.exportEngineperformance('Global').subscribe(response => {
      const file = new Blob([response], {
        type:
          'application/zip',
      });
      const downloadFile = (blob, fileName) => {
        const link = document.createElement('a');
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        window.addEventListener(
          'focus',
          (e) => URL.revokeObjectURL(link.href),
          { once: true }
        );
      };
      downloadFile(file, 'EnginePerformance.zip');
    });
  }


  exportPopup() {
    this.modalService.open(ExportAsComponent, { centered: true, windowClass: 'parts-popup', backdrop: 'static' });
  }

  listenEventChange()
  {
    this.subjectService.eventEmitListener$.subscribe(loader =>
      {
        if (loader && loader.recordsFetched)
        {
          this.showLoader = false;
          this.ref.detectChanges();
        }
      });
  }

  handleErrorMessage = (response) => {
    let hasMultipleErrors = (response.FeedbackItems && response.FeedbackItems.length > 1) ? true: false;
    if(hasMultipleErrors) {
      let errorMessage = this.translate.instant('serverErr');
      this.showSnackBar(errorMessage);
    }
    else {
      if(response.FeedbackItems && response.FeedbackItems.length > 0){
        let errorMessage = response.FeedbackItems[0].Message;
        this.showErrorMsg(errorMessage);
      }
    }
  }

  showErrorMsg(msg) {
    let modalProps: {} = { 
      centered: true, 
      windowClass: 'confirm-modal', 
      backdropClass: 'confirm-modal-backdrop', 
      backdrop: 'static',
      keyboard: false
    }
    const modalRef = this.modalService.open(ConfirmAlertComponent, modalProps);
    modalRef.componentInstance.isMessage = true;
    modalRef.componentInstance.noCloseIcon = true;
    modalRef.componentInstance.timeFrame = true;
    modalRef.componentInstance.alertMessage = msg;
    modalRef.componentInstance.buttons = [
      {
        text: this.translate.instant('confirmOk'),
        cssClass: ['cat-btn-primary'],
        handler: (modal) => {
          modal.close(false);
        }
      }
    ];
  }

  showSnackBar(message) {
    this.snackBar.open(message, '', {
      duration: 4000,
      panelClass: ['status-snack-bar'],
    });
  }
}
