import { Injectable } from '@angular/core';
import { RestService } from '@app/services/rest/rest.service';
import * as ServiceMappings from '@app/services/shared/app-constants';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnginePerformanceService {

  enginePerformanceDataTypeEnum: any;

  constructor(private restService: RestService) { }

  private viewEngine = new BehaviorSubject({});
  viewEngineDetails = this.viewEngine.asObservable();

  setViewEngineData(data: any) {
    this.viewEngine.next(data);
  }

  getEnginePerformance(type): Observable<any> {
    return this.restService.httpGet(ServiceMappings.getEnginePerformanceDetails
      + '?type=' + type);
  }

  mergeEnginePerformancedata(data, type): Observable<any> {
    if (type === this.enginePerformanceDataTypeEnum[1]) { // Global
      return this.restService.httpPost(ServiceMappings.mergeGlobalEnginePerformanceDetails, data);
    } else if (type === this.enginePerformanceDataTypeEnum[2]) { // Custom
      return this.restService.httpPost(ServiceMappings.mergeCustomEnginePerformanceDetails, data);
    }
  }

  calculateEnginePerformance(planNumber, jsonData): Observable<any> {
    return this.restService.httpPost(ServiceMappings.calculateEngineData
      + '?PlanNumber=' + planNumber, jsonData);
  }
  generateEventSchedule(jsonData): Observable<any> {
    return this.restService.httpPost(ServiceMappings.generateEvent, jsonData);
  }
  getRepairScheduleInput(planNumber, jsonData): Observable<any> {
    return this.restService.httpPost(ServiceMappings.repairScheduleInput + '?PlanNumber=' + planNumber, jsonData);
  }

  editEnginePerformancedata(data, type): Observable<any> {
    if (type === this.enginePerformanceDataTypeEnum[1]) { // Global
      return this.restService.httpPost(ServiceMappings.saveGlobalEnginePerformanceDetails, data);
    } else if (type === this.enginePerformanceDataTypeEnum[2]) { // Custom
      return this.restService.httpPost(ServiceMappings.saveCustomEnginePerformanceDetails, data);
    }
  }
  exportEnginePerformanceAs(data): Observable<any>{
    const contentType = 'application/octet-stream';
    if (data.ContentType == 'Excel'){
    return this.restService.httpPostTypeHtml(ServiceMappings.exportEnginePerformanceAsExcel, data, contentType);
    }
    else if (data.ContentType == 'Text'){
      return this.restService.httpPostTypeZip(ServiceMappings.exportEnginePerformanceAsExcel, data, contentType);
    }
  }

}
