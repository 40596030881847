import { CurrencyPipe, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren, ViewRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DataTableComponent } from '@app/common/data-table/data-table.component';
import { FormatCellPipe } from '@app/common/pipes/format-cell.pipe';
import { CcModal } from '@app/components/modal/modal.component.service';
import { CommonService } from '@app/services/common/common.service';
import { SubjectService } from '@app/services/data/subject.service';
import { EstimatePricingService } from '@app/services/estimate-pricing/estimate-pricing.service';
import { UserService } from '@app/services/misc/user.service';
import { WGRole } from '@app/services/models/roles';
import * as AppConstant from '@app/services/shared/app-constants';
import * as constants from '@app/services/shared/app-constants';
import { DataService } from '@app/services/shared/storage/data.service';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  ResizeEvent
} from 'angular-resizable-element';
import * as _ from 'lodash';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = { asc: 'desc', desc: '', '': 'asc' };

export interface SortEvent {
  column: string;
  direction: SortDirection;
}

@Directive({
  selector: '[appSortable]'
})

export class NgbdSortableHeaderDirective {

  @Input() appSortable: string;
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();
  @HostBinding('class.asc') dir = this.direction;

  @HostListener('click') rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.appSortable, direction: this.direction });
  }
}

@Component({
  selector: 'app-data-table-v3',
  templateUrl: './data-table-v3.component.html',
  styleUrls: ['./data-table-v3.component.scss']
})
export class DataTableV3Component implements OnInit {
  constructor(
    public formBuilder: FormBuilder,
    private modalService: NgbModal,
    public translate: TranslateService,
    private router: Router,
    private renderer: Renderer2,
    private formatPipe: FormatCellPipe,
    private subjectService: SubjectService,
    public dataService: DataService,
    private commonService: CommonService,
    private datePipe: DatePipe,
    private estimateService: EstimatePricingService,
    public snackBar: MatSnackBar,
    private userService: UserService,
    private ref: ChangeDetectorRef,
    public modal: CcModal,
    public currencyPipe: CurrencyPipe
  ) { 
    
  }
  @ViewChildren(NgbdSortableHeaderDirective) headers: QueryList<NgbdSortableHeaderDirective>;
  @ViewChildren(NgbPopover) popovers: QueryList<NgbPopover>;
  @ViewChild(MatTable, { read: ElementRef, static: true }) private matTableRef: ElementRef;

  @Input() showCheckbox = {
    sell: true,
    cost: true
  };
  @Input() columnHeaders: any[];
  @Input() tableData: any[];
  @Input() showeyeIcon = false;
  @Input() pagination = false;
  @Input() parentFunction = Function;
  @Input() isMobOrTab = false;
  @Input() showhover = false;
  @Input() showMoreIcon = false;
  @Input() moreOptions: any[];
  @Output() moreClickEvent = new EventEmitter();
  @Input() showEditIcon = false;
  @Input() showEditIconOnly = false;
  @Input() showDeleteIcon = false;
  @Output() editEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  @Input() linkFunction = Function;
  @Output() viewFunction = new EventEmitter();
  @Output() editFunction = new EventEmitter();
  @Output() copyFunction = new EventEmitter();
  @Output() deleteFunction = new EventEmitter();
  @Output() archiveFunction = new EventEmitter();
  @Output() checkboxEventHandler = new EventEmitter();
  @Output() inputFunction = new EventEmitter();
  @Output() isInitSaveFunction = new EventEmitter();
  @Output() currencyFunction = new EventEmitter();
  @Output() dropdownFunction = new EventEmitter();
  @Output() enableSave = new EventEmitter();

  @Input() disabled = false;
  @Input() checked: any;
  @Input() isEdit = true;
  @Input() prefernceName: string;
  @Input() editOnly = false;
  @Input() disableEditIcon = false;

  @Input() showFullTableMoreActionBtn = false;
  @Input() hideEyeIconFromHeader = false;
  @Input() isWritable = false;
  @Input() isStickyEyeIcon = false;
  @Output() estimateListColumnPopover = new EventEmitter(); // ui redesign popover change
  @Input() tableType;
  @Input() inputError = '';
  @Input() sellorCostError = '';
  @Input() isQESettingCrossRef = false;
  @Input() genericPartMapping;
  langChangeLocal: any;

  private dataSubscribe: any;
  originalData: any[];
  prefernceData = {
    Name: '',
    Settings: []
  };
  sortDirection = true;
  keys: string[];
  show = false;
  direction = 'asc';
  displayColumns = [];
  page = 1;
  isMobile = false;
  totalLength: number;
  pageLimit = 25;
  pageStartIndex = 1;
  hoverData: any;
  rowSelected: any;
  isFilterPopUpIsOn = false;
  backupData: any[];
  workGroup: any = ['Caterpillar', 'Joe Smith', 'Toromont'];
  searchInput: string;
  popover: any;
  sourceName = AppConstant.sourceName;
  dataTable: any;
  viewEnabled = false;
  editEnabled = false;
  copyEnabled = false;
  shareEnabled = false;
  deleteEnabled = false;
  reportEnabled = false;
  archiveEnabled = false;
  currentRow: any;
  column = [];
  tableWidth: number;
  currentResizeIndex: any;
  startX: any;
  pressed: boolean;
  startWidth: any;
  start: HTMLTableHeaderCellElement;
  isResizingRight: boolean;
  resizableMousemove: Function;
  resizableMouseup: Function;
  columns = [];
  resizer: any;
  widthChange: number;
  displayedColumns: string[] = [];
  scrollHandleDisplayed = false;
  filterPopover: any;
  dataSource = new MatTableDataSource();
  pager: any;
  itemsPerPage: number;
  planNumber = 401;
  showMoredetails;
  lastprefernceData;
  checkboxChecked;
  showEditOption = true;
  readonly = WGRole.ReadOnly;

  calculatedMenuColumnWidth = 0;
  resizeLineMargin = 0;
  mousePos = null;
  isResizing = false;
  currencyList = [];
  @Input() currencySelected;
  private searchSub: any;
  firstSelectedItem: any;
  lastSelectedItem: any;
  initiallySelectedItem: any;
  showTable = false;
  selectedItems = [];
  recentlySelectedItem: any;
  crossRefDealerMasterPartNum = [];
  currentDropdownSelected = '';
  dealerMasterPartSelected;
  selectedDealerMasterPartNum;
  saveData = [];
  @Output() showOrHideBulkEdit = new EventEmitter();
  @Output() resetValues = new EventEmitter<any>();

  @HostListener('window:keydown', ['$event']) onKeyDown(e) {
    if (e.shiftKey && (e.code == 'ArrowDown' || e.code == 'ArrowUp') && this.selectedItems.length > 0) {
      this.selectItemUsingArrow(e);
    }
    if (e.shiftKey && this.isEdit) {
      document.querySelectorAll('.data-rows').forEach(rowItem => rowItem.classList.add('shift'));
    }
  }

  @HostListener('window:keyup', ['$event']) onKeyUp(e) {
    if (e.key == 'Shift' && this.isEdit) {
      document.querySelectorAll('.data-rows').forEach(rowItem => rowItem.classList.remove('shift'));
    }
  }

  ngOnInit(): void {
    this.langChangeLocal = localStorage.getItem('locale');
    this.subjectService.sendMessage({recordsFetched: false}, 'eventEmitListen');
    this.currencyList = this.dataService.getWGCurencyList();
    fromEvent(document.body, 'mousemove').subscribe(e => {
      this.mousePos = e;
    });
    this.getPrefernces();
    this.isMobileOrTablet();
    this.priceTypeCall();
    this.setDisplayedColumns();
    this.searchSub = this.subjectService.matTableSearch$.pipe(
      debounceTime(500)
    ).subscribe(searchTextValue => {
      this.searchTable(searchTextValue.message);
    });
    this.backupData = { ...this.tableData };
    this.itemsPerPage = 100;
    if (this.showeyeIcon) {
      this.generateColumnHeader();
    } else {
      this.columnHeaders.forEach(col => {
        this.displayColumns.push(col);
      });
    }
    if (this.pagination) {
      this.totalLength = this.tableData.length;
    }
    if (this.moreOptions) {
      this.generateMoreOptions();
    }
  }

  priceTypeCall() {
    this.subjectService.priceTypeSelectionResult$.subscribe(data => {
      this.setDisplayedColumns();
    });

    this.subjectService.eventEmitListener$.subscribe(data => {
      if (data && data.refreshTable) {
        if (this.pagination) {
          this.setPaginatedData(); 
        } else { 
        this.dataSource.data = this.tableData;
        this.totalLength = this.tableData.length;
        }
        this.setTableDisplay();
      }
      if (data && data.pagination) {
        this.pager = data.pagination;
        this.setPaginatedData();
      }
    });
  }

  ngOnChanges(changes: any) {
    if (changes && changes.tableData) {
      this.newNgChanges(changes);
      this.originalData = _.clone(changes.tableData.currentValue);
      this.setTableDisplay();
      this.setPaginatedData();
      for (const i in this.columns) {
        if (this.columns[i].sortActive) {
          this.columns[i].order = !this.columns[i].order;
          this.sortTable(this.columns[i]);
          break;
        }
      }
      this.crossRefDealerMasterPartNum = [];
      this.tableData.forEach((each)=> {
        let dealerMasterPartNum = each.dealerMasterPartNum;
        this.crossRefDealerMasterPartNum.push(dealerMasterPartNum);
      });
      if(this.isQESettingCrossRef) {
        let tableDataLen = this.tableData.length;
        this.dealerMasterPartSelected = Array(this.tableData.length).fill(0);
        for(let i = 0;i < tableDataLen;i++) {
          this.dealerMasterPartSelected[i] = this.getMappedIndex(i);
        }
        this.initSaveData();
      }
    }
  }
newNgChanges(changes){
  if (this.moreOptions) { 
    this.generateMoreOptions();
  }
  if (!this.pagination) {
    this.dataSource.data =  changes.tableData.currentValue;
  }
  this.tableData = changes.tableData.currentValue;
  if (changes.tableData.currentValue) {
    this.totalLength = changes.tableData.currentValue.length;
  }
  if ((this.tableData && this.tableData.length > 0) && this.columns.length === 0) {
    this.setDisplayedColumns();
  }
}

  ngAfterContentChecked() {
    const arr = [];
    for (const key in this.columnHeaders) {
      if (this.columnHeaders[key].show) {
        arr.push({ label: this.columnHeaders[key].value, width: this.columnHeaders[key].width });
      }
    }
    const arr1 = [];
    // tslint:disable-next-line: forin
    for (const i in arr) {
      if (arr[i]) {
        arr1.push(
          {
            Name: arr[i].label,
            Value: arr[i].width
          }
        );
      }
    }
    this.prefernceData.Name = this.prefernceName;
    this.prefernceData.Settings = arr1;
    this.calculateMenuColumnWidth();
  }

  postPrefernce() {
    const sub = this.userService.saveUserPreferences(this.prefernceData).subscribe(() => {
      const data = this.dataService.userPrefenceData;
      const userData = data.filter(elem => elem.Name == this.prefernceName);
      if (userData.length != 0) {
        const index = this.dataService.userPrefenceData.indexOf(userData[0]);
        this.dataService.userPrefenceData.splice(index, 1);
      }
      this.dataService.userPrefenceData.push({ Name: this.prefernceName, Settings: this.prefernceData.Settings });
      sub.unsubscribe();
    });
  }

  getPrefernces() {
    if (this.prefernceName) {
      const data = this.dataService.userPrefenceData;
      const userData = data.filter(elem => elem.Name == this.prefernceName);
      if (userData.length == 0) {
        this.dataSubscribe = this.userService.getUserPreferences(this.prefernceName).subscribe(response => {
          this.subjectService.sendMessage({recordsFetched: true}, 'eventEmitListen');
          this.lastprefernceData = response.Settings;
          this.dataService.userPrefenceData.push({ Name: this.prefernceName, Settings: this.lastprefernceData });
          this.setPreferenceData();
          this.getPreferenceDetails();
          this.showTable = true;
        }, err => {
          if (this.tableData && this.tableData.length > 0) {
            this.setDisplayedColumns();
          }
          this.showTable = true;
        });
      } else {
        this.subjectService.sendMessage({recordsFetched: true}, 'eventEmitListen');
        this.lastprefernceData = userData[0].Settings;
        this.setPreferenceData();
        this.ref.detectChanges();
        if (this.tableData && this.tableData.length > 0) {
          this.setDisplayedColumns();
        }
        this.showTable = true;
        this.ref.detectChanges();
      }
    } else {
      this.setDisplayedColumns();
      this.showTable = true;
    }
  }

getPreferenceDetails(){
  if (this.tableData && this.tableData.length > 0) {
    this.setDisplayedColumns();
  }
}

  setPreferenceData() {
    if (this.lastprefernceData.length > 0) {
      for (const key in this.columnHeaders) {
        if (key) {
          this.columnHeaders[key].show = false;
          for (const keys in this.lastprefernceData) {
            this.changePreferenceKeyValue(key, keys);
          }
        }
      }
    }
  }

  changePreferenceKeyValue(key, keys) {
    if (this.lastprefernceData[keys].Name === this.columnHeaders[key].value) {
      this.columnHeaders[key].show = true;
      const valueObj = JSON.parse(this.lastprefernceData[keys].Value);
      if (valueObj?.width) {
        this.columnHeaders[key].width = Number(valueObj.width);
      }
      else if (valueObj) {
        this.columnHeaders[key].width = Number(valueObj);
      }
      if (this.lastprefernceData.length === 1) {
        this.columnHeaders[key].disabled = true;
      }
    }
  }

  setPaginatedData() {
    if (this.pager && this.pagination) {
      const pagedData = this.tableData.slice(this.pager.startIndex, this.pager.endIndex + 1);
      this.itemsPerPage = this.pager.pageSize;
      this.dataSource.data = pagedData;
      this.totalLength = this.tableData.length;
      this.setTableDisplay();
    }
  }

  setDisplayedColumns() {
    this.displayedColumns = [];
    this.columns = [];
    this.columnHeaders.forEach((column, index) => {
      if (column.show) {
        column.index = index;
        column.order = null;
        column.sortActive = false;
        this.columns.push(column);
        this.displayedColumns.push(column.value);
      }
    });
    if (this.showeyeIcon) {
      this.displayedColumns.push('eyeIcon');
    }
    if (!(this.ref as ViewRef).destroyed) {
      this.ref.detectChanges();
    }
  }


  generateMoreOptions() {
    this.viewEnabled = false;
    this.editEnabled = false;
    this.copyEnabled = false;
    this.shareEnabled = false;
    this.deleteEnabled = false;
    this.reportEnabled = false;
    this.archiveEnabled = false;
    this.moreOptions.forEach(option => {
      if (option.label === 'View') {
        this.viewEnabled = true;
      } else if (option.label === 'Edit') {
        this.editEnabled = true;
      } else if (option.label === 'Copy') {
        this.copyEnabled = true;
      } else if (option.label === 'Share') {
        this.shareEnabled = true;
      } else if (option.label === 'Delete') {
        this.deleteEnabled = true;
      } else if (option.label === 'Generate Report') {
        this.reportEnabled = true;
      } else if (option.label === 'Archive') {
        this.archiveEnabled = true;
      }
    });
  }

  getStyleVal(elm, css) {
    return (window.getComputedStyle(elm, null).getPropertyValue(css));
  }

  generateColumnHeader() {
    this.displayColumns = [];
    this.columnHeaders.forEach(col => {
      if (col.show) {
        this.displayColumns.push(col);
      }
    });
  }

  togglePopover(flag: boolean, popover) {
    this.popover = popover;
    if (!flag) {
      if (this.checkboxChecked) {
        this.postPrefernce();
        this.checkboxChecked = false;
      }
      this.setDisplayedColumns();
      this.calculateMenuColumnWidth();
    }
  }

  headerChange(event, column) {
    this.checkboxChecked = true;
    let count = 0;
    let tempCol;
    if (!event) {
      this.columnHeaders.forEach(col => {
        if (col.show && col.value !== 'eye') {
          tempCol = col;
          count++;
        }
      });
      if (count === 1) {
        tempCol.disabled = true;
      }
    } else {
      this.columnHeaders.forEach(col => {
        if (!col.alwaysDisable) {
          col.disabled = false;
        }
      });
    }
    this.estimateListPopOver();
  }

  estimateListPopOver() { // the trigger is given to estimate component to update the eye popover content
    this.estimateListColumnPopover.emit(this.columnHeaders);
  }

  isStickyColumn(columnValue) { // determine whether sticky prop should be applied or not
    return this.checkForColumnIndex(columnValue);
  }

  checkForColumnIndex(columnValue) {
    return false;
  }

  sortAscDesc(direction, column, data) {
    const n = this.tableData.length;
    for (let i = 0; i < n; i++) {
      for (let j = 0; j < n - i - 1; j++) {
        if (direction === 'asc') {
          if (data[j][column] > data[j + 1][column]) {
            const temp = data[j];
            data[j] = data[j + 1];
            data[j + 1] = temp;
          }
        } else {
          if (data[j][column] < data[j + 1][column]) {
            const temp = data[j];
            data[j] = data[j + 1];
            data[j + 1] = temp;
          }
        }
      }
    }
  }

  getCssClass(row: any, key: any) {
    return this.parentFunction(row, key);
  }

  generateId(val) {
    return val + '-id';
  }

  showpopover(row, index, popover, key) {
    this.rowSelected = row;
  }

  generateSerialNumber(estimate) {
    const arr = [];
    if (estimate.Range) {
      // tslint:disable-next-line: max-line-length
      return estimate.Prefix + estimate.Range + ' - ' + estimate.Prefix + estimate.Range;
    }
  }

  recordShown(popover) {
    this.filterPopover = popover;
    this.isFilterPopUpIsOn = true;
  }

  recordHidden() {
    this.isFilterPopUpIsOn = false;
  }
  recordShownHover(popover) {
    if (popover.isOpen() && this.isFilterPopUpIsOn) {
      popover.close();
    }
  }
  isMobileOrTablet() {
    // tslint:disable-next-line: max-line-length
    if (constants.mobOrTabCheckReg1.test(navigator.userAgent)
      // tslint:disable-next-line: max-line-length
      || constants.mobOrTabCheckReg2.test(navigator.userAgent.substr(0, 4))) {
      this.isMobOrTab = true;
    }
  }
  moreClick(target) {
    const dataObj = {
      target,
      data: this.rowSelected
    };
    this.moreClickEvent.emit(dataObj);
  }
  setEditAndDeleteOption() {
    this.showEditOption = true;
    this.deleteEnabled = true;
  }

  viewData() {
    this.viewFunction.emit(this.currentRow);
  }

  editData() {
    this.editFunction.emit(this.currentRow);
  }

  copyData() {
    this.copyFunction.emit(this.currentRow);
  }

  deleteData() {
    this.deleteFunction.emit(this.currentRow);
  }

  edit(target, j) {
    if (this.isWritable && (!this.disableEditIcon)) {
      const dataObj = {
        j,
        row: target,
      };
      this.editEvent.emit(dataObj);
    }
  }

  delete(target, j) {
    if (this.isWritable && (!this.disableEditIcon)) {
      const dataObj = {
        j,
        row: target,
      };
      this.deleteEvent.emit(dataObj);
    }
  }


  archiveData() {
    this.archiveFunction.emit(this.currentRow);
  }

  addHandle(id) {
    if (!this.scrollHandleDisplayed) {
      const element = document.getElementsByClassName('mat-column-' + id)[0];
      element.classList.add('border-right-style');
      const scrollClass = element.getElementsByClassName('scroll-handle')[0];
      scrollClass.classList.add('display-class');
    }
  }

  removeHandle(id) {
    if (!this.scrollHandleDisplayed) {
      const element = document.getElementsByClassName('mat-column-' + id)[0];
      element.classList.remove('border-right-style');
      const scrollClass = element.getElementsByClassName('scroll-handle')[0];
      scrollClass.classList.remove('display-class');
    }
  }

  onSearch(searchTextValue: string, event: Event) {
    event.stopPropagation();
    this.subjectService.sendMessage(searchTextValue, 'matTableSearch');
  }

  searchTable(searchTerm) {
    if (searchTerm) {
      const results = this.commonService.matTableSearch(searchTerm, this.originalData, this.columnHeaders);
      this.tableData = results;
      this.totalLength = results.length;
      if (!(this.ref as ViewRef).destroyed) {
        this.ref.detectChanges();
      }
    } else {
      this.tableData = this.originalData;
    }
    if (this.pagination) {
      this.setPaginatedData();
    } else {
      this.totalLength = this.tableData.length;
      this.dataSource.data = this.tableData;
      this.setTableDisplay();
    }
  }

  /** to sort table */
  sortTable(column) {

    this.resetSort();
    column.order = !column.order;
    column.sortActive = true;
    const unordered = [];
    const orderedArr = [];
    this.tableData.forEach((row, index) => {
      const cellData = this.formatPipe.transform(undefined, column, row);
      let rawData;
      if (column.hasOwnProperty('currency')) {
        rawData = this.formatPipe.getAmount(column, row);
      } else if (column.translate) {
        rawData = this.translate.instant(cellData);
      } else {
        rawData = cellData;
      }
      const data = {
        cellData,
        row,
        rawData
      };
      unordered.push(data);
    });


    // column.order = true means ascending
    // column.order = false means descending
    if (column.order) {
      unordered.sort(this.toAscending);
    } else {
      unordered.sort(this.toDescending);
    }

    unordered.forEach(item => {
      orderedArr.push(item.row);
    });
    this.tableData = orderedArr;
    if (this.pagination) {
      this.setPaginatedData();
    } else {
      this.dataSource.data = this.tableData;
    }
  }

  toAscending(a, b) {
    if (typeof a.rawData !== 'boolean') {
      if (a.cellData || a.cellData === 0) {
        // Use toUpperCase() to ignore character casing
        const x = ((typeof a.rawData !== 'number') && (typeof a.rawData !== 'boolean')) ? a.rawData.toUpperCase() : a.rawData;
        const y = ((typeof b.rawData !== 'number') && (typeof b.rawData !== 'boolean')) ? b.rawData.toUpperCase() : b.rawData;
        let comparison = 0;
        if (x > y) {
          comparison = 1;
        } else if (x < y) {
          comparison = -1;
        }
        return comparison;
      }
    } else {
      const x = a.rawData;
      const y = b.rawData;
      let result = 0;
      if( x === y){
        return result;
      }else{
          result = x ? -1 : 1
      }
      return result;
    }
  }

  toDescending(a, b) {
    if (typeof a.rawData !== 'boolean') {
      // Use toUpperCase() to ignore character casing
      if (a.cellData || a.cellData === 0) {
        const x = ((typeof a.rawData !== 'number') && (typeof a.rawData !== 'boolean')) ? a.rawData.toUpperCase() : a.rawData;
        const y = ((typeof b.rawData !== 'number') && (typeof b.rawData !== 'boolean')) ? b.rawData.toUpperCase() : b.rawData;
        let comparison = 0;
        if (x > y) {
              comparison = 1;
            } else if (x < y) {
              comparison = -1;
            }
        return comparison * -1;
      }
    } else {
      const x = a.rawData;
      const y = b.rawData;
      let result = 0;
      if( x == y ){
        return result;
      }else{
        result = x ? 1 : -1
      }
      return result;
    }
  }


  resetSort() {
    this.columns.forEach((item) => {
      item.sortActive = false;
    });
    this.setTableDisplay();
    this.setPaginatedData();
  }

  setTableDisplay() {
    if (this.totalLength === 0) {
      const element = document.getElementById('common-data-table-v3');
      if (element) {
        element.style.display = 'none';
      }
      const tableElement = document.getElementById('common-table-container-v3');
      if (tableElement) {
        tableElement.classList.add('no-border');
      }
    } else {
      const element = document.getElementById('common-data-table-v3');
      if (element) {
        element.style.display = 'table';
      }
      const tableElement = document.getElementById('common-table-container-v3');
      if (tableElement) {
        tableElement.classList.remove('no-border');
      }
    }
  }

  ngOnDestroy() {
    if (this.dataSubscribe) {
      this.dataSubscribe.unsubscribe();
    }
    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }
    if (this.selectedItems && this.selectedItems.length !== 0) {
      this.removeCurrentSelection();
      this.resetValues.emit();
    }
  }

  changeColumnHeaders(columns) {
    this.columnHeaders = columns;
    this.setDisplayedColumns();
  }

  updateTableData() {
    this.setTableDisplay();
  }

  /** for table resizing */
  onResizeEnd(event: ResizeEvent, columnName, index): void {
    this.resizeLineMargin = 0;
    this.isResizing = false;
    if (event.edges.right) {
      const cssValue = event.rectangle.width + 'px';
      const columnElts = document.getElementsByClassName('mat-column-' + columnName);
      for (let i = 0; i < columnElts.length; i++) {
        const currentEl = columnElts[i] as HTMLDivElement;
        currentEl.style.width = cssValue;
      }
      this.columns[index].width = columnElts[0].clientWidth;
      for (const key in this.prefernceData.Settings) {
        if (this.prefernceData.Settings[key].Name === columnName) {
          const value = JSON.stringify({ width: columnElts[0].clientWidth, isLock: false });
          this.prefernceData.Settings[key].Value = value;
        }
      }
      this.calculateMenuColumnWidth();
      this.postPrefernce();
    }
  }

  calculateMenuColumnWidth() {
    let totalColumnsWidth = 0;
    for (const key in this.columns) {
      const el = document.getElementsByClassName('mat-column-' + this.columns[key].value);
      if (el.length) {
        totalColumnsWidth = totalColumnsWidth + el[0].clientWidth;
      }
    }
    const dataTableContainerWidth = document.getElementById('common-table-container-v3').getBoundingClientRect().width;

    if (dataTableContainerWidth > (totalColumnsWidth + 50)) {
      this.calculatedMenuColumnWidth = dataTableContainerWidth - (totalColumnsWidth + 20);
    } else {
      this.calculatedMenuColumnWidth = 20;
    }

  }

  onResizing(resizeEvent: ResizeEvent) {
    if (resizeEvent.rectangle.width > 150 && resizeEvent.rectangle.right < document.getElementById('common-table-container-v3').getBoundingClientRect().width) {
      this.resizeLineMargin = this.mousePos.clientX;
    }
    this.isResizing = true;
  }

  constructSaveData = (index, tableRow) => {
    let currentCol = this.dealerMasterPartSelected[index];
    return {
      "sos": tableRow.SOS[currentCol],
      "custCode": tableRow.custCode[currentCol],
      "dealerMasterPartNum": tableRow.dealerMasterPartNum[currentCol],
      "defaultCostAmt": tableRow.defaultCostAmt[currentCol],
      "defaultCostCurrencyCode": tableRow.defaultCostCurrencyCode[currentCol],
      "defaultSellAmt": tableRow.defaultSellAmt[currentCol],
      "defaultSellCurrencyCode": tableRow.defaultSellCurrencyCode[currentCol],
      "fullCredit": tableRow.fullCredit[currentCol],
      "fullDealerNet": tableRow.fullDealerNet[currentCol],
      "genericPartInfo": tableRow.genericPartInfo[currentCol],
      "id": tableRow.id[currentCol],
      "mrkOrg": tableRow.mrkOrg[currentCol],
      "partDesc": tableRow.partDesc[currentCol],
      "partType": tableRow.partType[currentCol],
      "partialCredit": tableRow.partialCredit[currentCol],
      "partialDealerNet": tableRow.partialDealerNet[currentCol],
      "salesArea": tableRow.salesArea[currentCol],
      "sell": tableRow.sell[currentCol],
      "genericPartNum": tableRow.genericPartNum
    }
  }

  generateSaveData = () => {
    let tableDataLen = this.tableData.length;

    this.saveData = [];
    for(let i = 0;i < tableDataLen;i++) {
      let currentRow = this.tableData[i];
      let reConstructedSaveData = this.constructSaveData(i, currentRow);
      this.saveData.push(reConstructedSaveData);
    }

  }  

  initSaveData = () => {
    this.dealerMasterPartSelected.forEach(()=>{
      this.generateSaveData();
    })

    this.isInitSaveFunction.emit(true);
    this.inputFunction.emit(this.saveData);
  }

  /** for input fields */
  inputValueChange(event, row, column, keypath, rowIndex?) {

    if(this.isQESettingCrossRef) {
      let currentCol = this.dealerMasterPartSelected[rowIndex];
      this.tableData[rowIndex].sell[currentCol] = Number(event);
      this.generateSaveData();
      this.isInitSaveFunction.emit(false);
      this.inputFunction.emit(this.saveData);
    }
    else {
      const value = {
        row,
        value: event,
        column,
        keypath,
      };
      this.inputFunction.emit(value);
    }
    
  }

  /** for checkbox fields */
  checkboxValueChanged(column, event, type?) {
    const value = {
      column,
      value: event
    };
    if (type == 'sell' && event.target.checked) {
      column.isSell = true;
    } else if (type == 'cost' && event.target.checked) {
      column.isCost = true;
    }
    else if (type == 'cost' && !event.target.checked) {
      column.isCost = false;
    }
    else if (type == 'sell' && !event.target.checked) {
      column.isSell = false;
    }
    this.checkboxEventHandler.emit(value);
  }

  /** for mass selection update */
  selectData(e, item, index) {
    if (this.isEdit) {
      if (e.shiftKey) {
        document.getSelection().removeAllRanges();
        this.highlightSelection(item, index);
      } else if (e && e.target && e.target.name === 'input-field') {
        e.stopPropagation();
      } else {
        this.firstSelectedItem = null;
        this.lastSelectedItem = null;
        this.initiallySelectedItem = null;
        this.removeCurrentSelection();
      }
      if (this.selectedItems.length === 0) {
        this.resetValues.emit();
      }
    }
  }

  highlightSelection(item, index) {
    if (!this.firstSelectedItem) {
      this.initiallySelectedItem = index.toString();
      this.firstSelectedItem = index.toString();
    } else {
      if (this.lastSelectedItem) {
        this.firstSelectedItem = JSON.parse(JSON.stringify(this.initiallySelectedItem));
        this.lastSelectedItem = index.toString();
        this.recentlySelectedItem = JSON.parse(JSON.stringify(this.lastSelectedItem));
      } else {
        this.lastSelectedItem = index.toString();
        this.recentlySelectedItem = JSON.parse(JSON.stringify(this.lastSelectedItem));
      }
      this.removeCurrentSelection();
    }
    if (this.firstSelectedItem && this.lastSelectedItem) {
      this.checkAndArrangeFlatIndex();
    } else {
      this.selectRow(item);
    }
  }

  checkAndArrangeFlatIndex() {
    let temp;
    if (this.firstSelectedItem > this.lastSelectedItem) {
      temp = JSON.parse(JSON.stringify(this.firstSelectedItem));
      this.firstSelectedItem = JSON.parse(JSON.stringify(this.lastSelectedItem));
      this.lastSelectedItem = JSON.parse(JSON.stringify(temp));
    }
    this.applySelection();
  }

  applySelection() {
    this.tableData.forEach((item, index) => {
      if (index >= Number(this.firstSelectedItem) && index <= Number(this.lastSelectedItem)) {
        this.selectRow(item);
      }
    });
  }

  removeCurrentSelection() {
    this.selectedItems.forEach(item => {
      const ele = document.getElementById(item.Number);
      if (ele) {
        ele.classList.remove('highlight-row');
      }
      item.selected = false;
    });
    this.selectedItems = [];
    this.showOrHideBulkEdit.emit({ flag: false, rows: this.selectedItems.length, type: this.tableType });
  }

  resetSelection() {
    this.firstSelectedItem = null;
    this.lastSelectedItem = null;
    this.initiallySelectedItem = null;
    this.recentlySelectedItem = null;
    this.removeCurrentSelection();
  }

  selectRow(item) {
    const ele = document.getElementById(item.Number);
    if (ele) {
      this.selectedItems.push(item);
      item.selected = true;
      if (this.selectedItems.length > 0) {
        this.showOrHideBulkEdit.emit({ flag: true, rows: this.selectedItems.length, type: this.tableType });
      }
      ele.classList.add('highlight-row');
    }
  }

  selectItemUsingArrow(e) {
    if (e.code === 'ArrowDown') {
      this.checkAndAssignIndexDown();
    } else if (e.code === 'ArrowUp') {
      this.checkAndAssignIndexUp();
    }
  }

  checkAndAssignIndexDown() {
    if (!this.lastSelectedItem) {
      if (Number(this.firstSelectedItem) !== this.tableData.length - 1) {
        this.lastSelectedItem = Number(this.firstSelectedItem) + 1;
        this.recentlySelectedItem = JSON.parse(JSON.stringify(this.lastSelectedItem));
        this.removeCurrentSelection();
        this.checkAndArrangeFlatIndex();
      }
    } else {
      this.firstSelectedItem = JSON.parse(JSON.stringify(this.initiallySelectedItem));
      this.lastSelectedItem = JSON.parse(JSON.stringify(this.recentlySelectedItem));
      if (Number(this.recentlySelectedItem) !== this.tableData.length - 1) {
        this.lastSelectedItem = Number(this.recentlySelectedItem) + 1;
        this.recentlySelectedItem = JSON.parse(JSON.stringify(this.lastSelectedItem));
        this.removeCurrentSelection();
        this.checkAndArrangeFlatIndex();
      }
    }
  }

  checkAndAssignIndexUp() {
    if (!this.lastSelectedItem) {
      if (this.firstSelectedItem > 0) {
        this.lastSelectedItem = Number(this.firstSelectedItem) - 1;
        this.recentlySelectedItem = JSON.parse(JSON.stringify(this.lastSelectedItem));
        this.removeCurrentSelection();
        this.checkAndArrangeFlatIndex();
      }
    } else {
      this.firstSelectedItem = JSON.parse(JSON.stringify(this.initiallySelectedItem));
      this.lastSelectedItem = JSON.parse(JSON.stringify(this.recentlySelectedItem));
      if (this.recentlySelectedItem > 0) {
        this.lastSelectedItem = Number(this.recentlySelectedItem) - 1;
        this.recentlySelectedItem = JSON.parse(JSON.stringify(this.lastSelectedItem));
        this.removeCurrentSelection();
        this.checkAndArrangeFlatIndex();
      }
    }
  }

  onLongPress(e) {
    this.highlightSelection(e.item, e.group);
    if (this.selectedItems.length === 0) {
      this.resetValues.emit();
    }
  }

  /** for sell or cost input field */
  inputCurrencyChange(event, row, column) {
    this.currencyFunction.emit({ value: event.target.value, row, column });
  }

  /** to remove currency symbol on focus of input field */
  removeCurrency(e) {
    const value = e.target.value;
    e.target.value = value.replace(/[^\d.]/gm, '');
  }

  formatCurrency(event, currencyCode, row, isSell) {
    if (event.target.value.match(constants.numberRegex)) {
      if (isSell) {
        row.Sell.Value.Amount = JSON.parse(JSON.stringify(event.target.value));
      } else {
        row.Cost.Value.Amount = JSON.parse(JSON.stringify(event.target.value));
      }
      if ((event.target.value !== this.convertCurrency(event.target.value, currencyCode))) {
        const keyevent = new KeyboardEvent('keyup', {
          bubbles: true, cancelable: true, shiftKey: false
        });
        const inputElement = document.getElementById(event.target.id);
        event.target.value = this.convertCurrency(event.target.value, currencyCode);
        inputElement.dispatchEvent(keyevent);
      }
      this.transformValue(event, currencyCode);
    }
  }

  isPartsSettingsTable = () => {
    return this.tableType == 'PartsSettings';
  }

  isGenericPartNumCol = (currentCol) => {
    return currentCol == 'generic_part_number';
  }

  isDealerMasterPartNumCol = (currentCol) => {
    return currentCol == 'dealer_master_part_number';
  }

  isSellCol = (currentCol) => {
    return currentCol == 'sell';
  }

  isDefaultSellCol = (currentCol) => {
    return currentCol == 'default_sell';
  }

  isDefaultCostCol = (currentCol) => {
    return currentCol == 'default_cost';
  }

  convertCurrency(value, currencyCode) {
    return this.currencyPipe.transform(value, currencyCode).replace(/[^\d.]/gm, '');
  }

  transformValue(e, currencyCode) {
    const value = e.target.value;
    e.target.value = this.currencyPipe.transform(value, currencyCode);
  }

  currencyChange(event, row) {
    const value = { event: event.value, row };
    this.dropdownFunction.emit(value);

  }

  getMappedIndex = (rowIndex) => {
    let selectedIndex = 0, currentTableRow = this.tableData[rowIndex];
    let currentRow = currentTableRow?.dealerMasterPartNum, currentGenericPartNum = currentTableRow?.genericPartNum;
    let mappedDealerPartNum = this.genericPartMapping.get(currentGenericPartNum);
    let i = 0;
    if(currentRow){
    for(let value of currentRow) {
      if(value == mappedDealerPartNum) {
        selectedIndex = i;
        break;
      }
      i += 1;
    }
  }
    return selectedIndex;
  }

  getDefaultDealerMasterParNum = (rowIndex) => {
    let mappedIndex = this.getMappedIndex(rowIndex);
    return this.crossRefDealerMasterPartNum[rowIndex][mappedIndex];
  }

  dealerMasterPartNumChange = (event, row, rowIndex) => {
    this.saveData = [];
    let selectedVal = event.value, currentRow = this.tableData[rowIndex];
    let currentRowLen = currentRow?.dealerMasterPartNum.length;
    let keyPath = 'sell', column = 'sell';
    this.selectedDealerMasterPartNum = selectedVal;

    for(let i = 0;i < currentRowLen;i++) {
      if(selectedVal == currentRow?.dealerMasterPartNum[i]) 
        this.dealerMasterPartSelected[rowIndex] = i;
    }

    let selectedDropdownIndex = this.dealerMasterPartSelected[rowIndex];
    let sellValue = row.sell[selectedDropdownIndex];
    this.inputValueChange(sellValue,row,column, keyPath, rowIndex);
    this.enableSave.emit(true);
  }

  onKeypressEvent(event: any) {
    let cleanValue = event.target.value.replace(/[^\d.]/gm, '');
    if (!this.isNumber(event)) {
      cleanValue = event.target.value.replace(/[^\d.]/gm, '');
      event.target.value = cleanValue;
      event.preventDefault();

    }
  }

  isNumber(evt) {
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (
      charCode > 31
      && charCode != 8
      && charCode != 0
      && (charCode < 48 || charCode > 57)
      && (charCode < 96 || charCode > 105)
      && evt.charCode != 46
      && charCode != 190
      && charCode != 9
      && charCode != 110
      ||(charCode == 190 && evt.target.value.includes('.'))
    ) {
      return false;
    }
    // else if (charCode == 190 && evt.target.value.includes('.')) {
    //   return false;
    // }
    return true;
  }
}
