import { CurrencyPipe, getCurrencySymbol } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";

import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { Utility } from "@app/common/utility/utils";
import { CustomerdisplayComponent } from "@app/estimate/estimate-details/basic-details/customerdisplay/customerdisplay.component";
import { SubjectService } from "@app/services/data/subject.service";
import { EstimatePricingService } from "@app/services/estimate-pricing/estimate-pricing.service";
import { MultipleProductService } from "@app/services/multiple-product/multiple-product.service";
import * as constants from "@app/services/shared/app-constants";
import { DataService } from "@app/services/shared/storage/data.service";
import { WorkgroupService } from "@app/services/workgroup/workgroup.service";

@Component({
  selector: "app-add-edit-labor",
  templateUrl: "./add-edit-labor.component.html",
  styleUrls: ["./add-edit-labor.component.scss"],
})
export class AddEditLaborComponent implements OnInit {
  @Input() isEdit: boolean;
  @Input() labor: any;
  @Input() editOnlyCostSell: boolean;
  @Input() fullData: any;
  @Input() laborOverRideData: any;
  @Input() planNumber;
  @Input() showCurrency = false;
  @Input() item: any;
  @Input() isMpe;
  @Output() checkEstimateStatus = new EventEmitter();

  laborForm: FormGroup;
  submit = false;
  currencySymbol;
  costError = false;
  sellError = false;
  minSellError = false;
  minSellErrorMsg = "";
  addEditJsonFormat = {
    LaborRates: [],
  };
  isLoading = false;
  isFilter = false;
  globalErrorMsg = "";
  backUpFullData: any;
  saveButtonDisable = false;
  currencyList = [];
  baseCurrency = null;
  public currencyFilterCtrl: FormControl = new FormControl();
  public CustomerClass: FormControl = new FormControl();
  public CustomerNumber: FormControl = new FormControl();
  protected onDestroy = new Subject<boolean>();
  backupCurrency: any[];
  customerSubscribe: any;
  customerData: any;
  customerClass: any;
  filterCustomerClass: any;
  components: any;
  showCustomerNo = false;
  customerValue = "";
  customerValue1 = "";
  backupComponent: any;
  dropDownToggle = false;
  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
  displaycustomerValue = "";
  selectedCustomer = false;
  activeDrawerAddNew: any;
  customerLoading = false;
  updateRowData;
  isUserOrgIntermediate: boolean = false;
  utility = new Utility();
  constructor(
    public activeModal: NgbActiveModal,
    private workgroupService: WorkgroupService,
    public fb: FormBuilder,
    private translate: TranslateService,
    private estimatePricingService: EstimatePricingService,
    private subjectService: SubjectService,
    private dataService: DataService,
    private currencyPipe: CurrencyPipe,
    public ref: ChangeDetectorRef,
    private modalService: NgbModal,
    public mpeService: MultipleProductService
  ) {
    this.laborForm = this.fb.group({
      laborCode: [null, [Validators.required]],
      storeCode: [null, [Validators.required]],
      costCenter: [null],
      CustomerClass: [null],
      CustomerNumber: [null],
      cost: [
        null,
        [Validators.required, Validators.pattern(/^[0-9]*(\.[0-9]+)?$/)],
      ],
      sell: [
        null,
        [Validators.required, Validators.pattern(/^[0-9]*(\.[0-9]+)?$/)],
      ],
      currencyCode: [null, [Validators.required]],
    });

    this.isUserOrgIntermediate = this.dataService.isUserOrgIntermediate;
    if (this.isUserOrgIntermediate) {
      this.laborForm = this.utility.removeFormsMandatoryFields(this.laborForm, [
        "cost",
      ]);
    }
  }

  ngOnInit() {
    this.selectedCustomer = false;
    this.dropDownToggle = false;
    this.fetchCustomer();
    this.currencyList = this.dataService.getWGCurencyList();
    this.backupCurrency = this.currencyList;

    if (this.isEdit) {
      this.updateFormData();
      this.saveButtonDisable = true;
      this.currencySymbol = getCurrencySymbol(
        this.labor.LaborRate.Sell.CurrencyCode,
        "wide"
      );
      this.laborForm.controls.currencyCode.setValue(
        this.labor.LaborRate.Sell.CurrencyCode
      );
    } else {
      this.saveButtonDisable = false;
      this.currencySymbol = "";
      this.baseCurrency = this.workgroupService.getCurrencyCode();
      this.laborForm.controls.currencyCode.setValue(this.baseCurrency);
      this.currencySymbol = getCurrencySymbol(this.baseCurrency, "wide");
    }
    this.currencyFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterMyCurrency();
      });

    this.laborForm.controls.CustomerClass.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.customerChange();
        this.filterMyCustomerClass();
      });
  }

  close() {
    this.activeModal.close();
  }

  updateFormData() {
    this.laborForm.controls.laborCode.setValue(this.labor.LaborCode);
    this.laborForm.controls.storeCode.setValue(this.labor.StoreCode);
    this.laborForm.controls.costCenter.setValue(this.labor.CostCenter);
    this.laborForm.controls.CustomerClass.setValue(this.labor.CustomerClass);
    this.laborForm.controls.CustomerNumber.setValue(this.labor.CustomerNumber);
    this.displaycustomerValue = this.laborForm.controls.CustomerNumber.value;
    if (this.displaycustomerValue) {
      this.selectedCustomer = true;
    }
    if (this.labor.LaborRate.Cost) {
      this.laborForm.controls.cost.setValue(this.labor.LaborRate.Cost.Amount);
    }
    this.laborForm.controls.sell.setValue(this.labor.LaborRate.Sell.Amount);
  }

  submitForm(event) {
    const value = "";

    const formValidObj = {
      formName: !this.editOnlyCostSell
        ? "Add/Edit Labor Form - Settings"
        : "Add/Edit Labor Form - Estimate",
      formContent: this.laborForm.value.laborCode,
      formContent2: null,
      formStatus: "success",
    };
    this.globalErrorMsg = "";

    Object.keys(this.laborForm.value).forEach((key, index, array) => {
      if (
        this.laborForm.value[key] !== null &&
        this.laborForm.value[key] !== ""
      ) {
        this.checkCost(key, index, value, array);
      }
    });

    this.checkFormValidobj(formValidObj, value);
  }
  costValueChange(value) {
    const val = value.value;
    this.saveButtonDisable = false;
    if (val === "") {
      this.costError = true;
    } else {
      if (val.match(constants.numberRegex)) {
        this.costError = false;
      } else {
        this.costError = true;
      }
    }
  }
  sellValueChange(value) {
    const val = value.value;
    this.saveButtonDisable = false;
    if (val === "") {
      this.sellError = true;
    } else {
      if (val.match(constants.numberRegex)) {
        this.sellError = false;
      } else {
        this.sellError = true;
      }
    }
    
    if (this.isUserOrgIntermediate) {
      if (this.labor.MinimumSellValue) {
        this.minSellError = this.utility.minimumNumberValidation(
          this.labor.MinimumSellValue.Sell.Amount,
          val
        );
        this.minSellErrorMsg = `${this.translate.instant(
          "lessThanMinSellErrorMsg"
        )} ${this.labor.MinimumSellValue.Sell.Amount}`;
      }
    }
  }
  valueChange(event) {
    this.saveButtonDisable = false;
  }

  setParam(formData) {
    return {
      LaborCode: formData.laborCode,
      LaborRate: {
        Sell: {
          CurrencyCode: this.laborForm.controls.currencyCode.value,
          Amount: Number(formData.sell),
        },
        Cost: {
          CurrencyCode: this.laborForm.controls.currencyCode.value,
          Amount: Number(formData.cost),
        },
      },
      CostCenter: formData.costCenter,
      CustomerClass: formData.CustomerClass,
      CustomerNumber: formData.CustomerNumber,
      StoreCode: formData.storeCode,
    };
  }

  validateData(formData) {
    let isDuplicate = false;
    const indexOfFullData = this.fullData.indexOf(this.labor);
    const comparableString = this.getFormDataString(formData);
    this.fullData.forEach((el, index) => {
      const compareString = this.getCompareString(el);
      if (comparableString === compareString) {
        if (this.isEdit && indexOfFullData === index) {
          isDuplicate = false;
        } else {
          isDuplicate = true;
        }
        return;
      }
    });
    if (!isDuplicate) {
      if (this.editOnlyCostSell) {
        const formData1 = this.setParam(this.laborForm.value);
        const payload = {
          CorrelationId: this.labor.CorrelationId,
          Notes: this.labor.Notes,
          ...formData1,
        };
        this.backUpFullData = _.clone(this.fullData);
        this.backUpFullData[this.backUpFullData.indexOf(this.labor)] = payload;
        this.laborOverRideData.LaborRateFactorConfiguration =
          this.backUpFullData;
        this.estimateApplyAPI(payload);
      } else {
        this.handleAPICalls(formData);
      }
    } else {
      this.globalErrorMsg = this.translate.instant("laborValidationError");
    }
  }

  estimateApplyAPI(rowData: any) {
    this.isLoading = true;
    let configurationData;
    if (!this.isMpe) {
      configurationData = _.cloneDeep(
        this.estimatePricingService.configuration.FactorOverrides.LaborRates
          .LaborRateFactorConfiguration
      );
    } else {
      configurationData = _.cloneDeep(
        this.mpeService.factorsData.LaborRates.LaborRateFactorConfiguration
      );
    }
    const arr1Map = new Map(
      configurationData.map((o) => [this.getCompareString(o), o])
    );
    let isPushed = false;
    configurationData.forEach((element, index) => {
      if (
        arr1Map.get(this.getCompareString(this.labor)) &&
        this.getCompareString(element) === this.getCompareString(this.labor)
      ) {
        rowData.MinimumSellValue = this.labor.MinimumSellValue;
        configurationData[index] = rowData;
        isPushed = true;
      }
    });
    if (!isPushed) {
      configurationData.push(rowData);
    }
    const sampleArray = [];
    const sampleJson = _.cloneDeep(this.laborOverRideData);
    configurationData = !this.isUserOrgIntermediate
      ? configurationData
      : this.setNullForLaborCost(configurationData);
    sampleJson.LaborRateFactorConfiguration = JSON.parse(
      JSON.stringify(configurationData)
    );
    sampleArray.push(sampleJson);
    if (this.isMpe) {
      this.estimateAndApplyForMPE(sampleArray, rowData);
    } else {
      this.estimatePricingService
        .editEstimateAndApply(this.planNumber, sampleArray)
        .subscribe(
          (data) => {
            if (data && data.CheckoutResult && data.CheckoutResult.Success) {
              if (data.OverallStatus === "Success") {
                this.activeModal.close({
                  estimateUpdated: true,
                  response: rowData,
                });
                this.estimatePricingService.setEstimateEditResponse(data);
                this.subjectService.sendMessage(
                  { refreshBlendingData: true },
                  "eventEmitListen"
                );
              } else {
                this.isLoading = false;
                this.globalErrorMsg = this.translate.instant("serverErr");
              }
            } else {
              this.activeModal.close({ estimateLocked: true });
            }
          },
          (err) => {
            this.isLoading = false;
            if (err.status === 0 || err.status === 504) {
              this.globalErrorMsg = this.translate.instant("networkError");
            } else {
              this.globalErrorMsg = this.translate.instant("serverErr");
            }
          }
        );
    }
  }

  // TODO - To create reusable functions across in utility file
  setNullForLaborCost(payload) {
    for (let i = 0; i < payload.length; i++) {
      payload[i].LaborRate.Cost = null;
    }
    return payload;
  }

  getFormDataString(formData: any) {
    if (formData.CostCenter === null) {
      formData.CostCenter = "";
    }
    if (formData.CustomerClass === null) {
      formData.CustomerClass = "";
    }
    if (formData.StoreCode === null) {
      formData.StoreCode = "";
    }
    if (formData.CustomerNumber === null) {
      formData.CustomerNumber = "";
    }
    return (
      formData.LaborCode.toLowerCase() +
      formData.StoreCode.toLowerCase() +
      formData.CostCenter.toLowerCase() +
      formData.CustomerClass.toLowerCase() +
      formData.CustomerNumber.toLowerCase()
    );
  }

  getCompareString(el: any) {
    if (el.CostCenter === null) {
      el.CostCenter = "";
    }
    if (el.CustomerClass === null) {
      el.CustomerClass = "";
    }
    if (el.StoreCode === null) {
      el.StoreCode = "";
    }
    if (el.CustomerNumber === null) {
      el.CustomerNumber = "";
    }
    return (
      el.LaborCode.toLowerCase() +
      el.StoreCode.toLowerCase() +
      el.CostCenter.toLowerCase() +
      el.CustomerClass.toLowerCase() +
      el.CustomerNumber.toLowerCase()
    );
  }
  handleAPICalls(formData) {
    this.isLoading = true;
    if (!this.isEdit) {
      this.addEditJsonFormat.LaborRates.push(formData);
      if (this.workgroupService.isQuickEstimatorSettings) {
        this.mergeQELaborAPICall();
      } else {
        this.mergeLaborAPICall();
      }
    } else {
      this.backUpFullData = _.clone(this.fullData);
      this.backUpFullData[this.backUpFullData.indexOf(this.labor)] = formData;
      this.addEditJsonFormat.LaborRates = this.backUpFullData;
      if (this.workgroupService.isQuickEstimatorSettings) {
        this.saveQELaborAPICall();
      } else {
        this.saveLaborAPICall();
      }
    }
  }

  mergeLaborAPICall() {
    this.workgroupService.mergeWorkgroupData(this.addEditJsonFormat).subscribe(
      (data) => {
        this.activeModal.close(this.addEditJsonFormat.LaborRates[0]);
      },
      (err) => {
        this.isLoading = false;
        if (err.status === 0 || err.status === 504) {
          this.globalErrorMsg = this.translate.instant("networkError");
        } else {
          this.globalErrorMsg = this.translate.instant("serverErr");
        }
      }
    );
  }

  saveLaborAPICall() {
    this.workgroupService.saveWorkgroupData(this.addEditJsonFormat).subscribe(
      (data) => {
        const formData = this.setParam(this.laborForm.value);
        this.activeModal.close(formData);
      },
      (err) => {
        this.isLoading = false;
        if (err.status === 0 || err.status === 504) {
          this.globalErrorMsg = this.translate.instant("networkError");
        } else {
          this.globalErrorMsg = this.translate.instant("serverErr");
        }
      }
    );
  }

  currencyChange(event) {
    this.saveButtonDisable = false;
    this.currencySymbol = getCurrencySymbol(event.value, "wide");
    const sellValue = this.laborForm.value.sell;
    const costValue = this.laborForm.value.cost;
    if (sellValue && costValue) {
      this.setCurrentCostAndSellValue();
    }
  }
  setCurrentCostAndSellValue() {
    let sellValue = this.laborForm.value.sell;
    let costValue = this.laborForm.value.cost;
    const currencyCode = this.laborForm.value.currencyCode;
    sellValue = this.formatCurrency(sellValue, currencyCode);
    costValue = this.formatCurrency(costValue, currencyCode);
    this.laborForm.controls.sell.setValue(sellValue);
    this.laborForm.controls.cost.setValue(costValue);
  }
  formatCurrency(value, code) {
    return this.currencyPipe.transform(value, code).replace(/[^\d.]/gm, "");
  }

  filterMyCurrency() {
    const search = this.currencyFilterCtrl.value.toLowerCase();
    const res = [];
    if (!search) {
      this.currencyList = this.backupCurrency;
    } else {
      this.currencyList = this.backupCurrency;
      let currencyTemp = [];
      let currencyTempNew = [];
      currencyTemp = this.currencyList;
      if (currencyTemp && currencyTemp.length > 0) {
        currencyTempNew = currencyTemp.filter((elem) =>
          elem.toLowerCase().includes(search)
        );
      }
      this.currencyList = currencyTempNew;
    }
    this.ref.detectChanges();
  }

  filterMyCustomerClass() {
    if (this.laborForm.controls.CustomerClass.value != null) {
      const search = this.laborForm.controls.CustomerClass.value.toLowerCase();
      if (this.customerClass.length > 0) {
        this.customerClass = this.filterCustomerClass.filter((elem) =>
          elem.toLowerCase().includes(search)
        );
      } else {
        this.customerClass = this.filterCustomerClass;
      }
    }
  }

  filterMyCustomerNumber() {
    if (this.laborForm.controls.CustomerNumber.value != null) {
      const search = this.laborForm.controls.CustomerNumber.value.toLowerCase();
      const res = [];
      if (!search) {
        this.components = this.customerData;

        this.backupComponent = this.components;
      } else {
        let componentsTempNew = [];
        componentsTempNew = this.customerData.filter(
          (item: any) =>
            item.CustomerNumber.toLowerCase().includes(search) ||
            item.CustomerName.toLowerCase().includes(search)
        );
        this.components = componentsTempNew;
        this.backupComponent = this.components;
      }
    }
  }

  mergeQELaborAPICall() {
    this.workgroupService
      .mergeQEWorkgroupData(this.addEditJsonFormat)
      .subscribe(
        (data) => {
          this.activeModal.close(this.addEditJsonFormat.LaborRates[0]);
        },
        (err) => {
          this.isLoading = false;
          if (err.status === 0 || err.status === 504) {
            this.globalErrorMsg = this.translate.instant("networkError");
          } else {
            this.globalErrorMsg = this.translate.instant("serverErr");
          }
        }
      );
  }

  saveQELaborAPICall() {
    this.workgroupService.saveQEWorkgroupData(this.addEditJsonFormat).subscribe(
      (data) => {
        const formData = this.setParam(this.laborForm.value);
        this.activeModal.close(formData);
      },
      (err) => {
        this.isLoading = false;
        if (err.status === 0 || err.status === 504) {
          this.globalErrorMsg = this.translate.instant("networkError");
        } else {
          this.globalErrorMsg = this.translate.instant("serverErr");
        }
      }
    );
  }

  customerDataFn() {
    this.customerClass = this.customerData
      .map((item) => item.CustomerClass)
      .filter((value, index, self) => self.indexOf(value) === index)
      .filter((item) => {
        return item !== "" && item !== null && item !== undefined;
      });
    this.filterCustomerClass = this.customerData
      .map((item) => item.CustomerClass)
      .filter((value, index, self) => self.indexOf(value) === index)
      .filter((item) => {
        return item !== "" && item !== null && item !== undefined;
      });

    this.components = this.customerData;
  }

  fetchCustomer() {
    this.customerLoading = true;
    this.customerSubscribe = this.workgroupService
      .getCustomerData()
      .subscribe((data) => {
        this.customerLoading = false;
        this.customerData = data.Customers;
        this.customerDataFn();
      });
  }

  resetCustomer() {
    this.customerDataFn();
    this.laborForm.controls.CustomerClass.setValue(null);
    this.laborForm.controls.CustomerNumber.setValue(null);
    this.customerValue = "";
    this.displaycustomerValue = "";
    this.selectedCustomer = false;
    this.resetCustomer1();

    this.isFilter = false;
  }

  resetCustomer1() {
    this.laborForm.controls.CustomerNumber.setValue(null);
    this.customerValue1 = "";
    this.customerDataFn();

    if (this.customerValue) {
      const customerDemo = this.customerData.filter(
        (x) => x.CustomerClass === this.customerValue
      );
      this.components = customerDemo;
    }
  }

  customerChange() {
    this.showCustomerNo = true;
    this.customerValue = this.laborForm.controls.CustomerClass.value;

    if (this.customerValue) {
      const customerDemo = this.customerData.find(
        (x) => x.CustomerClass === this.customerValue
      );
      if (customerDemo) {
        this.laborForm.controls.CustomerNumber.setValue(
          customerDemo.CustomerNumber
        );
        this.customerValue1 = this.laborForm.controls.CustomerNumber.value;
        this.isFilter = false;
        this.components = this.backupComponent;
      } else {
        this.laborForm.controls.CustomerNumber.setValue(null);
        this.components = [];
        this.customerValue1 = this.laborForm.controls.CustomerNumber.value;
        this.isFilter = true;
      }
    }

    this.showCustomerNo = false;
  }

  customerChange1(event) {
    this.customerValue1 = this.laborForm.controls.CustomerNumber.value;
  }

  toggleDropdown(event) {
    this.dropDownToggle = !this.dropDownToggle;
    if (!this.dropDownToggle) {
      setTimeout(() => {
        this.trigger.closePanel();
        document.getElementById("searchText").blur();
      });
    } else {
      document.getElementById("searchText").focus();
    }
  }
  formCall(formValidObj) {
    if (this.laborForm.valid) {
      this.formValidCall(formValidObj);
    } else {
      this.formInvalidCall(formValidObj);
    }
  }
  formInvalidCall(formValidObj) {
    if (
      this.laborForm.controls.sell.value === "" ||
      this.laborForm.controls.sell.value === null ||
      this.laborForm.controls.sell.value === undefined
    ) {
      this.sellError = true;
      formValidObj.formStatus = "fail - " + this.translate.instant("sellError");
    }
    if (
      this.laborForm.controls.cost.value === "" ||
      this.laborForm.controls.cost.value === null ||
      !this.laborForm.controls.cost.value === undefined
    ) {
      this.costError = true;
      formValidObj.formStatus = "fail - " + this.translate.instant("costError");
    }
    this.submit = true;
  }
  formValidCall(formValidObj) {
    const labor = this.laborForm.value;
    const formData = this.setParam(labor);
    this.validateData(formData);
    if (this.globalErrorMsg !== "") {
      formValidObj.formStatus = "fail - " + this.globalErrorMsg;
    }
  }
  checkFormValidobj(formValidObj, value) {
    formValidObj.formContent2 = value;
    this.formCall(formValidObj);
    this.subjectService.setFormChangesStream({
      isFormSubmitted: true,
      formValue: formValidObj,
    });
  }
  checkCost(key, index, value, array) {
    if (key === "cost" || key === "sell") {
      value =
        index < array.length - 1
          ? value + "User Entered Value | "
          : value + "User Entered Value";
    } else {
      value =
        index < array.length - 1
          ? value + this.laborForm.value[key] + " | "
          : value + this.laborForm.value[key];
    }
  }

  showCustomerPopup() {
    const modalRef = (this.activeDrawerAddNew = this.modalService.open(
      CustomerdisplayComponent,
      { centered: true, windowClass: "customer-popup", keyboard: false }
    ));
    modalRef.componentInstance.isEdit = false;
    modalRef.componentInstance.customerData = this.customerData;
    modalRef.componentInstance.customerClass =
      this.laborForm.controls.CustomerClass.value;
    modalRef.result.then((data) => {
      if (data) {
        this.selectedCustomer = true;
        this.customerData = this.customerData.slice();
        this.ref.detectChanges();
        this.displaycustomerValue = data.CustomerNumber;
        this.laborForm.controls.CustomerClass.setValue(data.CustomerClass);
        this.laborForm.controls.CustomerNumber.setValue(data.CustomerNumber);
      }
    });
  }

  estimateAndApplyForMPE(sampleArray, rowData) {
    this.mpeService
      .editEstimateAndApply(this.planNumber, sampleArray)
      .subscribe(
        (data) => {
          if (data && data.IsSuccess) {
            this.checkEstimateStatus.emit();
            this.updateRowData = rowData;
            this.updateData();
          } else {
            this.globalErrorMsg = this.translate.instant("serverErr");
          }
        },
        (err) => {
          this.isLoading = false;
          if (err.status === 0 || err.status === 504) {
            this.globalErrorMsg = this.translate.instant("networkError");
          } else {
            this.globalErrorMsg = this.translate.instant("serverErr");
          }
        }
      );
  }

  updateData() {
    this.activeModal.close({
      estimateUpdated: true,
      response: this.updateRowData,
    });
  }
}
