import { Platform } from '@angular/cdk/platform';
import { DatePipe } from '@angular/common';
import { NativeDateAdapter } from 'saturn-datepicker';


export class MyDateAdapter extends NativeDateAdapter {
    private datePipe = new DatePipe(this.locale);


    constructor(matDateLocale: string){
        super(matDateLocale, new Platform(''));
    }
    format(date: Date): string{
        const format = localStorage.getItem('dateFormat');
        const result = this.datePipe.transform(date, format);
        return  result ? result : '';
    }
}
