export enum EstimateType {
  Machine = "Machine",
  Engine = "Engine",
}

export enum DefinitionTypes {
  Standard = "Standard",
  CCR = "CATCertifiedRebuild",
  MultiProduct = "MultiProduct",
}

export enum CustomGrouping {
  Standard = "Standard",
  Advanced = "Advanced",
}

export class Column {
  label: string = "";
  value: string = "";
  keyPath: string = "";
  show: boolean = false;
  checkbox?: boolean = false;
  cssClass: string = "";
  conditionalCss?: boolean = false;
  width: number = 0;
  searchEnabled?: boolean = false;
  isLock?: boolean = false;
  defenitionTypecheck?: boolean = false;
  date?: boolean = false;
  sortActive?: boolean = false;
  order?: boolean = true;
}

export class Estimate {
  Archived: boolean = false;
  CheckedOutBy: string = "";
  CreatedBy: string = "";
  CreatedOn: string = "";
  CurrencyType: string = "";
  CustomerClassCode: string = "";
  CustomerCustomId: string = "";
  CustomerEnterpriseId: string = "";
  CustomerName: string = "";
  DefinitionType: string = "";
  End: number;
  FactorImportCompletionPercent: number;
  FactorRevisionStatus: string = "";
  FactorRevisionTime: string = "";
  FamilyName: string = "";
  FinalizedAmendmentCount: number;
  HasPendingAmendment: false;
  HotelChargesSell: number;
  IncludePM: false;
  IsArticleOnly: false;
  IsDeleted: false;
  LastModifiedBy: string = "";
  LastModifiedOn: string = "";
  ManufacturerName: string = "";
  Model: string = "";
  OwningOrganization: string = "";
  OwningWorkgroup: string = "";
  PerDiemSell: number;
  PlanName: string = "";
  PlanNumber: number;
  Prefix: string = "";
  PriorOwningWorkgroup: string = "";
  Range: string = "";
  SellPerHour: number;
  Start: number;
  Status: string = "";
  TimeMarkedForDeletion: string = "";
  TimeToHardDelete: string = "";
  TimeToSoftDelete: string = "";
  TotalAdditionalSell: number;
  TotalContingencySell: number;
  TotalEscalatedSell: number;
  TotalLaborSell: number;
  TotalMiscellaneousSell: number;
  TotalPMSell: number;
  TotalPartsSell: number;
  TotalSell: number;
  TotalTravelSell: number;
  TotalTravelTrips: number;
  WasteDisposalSell: number;
}

export class FactorRevisionStatusInfo {
  CheckListStatuses: CheckListStatus[] = null;
  CompletionPercent: number = 0;
  Model: string = "";
  PlanName: string = "";
  PlanNumber: number = 0;
  Prefix: string = "";
  RevisionId: number = 0;
  Status: string = "";
}

export class CheckListStatus {
  Name: string = "";
  CompletionPercent: string = "";
}

export class ProductDetails {
  manufacturer: IdName;
  family: IdName;
  model: string;
  serialPrefix: string;
  serialRange: string;
  rangeList: any;
  flag: boolean;
  commercialEngine: any;
  commercialEngineData: any;
  CCRTypes: any;
  serialNumber?: string;
}

export class IdName {
  Identifier: string;
  Name: string;
}

export type DuplicateServiceOption = {
  CorrelationId: string | null;
  SMCSComponent: string | null;
  SMCSComponentDescription: string | null;
  FirstInterval: number | null;
  NextInterval: number | null;
  JobCode: string | null;
  JobCodeDescription: string | null;
  BeginSerialNumber: number | null;
  EndSerialNumber: number | null;
  LaborHours: string | null;
  IsSelected: boolean | null;
  ComponentQuantity: number | null;
  SMCSModifier: string | null;
  SMCSModifierDescription: string | null;
  SMCSQuantity: string | null;
  SMCSQuantityDescription: string | null;
  SMCSJobLocation: string | null;
  SMCSJobLocationDescription: string | null;
  SMCSWorkApplication: string | null;
  SMCSWorkApplicationDescription: string | null;
  SMCSJobCondition: string | null;
  SMCSJobConditionDescription: string | null;
  SMCSCabType: string | null;
  SMCSCabTypeDescription: string | null;
};

export type PartList = {
  CorrelationId: number | null;
  PartNumber: string | null;
  PartHeaderID: number | null;
  PartQuantity: number | null;
  PartReplacementPercent: number | null;
  PartTypeDescription: string | null;
  PartTypeCode: string | null;
  IsTool: boolean | null;
  IsFluid: boolean | null;
  PartDescription: string | null;
  SourceOfSupplyDescription: string | null;
  SourceOfSupplyCode: string | null;
  IsServiceable: boolean | null;
  MajorMinorCode: string | null;
  PPCCode: string | null;
  SISTypeCode: string | null;
  SISTypeDescription: string | null;
  PPCDescription: string | null;
  GroupNumber: string | null;
  CreditFlag: string | null;
  IsCCR: boolean | null;
  GenericPartInfo: string | null;
  invalidQty: boolean | null;
  invalidReplmt: boolean | null;
  Configuration: Configuration;
  Outcome: Outcome;
};

export type Configuration = {
  CorrelationId: number | null;
  Quantity: number | null;
  ReplacementPercentage: number | null;
  AdjustmentDetails: string | null;
};

export type Outcome = {
  CorrelationId: number | null;
  PartType: string | null;
  ExtendedPrice: ExtendedPrice | null;
  CoreCharges: StandardAdjusted | null;
  Price: StandardAdjusted | null;
  WasteManagementPrice: number | null;
  AppliedAdjustmentType: string | null;
};

export type ExtendedPrice = {
  Standard: StandardAdjusted | null;
  Adjusted: StandardAdjusted | null;
};

export type StandardAdjusted = {
  Sell: SellCost | null;
  Cost: SellCost | null;
};

export type SellCost = {
  CurrencyCode: string | null;
  Amount: number | null;
};

export enum CoreCreditFlag {
  Full = "Full",
  Partial = "Partial",
  None = "None",
}

export type ApplicationVariability = {
  CorrelationId: number;
  OverwriteUserEdits: boolean;
  SeverityLevel: string;
};
export interface Factor {
  category: string;
  percentage: string;
  isUserInputInvalid: boolean;
}

export type PriceInflationConfig = CorrelationIdIsActive | InflationConfiguration;
  
export type CorrelationIdIsActive = {
  CorrelationId: number;
  IsActive: boolean;
};

export type InflationConfiguration = {
  CorrelationId: number;
  InflationConfiguration: InflationData;
};

export type InflationData = {
  CorrelationId: number;
  InflationData: InflationDatum[];
  Notes: null;
};
export type InflationDatum = {
  PriceType: string;
  Rates: Rate[];
  Category: string;
};

export type Rate = {
  Start: number;
  End: number;
  Percentage: number | string;
};

export type EscalationFormValidity = {
  escalationFormValid: boolean;
};


export enum FirstIntervalAllStatus {
  AffectedByUnderCarriageVar = "AffectedByUnderCarriageVariabilityFactors",
  AffectedByMachineMaintenanceVar = "AffectedByMachineMaintenanceVariabilityFactors",
  AffectedByApplicationVar = "AffectedByApplicationVariabilityFactors",
}

export type ProductListColumns = {
  WorkGroup: string;
  Manufacturer: string;
  Model: string;
  SerialRange: string;
  UsageUnit: string;
  StartUsage: number;
  EndUsage: number;
  SerialNumber: string;
  UtilizationFactor: string;
};
  
export type MarketingOrganization = {
  Code: string | null;
  Description: string | null;
};

export type SalesArea = {
  Code: string | null;
  Description: string | null;
};

export type RegionSettings = {
  RegionSettings: MarketingOrganization | null;
};

export type QuickEstimatorSettings = {
  QuickEstimatorSettings: RegionSettings | null;
};

export type UserSettings = {
  QuickEstimatorSettings: QuickEstimatorSettings | null;
};

export type Organization = {
  Type: string | null;
  Id: string | null;
};

export type Workgroup = {
  WorkGroupName: string | null;
  WorkGroupId: string | null;
  Role: string | null;
};

export type Affiliation = {
  Organization: Organization | null;
  Role: string | null;
  Workgroups: Workgroup[] | null;
  OrganizationName: string | null;
};

export type UserDetails = {
  FirstName: string | null,
  LastName: string | null,
  Status: string | null,
  Affiliations: Affiliation[] | null,
  UserSettings: UserSettings | null,
  IdentityType: string | null,
  CWSID: string | null,
  CatRecId: string | null,
  AppId: string | null
}
 
export type AvailableItems = {
  MarketingOrganization: MarketingOrganization;
  SalesArea: SalesArea;
};

export type Setting = {
  SOCDealerCode: string | null;
  IsEnterpriseDealerCode: boolean | null;
  RegionSettings: {
    DefaultSelection: AvailableItems | null;
    AvailableItems: AvailableItems[] | null;
  } | null;
  PPIDSDealerCode: {
    DefaultSelection: string | null;
    AvailableItems: string[] | null;
  } | null;
  ECommerceDealerCode: {
    DefaultSelection: string | null;
    AvailableItems: string[] | null;
  } | null;
};

export type WorkgroupDetail = {
  Id: string | null;
  Name: string | null;
  Description: string | null;
  Type: string | null;
  Settings: null;
  UserDetails: UserDetails[] | null;
};

export type OrganizationDetail = {
  Id: Organization | null;
  Name: string | null;
  AssociatedOrganizationCodes: string[] | null;
  Workgroups: WorkgroupDetail[] | null;
  UserDetails: UserDetails[] | null;
  Status: string | null;
  DeleteTime: string | null;
};

export type PartsColumnConfig = {
  label: string | null,
  value: string | null,
  keyPath: string | null,
  show: boolean,
  cssClass: string | null,
  width: number,
  searchEnabled: boolean,
};

export type PartsRowConfig = {
  CorrelationId: number,
  Description: string | null,
  Sell: SellPartsConfig | null,
  Cost: CostPartsConfig | null,
  GenericPartInfo: string | null,
  IsFluid: boolean,
  Number: string | null,
  SourceOfSupply: string | null,
  MarketingOrganization: string | null,
  SalesArea: SalesArea | null,
  CustomerCode: string | null,
  PartTypeID: string | null
};

export type CurrencyConfig = {
  CurrencyCode: string | null,
  Amount: number
};

export type SellPartsConfig = {
  FullCredit: CurrencyConfig | null,
  PartialCredit: CurrencyConfig | null,
  MinimumSellValue: MinimumSellConfig | null,
  Source: string | null,
  RevisionId: number,
  Value: CurrencyConfig | null,
  UpdatedTime: string | null,
};

export type MinimumSellConfig = {
  Sell : CurrencyConfig | null
};

export type CostPartsConfig = {
  FullDealerNet: CurrencyConfig | null,
  PartialDealerNet: CurrencyConfig | null,
  Source: string | null,
  RevisionId: number,
  Value: CurrencyConfig | null,
  UpdatedTime: string | null
};


export enum WorkgroupRoles {
  ReadOnly = "ReadOnly",
  Advanced = "Advanced",
  Administrator = "Administrator"
}