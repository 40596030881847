import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'dateDifference'
})
export class DateDifferencePipe implements PipeTransform {
  currentDate = new Date();
  transform(value: unknown, args?: any): unknown {

    const date1 = new Date();
    const date2 = new Date(value.toString());
    let time = date2.getTime() - date1.getTime();
    if (args) {
      time = date1.getTime() - date2.getTime();
    }
    let diffDays = time / (1000 * 3600 * 24);
    if (args) {
      diffDays = Math.round(diffDays);

    }
    return diffDays;
  }

}
