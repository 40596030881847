export enum OrgRole {
    AdvancedUser = 'Advanced',
    BasicUser = 'Basic',
    Admin = 'Administrator',
    Intermediate = "Intermediate"
}
export enum WGRole {
    ReadOnly = 'ReadOnly',
    Advanced = 'Advanced',
    Admin = 'Administrator'
}
export enum SysRole {
    User = 'User',
    Admin = 'Administrator'
}
export enum OrgType {
    Global = 'Global',
    Unaffiliated = 'Unaffiliated',
    Consumer = 'Consumer',
    Dealer = 'Dealer',
    Corporate = 'Corporate'
}
export enum WGType {
    OrganizationSpecific = 'OrganizationSpecific',
    Global = 'Global'
}

export enum OrgRoleRename {
    Intermediate = "Standard"
}
