import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable, of, Subject } from "rxjs";
import { map, mergeMap } from "rxjs/operators";

import { RestService } from "@app/services/rest/rest.service";
import * as ServiceConstants from "@app/services/shared/app-constants";
import { DataService } from "@app/services/shared/storage/data.service";
import { OrgRole, WGRole, SysRole } from "@app/services/models/roles";
import { SubjectService } from "../data/subject.service";

export const GeneralUserPreferences = "UserPreferences";
export const UOM = "Unit of Measurement";
export const HidePartsWithZeroReplacement = "Hide Parts with Zero Replacement";
export const DefaultUserWGPreferences = "DefaultWG";
export const UserLanguage = "UserLanguage";
export const UserDateFormat = "UserDateFormat";
export const UserTimeFormat = "UserTimeFormat";

@Injectable({
  providedIn: "root",
})
export class UserService {
  public isProfileLoaded = new Subject<boolean>();
  constructor(
    private restService: RestService,
    private dataService: DataService,
    private http: HttpClient,
    public subjectService: SubjectService
  ) {}
  getPermissions(force: boolean = false): Observable<any> {
    return this.restService.httpGet(ServiceConstants.getUserProfile).pipe(
      mergeMap((res: any) => {
        this.dataService.isUserLoggedIn = true;
        this.dataService.canAccess = true; // not used for now
        this.dataService.userName =
          (res.FirstName ? res.FirstName + " " : "") +
          (res.LastName ? res.LastName : "");
        this.dataService.displayName =
          (res.FirstName && res.FirstName[0] ? res.FirstName[0] : "") +
          (res.LastName && res.LastName[0] ? res.LastName[0] : "");
        this.dataService.cwsId = res.UserAffliation.Profile.CWSID;
        this.dataService.CatRecId = res.UserAffliation.Profile.CatRecId;
        this.dataService.isUserActive = res.UserAffliation.Profile.Active;
        this.dataService.email = res.Email;
        this.dataService.phoneNumber = res.Phone;
        this.dataService.marketingOrg =
          res.UserSettings.QuickEstimatorSettings.RegionSettings.MarketingOrganization;
        this.dataService.salesArea =
          res.UserSettings.QuickEstimatorSettings.RegionSettings.SalesArea;
        this.dataService.ssoAffilitation = res.AffiliationType;
        // Organisation code from response
        this.dataService.organizationCode = res.OrganizationCode;
        // Dealer code replaced with organization code
        this.dataService.dealerCode = res.DealerCode;

        this.dataService.systemRole =
          res.UserAffliation.ApplicationPrivilegeAccess.SystemRole;

        // Allow access to Advanced Calculator if Sys role is Admin
        if (this.dataService.systemRole === SysRole.Admin) {
          this.dataService.canAccessCalc = true;
        }

        // set the defaut ORG and its WG
        const affiliations = res.UserAffliation.Profile.Affiliations.sort(
          (a, b) => a.Organization.Id.localeCompare(b.Organization.Id)
        );
        this.dataService.setUserAffiliation(res.UserAffliation);

        this.setUserPermissionsFromAffliations(affiliations);
        if (affiliations.length > 0) {
          this.dataService.defaultOrg = affiliations[0];
          this.dataService.orgRole = affiliations[0].Role;
          this.dataService.orgName = affiliations[0].OrganizationName;
          this.dataService.workgroups = affiliations[0].Workgroups; // Select WG default
          const wgs = affiliations[0].Workgroups;
          if (wgs.length > 0) {
            // Set the default WG
            this.dataService.defaultOrgWG = wgs[0];
            this.dataService.defaultWGRole = wgs[0].Role;
            this.subjectService.sendMessage(
              wgs[0].Role,
              "defaultWGRoleChanged"
            );
          }
        }
        this.dataService.isDealer = this.isDealer();
        if (this.dataService.orgRole.toLowerCase() !== "none") {
          return this.setDefaultWGPreference(this.dataService.workgroups, res);
        } else {
          return of(void 0);
        }
      })
    );
  }

  setDefaultWGPreference(OrgWorkGroups: any, res: any): Observable<any> {
    return this.getUserPreferences(DefaultUserWGPreferences).pipe(
      map((pref: any) => {
        if (pref.Settings.length > 0) {
          if (pref.Settings[0].Name === DefaultUserWGPreferences) {
            OrgWorkGroups.forEach((element) => {
              if (element.WorkGroupId === pref.Settings[0].Value) {
                this.dataService.defaultOrgWG = element;
                this.dataService.defaultWGRole = element.Role;
                this.subjectService.sendMessage(
                  element.Role,
                  "defaultWGRoleChanged"
                );
              }
            });
          }
        }
        this.isProfileLoaded.next(true);
        return res;
      })
    );
  }

  // TODO - Simplify this method
  setUserPermissionsFromAffliations(affiliations: any) {
    affiliations.forEach((org) => {
      if (org.Role === OrgRole.Admin) {
        this.dataService.isUserOrgAdmin = true;
        this.dataService.canAccessCalc = true; // allow user to Advanced Calc if any of organization role is Admin
      }
      if (org.Role === OrgRole.AdvancedUser) {
        this.dataService.canAccessCalc = true; // allow user to Advanced Calc if any of organization role is Advanced
      }
      // Todo - Intermediate role can also have adv level access only difference is cost ristriction
      if (org.Role === OrgRole.Intermediate) {
        this.dataService.isUserOrgIntermediate = true;
        this.dataService.canAccessCalc = true;
      }
      org.Workgroups.forEach((wg) => {
        if (wg.Role === WGRole.Admin) {
          this.dataService.isUserWGAdmin = true;
        }
      });
    });
    if (
      this.dataService.systemRole === SysRole.Admin ||
      this.dataService.isUserOrgAdmin ||
      this.dataService.isUserWGAdmin
    ) {
      this.dataService.isUserAdmin = true;
    } else {
      this.dataService.isUserAdmin = false;
    }
  }

  testCall(): Observable<any> {
    return this.restService.httpPost(ServiceConstants.testCall, {});
  }

  saveUserPreferences(params): Observable<any> {
    return this.restService.httpPost(
      ServiceConstants.saveUserPreferences,
      params
    );
  }

  getUserPreferences(preferenceName: any): Observable<any> {
    if (this.dataService.orgRole?.toLowerCase() !== "none") {
      return this.restService.httpGet(
        ServiceConstants.getUserPreferences + preferenceName
      );
    }
  }

  isDealer() {
    if (
      this.dataService.defaultOrg.Organization.Type === "Dealer" &&
      this.dataService.organizationCode !== "CAT"
    ) {
      return true;
    } else {
      return false;
    }
  }

  getWorkgroupDetails(): Observable<any> {
    return this.restService.httpGet(ServiceConstants.loadWorkGroup);
  }

  getUserAccessLevel(): Observable<any> {
    return this.restService.httpGet(ServiceConstants.getUserAccessLevel);
  }

  getRequestElevatedAccess(): Observable<any> {
    return this.restService.httpGet(ServiceConstants.getRequestElevatedAccess);
  }

  getUserListRequests(): Observable<any> {
    return this.restService.httpGet(ServiceConstants.getUserListRequests);
  }

  userRequestElevation(params): Observable<any> {
    return this.restService.httpPost(
      ServiceConstants.userRequestElevation,
      params
    );
  }

  sendQuickEstimatorRequest(content): Observable<any> {
    return this.restService.httpPost(ServiceConstants.sendQERequest, content);
  }

  getRequestsList(): Observable<any> {
    return this.restService.httpGet(ServiceConstants.getRequestsList);
  }

  saveNotificationSettings(content): Observable<any> {
    return this.restService.httpPost(
      ServiceConstants.saveNotificationSettings,
      content
    );
  }

  loadNotificationSettings(): Observable<any> {
    return this.restService.httpGet(ServiceConstants.loadNotificationSettings);
  }
  loadNotificationData(): Observable<any> {
    return this.restService.httpGet(ServiceConstants.loadNotificationData);
  }

  alertMessage(): Observable<any> {
    return this.restService.httpGet(ServiceConstants.alertMessage);
  }
}
