import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appLongPress]'
})
export class LongPressDirective {
  @Output()
  longPress = new EventEmitter();
  @Input() groupIndex;
  @Input() intervalIndex;
  @Input() interval;
  @Input() headerDetails;
  @Input() isEdit = true;
  timeOut = 0;
  intervalObj: any;

  private _timeout: any;

  @HostListener('touchstart', ['$event']) onTap(e) {
    document.getSelection().removeAllRanges();
    if (this.isEdit) {
      this.timeOut = 0;
      this.intervalObj = setTimeout(() => {
        this.updateTimeOut();
      }, 500);
    }
  }
  @HostListener('touchend', ['$event']) onTouchEnd(e) {
    document.getSelection().removeAllRanges();
    if (this.isEdit) {
      clearTimeout(this.intervalObj);
      if (this.timeOut >= 2) {
        const data = { group: this.groupIndex, interval: this.intervalIndex, item: this.interval, headerDetails: this.headerDetails };
        this.longPress.emit(data);
      }
      this.timeOut = 0;
    }
  }

  updateTimeOut() {
    this.timeOut++;
    if (this.timeOut >= 3) {
      clearTimeout(this.intervalObj);
    } else {
      this.intervalObj = setTimeout(() => {
        this.updateTimeOut();
      }, 1000);
    }
  }

}
