import { CurrencyPipe, getCurrencySymbol } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import _ from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { Utility } from "@app/common/utility/utils";
import { SubjectService } from "@app/services/data/subject.service";
import { EstimatePricingService } from "@app/services/estimate-pricing/estimate-pricing.service";
import { MultipleProductService } from "@app/services/multiple-product/multiple-product.service";
import * as constants from "@app/services/shared/app-constants";
import { DataService } from "@app/services/shared/storage/data.service";
import { WorkgroupService } from "@app/services/workgroup/workgroup.service";

@Component({
  selector: "app-add-edit-misc-prices",
  templateUrl: "./add-edit-misc-prices.component.html",
  styleUrls: ["./add-edit-misc-prices.component.scss"],
})
export class AddEditMiscPricesComponent implements OnInit {
  @Input() isEdit: boolean;
  @Input() miscRow: any;
  @Input() editOnlyCostSell: boolean;
  @Input() fullData: any;
  @Input() miscOverRideData: any;
  @Input() planNumber;
  @Input() showCurrency = false;
  @Input() item: any;
  @Input() isMpe = false;
  @Output() checkEstimateStatus = new EventEmitter();
  miscForm: FormGroup;
  submit = false;
  currencySymbol;
  costError = false;
  sellError = false;
  descriptionError = false;
  addEditJsonFormat = {
    MiscellaneousPrices: [],
  };
  isLoading = false;
  isFilter = false;
  backUpFullData: any;
  saveButtonDisable = false;
  currencyList = [];
  baseCurrency = null;
  public currencyFilterCtrl: FormControl = new FormControl();
  protected onDestroy = new Subject<boolean>();
  backupCurrency: any[];
  globalErrorMsg = "";
  isUserOrgIntermediate: boolean = false;
  utility = new Utility();
  minSellError = false;
  minSellErrorMsg = "";

  constructor(
    public activeModal: NgbActiveModal,
    private subjectService: SubjectService,
    private fb: FormBuilder,
    private dataService: DataService,
    private estimatePricingService: EstimatePricingService,
    private workgroupService: WorkgroupService,
    private currencyPipe: CurrencyPipe,
    public mpeService: MultipleProductService,
    private ref: ChangeDetectorRef,
    private translate: TranslateService
  ) {
    this.miscForm = this.fb.group({
      Code: [null, [Validators.required]],
      storeCode: [null, [Validators.required]],
      customerCode: [null],
      cost: [
        null,
        [Validators.required, Validators.pattern(/^[0-9]*(\.[0-9]+)?$/)],
      ],
      sell: [
        null,
        [Validators.required, Validators.pattern(/^[0-9]*(\.[0-9]+)?$/)],
      ],
      currencyCode: [null, [Validators.required]],
      description: [null],
    });

    this.isUserOrgIntermediate = this.dataService.isUserOrgIntermediate;
    if (this.isUserOrgIntermediate) {
      this.miscForm = this.utility.removeFormsMandatoryFields(this.miscForm, [
        "cost",
      ]);
    }
  }

  ngOnInit(): void {
    this.currencyList = this.dataService.getWGCurencyList();
    this.backupCurrency = this.currencyList;
    if (this.isEdit) {
      this.updateFormData();
      this.saveButtonDisable = true;
      this.currencySymbol = getCurrencySymbol(
        this.miscRow.Pricing.Sell.CurrencyCode,
        "wide"
      );
      this.miscForm.controls.currencyCode.setValue(
        this.miscRow.Pricing.Sell.CurrencyCode
      );
    } else {
      this.saveButtonDisable = false;
      this.currencySymbol = "";
      this.baseCurrency = this.workgroupService.getCurrencyCode();
      this.miscForm.controls.currencyCode.setValue(this.baseCurrency);
      this.currencySymbol = getCurrencySymbol(this.baseCurrency, "wide");
    }
    this.currencyFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterMyCurrency();
      });
  }
  close() {
    this.activeModal.close();
  }
  updateFormData() {
    this.miscForm.controls.Code.setValue(this.miscRow.Code);
    this.miscForm.controls.storeCode.setValue(this.miscRow.StoreCode);
    if (this.miscRow.Pricing.Cost) {
      this.miscForm.controls.cost.setValue(this.miscRow.Pricing.Cost.Amount);
    }
    this.miscForm.controls.sell.setValue(this.miscRow.Pricing.Sell.Amount);
    this.miscForm.controls.description.setValue(this.miscRow.Description);
  }

  filterMyCurrency() {
    const search = this.currencyFilterCtrl.value.toLowerCase();
    const res = [];
    if (!search) {
      this.currencyList = this.backupCurrency;
    } else {
      this.currencyList = this.backupCurrency;
      let currencyTemp = [];
      let currencyTempNew = [];
      currencyTemp = this.currencyList;
      if (currencyTemp && currencyTemp.length > 0) {
        currencyTempNew = currencyTemp.filter((elem) =>
          elem.toLowerCase().includes(search)
        );
      }
      if (currencyTempNew.length > 0) {
        this.currencyList = currencyTempNew;
      }
    }
    this.ref.detectChanges();
  }
  costValueChange(value) {
    const val = value.value;
    this.saveButtonDisable = false;
    if (val === "") {
      this.costError = true;
    } else {
      if (val.toString().match(constants.numberRegex)) {
        this.costError = false;
      } else {
        this.costError = true;
      }
    }
  }
  sellValueChange(value) {
    const val = value.value;
    this.saveButtonDisable = false;
    if (val === "") {
      this.sellError = true;
    } else {
      if (val.toString().match(constants.numberRegex)) {
        this.sellError = false;
      } else {
        this.sellError = true;
      }
    }

    if (this.isUserOrgIntermediate) {
      if (this.miscRow.MinimumSellValue) {
        this.minSellError = this.utility.minimumNumberValidation(
          this.miscRow.MinimumSellValue.Sell.Amount,
          val
        );
        this.minSellErrorMsg = `${this.translate.instant(
          "lessThanMinSellErrorMsg"
        )} ${this.miscRow.MinimumSellValue.Sell.Amount}`;
      }
    }
  }

  valueChange(event) {
    this.saveButtonDisable = false;
  }
  submitForm(event) {
    let value = "";

    const formValidObj = {
      formName: "Add/Edit Labor Form - Settings",
      formContent: this.miscForm.value.storeCode,
      formContent2: null,
      formStatus: "success",
    };
    this.globalErrorMsg = "";
    Object.keys(this.miscForm.value).forEach((key, index, array) => {
      if (this.miscForm.value[key] !== null) {
        if (key === "cost" || key === "sell") {
          const temp =
            index < array.length - 1
              ? "User Entered Value | "
              : "User Entered Value";
          value = value + temp;
        } else {
          const temp =
            index < array.length - 1
              ? this.miscForm.value[key] + " | "
              : this.miscForm.value[key];
          value = value + temp;
        }
      }
    });

    formValidObj.formContent2 = value;
    this.formCall(formValidObj);
    this.subjectService.setFormChangesStream({
      isFormSubmitted: true,
      formValue: formValidObj,
    });
  }

  setParam(formData) {
    return {
      Code: formData.Code,
      Pricing: {
        Sell: {
          CurrencyCode: this.miscForm.controls.currencyCode.value,
          Amount: Number(formData.sell),
        },
        Cost: {
          CurrencyCode: this.miscForm.controls.currencyCode.value,
          Amount: Number(formData.cost),
        },
      },
      CustomerCode: formData.customerCode,
      StoreCode: formData.storeCode,
      Description: formData.description,
    };
  }
  validateData(formData) {
    let isDuplicate = false;
    const indexOfFullData = this.fullData.indexOf(this.miscRow);
    const comparableString = this.getFormDataString(formData);
    this.fullData.forEach((el, index) => {
      const compareString = this.getCompareString(el);
      if (comparableString === compareString) {
        if (this.isEdit && indexOfFullData === index) {
          isDuplicate = false;
        } else {
          isDuplicate = true;
        }
        return;
      }
    });
    if (!isDuplicate) {
      if (this.editOnlyCostSell) {
        const formValueData = this.setParam(this.miscForm.value);
        const payload = {
          CorrelationId: this.miscRow.CorrelationId,
          Notes: this.miscRow.Notes,
          ...formValueData,
        };
        this.backUpFullData = _.clone(this.fullData);
        this.backUpFullData[this.backUpFullData.indexOf(this.miscRow)] =
          payload;
        this.miscOverRideData.MiscellaneousPriceFactorConfiguration =
          this.backUpFullData;
        this.estimateApplyAPI(payload);
      } else {
        this.handleAPICalls(formData);
      }
    } else {
      this.globalErrorMsg = this.translate.instant("Lbl_misc_validation");
    }
  }
  getFormDataString(formData: any) {
    if (formData.Code === null) {
      formData.Code = "";
    }
    if (formData.StoreCode === null) {
      formData.StoreCode = "";
    }

    if (formData.CustomerCode === null) {
      formData.CustomerCode = "";
    }
    return (
      formData.Code.toLowerCase() +
      formData.StoreCode.toLowerCase() +
      formData.CustomerCode.toLowerCase()
    );
  }

  getCompareString(el: any) {
    if (el.Code === null) {
      el.Code = "";
    }
    if (el.StoreCode === null) {
      el.StoreCode = "";
    }

    if (el.CustomerCode === null) {
      el.CustomerCode = "";
    }
    return (
      el.Code.toLowerCase() +
      el.StoreCode.toLowerCase() +
      el.CustomerCode.toLowerCase()
    );
  }

  estimateApplyAPI(rowData: any) {
    if (this.isMpe) {
      this.saveForMPE(rowData);
    } else {
      this.isLoading = true;
      let configurationData = _.cloneDeep(
        this.estimatePricingService.configuration.FactorOverrides
          .MiscellaneousPrices.MiscellaneousPriceFactorConfiguration
      );
      const arr1Map = new Map(
        configurationData.map((o) => [this.getCompareString(o), o])
      );
      let isPushed = false;
      configurationData.forEach((element, index) => {
        if (
          arr1Map.get(this.getCompareString(this.miscRow)) &&
          this.getCompareString(element) === this.getCompareString(this.miscRow)
        ) {
          rowData.MinimumSellValue = this.miscRow.MinimumSellValue;
          configurationData[index] = rowData;
          isPushed = true;
        }
      });
      if (!isPushed) {
        configurationData.push(rowData);
      }
      const sampleArray = [];
      const sampleJson = _.cloneDeep(this.miscOverRideData);
      configurationData = !this.isUserOrgIntermediate
        ? configurationData
        : this.setNullForMiscCost(configurationData);
      sampleJson.MiscellaneousPriceFactorConfiguration = JSON.parse(
        JSON.stringify(configurationData)
      );
      sampleArray.push(sampleJson);
      this.estimatePricingService
        .editEstimateAndApply(this.planNumber, sampleArray)
        .subscribe(
          (data) => {
            if (data && data.CheckoutResult && data.CheckoutResult.Success) {
              if (data.OverallStatus === "Success") {
                this.activeModal.close({
                  estimateUpdated: true,
                  response: rowData,
                });
                this.estimatePricingService.setEstimateEditResponse(data);
                this.subjectService.sendMessage(
                  { refreshBlendingData: true },
                  "eventEmitListen"
                );
              } else {
                this.isLoading = false;
                this.globalErrorMsg = this.translate.instant("serverErr");
              }
            } else {
              this.activeModal.close({ estimateLocked: true });
            }
          },
          (err) => {
            this.isLoading = false;
            if (err.status == 0 || err.status == 504) {
              this.globalErrorMsg = this.translate.instant("networkError");
            } else {
              this.globalErrorMsg = this.translate.instant("serverErr");
            }
          }
        );
    }
  }

  // TODO - To create reusable functions across in utility file
  setNullForMiscCost(payload) {
    for (let i = 0; i < payload.length; i++) {
      payload[i].Pricing.Cost = null;
    }
    return payload;
  }

  handleAPICalls(formData) {
    this.isLoading = true;
    if (!this.isEdit) {
      this.addEditJsonFormat.MiscellaneousPrices.push(formData);
      this.mergeMiscApiCall();
    } else {
      this.backUpFullData = _.clone(this.fullData);
      this.backUpFullData[this.backUpFullData.indexOf(this.miscRow)] = formData;
      this.addEditJsonFormat.MiscellaneousPrices = this.backUpFullData;
      this.saveMiscApiCall();
    }
  }

  mergeMiscApiCall() {
    this.workgroupService.mergeWorkgroupData(this.addEditJsonFormat).subscribe(
      (data) => {
        this.activeModal.close(this.addEditJsonFormat.MiscellaneousPrices[0]);
      },
      (err) => {
        this.isLoading = false;
        if (err.status == 0 || err.status == 504) {
          this.globalErrorMsg = this.translate.instant("networkError");
        } else {
          this.globalErrorMsg = this.translate.instant("serverErr");
        }
      }
    );
  }

  saveMiscApiCall() {
    this.workgroupService.saveWorkgroupData(this.addEditJsonFormat).subscribe(
      (data) => {
        const formData = this.setParam(this.miscForm.value);
        this.activeModal.close(formData);
      },
      (err) => {
        this.isLoading = false;
        if (err.status == 0 || err.status == 504) {
          this.globalErrorMsg = this.translate.instant("networkError");
        } else {
          this.globalErrorMsg = this.translate.instant("serverErr");
        }
      }
    );
  }
  currencyChange(event) {
    this.saveButtonDisable = false;
    this.currencySymbol = getCurrencySymbol(event.value, "wide");
    const sellValue = this.miscForm.value.sell;
    const costValue = this.miscForm.value.cost;
    if (sellValue && costValue) {
      this.setCurrentCostAndSellValue();
    }
  }
  setCurrentCostAndSellValue() {
    let sellValue = this.miscForm.value.sell;
    let costValue = this.miscForm.value.cost;
    const currencyCode = this.miscForm.value.currencyCode;
    sellValue = this.formatCurrency(sellValue, currencyCode);
    costValue = this.formatCurrency(costValue, currencyCode);
    this.miscForm.controls.sell.setValue(sellValue);
    this.miscForm.controls.cost.setValue(costValue);
  }
  formatCurrency(value, code) {
    return this.currencyPipe.transform(value, code).replace(/[^\d.]/gm, "");
  }
  validFormCall(formValidObj) {
    const misc = this.miscForm.value;
    const formData = this.setParam(misc);
    this.validateData(formData);
    if (this.globalErrorMsg !== "") {
      formValidObj.formStatus = "fail - " + this.globalErrorMsg;
    }
  }
  formInvalidCall(formValidObj) {
    if (
      this.miscForm.controls.sell.value === "" ||
      this.miscForm.controls.sell.value === null ||
      this.miscForm.controls.sell.value === undefined
    ) {
      this.sellError = true;
      formValidObj.formStatus = "fail - " + this.translate.instant("sellError");
    }
    if (
      this.miscForm.controls.cost.value === "" ||
      this.miscForm.controls.cost.value === null ||
      !this.miscForm.controls.cost.value === undefined
    ) {
      this.costError = true;
      formValidObj.formStatus = "fail - " + this.translate.instant("costError");
    }
    this.submit = true;
  }
  formCall(formValidObj) {
    if (this.miscForm.valid) {
      this.validFormCall(formValidObj);
    } else {
      this.formInvalidCall(formValidObj);
    }
  }

  saveForMPE(rowData) {
    this.isLoading = true;
    let configurationData = _.cloneDeep(
      this.mpeService.productSummary.CommonConfiguration.FactorOverrides
        .MiscellaneousPrices.MiscellaneousPriceFactorConfiguration
    );
    let isPushed = false;
    configurationData.forEach((element, index) => {
      if (element.CorrelationId === this.miscRow.CorrelationId) {
        isPushed = true;
      }
    });
    if (!isPushed) {
      configurationData.push(rowData);
    }
    const sampleArray = [];
    const sampleJson = _.cloneDeep(this.miscOverRideData);
    configurationData = !this.isUserOrgIntermediate
      ? configurationData
      : this.setNullForMiscCost(configurationData);
    sampleJson.MiscellaneousPriceFactorConfiguration = JSON.parse(
      JSON.stringify(configurationData)
    );
    sampleArray.push(sampleJson);
    this.mpeService
      .editEstimateAndApply(this.mpeService.planNumbers, sampleArray)
      .subscribe(
        (data) => {
          if (data && data.IsSuccess) {
            this.checkEstimateStatus.emit();
            this.activeModal.close({
              estimateUpdated: true,
              response: rowData,
            });
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.activeModal.close({ estimateLocked: true });
          }
        },
        (err) => {
          this.isLoading = false;
          if (err.status == 0 || err.status == 504) {
            this.globalErrorMsg = this.translate.instant("networkError");
          } else {
            this.globalErrorMsg = this.translate.instant("serverErr");
          }
        }
      );
  }
}
