export enum TableDescription {
    AddUsers = 'Add users to the workgroup while workgroup creation',
    ListDuplicateSO =  'Table displays duplicate service options',
    LaborData = 'Table contains Labor data',
    ConfigureAlert = 'Configure alert for the application downtime, emergency, informational',
    ScheduleAlert = 'List all the scheduled alert in the application',
    ConfirmAlert = 'Show compare estimate data',
    ServiceJobEstimate = 'Display service job details while estimate comparision',
    ServiceParts = 'Table contains service parts data while estaimte comparision',
    TravelDetails = 'Table contains travel value for setting page',
    ServiceInterval = 'Table contains service interval details while estimate comparision',
    ServiceJob = 'Table contains service job details while estimate comparision',
    IncludeTravel = 'Table contains included travel factor',
    GlobalParts = 'Table contains application setting parts details',
    GlobalTravel = 'Table contains application setting travel details',
    ProductSearch = 'Table contains the search product details for multiproduct estimate',
    QEWizard = 'Table constains the PM wizards details for the quick estimator',
    ExportSetting = 'Table contains the setting value for the export of engine performance',
    EstimateTravelDetails = 'Table contains travel details while estimate creation'
}
