import { hasModifierKey } from '@angular/cdk/keycodes';
import { GlobalPositionStrategy, OverlayRef } from '@angular/cdk/overlay';
import { Location } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CcModalContainerComponent } from '@app/components/modal/modal.component.container';
import { ModalPosition } from '@app/components/modal/modal.component.model';

let uniqueId = 0;

/**
 * Track the modal state
 */
export const enum CcModalState {
  OPEN,
  CLOSING,
  CLOSED
}

export class CcModalRef<T, R = any> {
  componentInstance: T;

  closeOnEsc: boolean | undefined = this._containerInstance._config.closeOnEsc;

  private readonly _afterClosed = new Subject<R | undefined>();

  private _state = CcModalState.OPEN;

  private _result: R | undefined;

  readonly onBackdropClick: Observable<MouseEvent>;
  protected onClose$: Subject<any> = new Subject();
  readonly onClose: Observable<any> = this.onClose$.asObservable();

  /**
   * Close the modal window
   *
   * @param dialogResult
   */
  close(dialogResult?: R) {
    if (this._containerInstance._config.panelClass === 'cc-flag-message-panel') {
      this._overlayRef.hostElement.classList.add('fade-out');
      setTimeout(() => {
        this._result = dialogResult;
        this._overlayRef.detach();
        this._overlayRef.dispose();
        this.onClose$.next(dialogResult);
        this.onClose$.complete();
        this._state = CcModalState.CLOSING;
      }, 500);

      return;
    }
    this._result = dialogResult;
    this._overlayRef.detach();
    this._overlayRef.dispose();
    this.onClose$.next(dialogResult);
    this.onClose$.complete();
    this._state = CcModalState.CLOSING;
  }

  constructor(
    private _overlayRef: OverlayRef,
    public _containerInstance: CcModalContainerComponent,
    _location?: Location,
    readonly id: string = `cc-modal-${uniqueId++}`
  ) {
    /**
     * Listerning backdrop click
     */
    this.onBackdropClick = this._overlayRef.backdropClick();
    _containerInstance._id = id;

    /**
     * Gets an observable that emits when the overlay has been detached.
     */
    _overlayRef.detachments().subscribe(() => {
      this._afterClosed.next(this._result);
      this._afterClosed.complete();
      this.componentInstance = null;
      this._overlayRef.dispose();
    });

    /**
     * Listern observable of keydown events targeted to this overlay.
     */
    _overlayRef
      .keydownEvents()
      .pipe(
        filter(event => {
          return (
            (event.key === 'Escape' || event.key === 'Esc') && !this.closeOnEsc && !hasModifierKey(event)
          );
        })
      )
      .subscribe(event => {
        event.preventDefault();
        this.close();
      });
  }
  /**
   * Create a obervable for return data when modal closed
   */
  afterClosed(): Observable<R | undefined> {
    return this._afterClosed.asObservable();
  }

  /**
   *Update Modal position

   * @param position
   */
  updatePosition(position?: ModalPosition): this {
    const strategy = this._getPositionStrategy();

    if (position && (position.left || position.right)) {
      position.left ? strategy.left(position.left) : strategy.right(position.right);
    } else {
      strategy.centerHorizontally();
    }

    if (position && (position.top || position.bottom)) {
      position.top ? strategy.top(position.top) : strategy.bottom(position.bottom);
    } else {
      strategy.centerVertically();
    }

    this._overlayRef.updatePosition();

    return this;
  }

  /**
   * Get the current state of the modal overlay
   */

  getState(): CcModalState {
    return this._state;
  }
  /**
   * Listern the global position strategy
   */
  private _getPositionStrategy(): GlobalPositionStrategy {
    return this._overlayRef.getConfig().positionStrategy as GlobalPositionStrategy;
  }
}
