import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { SubjectService } from '@app/services/data/subject.service';

@Component({
  selector: 'app-add-edit-existing-pds',
  templateUrl: './add-edit-existing-pds.component.html',
  styleUrls: ['./add-edit-existing-pds.component.scss'],
})
export class AddEditExistingPDSComponent implements OnInit {
  existingPDSForm: FormGroup;
  isLoading = false;
  isEdit = true;
  saveButtonDisable = false;
  bsfcLabel: any;
  fuelRateLabel: any;
  engineGroupType: any;
  submit = false;
  showGeneratorError = false;
  speedError = false;
  formError = false;
  index = -1;

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public pdsData,
    private dialogRef: MatDialogRef<AddEditExistingPDSComponent>,
    public fb: FormBuilder,
    private subjectService: SubjectService
  ) {}

  ngOnInit() {
    this.engineGroupType = this.pdsData.engineGroupType;
    this.isEdit = this.pdsData.isEdit;
    this.index = this.pdsData.index;
    this.existingPDSForm = this.fb.group({
      PdsID: [this.pdsData.pdsId],
      EngineSpeed: [
        null,
        [Validators.required, Validators.pattern(/^[0-9]*(\.[0-9]+)?$/)],
      ],
      EngineBrakeKiloWatt: [
        null,
        [Validators.required, Validators.pattern(/^[0-9]*(\.[0-9]+)?$/)],
      ],
      GeneratorEfficiency: [
        null,
        [Validators.required, Validators.pattern(/^[0-9]*(\.[0-9]+)?$/)],
      ],
      BrakeSpecificFuelConsumption: [
        null,
        [Validators.required, Validators.pattern(/^[0-9]*(\.[0-9]+)?$/)],
      ],
      FuelRate: [
        null,
        [Validators.required, Validators.pattern(/^[0-9]*(\.[0-9]+)?$/)],
      ],
      EngineElectricalKiloWatt: [
        null,
        [Validators.required, Validators.pattern(/^[0-9]*(\.[0-9]+)?$/)],
      ],
    });
    if (this.isEdit) {
      this.setValues();
    }
  }

  setValues() {
    this.existingPDSForm.controls.EngineSpeed.setValue(
      this.pdsData.rowData.EngineSpeed
    );
    this.existingPDSForm.controls.EngineBrakeKiloWatt.setValue(
      this.pdsData.rowData.EngineBrakeKiloWatt
    );
    this.existingPDSForm.controls.GeneratorEfficiency.setValue(
      this.pdsData.rowData.GeneratorEfficiency
    );
    this.existingPDSForm.controls.BrakeSpecificFuelConsumption.setValue(
      this.pdsData.rowData.BrakeSpecificFuelConsumption
    );
    this.existingPDSForm.controls.FuelRate.setValue(
      this.pdsData.rowData.FuelRate
    );
    this.existingPDSForm.controls.EngineElectricalKiloWatt.setValue(
      this.pdsData.rowData.EngineElectricalKiloWatt
    );
  }

  close() {
    this.dialogRef.close();
  }
  saveExistingPDS() {
    let value = '';

    const formValidObj = {
      formName: 'Add/Edit Exisiting PDS',
      formContent: this.existingPDSForm.value.PdsID,
      formContent2: null,
      formStatus: 'success',
    };

    Object.keys(this.existingPDSForm.value).forEach((key, index, array) => {
      if (this.existingPDSForm.value[key] !== null) {
        value = index < array.length - 1
          ? ( value + this.existingPDSForm.value[key] + ' | ')
          : ( value + this.existingPDSForm.value[key]);
      }
    });
    formValidObj.formContent2 = value;

    this.submit = true;
    if (
      this.existingPDSForm.valid &&
      !this.pdsData.isEdit &&
      this.showGeneratorError === false
    ) {
      this.subjectService.setFormChangesStream({
        isFormSubmitted: true,
        formValue: formValidObj,
      });
      this.dialogRef.close(this.generateExistingpds());
    } else if (
      this.existingPDSForm.valid &&
      this.pdsData.isEdit &&
      this.showGeneratorError === false
    ) {
      this.pdsData.fulldata.splice(this.index, 1, this.generateExistingpds());
      this.subjectService.setFormChangesStream({
        isFormSubmitted: true,
        formValue: formValidObj,
      });
      this.dialogRef.close(this.pdsData.fulldata);
    }
  }
  generateExistingpds() {
    return {
      BrakeSpecificFuelConsumption: Number(
        this.existingPDSForm.controls.BrakeSpecificFuelConsumption.value
      ),
      EngineBrakeKiloWatt: Number(
        this.existingPDSForm.controls.EngineBrakeKiloWatt.value
      ),
      EngineElectricalKiloWatt: Number(
        this.existingPDSForm.controls.EngineElectricalKiloWatt.value
      ),
      EngineSpeed: Number(this.existingPDSForm.controls.EngineSpeed.value),
      FuelRate: Number(this.existingPDSForm.controls.FuelRate.value),
      GeneratorEfficiency: Number(
        this.existingPDSForm.controls.GeneratorEfficiency.value
      ),
      PdsID: this.existingPDSForm.controls.PdsID.value,
    };
  }
  valueChange(event, value?) {
    // Engine EKW / Engine BKW * 100
    if (
      this.existingPDSForm.controls.EngineElectricalKiloWatt.value &&
      isNaN(this.existingPDSForm.controls.EngineElectricalKiloWatt.value) ===
        false &&
      this.existingPDSForm.controls.EngineBrakeKiloWatt.value &&
      isNaN(this.existingPDSForm.controls.EngineBrakeKiloWatt.value) ===
        false &&
      this.existingPDSForm.controls.EngineBrakeKiloWatt.value > 0
    ) {
      this.existingPDSForm.controls.GeneratorEfficiency.setValue(
        Number(
          (this.existingPDSForm.controls.EngineElectricalKiloWatt.value /
            this.existingPDSForm.controls.EngineBrakeKiloWatt.value) *
            100
        ).toFixed(2)
      );
    } else {
      this.existingPDSForm.controls.GeneratorEfficiency.setValue('');
    }
    if (
      this.existingPDSForm.controls.GeneratorEfficiency.value < 0 ||
      this.existingPDSForm.controls.GeneratorEfficiency.value > 100
    ) {
      this.showGeneratorError = true;
    } else {
      this.showGeneratorError = false;
    }
    if (value === 'speed') {
      if (event.value >= 10 && event.value <= 100000) {
        this.speedError = false;
        this.formError = false;
      } else {
        this.speedError = true;
        this.formError = true;
      }
    }
  }
}
