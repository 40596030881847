import { Component } from '@angular/core';
import { TableDescription } from '@app/services/models/misc/table-description';
import { EnginePerformanceService } from '@app/services/workgroup/engineperformance.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-export-as',
  templateUrl: './export-as.component.html',
  styleUrls: ['./export-as.component.scss'],
})
export class ExportAsComponent {
  radioType = 'excel';

  constructor(
    public enginePerformanceService: EnginePerformanceService,
    public activeModal: NgbActiveModal
  ) {}

  exportEnginePerformanceAsExcel() {
    const payload = {
      DataType: 'Combined',
      ContentType: 'Excel',
    };
    this.enginePerformanceService
      .exportEnginePerformanceAs(payload)
      .subscribe((response) => {
        const file = new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const downloadFile = (blob, fileName) => {
          const link = document.createElement('a');
          // create a blobURI pointing to our Blob
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          // some browser needs the anchor to be in the doc
          document.body.append(link);
          link.click();
          link.remove();
          // in case the Blob uses a lot of memory
          window.addEventListener(
            'focus',
            (e) => URL.revokeObjectURL(link.href),
            { once: true }
          );
        };
        downloadFile(file, 'EnginePerformance.xlsx');
        this.activeModal.close();
      });
  }
  exportEnginePerformanceAsText() {
    const payload = {
      DataType: 'Combined',
      ContentType: 'Text',
    };
    this.enginePerformanceService
      .exportEnginePerformanceAs(payload)
      .subscribe((response) => {
        const file = new Blob([response], {
          type: 'application/zip',
        });
        const downloadFile = (blob, fileName) => {
          const link = document.createElement('a');
          // create a blobURI pointing to our Blob
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          // some browser needs the anchor to be in the doc
          document.body.append(link);
          link.click();
          link.remove();
          // in case the Blob uses a lot of memory
          window.addEventListener(
            'focus',
            (e) => URL.revokeObjectURL(link.href),
            { once: true }
          );
        };
        downloadFile(file, 'EnginePerformance.zip');
        this.activeModal.close();
      });
  }
  close() {
    this.activeModal.close();
  }
  exportEnginePerformance() {
    if (this.radioType === 'excel') {
      this.exportEnginePerformanceAsExcel();
    } else if (this.radioType === 'text') {
      this.exportEnginePerformanceAsText();
    }
  }
  radiochange(type) {
    this.radioType = type;
  }
  getExportTableDesc = () => {
    let tableDesc = TableDescription.ExportSetting;
    return tableDesc;
  }
}
