import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertButton } from '@app/modals/alert-button';
import { SubjectService } from '@app/services/data/subject.service';
import { DataService } from '@app/services/shared/storage/data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-select-currency',
  templateUrl: './select-currency.component.html',
  styleUrls: ['./select-currency.component.scss']
})
export class SelectCurrencyComponent implements OnInit {

  @Input() alertMessage: string;
  @Input() alertHeader: string;
  @Input() buttons?: (AlertButton | string)[];
  @Input() isConfirm = false;
  @Input() isMessage = false;
  @Input() noCloseIcon = false;
  @Input() currencyList: any;
  @Input() selectedCurrency: any;
  public currencyFilterCtrl: FormControl = new FormControl();
  protected onDestroy = new Subject<boolean>();
  backupCurrency: any;
  constructor(
    public activeModal: MatDialogRef<SelectCurrencyComponent>,
    public ref: ChangeDetectorRef,
    private dataService: DataService,
    private subjectService: SubjectService

  ) { }

  ngOnInit() {
    this.backupCurrency = this.currencyList;
    this.currencyFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterMyCurrency();
      });
    this.listenEventChange();
  }
  listenEventChange() {
    this.subjectService.eventEmitListener$.subscribe(change => {
      if (change && change.currencyListUpdated) {
        this.currencyList = this.dataService.currencyList;
        this.backupCurrency = this.currencyList;
        this.selectedCurrency = change.baseCurrency;
      }
    });
  }

  close() {
    this.activeModal.close(this.selectedCurrency);
  }
  save() {
    this.activeModal.close(this.selectedCurrency);
  }

  filterMyCurrency() {
    const search = this.currencyFilterCtrl.value.toLowerCase();
    const res = [];
    if (!search) {
      this.currencyList = this.backupCurrency;
    } else {
      this.currencyList = this.backupCurrency;
      let currencyTemp = [];
      let currencyTempNew = [];
      currencyTemp = this.currencyList;
      if (currencyTemp && currencyTemp.length > 0) {
        currencyTempNew = currencyTemp.filter((elem) => (elem.toLowerCase().includes(search)));
      }
      if (currencyTempNew.length > 0) {

          this.currencyList = currencyTempNew;
        
      } 
      
    }
    this.ref.detectChanges();
  }


}

