
import { Component, Input, OnInit } from '@angular/core';
import { DrawerService } from '@cat-digital-workspace/shared-ui-core/drawer';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';


@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  @Input() accordionData;
  @Input() activeDrawer;
  @Input() isEdit;
  @Input() estimateSaveJSON;
  @Input() activeTab;
  @Input() masterAccordionData;
  @Input() estimateHeader;
  @Input() pmsOnly;
  @Input() save;
  @Input() filterInComp;
  accordionDataCopy = [];
  selectedTab = 'component';
  intervalsForFilter = [];
  modifierCodesForFilter = [];
  SMCSObjectsForFilter = [];
  SMCSObjectsForTravelFilter = [];
  SMCSObjectsForIntervalFilter = [];
  SMCSObjectsForContingencyFilter = [];

  AllJobsForFilter = [];
  AllJobsForInterval = [];
  AllJobsForContingency = [];
  jobLocationsForFilter = [];
  quantityCodesForFilter = [];
  workApplicationCodesForFilter = [];
  jobConditionsForFilter = [];
  cabTypeForFilter = [];
  jobsForFilter = [];
  backupData = [];

  includeComponent = this.translate.instant('includeEstimate');
  excludeComponent = this.translate.instant('excludeEstimate');
  includeContingencytooltip = this.translate.instant('includeContingency');
  excludeContingencytooltip = this.translate.instant('excludeContingency');
  excludeTraveltooltip = this.translate.instant('excludeTravel');
  includeTraveltooltip = this.translate.instant('includeTravel');
  objectReferences = {
    intervalCode: 'SMCSComponent',
    modifier: 'SMCSModifier',
    jobLocation: 'SMCSJobLocation',
    quantityCode: 'SMCSQuantity',
    workApplication: 'SMCSWorkApplication',
    jobCondition: 'SMCSJobCondition',
    cabType: 'SMCSCabType',
  };
  isLoading = true;
  selectAllEvent: any;
  selectAll = true;

  selectAllJobs = true;

  selectAllModifiers = {
    intervalCode: true,
    modifier: true,
    jobLocation: true,
    quantityCode: true,
    workApplication: true,
    cabType: true,
    jobCondition: true,
  };
  filterSelectAllJobs = true;
  arrayReferencesForFilter: any = {
    intervalCode: this.intervalsForFilter,
    modifier: this.modifierCodesForFilter,
    jobLocation: this.jobLocationsForFilter,
    quantityCode: this.quantityCodesForFilter,
    workApplication: this.workApplicationCodesForFilter,
    cabType: this.cabTypeForFilter,
    jobCondition: this.jobConditionsForFilter,
  };
  valueChanged = false;
  jobChange = false;
  selectall = this.translate.instant('selectAll');
  unselectall = this.translate.instant('unselectall');
  noData = this.translate.instant('lbl_no_data');
  ComponentsAndSmcsChanges = [];
  JobsChanges = [];

  intervalCodeForTravelAndContigency = [];
  jobCodeForTravelAndContigency = [];
  modifierForTravelAndContigency = [];
  locationForTravelAndContigency = [];
  applicationForTravelAndContigency = [];
  quantityCodeForTravelAndContigency = [];
  conditionCodeForTravelAndContigency = [];
  cabTypeCodeForTravelAndContigency = [];
  disableSelectAllTravel = {
    Interval: false,
    Modifier: false,
    Location: false,
    Quantity: false,
    Application: false,
    Condition: false,
    CabType: false,
    Jobs: false
  };
  selectAllTravel = {
    Interval: false,
    Modifier: false,
    Location: false,
    Quantity: false,
    Application: false,
    Condition: false,
    CabType: false,
    Jobs: false
  };
  selectAllInterval = {
    Interval: false,
    Modifier: false,
    Location: false,
    Quantity: false,
    Application: false,
    Condition: false,
    CabType: false,
    Jobs: false
  };
  selectAllContingency = {
    Interval: false,
    Jobs: false
  };
  disableSelectAllContingency = {
    Interval: false,
    Jobs: false,
    Modifier: false,
    Location: false,
    Quantity: false,
    Application: false,
    Condition: false,
    CabType: false,
  };
  filterChanges = {
    groupingChanges: [],
    componentChanges: [],
    jobChanges: []
  };
  filterTooltip;
  arrayReferencesForInterval = {
    intervalCode: this.intervalCodeForTravelAndContigency,
    job: this.jobCodeForTravelAndContigency,
    modifier: this.modifierForTravelAndContigency,
    jobLocation: this.locationForTravelAndContigency,
    quantityCode: this.quantityCodeForTravelAndContigency,
    workApplication: this.applicationForTravelAndContigency,
    cabType: this.cabTypeForFilter,
    jobCondition: this.conditionCodeForTravelAndContigency,

  };
  constructor(
    private drawerService: DrawerService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    setTimeout(() => {
      this.setupData();
    }, 5);


  }
  closeModal() {
    this.activeDrawer.close({ button: 'Cancel' });

  }

  setupData() {
    this.accordionData = _.cloneDeep(this.accordionDataCopy);
    if (this.activeTab === 'travel' || this.activeTab === 'intervals' || this.activeTab === 'contingency') {
      this.getDistinctSMCSForTravelAndContingency();
    } else {
      this.getDistinctSMCSCodesForFilter();
      this.getDistinctIntervalCode();
      this.getDistinctJobCode();
      this.getDistinctModifiers();
      this.getDistinctJobLocations();
      this.getDistinctQuantity();
      this.getDistinctWorkApplication();
      this.getDistinctJobCondition();
      this.getDistinctCabType();
      this.checkForSelectAll();
    }
    this.isLoading = false;
  }
  applyFilter() {
    if (this.activeTab === 'travel' || this.activeTab === 'intervals' || this.activeTab === 'contingency') {
      this.activeDrawer.close({ button: 'Apply', changes: this.filterChanges });
    } else {
      this.activeDrawer.close({ button: 'Apply', componentChanges: this.ComponentsAndSmcsChanges, jobChanges: this.JobsChanges, selectAllEvent: this.selectAllEvent, selectAllChange: this.selectAllModifiers.intervalCode });
    }
  }
  tabChange(event) {
    this.selectedTab = event.target.id;
    this.changeTabStyle();
    document.getElementById(this.selectedTab).classList.add('active');
  }
  changeTabStyle() {
    const elements = document.getElementsByClassName('filter-drawer-menu');
    _.forEach(elements, function(item) {
      item.className = item.className.replace(/\bactive\b/g, '');
    });
  }

  checkForContingencyIncluded(interval) {
    let flag = false;
    interval.Details.Jobs.forEach((job) => {
      if (job.Configuration.IsContingencyActive) {
        flag = true;
      }
    });
    return flag;
  }


  selectOrUnselectInterval(event, interval, isParent?) {
    interval.Configuration.Header.IsActive = event;
    if (isParent) {
      interval.Configuration.Header.IsTravelIncluded = event;
    }
    interval.Configuration.Header.IsActive = event;
    if (!interval.IsAdditionalPriceInterval) {
      interval.Details.Jobs.forEach(job => {
        this.selectOrUnselectJob(event, job, true);
      });
      this.selectOrUnselectIntervalConfig(event, interval);
      this.checkAndAddToIntervalChanges(interval);
    }
  }

  selectOrUnselectJob(event, job, isParent?) {
    job.Configuration.IsActive = event;
    if (isParent && !event) {
      job.Configuration.IsContingencyActive = event;
    }
    job.ServiceIntervals.forEach(interval => {
      this.selectOrUnselectInterval(event, interval);
    });
    this.selectOrUnselectJobConfig(event, job);
    this.checkAndAddToJobChanges(job);
  }

  selectOrUnselectIntervalConfig(e, interval) {
    interval.Configuration.Details.Jobs.forEach(job => {
      job.IsActive = e;
    });
  }

  selectOrUnselectJobConfig(e, job) {
    if (job.Configuration.ServiceIntervals) {
      job.Configuration.ServiceIntervals.forEach(interval => {
        interval.Header.IsActive = e;
      });
    }
  }

  addToSaveJSON(configuration, correlationId) {
    this.estimateSaveJSON.forEach((el, index, array) => {
      if (el.CorrelationId === correlationId) {
        array.splice(index, 1);
      }
    });
    this.estimateSaveJSON.push(JSON.parse(JSON.stringify(configuration)));
  }
  getDistinctSMCSCodesForFilter() {
    if (this.accordionData && this.accordionData.length) {
      this.accordionData.filter((item) => {
        if (!item.IsAdditionalGrouping) {
          this.fetchDistinctSMCSCodesForFilter(item, true);
        }
      });
    }
  }
  fetchDistinctSMCSCodesForFilter(item, addForTravel?) {
    item.ServiceIntervals.filter((serviceInterval) => {
      if (
        (!serviceInterval.IsAdditionalInterval ||
          (serviceInterval.IsAdditionalInterval &&
            serviceInterval.Header.ServiceIntervalTypeID === 5)) &&
        !serviceInterval.IsFuelInterval &&
        !serviceInterval.IsAdditionalPriceInterval
      ) {
        this.SMCSObjectsForFilter.push({
          intervalCode: serviceInterval.Header.SMCSComponent,
          intervalDescription: serviceInterval.Header.SMCSComponentDescription,
          intervalIncluded: serviceInterval.Configuration.Header.IsActive,
          intervalPreviousValue: serviceInterval.Configuration.Header.IsActive,
          modifier: serviceInterval.Header.SMCSModifier,
          modifierDescription: serviceInterval.Header.SMCSModifierDescription,
          jobLocation: serviceInterval.Header.SMCSJobLocation,
          jobLocationDescription:
            serviceInterval.Header.SMCSJobLocationDescription,
          quantityCode: serviceInterval.Header.SMCSQuantity,
          quantityDescription: serviceInterval.Header.SMCSQuantityDescription,
          workApplication: serviceInterval.Header.SMCSWorkApplication,
          workApplicationDescription:
            serviceInterval.Header.SMCSWorkApplicationDescription,
          jobCondition: serviceInterval.Header.SMCSJobCondition,
          jobConditionDescription:
            serviceInterval.Header.SMCSJobConditionDescription,
          cabType: serviceInterval.Header.SMCSCabType,
          cabTypeDescription: serviceInterval.Header.SMCSCabTypeDescription,
        });
        if (addForTravel) {
          this.SMCSObjectsForTravelFilter.push({
            intervalCode: serviceInterval.Header.SMCSComponent,
            intervalDescription:
              serviceInterval.Header.SMCSComponentDescription,
            intervalIncluded: serviceInterval.Configuration.Header.IsActive,
            intervalPreviousValue: serviceInterval.Configuration.Header.IsActive,
            modifier: serviceInterval.Header.SMCSModifier,
            modifierDescription: serviceInterval.Header.SMCSModifierDescription,
            jobLocation: serviceInterval.Header.SMCSJobLocation,
            jobLocationDescription:
              serviceInterval.Header.SMCSJobLocationDescription,
            quantityCode: serviceInterval.Header.SMCSQuantity,
            quantityDescription: serviceInterval.Header.SMCSQuantityDescription,
            workApplication: serviceInterval.Header.SMCSWorkApplication,
            workApplicationDescription:
              serviceInterval.Header.SMCSWorkApplicationDescription,
            jobCondition: serviceInterval.Header.SMCSJobCondition,
            jobConditionDescription:
              serviceInterval.Header.SMCSJobConditionDescription,
            cabType: serviceInterval.Header.SMCSCabType,
            cabTypeDescription: serviceInterval.Header.SMCSCabTypeDescription,
            travelIncluded:
              serviceInterval.Configuration.Header.IsTravelIncluded,
            contingencyIncluded:
              this.checkForContingencyIncluded(serviceInterval),
          });
          this.SMCSObjectsForIntervalFilter.push({
            intervalCode: serviceInterval.Header.SMCSComponent,
            intervalDescription:
              serviceInterval.Header.SMCSComponentDescription,
            intervalIncluded: serviceInterval.Configuration.Header.IsActive,
            intervalPreviousValue: serviceInterval.Configuration.Header.IsActive,
            modifier: serviceInterval.Header.SMCSModifier,
            modifierDescription: serviceInterval.Header.SMCSModifierDescription,
            jobLocation: serviceInterval.Header.SMCSJobLocation,
            jobLocationDescription:
              serviceInterval.Header.SMCSJobLocationDescription,
            quantityCode: serviceInterval.Header.SMCSQuantity,
            quantityDescription: serviceInterval.Header.SMCSQuantityDescription,
            workApplication: serviceInterval.Header.SMCSWorkApplication,
            workApplicationDescription:
              serviceInterval.Header.SMCSWorkApplicationDescription,
            jobCondition: serviceInterval.Header.SMCSJobCondition,
            jobConditionDescription:
              serviceInterval.Header.SMCSJobConditionDescription,
            cabType: serviceInterval.Header.SMCSCabType,
            cabTypeDescription: serviceInterval.Header.SMCSCabTypeDescription,
          });
        }
        serviceInterval.Details.Jobs.forEach((job) => {
          this.fetchDistinctSMCSCodesForFilter(job);
        });
      }
    });
  }
  fetchDistinctCodes(
    item,
    addContingency?
  ) {
    for (const serviceInterval of item.ServiceIntervals) {
      if (
        !serviceInterval.IsAdditionalInterval ||
        (serviceInterval.IsAdditionalInterval &&
          (serviceInterval.Header.ServiceIntervalTypeID === 5 || serviceInterval.Header.ServiceIntervalTypeID === 4 || serviceInterval.Header.ServiceIntervalTypeID === 3))
      ) {
        for (const job of serviceInterval.Details.Jobs) {
          this.AllJobsForFilter.push({
            jobCode: job.JobCode,
            jobIncluded: job.Configuration.IsActive,
            jobPreviousValue: job.Configuration.isActive,
            jobDescription: job.JobCodeDescription,
          });
          if (addContingency) {
            this.AllJobsForContingency.push({
              jobCode: job.JobCode,
              jobIncluded: job.Configuration.IsActive,
              jobPreviousValue: job.Configuration.isActive,
              jobDescription: job.JobCodeDescription,
              contingencyIncluded: job.Configuration.IsContingencyActive,
            });
            this.AllJobsForInterval.push({
              jobCode: job.JobCode,
              jobIncluded: job.Configuration.IsActive,
              jobPreviousValue: job.Configuration.isActive,
              jobDescription: job.JobCodeDescription,
            });
          }
          this.fetchDistinctCodes(job);
        }
      }
    }
  }
  getDistinctIntervalCode() {
    this.SMCSObjectsForFilter = [];
    this.getDistinctSMCSCodesForFilter();
    if (this.accordionData && this.accordionData.length > 0) {
      const itemsFound = [];
      this.isLoading = true;
      this.SMCSObjectsForFilter.forEach((item) => {
        const index = itemsFound.indexOf(item.intervalCode);
        if (index === -1 && item.intervalCode && item.intervalCode != null) {
          itemsFound.push(item.intervalCode);
          this.intervalsForFilter.push(item);
        } else if (item.intervalCode && item.intervalCode != null) {
          if (!item.intervalIncluded) {
            this.intervalsForFilter[index].intervalIncluded = false;
          }
        }
      });
    }

    if (this.intervalsForFilter && this.intervalsForFilter.length) {
      this.intervalsForFilter.forEach((interval) => {
        interval.intervalIncluded = false;
        this.handleSelection(interval, 'intervalCode');
      });
    }
    this.isLoading = false;
  }
  getDistinctJobCode() {
    this.AllJobsForFilter = [];
    this.fetchAllJobCodes();
    const itemsFound = [];
    for (let i = 0, l = this.AllJobsForFilter.length; i < l; i++) {
      const index = itemsFound.indexOf(this.AllJobsForFilter[i].jobCode);
      if (index === -1) {
        itemsFound.push(this.AllJobsForFilter[i].jobCode);
        this.jobsForFilter.push(this.AllJobsForFilter[i]);
      } else {
        if (!this.AllJobsForFilter[i].jobIncluded) {
          this.jobsForFilter[index].jobIncluded = false;
        }
      }
    }
    this.handleFilterJobSelection();

  }
  getDistinctModifiers() {
    this.SMCSObjectsForFilter = [];
    this.getDistinctSMCSCodesForFilter();
    const itemsFound = [];
    this.SMCSObjectsForFilter.forEach((item) => {
      const index = itemsFound.indexOf(item.modifier);
      if (index === -1 && item.modifier && item.modifier != null) {
        itemsFound.push(item.modifier);
        this.modifierCodesForFilter.push(item);
      } else if (item.modifier && item.modifier != null) {
        if (!item.intervalIncluded) {
          this.modifierCodesForFilter[index].intervalIncluded = false;
        }
      }
    });

    this.isLoading = false;
  }

  getDistinctJobLocations() {
    this.SMCSObjectsForFilter = [];
    this.getDistinctSMCSCodesForFilter();
    const itemsFound = [];
    for (let i = 0, l = this.SMCSObjectsForFilter.length; i < l; i++) {
      const index = itemsFound.indexOf(
        this.SMCSObjectsForFilter[i].jobLocation
      );
      if (
        index === -1 &&
        this.SMCSObjectsForFilter[i].jobLocation &&
        this.SMCSObjectsForFilter[i].jobLocation != null
      ) {
        itemsFound.push(this.SMCSObjectsForFilter[i].jobLocation);
        this.jobLocationsForFilter.push(this.SMCSObjectsForFilter[i]);
      } else if (
        this.SMCSObjectsForFilter[i].jobLocation &&
        this.SMCSObjectsForFilter[i].jobLocation != null
      ) {
        if (!this.SMCSObjectsForFilter[i].intervalIncluded) {
          this.jobLocationsForFilter[index].intervalIncluded = false;
        }
      }
    }
  }
  getDistinctQuantity() {
    this.SMCSObjectsForFilter = [];
    this.getDistinctSMCSCodesForFilter();
    const itemsFound = [];
    for (let i = 0, l = this.SMCSObjectsForFilter.length; i < l; i++) {
      const index = itemsFound.indexOf(
        this.SMCSObjectsForFilter[i].quantityCode
      );
      if (
        index === -1 &&
        this.SMCSObjectsForFilter[i].quantityCode &&
        this.SMCSObjectsForFilter[i].quantityCode != null
      ) {
        itemsFound.push(this.SMCSObjectsForFilter[i].quantityCode);
        this.quantityCodesForFilter.push(this.SMCSObjectsForFilter[i]);
      } else if (
        this.SMCSObjectsForFilter[i].quantityCode &&
        this.SMCSObjectsForFilter[i].quantityCode != null
      ) {
        if (!this.SMCSObjectsForFilter[i].intervalIncluded) {
          this.quantityCodesForFilter[index].intervalIncluded = false;
        }
      }
    }

    this.isLoading = false;
  }

  getDistinctWorkApplication() {
    this.SMCSObjectsForFilter = [];
    this.getDistinctSMCSCodesForFilter();
    const itemsFound = [];
    this.SMCSObjectsForFilter.forEach((item) => {
      const index = itemsFound.indexOf(item.workApplication);
      if (
        index === -1 &&
        item.workApplication &&
        item.workApplication != null
      ) {
        itemsFound.push(item.workApplication);
        this.workApplicationCodesForFilter.push(item);
      } else if (item.workApplication && item.workApplication != null) {
        if (!item.intervalIncluded) {
          this.workApplicationCodesForFilter[index].intervalIncluded = false;
        }
      }
    });
  }
  getDistinctJobCondition() {
    this.SMCSObjectsForFilter = [];
    this.getDistinctSMCSCodesForFilter();
    const itemsFound = [];
    this.SMCSObjectsForFilter.forEach((item) => {
      const index = itemsFound.indexOf(item.jobCondition);
      if (index === -1 && item.jobCondition && item.jobCondition != null) {
        itemsFound.push(item.jobCondition);
        this.jobConditionsForFilter.push(item);
      } else if (item.jobCondition && item.jobCondition != null) {
        if (!item.intervalIncluded) {
          this.jobConditionsForFilter[index].intervalIncluded = false;
        }
      }
    });
  }

  getDistinctCabType() {
    this.SMCSObjectsForFilter = [];
    this.getDistinctSMCSCodesForFilter();
    const itemsFound = [];
    this.SMCSObjectsForFilter.forEach((item) => {
      const index = itemsFound.indexOf(item.cabType);
      if (index === -1 && item.cabType && item.cabType != null) {
        itemsFound.push(item.cabType);
        this.cabTypeForFilter.push(item);
      } else if (item.cabType && item.cabType != null) {
        if (!item.intervalIncluded) {
          this.cabTypeForFilter[index].intervalIncluded = false;
        }
      }
    });
  }
  handleSelection(interval, modifierType) {
    interval.intervalIncluded = false;
    this.accordionData.forEach((component) => {
      this.checkForSelection(component, interval, modifierType);
    });
    if (!interval.intervalIncluded) {
      this.selectAllModifiers[modifierType] = false;
    }
  }
  checkForSelection(item, interval, modifierType) {
    item.ServiceIntervals.forEach((serviceInterval) => {
      const key = _.get(this.objectReferences, modifierType);
      const modifierValue = _.get(serviceInterval.Header, key);
      if (
        modifierValue === interval[modifierType] &&
        serviceInterval.Configuration.Header.IsActive
      ) {
        interval.intervalIncluded = true;
      }
      serviceInterval.Details.Jobs.forEach((job) => {
        this.checkForSelection(job, interval, modifierType);
      });
    });
  }

  filterCheckBoxChange(event, code, modifierType, interval?) {
    this.setComponentAndSMCSChanges(interval, modifierType);
    this.selectAllEvent = false;
    this.valueChanged = true;
    this.accordionData.forEach((element) => {
      if (!element.IsAdditionalGrouping) {
        element.Configuration.IsActive = false;
        element.ServiceIntervals.forEach((serviceInterval) => {
          if (
            !serviceInterval.IsAdditionalInterval ||
            (serviceInterval.IsAdditionalInterval &&
              serviceInterval.Header.ServiceIntervalTypeID === 5)
          ) {
            this.applyCheckBoxSelection(
              event,
              serviceInterval,
              code,
              modifierType,
              true
            );
          }
        });

        const selectedIntervals = this.findSelectedInterval(element);
        if (selectedIntervals && !element.Configuration.IsActive) {
          element.Configuration.IsActive = true;

        } else if (!selectedIntervals && element.Configuration.IsActive) {
          element.Configuration.IsActive = false;

        }
      }
    });
    this.checkForSelectAll();
  }

  setComponentAndSMCSChanges(interval, modifierType) {
    if (this.ComponentsAndSmcsChanges.length == 0) {
      this.ComponentsAndSmcsChanges.push({ Interval: interval, Modifier: modifierType });
    }
    else {
      const index = this.ComponentsAndSmcsChanges.map((item) => item.Interval).indexOf(interval);
      if (index == -1) {
        this.ComponentsAndSmcsChanges.push({ Interval: interval, Modifier: modifierType });
      }
      else {
        this.ComponentsAndSmcsChanges[index].Interval = interval;
      }
    }
  }

  findSelectedInterval(element) {
    const selectedIntervals = _.find(
      element.ServiceIntervals,
      (interval) =>
        (!interval.IsAdditionalInterval ||
          (interval.IsAdditionalInterval &&
            interval.Header.ServiceIntervalTypeID === 5)) &&
        interval.Configuration.Header.IsActive
    );
    return selectedIntervals;
  }

  applyCheckBoxSelection(event, item, code, modifierType, isParent?) {
    const modifierValue = _.get(
      item.Header,
      this.objectReferences[modifierType]
    );
    if (code === modifierValue && modifierValue != null) {
      this.selectOrUnselectInterval(event, item, isParent);
    } else {
      for (let job of item.Details.Jobs) {
        if (job.ServiceIntervals.length !== 0) {
          for (const interval of job.ServiceIntervals) {
            this.applyCheckBoxSelection(event, interval, code, modifierType);
          }
          this.checkForJobActive(job);
        }
      }
       this.checkForIntervalActive(item);
    }
  }

  checkForJobActive(job) {
    const selectedIntervals = _.find(
      job.ServiceIntervals,
      (interval) => interval.Configuration.Header.IsActive
    );
    if (selectedIntervals && !job.Configuration.IsActive) {
      job.Configuration.IsActive = true;
    } else if (!selectedIntervals && job.Configuration.IsActive) {
      job.Configuration.IsActive = false;
    }
    return job;
  }

  checkForIntervalActive(item) {
    const selectedJobs = _.find(
      item.Details.Jobs,
      (jobs) => jobs.Configuration.IsActive
    );
    if (selectedJobs && !item.Configuration.Header.IsActive) {
      item.Configuration.Header.IsActive = true;

    } else if (!selectedJobs && item.Configuration.Header.IsActive) {
      item.Configuration.Header.IsActive = false;
    }
    return item;
  }

  handleSelectAll(event, modifierType) {
    this.selectAllModifiers[modifierType] = event;
  }
  selectAllJobLocationChange(event, modifierType) {
    this.selectAllModifiers[modifierType] = event;
    this.jobConditionsForFilter.forEach((interval) => {
      this.filterCheckBoxChange(event, interval.jobLocation, 'jobLocation', interval);
      interval.intervalIncluded = event;
    });
  }
  selectAllModifiersChange(event, modifierType) {
    this.selectAllEvent = false;
    this.selectAllModifiers[modifierType] = event;
    this.arrayReferencesForFilter[modifierType].forEach((interval) => {
      this.filterCheckBoxChange(event, interval[modifierType], modifierType, interval);
      interval.intervalIncluded = event;
    });
  }
  selectAllCabTypeChange(event, modifierType) {
    this.selectAllModifiers[modifierType] = event;
    this.cabTypeForFilter.forEach((interval) => {
      this.filterCheckBoxChange(event, interval.cabType, modifierType, interval);
      interval.intervalIncluded = event;
    });
  }
  selectAllWorkApplicationChange(event, modifierType) {
    this.selectAllModifiers[modifierType] = event;
    this.workApplicationCodesForFilter.forEach((interval) => {
      this.filterCheckBoxChange(event, interval.workApplication, modifierType, interval);
      interval.intervalIncluded = event;
    });
  }
  selectAllJobConditionChange(event, modifierType) {
    this.selectAllModifiers[modifierType] = event;
    this.jobConditionsForFilter.forEach((interval) => {
      this.filterCheckBoxChange(event, interval.jobCondition, modifierType, interval);
      interval.intervalIncluded = event;
    });
  }
  selectAllQuantityChange(event, modifierType) {
    this.selectAllModifiers[modifierType] = event;
    this.quantityCodesForFilter.forEach((interval) => {
      this.filterCheckBoxChange(event, interval.quantityCode, modifierType, interval);
      interval.intervalIncluded = event;
    });
  }
  checkForSelectAll() {
    Object.keys(this.selectAllModifiers).forEach((key) => {
      this.selectAllModifiers[key] = true;
    });
    this.selectAllJobs = true;

    this.handleFilterJobSelection();
    if (this.intervalsForFilter && this.intervalsForFilter.length) {
      this.intervalsForFilter.forEach((interval) => {
        interval.intervalIncluded = false;
        this.handleSelection(interval, 'intervalCode');
      });
    }

    this.handleModifiers();
    if (this.pmsOnly) {
      this.checkIfAllPmsOnlySelected();
    }


  }
  handleFilterJobSelection() {
    this.filterSelectAllJobs = true;
    this.jobsForFilter.forEach((jobCode) => {
      jobCode.jobIncluded = false;
      this.accordionData.forEach((component) => {
        component.ServiceIntervals.forEach((interval) => {
          interval.Details.Jobs.forEach((job) => {
            if (job.JobCode === jobCode.jobCode && job.Configuration.IsActive) {
              jobCode.jobIncluded = true;
            }
            this.setJobIncluded(job,jobCode);
          });
        });
      });
      if (!jobCode.jobIncluded) {
        this.selectAllJobs = false;
      }
    });
  }

  setJobIncluded(job: any, jobCode: any) {
    if (job.ServiceIntervals) {
      job.ServiceIntervals.forEach((nestedInt) => {
        if (nestedInt.Details.Jobs) {
          nestedInt.Details.Jobs.forEach((nestedJob) => {
            if (
              nestedJob.JobCode === jobCode.jobCode &&
              nestedJob.Configuration.IsActive
            ) {
              jobCode.jobIncluded = true;
            }
          });
        }
      });
    }
  }

  checkIfIntervalAndJobIsSelected(item) {
    item.ServiceIntervals.forEach((interval) => {
      if (!interval.Configuration.Header.IsActive) {
        this.selectAll = false;
        return;
      }
      interval.Details.Jobs.forEach((job) => {
        if (!job.Configuration.IsActive) {
          this.selectAll = false;
          return;
        }
        this.checkIfIntervalAndJobIsSelected(job);
      });
    });
  }
  selectAllJobsChange(event) {
    this.selectAllJobs = event;
    this.selectAllEvent = true;
    this.valueChanged = true;
    this.jobChange = true;
    Object.keys(this.selectAllModifiers).forEach((key) => {
      this.selectAllModifiers[key] = event;
    });
    this.selectAllElements(event);
    this.selectAllComponentChange(event);
    this.selectAllFilterJobs(event);
  }
  selectAllComponentChange(event) {
    this.valueChanged = true;
    this.selectAllModifiers.intervalCode = event;
    this.selectAllEvent = true;
    Object.keys(this.selectAllModifiers).forEach((key) => {
      this.selectAllModifiers[key] = event;
    });
    this.selectAllEvent = event;
    this.selectAllElements(event);
    this.selectAllFilterComponent(event);
    this.selectAllFilterJobs(event);
    this.intervalsForFilter.forEach((value) => {
      if (this.ComponentsAndSmcsChanges.length == 0) {
        this.ComponentsAndSmcsChanges.push({ Interval: value, Modifier: 'intervalCode' });
      }
      else {
        const index = this.ComponentsAndSmcsChanges.map((item) => item.Interval).indexOf(value);
        if (index == -1) {
          this.ComponentsAndSmcsChanges.push({ Interval: value, Modifier: 'intervalCode' });
        }
        else {
          this.ComponentsAndSmcsChanges[index].Interval = value;
        }
      }
    });

  }
  selectAllElements(event) {
    this.accordionData.forEach((grouping) => {
      if (!grouping.IsAdditionalGrouping) {
        grouping.Configuration.IsActive = event;
        grouping.ServiceIntervals.forEach((interval) => {
          this.handleIntervalSelection(event, interval);
        });

      } else if (
        grouping.IsAdditionalGrouping &&
        grouping.ServiceIntervals.length !== 0 &&
        !grouping.ServiceIntervals[0].IsFuelInterval
      ) {
        grouping.Configuration.IsActive = event;
        grouping.ServiceIntervals.forEach((interval) => {
          if (!interval.IsFuelInterval) {
            interval.Configuration.Header.IsActive = event;
          }
        });
        this.additionalIntervalJson(event);
      }
    });
    this.handleFilterJobSelection();
    if (this.intervalsForFilter && this.intervalsForFilter.length) {
      this.intervalsForFilter.forEach((interval) => {
        interval.intervalIncluded = false;
        this.handleSelection(interval, 'intervalCode');
      });
    }
    this.selectAllElements1();
  }

  selectAllElements1() {
    if (this.modifierCodesForFilter && this.modifierCodesForFilter.length) {
      this.selectAllModifiers.modifier = true;
      this.modifierCodesForFilter.forEach((interval) => {
        interval.intervalIncluded = false;
        this.handleSelection(interval, 'modifier');
      });
    }

    if (this.jobLocationsForFilter && this.jobLocationsForFilter.length) {
      this.jobLocationsForFilter.forEach((interval) => {
        interval.intervalIncluded = false;
        this.handleSelection(interval, 'jobLocation');
      });
    }

    if (this.quantityCodesForFilter && this.quantityCodesForFilter.length) {
      this.quantityCodesForFilter.forEach((interval) => {
        interval.intervalIncluded = false;
        this.handleSelection(interval, 'quantityCode');
      });
    }
    if (
      this.workApplicationCodesForFilter &&
      this.workApplicationCodesForFilter.length
    ) {
      this.workApplicationCodesForFilter.forEach((interval) => {
        interval.intervalIncluded = false;
        this.handleSelection(interval, 'workApplication');
      });
    }
    this.selectAllElements2();
  }

  selectAllElements2() {
    if (this.jobConditionsForFilter && this.jobConditionsForFilter.length) {
      this.jobConditionsForFilter.forEach((interval) => {
        interval.intervalIncluded = false;
        this.handleSelection(interval, 'jobCondition');
      });
    }
    if (this.cabTypeForFilter && this.cabTypeForFilter.length) {
      this.cabTypeForFilter.forEach((interval) => {
        interval.intervalIncluded = false;
        this.handleSelection(interval, 'cabType');
      });
    }
  }
  handleIntervalSelection(event, interval) {
    if (
      !interval.IsAdditionalInterval ||
      (interval.IsAdditionalInterval &&
        interval.Header.ServiceIntervalTypeID === 5)
    ) {
      this.selectOrUnselectInterval(event, interval, true);
    }
  }
  additionalIntervalJson(event) {
    this.estimateHeader.AdditionalPriceConfiguration.IsActive = event;
    this.estimateHeader.AdditionalPriceConfiguration.AdditionalPrices.forEach(
      (interval) => {
        interval.IsActive = event;
      }
    );
    this.estimateSaveJSON.push(
      this.estimateHeader.AdditionalPriceConfiguration
    );
  }
  selectAllFilterComponent(event) {
    this.intervalsForFilter.forEach((interval) => {
      interval.intervalIncluded = event;
    });
  }
  selectAllFilterJobs(event) {
    this.jobsForFilter.forEach((job) => {
      job.jobIncluded = event;
    });
  }

  handleModifiers(modifierType?) {
    if (!modifierType) {
      Object.keys(this.arrayReferencesForFilter).forEach((key) => {
        if (
          this.arrayReferencesForFilter[key] &&
          this.arrayReferencesForFilter[key].length
        ) {
          this.selectAllModifiers[key] = true;
          this.arrayReferencesForFilter[key].forEach((interval) => {
            interval.intervalIncluded = false;
            this.handleSelection(interval, key);
          });
        }
      });
    } else {
      if (
        this.arrayReferencesForFilter[modifierType] &&
        this.arrayReferencesForFilter[modifierType].length
      ) {
        this.arrayReferencesForFilter[modifierType].forEach((interval) => {
          interval.intervalIncluded = false;
          this.handleSelection(interval, modifierType);
        });
      }
    }
    if (this.pmsOnly) {
      this.checkIfAllPmsOnlySelected();
    }
  }
  checkIfAllPmsOnlySelected() {
    let flag = true;
    this.accordionData.forEach((component) => {
      if (
        (!component.Configuration.IsActive && component.Outcome.IsPM) ||
        (component.Configuration.IsActive && !component.Outcome.IsPM)
      ) {
        flag = false;
      }
    });
    this.pmsOnly = flag;
  }

  filterJobChange(event, code, interval?) {
    this.addJobChanges(interval);
    this.jobChange = true;
    this.valueChanged = true;
    for (let item of this.accordionData) {
      if (!item.IsAdditionalGrouping) {
        for (let serviceInterval of item.ServiceIntervals) {
          this.checkFilterJobSelectionOrIntervalAndTravel(serviceInterval,code,event);
        }
        this.changeGroupingActive(item);
      }
    }
    this.checkForSelectAll();
  }

  checkFilterJobSelectionOrIntervalAndTravel(serviceInterval: any, code: any, event: any) {
    if (!serviceInterval.IsAdditionalInterval || (serviceInterval.IsAdditionalInterval && (serviceInterval.Header.ServiceIntervalTypeID === 3 || serviceInterval.Header.ServiceIntervalTypeID === 4))) {
      for (const job of serviceInterval.Details.Jobs) {
        this.applyFilterJobSelection(job, code, event);
      }
      this.changeIntervalActiveAndTravel(serviceInterval);
    }
  }

  changeIntervalActiveAndTravel(serviceInterval) {
    const selectedJobs = _.find(serviceInterval.Details.Jobs, (job) => job.Configuration.IsActive);
    if (selectedJobs && !serviceInterval.Configuration.Header.IsActive) {
      serviceInterval.Configuration.Header.IsActive = true;
      serviceInterval.Configuration.Header.IsTravelIncluded = true;
    } else if (!selectedJobs && serviceInterval.Configuration.Header.IsActive) {
      serviceInterval.Configuration.Header.IsActive = false;
      serviceInterval.Configuration.Header.IsTravelIncluded = false;
    }
  }

  changeGroupingActive(item) {
    const selectedIntervals = _.find(item.ServiceIntervals, (interval) => !interval.IsAdditionalInterval && interval.Configuration.Header.IsActive);
    if (selectedIntervals && !item.Configuration.IsActive) {
      item.Configuration.IsActive = true;
    } else if (!selectedIntervals && item.Configuration.IsActive) {
      item.Configuration.IsActive = false;
    }
  }

  addJobChanges(interval) {
    if (this.JobsChanges.length == 0) {
      this.JobsChanges.push(interval);
    } else {
      const index = this.JobsChanges.indexOf(interval);
      if (index == -1) {
        this.JobsChanges.push(interval);
      }
      else {
        this.JobsChanges[index] = interval;
      }
    }
  }

  applyFilterJobSelection(item, code, event) {
    if (code === item.JobCode) {
      this.selectOrUnselectJob(event, item);
    } else {
      if (item.ServiceIntervals.length !== 0) {
        for (let interval of item.ServiceIntervals) {
          for (const currentJob of interval.Details.Jobs) {
            this.applyFilterJobSelection(currentJob, code, event);
          }
          this.changeFilterJobForInterval(interval);
        }
         this.changeFilterJobForGroup(item);
      }
    }
  }

  changeFilterJobForGroup(item) {
    const selectedIntervals = _.find(item.ServiceIntervals, (interval) => interval.Configuration.Header.IsActive);
    if (selectedIntervals && !item.Configuration.IsActive) {
      item.Configuration.IsActive = true;
    } else if (!selectedIntervals && item.Configuration.IsActive) {
      item.Configuration.IsActive = false;
    }
    return item;
  }

  changeFilterJobForInterval(interval) {
    const selectedJobs = _.find(interval.Details.Jobs, (job) => job.Configuration.IsActive);
    if (selectedJobs && !interval.Configuration.Header.IsActive) {
      interval.Configuration.Header.IsActive = true;
    } else if (!selectedJobs && interval.Configuration.Header.IsActive) {
      interval.Configuration.Header.IsActive = false;
    }
  }

  fetchAllJobCodes() {
    if (this.accordionData && this.accordionData.length) {
      for (const item of this.accordionData) {
        this.fetchDistinctCodes(item, true);
      }
    }

  }



  // For Travel Tab

  getDistinctSMCSForTravelAndContingency() {
    const intArray = [];
    const jobArray = [];
    const modArray = [];
    const locArray = [];
    const qtyArray = [];
    const appArray = [];
    const cabTypeArray = [];
    const condArray = [];
    if (this.accordionData && this.accordionData.length > 0) {
      for (const item of this.accordionData) {
        if (!item.IsAdditionalGrouping) {
          this.fetchDistinctIntervalCodes(item, intArray, jobArray, modArray, locArray, qtyArray, appArray, cabTypeArray, condArray);
        }
      }
    }
    if (this.activeTab === 'travel') {
      this.filterDistinctIntCodes(intArray);
      this.checkIfAllIntervalCodesIncludedInTravel();
    } else if (this.activeTab === 'contingency') {
      this.filterDistinctIntCodes(intArray);
      this.filterDistinctJobCodes(jobArray);
      this.checkForFlagInContingency();
    } else {
      this.filterDistinctIntCodes(intArray);
      this.filterDistinctJobCodes(jobArray);
      this.filterDistinctModCodes(modArray);
      this.filterDistinctLocCodes(locArray);
      this.filterDistinctQtyCodes(qtyArray);
      this.filterDistinctAppCodes(appArray);
      this.filterDistinctCabTypeCodes(cabTypeArray);
      this.filterDistinctContCodes(condArray);
      this.checkForSelectAllFlagsInInterval();
    }
  }

  fetchDistinctIntervalCodes(item, intArray, jobArray, modArray, locArray, qtyArray, appArray, cabTypeArray, condArray) {
    for (const serviceInterval of item.ServiceIntervals) {
      if (this.conditionForDistinctIntervalCode(serviceInterval)) {
        const contingency = this.checkForContingencyIncluded(serviceInterval);
        intArray.push({
          intervalCode: serviceInterval.Header.SMCSComponent,
          modifier: serviceInterval.Header.SMCSModifier,
          cabType: serviceInterval.Header.SMCSCabType,
          jobLocation: serviceInterval.Header.SMCSJobLocation,
          jobCondition: serviceInterval.Header.SMCSJobCondition,
          quantityCode: serviceInterval.Header.SMCSQuantity,
          workApplication: serviceInterval.Header.SMCSWorkApplication,
          intervalIncluded: serviceInterval.Configuration.Header.IsActive,
          intervalDescription: serviceInterval.Header.SMCSComponentDescription,
          travelIncluded: serviceInterval.Configuration.Header.IsTravelIncluded,
          contingencyIncluded: contingency
        });
        this.fetchmodifierCode(serviceInterval, modArray, contingency);
        this.fetchCabCode(serviceInterval, cabTypeArray, contingency);
        this.fetchLocCode(serviceInterval, locArray, contingency);
        this.fetchAppCode(serviceInterval, appArray, contingency);
        this.fetchQuantCode(serviceInterval, qtyArray, contingency);
        this.fetchCondCode(serviceInterval, condArray, contingency);
        for (const job of serviceInterval.Details.Jobs) {
          if (!job.IsAddOnJob || (job.IsAddOnJob && (serviceInterval.Header.ServiceIntervalTypeID === 3 || serviceInterval.Header.ServiceIntervalTypeID === 4))) {
            jobArray.push({
              jobCode: job.JobCode, jobIncluded: job.Configuration.IsActive,
              jobDescription: job.JobCodeDescription,
              contingencyIncluded: job.Configuration.IsContingencyActive
            });
          }
        }
      }
    }
  }

  conditionForDistinctIntervalCode(serviceInterval) {
    if ((!serviceInterval.IsAdditionalInterval || (serviceInterval.IsAdditionalInterval && (serviceInterval.Header.ServiceIntervalTypeID === 5 || serviceInterval.Header.ServiceIntervalTypeID === 4 || serviceInterval.Header.ServiceIntervalTypeID === 3))) && !serviceInterval.IsFuelInterval && !serviceInterval.IsAdditionalPriceInterval) {
      return true;
    } else {
      return false;
    }
  }

  fetchmodifierCode(serviceInterval, modArray, isContingencyIncluded?) {
    if (serviceInterval.Header.SMCSModifier) {
      modArray.push({
        modifier: serviceInterval.Header.SMCSModifier,
        intervalCode: serviceInterval.Header.SMCSComponent,
        cabType: serviceInterval.Header.SMCSCabType,
        jobLocation: serviceInterval.Header.SMCSJobLocation,
        jobCondition: serviceInterval.Header.SMCSJobCondition,
        quantityCode: serviceInterval.Header.SMCSQuantity,
        workApplication: serviceInterval.Header.SMCSWorkApplication,
        intervalIncluded: serviceInterval.Configuration.Header.IsActive,
        modifierDescription: serviceInterval.Header.SMCSModifierDescription,
        travelIncluded: serviceInterval.Configuration.Header.IsTravelIncluded,
        contingencyIncluded: isContingencyIncluded ? isContingencyIncluded : false,
      });
    }
  }

  fetchCabCode(serviceInterval, cabTypeArray, isContingencyIncluded?) {
    if (serviceInterval.Header.SMCSCabType) {
      cabTypeArray.push({
        cabType: serviceInterval.Header.SMCSCabType,
        intervalCode: serviceInterval.Header.SMCSComponent,
        modifier: serviceInterval.Header.SMCSModifier,
        jobLocation: serviceInterval.Header.SMCSJobLocation,
        jobCondition: serviceInterval.Header.SMCSJobCondition,
        quantityCode: serviceInterval.Header.SMCSQuantity,
        workApplication: serviceInterval.Header.SMCSWorkApplication,
        cabTypeDescription: serviceInterval.Header.SMCSCabTypeDescription,
        intervalIncluded: serviceInterval.Configuration.Header.IsActive,
        travelIncluded: serviceInterval.Configuration.Header.IsTravelIncluded,
        contingencyIncluded: isContingencyIncluded ? isContingencyIncluded : false,
      });
    }
  }

  fetchLocCode(serviceInterval, locArray, isContingencyIncluded?) {
    if (serviceInterval.Header.SMCSJobLocation) {
      locArray.push({
        jobLocation: serviceInterval.Header.SMCSJobLocation,
        intervalCode: serviceInterval.Header.SMCSComponent,
        modifier: serviceInterval.Header.SMCSModifier,
        cabType: serviceInterval.Header.SMCSCabType,
        jobCondition: serviceInterval.Header.SMCSJobCondition,
        quantityCode: serviceInterval.Header.SMCSQuantity,
        workApplication: serviceInterval.Header.SMCSWorkApplication,
        jobLocationDescription: serviceInterval.Header.SMCSJobLocationDescription,
        intervalIncluded: serviceInterval.Configuration.Header.IsActive,
        travelIncluded: serviceInterval.Configuration.Header.IsTravelIncluded,
        contingencyIncluded: isContingencyIncluded ? isContingencyIncluded : false
      });
    }
  }

  fetchAppCode(serviceInterval, appArray, isContingencyIncluded?) {
    if (serviceInterval.Header.SMCSWorkApplication) {
      appArray.push({
        workApplication: serviceInterval.Header.SMCSWorkApplication,
        intervalCode: serviceInterval.Header.SMCSComponent,
        modifier: serviceInterval.Header.SMCSModifier,
        cabType: serviceInterval.Header.SMCSCabType,
        jobLocation: serviceInterval.Header.SMCSJobLocation,
        jobCondition: serviceInterval.Header.SMCSJobCondition,
        quantityCode: serviceInterval.Header.SMCSQuantity,
        workApplicationDescription: serviceInterval.Header.SMCSWorkApplicationDescription,
        intervalIncluded: serviceInterval.Configuration.Header.IsActive,
        travelIncluded: serviceInterval.Configuration.Header.IsTravelIncluded,
        contingencyIncluded: isContingencyIncluded ? isContingencyIncluded : false
      });
    }
  }

  fetchQuantCode(serviceInterval, qtyArray, isContingencyIncluded?) {
    if (serviceInterval.Header.SMCSQuantity) {
      qtyArray.push({
        quantityCode: serviceInterval.Header.SMCSQuantity,
        intervalCode: serviceInterval.Header.SMCSComponent,
        modifier: serviceInterval.Header.SMCSModifier,
        cabType: serviceInterval.Header.SMCSCabType,
        jobLocation: serviceInterval.Header.SMCSJobLocation,
        jobCondition: serviceInterval.Header.SMCSJobCondition,
        workApplication: serviceInterval.Header.SMCSWorkApplication,
        quantityDescription: serviceInterval.Header.SMCSQuantityDescription,
        intervalIncluded: serviceInterval.Configuration.Header.IsActive,
        travelIncluded: serviceInterval.Configuration.Header.IsTravelIncluded,
        contingencyIncluded: isContingencyIncluded ? isContingencyIncluded : false
      });
    }
  }

  fetchCondCode(serviceInterval, condArray, isContingencyIncluded?) {
    if (serviceInterval.Header.SMCSJobCondition) {
      condArray.push({
        jobCondition: serviceInterval.Header.SMCSJobCondition,
        intervalCode: serviceInterval.Header.SMCSComponent,
        modifier: serviceInterval.Header.SMCSModifier,
        cabType: serviceInterval.Header.SMCSCabType,
        jobLocation: serviceInterval.Header.SMCSJobLocation,
        quantityCode: serviceInterval.Header.SMCSQuantity,
        workApplication: serviceInterval.Header.SMCSWorkApplication,
        jobConditionDescription: serviceInterval.Header.SMCSJobConditionDescription,
        intervalIncluded: serviceInterval.Configuration.Header.IsActive,
        travelIncluded: serviceInterval.Configuration.Header.IsTravelIncluded,
        contingencyIncluded: isContingencyIncluded ? isContingencyIncluded : false
      });
    }
  }

  filterDistinctIntCodes(intArray) {
    const itemsFound = [];
    for (let i = 0, l = intArray.length; i < l; i++) {
      const index = itemsFound.indexOf(intArray[i].intervalCode);
      if (index === -1) {
        itemsFound.push(intArray[i].intervalCode);
        intArray[i].disabled = true;
        if (intArray[i].intervalIncluded) {
          intArray[i].disabled = false;
        }
        this.intervalCodeForTravelAndContigency.push(intArray[i]);
      } else {
        this.handleTravelIntAndContingency(intArray, i, index);
      }
    }
    this.intervalCodeForTravelAndContigency.forEach(item => {
      this.disableSelectAllForTravelTab(item, 'Interval');
    });
  }

  handleTravelIntAndContingency(intArray, i, index) {
    if (intArray[i].intervalIncluded) {
      this.intervalCodeForTravelAndContigency[index].intervalIncluded = true;
      this.intervalCodeForTravelAndContigency[index].disabled = false;
    }
    if (intArray[i].travelIncluded) {
      this.intervalCodeForTravelAndContigency[index].travelIncluded = true;
    }
    if (intArray[i].contingencyIncluded) {
      this.intervalCodeForTravelAndContigency[index].contingencyIncluded = true;
    }
  }

  filterDistinctJobCodes(jobArray) {
    const itemsFound = [];
    for (let i = 0, l = jobArray.length; i < l; i++) {
      const index = itemsFound.indexOf(jobArray[i].jobCode);
      if (index === -1) {
        jobArray[i].disabled = false;
        itemsFound.push(jobArray[i].jobCode);
        this.jobCodeForTravelAndContigency.push(jobArray[i]);
        if (!jobArray[i].jobIncluded) {
          jobArray[i].disabled = true;
        }
      } else {
        this.checkForTravelAndContingencyInJob(jobArray, i, index);
      }
    }
    this.checkContingencyForJob();
  }

  checkForTravelAndContingencyInJob(jobArray, i, index) {
    if (jobArray[i].contingencyIncluded) {
      this.jobCodeForTravelAndContigency[index].contingencyIncluded = true;
    }
    if (!jobArray[i].jobIncluded) {
      this.jobCodeForTravelAndContigency[index].disabled = true;
    } else {
      this.jobCodeForTravelAndContigency[index].jobIncluded = true;
    }
  }

  checkContingencyForJob() {
    if (this.activeTab === 'contingency') {
      this.disableSelectAllContingency.Jobs = false;
      this.jobCodeForTravelAndContigency.forEach(code => {
        if (code.disabled) {
          this.disableSelectAllContingency.Jobs = true;
        }
      });
    }
  }

  disableSelectAllForTravelTab(item, code) {
    if (item.disabled) {
      this.disableSelectAllTravel[code] = true;
      this.disableSelectAllContingency[code] = true;
    }
  }

  filterDistinctModCodes(modArray) {
    const itemsFound = [];
    for (let i = 0, l = modArray.length; i < l; i++) {
      const index = itemsFound.indexOf(modArray[i].modifier);
      if (index === -1) {
        itemsFound.push(modArray[i].modifier);
        modArray[i].disabled = true;
        if (modArray[i].intervalIncluded) {
          modArray[i].disabled = false;
        }
        this.modifierForTravelAndContigency.push(modArray[i]);
      } else {
        if (modArray[i].intervalIncluded) {
          this.modifierForTravelAndContigency[index].intervalIncluded = true;
          this.modifierForTravelAndContigency[index].disabled = false;
        }
      }
    }
    this.locationForTravelAndContigency.forEach(item => {
      this.disableSelectAllForTravelTab(item, 'Modifier');
    });
  }

  filterDistinctLocCodes(locArray) {
    const itemsFound = [];
    this.selectAllTravel.Location = true;
    for (let i = 0, l = locArray.length; i < l; i++) {
      const index = itemsFound.indexOf(locArray[i].jobLocation);
      if (index === -1) {
        itemsFound.push(locArray[i].jobLocation);
        locArray[i].disabled = true;
        if (locArray[i].intervalIncluded) {
          locArray[i].disabled = false;
        }
        this.locationForTravelAndContigency.push(locArray[i]);
      } else {
        if (locArray[i].intervalIncluded) {
          this.locationForTravelAndContigency[index].intervalIncluded = true;
          this.locationForTravelAndContigency[index].disabled = false;
        }
      }
    }
    this.locationForTravelAndContigency.forEach(item => {
      this.disableSelectAllForTravelTab(item, 'Location');
    });
  }

  filterDistinctQtyCodes(qtyArray) {
    const itemsFound = [];
    this.selectAllTravel.Quantity = true;
    for (let i = 0, l = qtyArray.length; i < l; i++) {
      const index = itemsFound.indexOf(qtyArray[i].quantityCode);
      if (index === -1) {
        itemsFound.push(qtyArray[i].quantityCode);
        qtyArray[i].disabled = true;
        if (qtyArray[i].intervalIncluded) {
          qtyArray[i].disabled = false;
        }
        this.quantityCodeForTravelAndContigency.push(qtyArray[i]);
      } else {
        if (qtyArray[i].intervalIncluded) {
          this.quantityCodeForTravelAndContigency[index].intervalIncluded = true;
          this.quantityCodeForTravelAndContigency[index].disabled = false;
        }
      }
    }
    this.quantityCodeForTravelAndContigency.forEach(item => {
      this.disableSelectAllForTravelTab(item, 'Quantity');
    });
  }

  filterDistinctAppCodes(appArray) {
    const itemsFound = [];
    this.selectAllTravel.Application = true;
    for (let i = 0, l = appArray.length; i < l; i++) {
      const index = itemsFound.indexOf(appArray[i].workApplication);
      if (index === -1) {
        itemsFound.push(appArray[i].workApplication);
        appArray[i].disabled = true;
        if (appArray[i].intervalIncluded) {
          appArray[i].disabled = false;
        }
        this.applicationForTravelAndContigency.push(appArray[i]);
      } else {
        if (appArray[i].intervalIncluded) {
          this.applicationForTravelAndContigency[index].intervalIncluded = true;
          this.applicationForTravelAndContigency[index].disabled = false;
        }
      }
    }
    this.applicationForTravelAndContigency.forEach(item => {
      this.disableSelectAllForTravelTab(item, 'Application');
    });
  }

  filterDistinctContCodes(contArray) {
    const itemsFound = [];
    this.selectAllTravel.Condition = true;
    for (let i = 0, l = contArray.length; i < l; i++) {
      const index = itemsFound.indexOf(contArray[i].jobCondition);
      if (index === -1) {
        itemsFound.push(contArray[i].jobCondition);
        contArray[i].disabled = true;
        if (contArray[i].intervalIncluded) {
          contArray[i].disabled = false;
        }
        this.conditionCodeForTravelAndContigency.push(contArray[i]);
      } else {
        if (contArray[i].intervalIncluded) {
          this.conditionCodeForTravelAndContigency[index].intervalIncluded = true;
          this.conditionCodeForTravelAndContigency[index].disabled = false;
        }
      }
    }
    this.conditionCodeForTravelAndContigency.forEach(item => {
      this.disableSelectAllForTravelTab(item, 'Condition');
    });
  }

  filterDistinctCabTypeCodes(cabTypeArray) {
    const itemsFound = [];
    this.selectAllTravel.CabType = true;
    for (let i = 0, l = cabTypeArray.length; i < l; i++) {
      const index = itemsFound.indexOf(cabTypeArray[i].cabType);
      if (index === -1) {
        itemsFound.push(cabTypeArray[i].cabType);
        cabTypeArray[i].disabled = true;
        if (cabTypeArray[i].intervalIncluded) {
          cabTypeArray[i].disabled = false;
        }
        this.cabTypeCodeForTravelAndContigency.push(cabTypeArray[i]);
      } else {
        if (cabTypeArray[i].intervalIncluded) {
          this.cabTypeCodeForTravelAndContigency[index].intervalIncluded = true;
          this.cabTypeCodeForTravelAndContigency[index].disabled = false;
        }
      }
    }
    this.cabTypeCodeForTravelAndContigency.forEach(item => {
      this.disableSelectAllForTravelTab(item, 'CabType');
    });
  }

  componentTravelIncludeChange(e, code, modifierType, filterItem?) {
    this.valueChanged = true;
    this.accordionData.forEach(grouping => {
      if (!grouping.IsAddOnGrouping && !grouping.IsAdditionalGrouping) {
        for (const serviceInterval of grouping.ServiceIntervals) {
          this.applyModifierSelection(e, modifierType, serviceInterval, code);
        }
      }
    });
    this.handleComponentSelectionInTravel();
  }

  applyModifierSelection(event, modifierType, item, code) {
    const modifierValue = _.get(
      item.Header,
      this.objectReferences[modifierType]
    );
    if ((!item.IsAdditionalInterval || (item.IsAdditionalInterval && (item.Header.ServiceIntervalTypeID === 4 || item.Header.ServiceIntervalTypeID === 3))) && (code === modifierValue && modifierValue != null) && item.Configuration.Header.IsActive) {
      item.Configuration.Header.IsTravelIncluded = event;
      this.checkAndAddToIntervalChanges(item);
    }
  }

  handleComponentSelectionInTravel() {
    this.intervalCodeForTravelAndContigency.forEach(interval => {
      interval.travelIncluded = false;
      this.checkForTravelFilterSelection(interval, 'intervalCode');
    });
    this.checkIfAllIntervalCodesIncludedInTravel();
  }

  checkForTravelFilterSelection(interval, modifierType) {
    this.accordionData.forEach(component => {
      component.ServiceIntervals.forEach(serviceInterval => {
        const key = _.get(this.objectReferences, modifierType);
        const modifierValue = _.get(serviceInterval.Header, key);
        if ((modifierValue === interval[modifierType]) && serviceInterval.Configuration.Header.IsTravelIncluded) {
          interval.travelIncluded = true;
        }
      });
    });
  }

  checkIfAllIntervalCodesIncludedInTravel() {
    this.selectAllTravel.Interval = true;
    this.intervalCodeForTravelAndContigency.forEach(interval => {
      if (!interval.travelIncluded) {
        this.selectAllTravel.Interval = false;
      }
    });
  }

  checkIfAllModCodesIncludedInTravel() {
    this.selectAllTravel.Modifier = true;
    this.modifierForTravelAndContigency.forEach(interval => {
      if (!interval.travelIncluded) {
        this.selectAllTravel.Modifier = false;
      }
    });
  }

  checkIfAllLocationCodesIncludedInTravel() {
    this.selectAllTravel.Location = true;
    this.locationForTravelAndContigency.forEach(interval => {
      if (!interval.travelIncluded) {
        this.selectAllTravel.Location = false;
      }
    });
  }

  checkIfAllAppCodesIncludedInTravel() {
    this.selectAllTravel.Application = true;
    this.applicationForTravelAndContigency.forEach(interval => {
      if (!interval.travelIncluded) {
        this.selectAllTravel.Application = false;
      }
    });
  }

  checkIfAllCabTypeCodesIncludedInTravel() {
    this.selectAllTravel.CabType = true;
    this.cabTypeCodeForTravelAndContigency.forEach(interval => {
      if (!interval.travelIncluded) {
        this.selectAllTravel.CabType = false;
      }
    });
  }

  checkIfAllQtyCodesIncludedInTravel() {
    this.selectAllTravel.Quantity = true;
    this.quantityCodeForTravelAndContigency.forEach(interval => {
      if (!interval.travelIncluded) {
        this.selectAllTravel.Quantity = false;
      }
    });
  }

  checkIfAllConditionCodesIncludedInTravel() {
    this.selectAllTravel.Condition = true;
    this.conditionCodeForTravelAndContigency.forEach(interval => {
      if (!interval.travelIncluded) {
        this.selectAllTravel.Condition = false;
      }
    });
  }

  selectAllTravelComponentChange(e) {
    this.selectAllTravel.Interval = e;
    this.intervalCodeForTravelAndContigency.forEach(entry => {
      this.componentTravelIncludeChange(e, entry.intervalCode, 'intervalCode');
      entry.travelIncluded = e;
    });
  }

  // For Interval Tab

  componentIntervalIncludeChange(event, code, modifierType, item) {
    this.valueChanged = true;
    for (const item of this.accordionData) {
      if (!item.IsAdditionalGrouping) {
        this.checkForApplyIntervalSelection(item,code,modifierType);
        const selectedIntervals = this.checkForAdditionalIntervals(item);
        if (selectedIntervals && !item.Configuration.IsActive) {
          item.Configuration.IsActive = true;
          this.checkAndAddToGroupingChange(item);
        } else if (!selectedIntervals && item.Configuration.IsActive) {
          item.Configuration.IsActive = false;
          this.checkAndAddToGroupingChange(item);
        }
      }
    }
    this.handleComponentSelectionInInterval();
  }

  checkForAdditionalIntervals(item: any) {
    return _.find(item.ServiceIntervals, (interval) => (!interval.IsAdditionalInterval || (interval.IsAdditionalInterval && (interval.Header.ServiceIntervalTypeID === 5 || interval.Header.ServiceIntervalTypeID === 4 || interval.Header.ServiceIntervalTypeID === 3))) && interval.Configuration.Header.IsActive);
  }

  checkForApplyIntervalSelection( item: any, code: any, modifierType: any) {
    for (const serviceInterval of item.ServiceIntervals) {
      if ((!serviceInterval.IsAdditionalInterval || (serviceInterval.IsAdditionalInterval && (serviceInterval.Header.ServiceIntervalTypeID === 5 || serviceInterval.Header.ServiceIntervalTypeID === 4 || serviceInterval.Header.ServiceIntervalTypeID === 3)))) {
        this.applyIntervalSelection(event, serviceInterval, code, modifierType, true);
      }
    }
  }

  checkForSelectAllFlagsInInterval() {
    this.checkIfAllAppCodesIncluded();
    this.checkIfAllCabTypeCodesIncluded();
    this.checkIfAllConditionCodesIncluded();
    this.checkIfAllIntervalCodesIncluded();
    this.checkIfAllLocationCodesIncluded();
    this.checkIfAllQtyCodesIncluded();
    this.checkIfAllModCodesIncluded();
    this.checkIfAllJobCodesIncluded();
  }

  checkForFlagInContingency() {
    this.checkIfAllIntervalCodesIncludedInContingency();
    this.checkForAllJobSelectedInContingency();
  }

  applyIntervalSelection(event, item, code, modifierType, isParent?) {
    const modifierValue = _.get(
      item.Header,
      this.objectReferences[modifierType]
    );
    if (code === modifierValue) {
      this.selectOrUnselectRootInterval(event, item);
    } else {
      for (const job of item.Details.Jobs) {
        if (job.ServiceIntervals.length !== 0) {
          this.checkIntervalSelectionAndJobChanges(event,job,code,modifierType);
        }
      }
      this.applyIntervalSelection1(item);
    }
  }

  checkIntervalSelectionAndJobChanges(event: any, job: any, code: any, modifierType: any) {
    for (const interval of job.ServiceIntervals) {
      this.applyIntervalSelection(event, interval, code, modifierType);
    }
    const selectedIntervals = _.find(job.ServiceIntervals, (interval) => interval.Configuration.Header.IsActive);
    if (selectedIntervals && !job.Configuration.IsActive) {
      job.Configuration.IsActive = true;
      this.checkAndAddToJobChanges(job);
    } else if (!selectedIntervals && job.Configuration.IsActive) {
      job.Configuration.IsActive = false;
      this.checkAndAddToJobChanges(job);
    }
  }

  applyIntervalSelection1(item) {
    const selectedJobs = _.find(item.Details.Jobs, (jobs) => jobs.Configuration.IsActive);
    if (selectedJobs && !item.Configuration.Header.IsActive) {
      item.Configuration.Header.IsActive = true;
      this.checkAndAddToIntervalChanges(item);
    } else if (!selectedJobs && item.Configuration.Header.IsActive) {
      item.Configuration.Header.IsActive = false;
      this.checkAndAddToIntervalChanges(item);
    }
  }
  checkAndAddToGroupingChange(item) {
    this.filterChanges.groupingChanges.forEach((el, index, array) => {
      if (el.CorrelationId === item.Configuration.CorrelationId) {
        array.splice(index, 1);
      }
    });
    this.filterChanges.groupingChanges.push(JSON.parse(JSON.stringify(item.Configuration)));
  }

  checkAndAddToJobChanges(job) {
    this.filterChanges.jobChanges.forEach((el, index, array) => {
      if (el.CorrelationId === job.Configuration.CorrelationId) {
        array.splice(index, 1);
      }
    });
    this.filterChanges.jobChanges.push(JSON.parse(JSON.stringify(job.Configuration)));
  }

  checkAndAddToIntervalChanges(interval) {
    this.filterChanges.componentChanges.forEach((el, index, array) => {
      if (el.CorrelationId === interval.Configuration.Header.CorrelationId) {
        array.splice(index, 1);
      }
    });
    this.filterChanges.componentChanges.push(JSON.parse(JSON.stringify(interval.Configuration.Header)));
  }

  handleComponentSelectionInInterval() {
    this.intervalCodeForTravelAndContigency.forEach(interval => {
      interval.intervalIncluded = false;
      this.checkForIntervalFilterSelection(interval, 'intervalCode');
    });
    this.locationForTravelAndContigency.forEach(interval => {
      interval.intervalIncluded = false;
      this.checkForIntervalFilterSelection(interval, 'jobLocation');
    });
    this.quantityCodeForTravelAndContigency.forEach(interval => {
      interval.intervalIncluded = false;
      this.checkForIntervalFilterSelection(interval, 'quantityCode');
    });
    this.applicationForTravelAndContigency.forEach(interval => {
      interval.intervalIncluded = false;
      this.checkForIntervalFilterSelection(interval, 'workApplication');
    });
    this.modifierForTravelAndContigency.forEach(interval => {
      interval.intervalIncluded = false;
      this.checkForIntervalFilterSelection(interval, 'modifier');
    });
    this.conditionCodeForTravelAndContigency.forEach(interval => {
      interval.intervalIncluded = false;
      this.checkForIntervalFilterSelection(interval, 'jobCondition');
    });
    this.cabTypeCodeForTravelAndContigency.forEach(interval => {
      interval.intervalIncluded = false;
      this.checkForIntervalFilterSelection(interval, 'cabType');
    });
    if (this.activeTab === 'intervals') {
      this.selectAllInterval.Jobs = true;
      this.jobCodeForTravelAndContigency.forEach(job => {
        job.jobIncluded = false;
        this.checkForJobFilterSelection(job);
        if (!job.jobIncluded) {
          this.selectAllInterval.Jobs = false;
        }
      });
    }
    this.checkIfAllAppCodesIncluded();
    this.checkIfAllCabTypeCodesIncluded();
    this.checkIfAllConditionCodesIncluded();
    this.checkIfAllIntervalCodesIncluded();
    this.checkIfAllLocationCodesIncluded();
    this.checkIfAllQtyCodesIncluded();
    this.checkIfAllModCodesIncluded();
  }


  checkForIntervalFilterSelection(interval, modifierType) {
    this.accordionData.forEach(component => {
      component.ServiceIntervals.forEach(serviceInterval => {
        const key = _.get(this.objectReferences, modifierType);
        const modifierValue = _.get(serviceInterval.Header, key);
        if ((modifierValue === interval[modifierType]) && serviceInterval.Configuration.Header.IsActive) {
          interval.intervalIncluded = true;
        }
      });
    });
  }

  checkIfAllIntervalCodesIncluded() {
    this.selectAllInterval.Interval = true;
    this.intervalCodeForTravelAndContigency.forEach(interval => {
      if (!interval.intervalIncluded) {
        this.selectAllInterval.Interval = false;
      }
    });
  }

  checkIfAllModCodesIncluded() {
    this.selectAllInterval.Modifier = true;
    this.modifierForTravelAndContigency.forEach(interval => {
      if (!interval.intervalIncluded) {
        this.selectAllInterval.Modifier = false;
      }
    });
  }

  checkIfAllJobCodesIncluded() {
    this.selectAllInterval.Jobs = true;
    this.jobCodeForTravelAndContigency.forEach(job => {
      if (!job.jobIncluded) {
        this.selectAllInterval.Jobs = false;
      }
    });
  }

  checkIfAllLocationCodesIncluded() {
    this.selectAllInterval.Location = true;
    this.locationForTravelAndContigency.forEach(interval => {
      if (!interval.intervalIncluded) {
        this.selectAllInterval.Location = false;
      }
    });
  }

  checkIfAllAppCodesIncluded() {
    this.selectAllInterval.Application = true;
    this.applicationForTravelAndContigency.forEach(interval => {
      if (!interval.intervalIncluded) {
        this.selectAllInterval.Application = false;
      }
    });
  }

  checkIfAllCabTypeCodesIncluded() {
    this.selectAllInterval.CabType = true;
    this.cabTypeCodeForTravelAndContigency.forEach(interval => {
      if (!interval.intervalIncluded) {
        this.selectAllInterval.CabType = false;
      }
    });
  }

  checkIfAllQtyCodesIncluded() {
    this.selectAllInterval.Quantity = true;
    this.quantityCodeForTravelAndContigency.forEach(interval => {
      if (!interval.intervalIncluded) {
        this.selectAllInterval.Quantity = false;
      }
    });
  }

  checkIfAllConditionCodesIncluded() {
    this.selectAllInterval.Condition = true;
    this.conditionCodeForTravelAndContigency.forEach(interval => {
      if (!interval.intervalIncluded) {
        this.selectAllInterval.Condition = false;
      }
    });
  }

  checkForJobFilterSelection(job) {
    this.accordionData.forEach(component => {
      component.ServiceIntervals.forEach(serviceInterval => {
        serviceInterval.Details.Jobs.forEach(item => {
          if (job.jobCode === item.JobCode && item.Configuration.IsActive) {
            job.jobIncluded = true;
          }
        });
      });
    });
  }

  selectOrUnselectRootInterval(event, interval) {
    if ((interval.Configuration.Header.IsActive !== event) || (interval.Configuration.Header.IsTravelIncluded === event)) {
      interval.Configuration.Header.IsActive = event;
      interval.Configuration.Header.IsTravelIncluded = event;
      this.checkAndAddToIntervalChanges(interval);
    }
    interval.Details.Jobs.forEach(job => {
      this.selectOrUnselectRootJob(event, job);
    });
  }

  selectOrUnselectRootJob(event, job) {
    if ((job.Configuration.IsActive !== event) || (job.Configuration.IsContingencyActive !== event)) {
      job.Configuration.IsActive = event;
      job.Configuration.IsContingencyActive = event;
      this.checkAndAddToJobChanges(job);
    }
    job.ServiceIntervals.forEach(interval => {
      this.selectOrUnselectRootInterval(event, interval);
    });
  }

  intervalTabJobChange(event, code) {
    this.valueChanged = true;
    for (const item of this.accordionData) {
      if (!item.IsAdditionalGrouping) {
        for (const serviceInterval of item.ServiceIntervals) {
          if (!serviceInterval.IsAdditionalInterval || (serviceInterval.IsAdditionalInterval && (serviceInterval.Header.ServiceIntervalTypeID === 3 || serviceInterval.Header.ServiceIntervalTypeID === 4))) {
            this.checkForIntervalTabJobAndIntervalChanges(event,serviceInterval,code);  
          }
        }
        this.intervalTabJobChange1(item);
      }
    }
    this.handleComponentSelectionInInterval();
  }

  checkForIntervalTabJobAndIntervalChanges(event: any, serviceInterval: any, code: any) {
    for (const job of serviceInterval.Details.Jobs) {
      this.applyIntervalFilterJobSelection(job, code, event);
    }
    const selectedJobs = _.find(serviceInterval.Details.Jobs, (job) => job.Configuration.IsActive);
    if (selectedJobs && !serviceInterval.Configuration.Header.IsActive) {
      serviceInterval.Configuration.Header.IsActive = true;
      serviceInterval.Configuration.Header.IsTravelIncluded = true;
      this.checkAndAddToIntervalChanges(serviceInterval);
    } else if (!selectedJobs && serviceInterval.Configuration.Header.IsActive) {
      serviceInterval.Configuration.Header.IsActive = false;
      serviceInterval.Configuration.Header.IsTravelIncluded = false;
      this.checkAndAddToIntervalChanges(serviceInterval);
    }
  }

  intervalTabJobChange1(item) {
    const selectedIntervals = _.find(item.ServiceIntervals, (interval) => !interval.IsAdditionalInterval && interval.Configuration.Header.IsActive);
    if (selectedIntervals && !item.Configuration.IsActive) {
      item.Configuration.IsActive = true;
      this.checkAndAddToGroupingChange(item);
    } else if (!selectedIntervals && item.Configuration.IsActive) {
      item.Configuration.IsActive = false;
      this.checkAndAddToGroupingChange(item);
    }
  }

  applyIntervalFilterJobSelection(item, code, event) {
    if (code === item.JobCode) {
      this.selectOrUnselectRootJob(event, item);
    } else {
      if (item.ServiceIntervals.length !== 0) {
        for (const interval of item.ServiceIntervals) {
          this.checkForIntervalFilterJobSelection(event,interval,code);
        }
        this.applyIntervalSelection1(item);
      }
    }
  }

  checkForIntervalFilterJobSelection(event: any, interval: any, code: any) {
    for (const currentJob of interval.Details.Jobs) {
      this.applyIntervalFilterJobSelection(currentJob, code, event);
    }
    const selectedJobs = _.find(interval.Details.Jobs, (job) => job.Configuration.IsActive);
    if (selectedJobs && !interval.Configuration.Header.IsActive) {
      interval.Configuration.Header.IsActive = true;
      this.checkAndAddToIntervalChanges(interval);
    } else if (!selectedJobs && interval.Configuration.Header.IsActive) {
      interval.Configuration.Header.IsActive = false;
      this.checkAndAddToIntervalChanges(interval);
    }
  }

  applyIntervalFilterJobSelection1(item) {
    const selectedIntervals = _.find(item.ServiceIntervals, (interval) => interval.Configuration.Header.IsActive);
    if (selectedIntervals && !item.Configuration.IsActive) {
      item.Configuration.IsActive = true;
      this.checkAndAddToJobChanges(item);
    } else if (!selectedIntervals && item.Configuration.IsActive) {
      item.Configuration.IsActive = false;
      this.checkAndAddToJobChanges(item);
    }
  }

  selectAllComponentInInterval(event, modifierType) {
    this.selectAllInterval.Interval = event;
    this.intervalCodeForTravelAndContigency.forEach((interval) => {
      this.componentIntervalIncludeChange(event, interval.intervalCode, modifierType, interval);
      interval.intervalIncluded = event;
    });
  }

  selectAllJobsInInterval(event) {
    this.selectAllInterval.Jobs = event;
    this.jobCodeForTravelAndContigency.forEach((job) => {
      this.intervalTabJobChange(event, job.jobCode);
      job.jobIncluded = event;
    });
  }

  selectAllJobLocationInIntervalChange(event, modifierType) {
    this.selectAllInterval.Location = event;
    this.jobCodeForTravelAndContigency.forEach((interval) => {
      this.componentIntervalIncludeChange(event, interval.jobLocation, 'jobLocation', interval);
      interval.intervalIncluded = event;
    });
  }
  selectAllModifiersInIntervalChange(event, modifierType) {
    this.selectAllInterval.Modifier = event;
    this.modifierForTravelAndContigency.forEach((interval) => {
      this.componentIntervalIncludeChange(event, interval[modifierType], modifierType, interval);
      interval.intervalIncluded = event;
    });
  }
  selectAllCabTypeInIntervalChange(event, modifierType) {
    this.selectAllInterval.CabType = event;
    this.cabTypeForFilter.forEach((interval) => {
      this.componentIntervalIncludeChange(event, interval.cabType, modifierType, interval);
      interval.intervalIncluded = event;
    });
  }
  selectAllWorkApplicationInIntervalChange(event, modifierType) {
    this.selectAllInterval.Application = event;
    this.applicationForTravelAndContigency.forEach((interval) => {
      this.componentIntervalIncludeChange(event, interval.workApplication, modifierType, interval);
      interval.intervalIncluded = event;
    });
  }
  selectAllJobConditionInIntervalChange(event, modifierType) {
    this.selectAllInterval.Condition = event;
    this.conditionCodeForTravelAndContigency.forEach((interval) => {
      this.componentIntervalIncludeChange(event, interval.jobCondition, modifierType, interval);
      interval.intervalIncluded = event;
    });
  }
  selectAllQuantityInIntervalChange(event, modifierType) {
    this.selectAllInterval.Quantity = event;
    this.quantityCodeForTravelAndContigency.forEach((interval) => {
      this.componentIntervalIncludeChange(event, interval.quantityCode, modifierType, interval);
      interval.intervalIncluded = event;
    });
  }


  // For contingency

  componentContingencyIncludeChange(e, code, modifierType, filterItem?) {
    this.valueChanged = true;
    this.accordionData.forEach(grouping => {
      if (!grouping.IsAddOnGrouping && !grouping.IsAdditionalGrouping) {
        for (const serviceInterval of grouping.ServiceIntervals) {
          this.applyContingencySelection(e, modifierType, serviceInterval, code);
        }
      }
    });
    this.handleComponentSelectionInContingency();
  }

  applyContingencySelection(event, modifierType, item, code) {
    const modifierValue = _.get(
      item.Header,
      this.objectReferences[modifierType]
    );
    if (!item.IsAdditionalInterval && (code === modifierValue && modifierValue != null) && item.Configuration.Header.IsActive) {
      for (const job of item.Details.Jobs) {
        if (!job.IsAddOnJob && job.Configuration.IsActive) {
          job.Configuration.IsContingencyActive = event;
          this.checkAndAddToJobChanges(job);
        }
      }
    }
  }

  jobContingencyIncludeChange(e, code) {
    this.valueChanged = true;
    for (const item of this.accordionData) {
      if (!item.IsAddOnGrouping) {
        for (const serviceInterval of item.ServiceIntervals) {
          this.checkForJobContingencyIncludeChange(e,serviceInterval,code);
        }
      }
    }
    this.handleComponentSelectionInContingency();
  }

  checkForJobContingencyIncludeChange(e: any, serviceInterval: any, code: any) {
    if (!serviceInterval.IsAdditionalInterval && serviceInterval.Configuration.Header.IsActive) {
      for (const job of serviceInterval.Details.Jobs) {
        if (!job.IsAddOnJob && (job.JobCode === code) && job.Configuration.IsActive) {
          job.Configuration.IsContingencyActive = e;
          this.checkAndAddToJobChanges(job);
        }
      }
    }
  }

  handleComponentSelectionInContingency() {
    this.intervalCodeForTravelAndContigency.forEach(interval => {
      interval.contingencyIncluded = false;
      this.checkForContingencyFilterSelection(interval, 'intervalCode');
    });
    this.selectAllContingency.Jobs = true;
    this.jobCodeForTravelAndContigency.forEach(job => {
      job.contingencyIncluded = false;
      this.checkForJobFilterSelectionContingency(job);
      if (!job.contingencyIncluded) {
        this.selectAllContingency.Jobs = false;
      }
    });
    this.checkIfAllIntervalCodesIncludedInContingency();
  }

  checkForContingencyFilterSelection(interval, modifierType) {
    this.accordionData.forEach(component => {
      component.ServiceIntervals.forEach(serviceInterval => {
        const key = _.get(this.objectReferences, modifierType);
        const modifierValue = _.get(serviceInterval.Header, key);
        if ((modifierValue === interval[modifierType]) && this.checkForContingencyIncluded(serviceInterval)) {
          interval.contingencyIncluded = true;
        }
      });
    });
  }

  checkIfAllIntervalCodesIncludedInContingency() {
    this.selectAllContingency.Interval = true;
    this.intervalCodeForTravelAndContigency.forEach(interval => {
      if (!interval.contingencyIncluded) {
        this.selectAllContingency.Interval = false;
      }
    });
  }

  checkForJobFilterSelectionContingency(job) {
    this.accordionData.forEach(component => {
      component.ServiceIntervals.forEach(serviceInterval => {
        serviceInterval.Details.Jobs.forEach(item => {
          if (job.jobCode === item.JobCode && item.Configuration.IsContingencyActive) {
            job.contingencyIncluded = true;
          }
        });
      });
    });
  }

  selectAllContingencyComponentChange(e, modifierType) {
    this.selectAllContingency.Interval = e;
    this.intervalCodeForTravelAndContigency.forEach(entry => {
      this.componentContingencyIncludeChange(e, entry.intervalCode, modifierType);
      entry.contingencyIncluded = e;
    });
  }

  selectAllJobsInContingency(e) {
    this.selectAllContingency.Jobs = e;
    this.jobCodeForTravelAndContigency.forEach(entry => {
      this.jobContingencyIncludeChange(e, entry.jobCode);
      entry.contingencyIncluded = e;
    });
  }

  checkTab(activeTab, modifierType) {

    if (modifierType == 'modifier') {
      this.filterTooltip = this.translate.instant('modifiers');
    }
    else {
      this.filterTooltip = this.translate.instant(modifierType);
    }
    if (activeTab !== 'contingency' && activeTab !== 'travel' && activeTab !== 'intervals') {
      if (modifierType !== 'job') {
        return this.arrayReferencesForFilter[modifierType].length > 0;
      }
      else {
        return this.jobsForFilter.length > 0;
      }
    }
    else if (activeTab == 'intervals') {

      return this.arrayReferencesForInterval[modifierType].length > 0;
    }
  }

  checkForAllJobSelectedInContingency() {
    this.selectAllContingency.Jobs = true;
    this.jobCodeForTravelAndContigency.forEach(job => {
      if (!job.contingencyIncluded) {
        this.selectAllContingency.Jobs = false;
      }
    });
  }

  ngOnDestroy(){
    this.accordionData = null;
  }
}

