import { getCurrencySymbol } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { TableDescription } from "@app/services/models/misc/table-description";
import { DataService } from "@app/services/shared/storage/data.service";

@Component({
  selector: "app-labour-data-table",
  templateUrl: "./labour-data-table.component.html",
  styleUrls: ["./labour-data-table.component.scss"],
})
export class LabourDataTableComponent implements OnInit, OnChanges {
  @Input() laborDetails;
  @Input() isEdit;
  @Output() laborEventEmitter = new EventEmitter();
  currencySymbol: any;
  langChangeLocal: any;
  currencyCode: any;
  isUserOrgIntermediate: boolean = false;
  constructor(
    private modalService: NgbModal,
    public ref: ChangeDetectorRef,
    private dataService: DataService
  ) {
    this.isUserOrgIntermediate = this.dataService.isUserOrgIntermediate;
  }

  ngOnInit(): void {
    this.langChangeLocal = localStorage.getItem("locale");
    this.currencyCode = this.laborDetails.LaborRate?.Sell?.CurrencyCode;
    this.currencySymbol = getCurrencySymbol(
      this.laborDetails.LaborRate?.Sell?.CurrencyCode,
      "wide"
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.currencyCode = this.laborDetails.LaborRate?.Sell?.CurrencyCode;
    this.currencySymbol = getCurrencySymbol(
      this.laborDetails.LaborRate?.Sell?.CurrencyCode,
      "wide"
    );
  }

  addLabour(element) {
    this.laborEventEmitter.emit(element);
  }

  getLaborDataTableDesc = () => {
    let tableDesc = TableDescription.LaborData;
    return tableDesc;
  };
}
