import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dropdownList'
})
export class DropdownListPipe implements PipeTransform {

  transform(list: any, item: any): any {
    const arr = JSON.parse(JSON.stringify(list));
    const newList = arr.slice(list.indexOf(item.Start));
    return newList;
  }

}
