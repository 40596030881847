import { FormGroup } from "@angular/forms";

import {
  DuplicateServiceOption,
  CoreCreditFlag,
} from "@app/services/models/misc/general";

export class Utility {
  /**
   * @ Accepts 2 arguments
   * @ 1st argument type as array
   * @ 2nd argument type as array of indexes which will be splice from an array
   * @ This method splice value from an array based on index
   */
  spliceArrayAtIndex(array: Array<any>, indexes: number[]) {
    indexes.sort((a, b) => b - a);

    indexes.forEach((index) => {
      if (index >= 0 && index < array.length) {
        array.splice(index, 1);
      }
    });
    return array;
  }

  /**
   * @ Accepts argument type as DuplicateServiceOption
   * @ Helps to format smcs desctiption string as mentioned below
   * @ Component Description + Job Description + Modifier Description + Quantity Description + Location Description + Work Application Description + Job Condition Description
   */
  getSmcsDesc(duplicateServiceOption: DuplicateServiceOption): string {
    let smcsDesc =
      `${
        duplicateServiceOption.SMCSComponentDescription
          ? duplicateServiceOption.SMCSComponentDescription
          : ""
      }` +
      `${
        duplicateServiceOption.JobCodeDescription
          ? "-" + duplicateServiceOption.JobCodeDescription
          : ""
      }` +
      `${
        duplicateServiceOption.SMCSModifierDescription
          ? "-" + duplicateServiceOption.SMCSModifierDescription
          : ""
      }` +
      `${
        duplicateServiceOption.SMCSQuantityDescription
          ? "-" + duplicateServiceOption.SMCSQuantityDescription
          : ""
      }` +
      `${
        duplicateServiceOption.SMCSJobLocationDescription
          ? "-" + duplicateServiceOption.SMCSJobLocationDescription
          : ""
      }` +
      `${
        duplicateServiceOption.SMCSWorkApplicationDescription
          ? "-" + duplicateServiceOption.SMCSWorkApplicationDescription
          : ""
      }` +
      `${
        duplicateServiceOption.SMCSJobConditionDescription
          ? "-" + duplicateServiceOption.SMCSJobConditionDescription
          : ""
      }`;

    smcsDesc = smcsDesc == "" ? "-" : smcsDesc;

    return smcsDesc;
  }

  /**
   * @ Accepts argument type as string or null.
   * @ Converts CreditFlag property value of short forms to the meaning full name.
   * @ If value is null or blank string converts it into '-'.
   * @ Example: F - Full, P - Partial, N - None.
   */
  formatCoreCreditflag(creditFlag: string | null): string {
    const coreCreditFormatedLabel =
      creditFlag === "F"
        ? CoreCreditFlag.Full
        : creditFlag === "P"
        ? CoreCreditFlag.Partial
        : creditFlag === "N"
        ? CoreCreditFlag.None
        : "-";

    return coreCreditFormatedLabel;
  }

  /**
   * @ Accepts 2 arguments
   * @ 1st argument type as FormGroup object
   * @ 2nd argument type as form fields string array
   * @ Remove form validation for particular fields mentioned in 2nd argument
   * @ This method's return type is FormGroup
   */
  removeFormsMandatoryFields(
    form: FormGroup,
    formFields: Array<string>
  ): FormGroup {
    if (formFields.length > 0 && form !== null) {
      for (let i = 0; i < formFields.length; i++) {
        form.get(formFields[i]).clearValidators();
        form.updateValueAndValidity();
      }
    }

    return form;
  }

  /**
   * @ Accepts 2 arguments
   * @ 1st argument type as number - Setting minimum value
   * @ 2nd argument type as number - Value will be checked for minimum validation
   * @ This menthod's return type is boolean
   */
  minimumNumberValidation(minVal: number, value: number) {
    value = value.toString() === "-" ? -1 : value;
    return value < minVal ? true : false;
  }

  /**
   * @ Accepts 1 argument.
   * @ 1st argument type as number.
   * @ This menthod's return type is number.
   */
  calculateEstimateLength(totalMonths: number): number {
    let roundedYears = Math.floor(totalMonths / 12);
    let remainingMonths = totalMonths % 12;
    switch (remainingMonths) {
      case 0:
        return roundedYears;
      case 3:
        return roundedYears + 0.25;
      case 6:
        return roundedYears + 0.5;
      case 9:
        return roundedYears + 0.75;
      default:
        return roundedYears;
    }
  }
}
