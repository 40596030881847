import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-product-details-view',
  templateUrl: './product-details-view.component.html',
  styleUrls: ['./product-details-view.component.scss']
})
export class ProductDetailsViewComponent implements OnInit {

  columns = [
    {
      label: 'Manufacturer', value: 'Manufacturer',
      keyPath: 'Manufacturer.Name', show: true, cssClass: 'col-Manufacturer', width: 150
    },
    {
      label: 'family', value: 'family',
      keyPath: 'Family.Name', show: true, cssClass: 'col-family', width: 170
    },
    {
      label: 'model', value: 'model',
      keyPath: 'Model.SalesModel', show: true, cssClass: 'col-model',
      width: 100
    },
    {
      label: 'serialPrefix', value: 'serialPrefix',
      keyPath: 'PrefixAndSerialNumbers.Prefix', show: true, cssClass: 'col-serialPrefix', width: 120
    },
    {
      label: 'serialRange', value: 'serialRange',
      keyPath: 'PrefixAndSerialNumbers.Ranges[0].Low', show: true, cssClass: 'col-serialRange', width: 150
    }
  ];
  displayedColumns = [];
  selectedProductIndex = 0;
  selectedProduct;

  constructor(private activeModal: NgbActiveModal) { }
  @Input() productData;
  ngOnInit() {
    this.displayedColumns.push('radio');
    this.productData.forEach(item => {
        item.selectedSerialRange = item.PrefixAndSerialNumbers.Range.Low + '-' + item.PrefixAndSerialNumbers.Range.High;
    });
    this.setDisplayedColumns();
    this.selectedProduct = this.productData[0];
  }

  close(){
    this.activeModal.close();
  }

  setDisplayedColumns(){
    this.columns.forEach((column, index) => {

      this.displayedColumns.push(column.value);
    });
  }

  selectData(event){
    this.activeModal.close(this.selectedProduct);
  }

  selectedRow(element, i){
    this.selectedProductIndex = i;
    this.selectedProduct = element;
  }

}
