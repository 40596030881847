import { Component, ElementRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { SubjectService } from '@app/services/data/subject.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

  @Input() numberofRecords;
  @Input() itemsPerPageOptions = [];
  @Input() itemsPerPage = 10;
  @Input() pageName = '';
  @Input() alignSet=false;
  @Output() emitPagedData = new EventEmitter();
  pageStartIndex = 1;
  pageEndIndex: number;
  activePage = 1;
  pager: any;
  previousPager: any;
  pagesShown: number;
  _maxPagesShown: number;
  _advanced = true;
  mobile = false;
  paged: { pageNumber: any; pageSize: any; startIndex: any; endIndex: any; startItem: any; endItem: any; numberOfPages: any; pageName: string};
  isFirstLargeEllipses = false;
  isSecondLargeEllipses = false;
  isBackArrowDisabled = false;
  isForwardArrowDisabled = false;
  isNumber: boolean[] = [];
  isLastNumberClickable: boolean[] = [];
  isCurrentPage: boolean[] = [];
  _maxActive = 4;

  constructor(private renderer: Renderer2, private dataservice: SubjectService) { }

  ngOnChanges(changes: any) {
    if (changes && changes.numberofRecords) {
      this.numberofRecords = changes.numberofRecords.currentValue;
      this.setPage(this.activePage);
    }
    if (changes && changes.itemsPerPage) {
      this.itemsPerPage = changes.itemsPerPage.currentValue;
      this.setPage(this.activePage);
    }
  }

  setPage(page: number, itemsPerPage?) {
    if (this.pageName === 'my-org-wg' && itemsPerPage) {
      this.itemsPerPage = Number(itemsPerPage);
    }
    if (this.itemsPerPage) {
      this.pager = this.getPager(Number(this.numberofRecords), Number(page), Number(this.itemsPerPage));
      this.previousPager = this.pager;

      this.paged = {
        pageNumber: this.pager.currentPage,
        pageSize: this.itemsPerPage,
        startIndex: this.pager.startIndex,
        endIndex: this.pager.endIndex,
        startItem: this.pager.startIndex + 1,
        endItem: this.pager.endIndex + 1,
        numberOfPages: this.pager.pageArray.length,
        pageName: this.pageName
      };

      this.dataservice.sendMessage({ pagination: this.paged }, 'eventEmitListen');
      this.emitPagedData.emit(this.paged);

      this.evaluateLogic();
    }
  }

  getPager(numberOfItems: number, activePage: number = 1, itemPerPage: number = 10) {
    const totalPages = Math.ceil(Number(numberOfItems) / Number(itemPerPage));
    const pageLimit = this.getPageLimit(totalPages, activePage);
    if (activePage < 1) {
      activePage = 1;
    } else if (activePage > totalPages) {
      activePage = totalPages;
    }
    const startIndex = (activePage - 1) * itemPerPage;
    const endIndex = Math.min(startIndex + itemPerPage - 1, numberOfItems - 1);
    const pages =
      totalPages <= pageLimit
        ? { pages: Array.from(Array(totalPages).keys(), x => x + 1), firstDotRange: '', secondDotRange: '' }
        : this.paginate(activePage, totalPages);
    const pageArray = Array(totalPages)
      .fill(1)
      .map((x, i) => i + 1);

    this.pageStartIndex = startIndex + 1;
    this.pageEndIndex = endIndex + 1;
    return {
      currentPage: activePage,
      startIndex,
      endIndex,
      pages: pages.pages,
      firstDotRange: pages.firstDotRange,
      secondDotRange: pages.secondDotRange,
      pageArray
    };
  }

  getPageLimit(totalPages: number, currentPage: number) {
    let pageLimit: number;
    pageLimit = this.getAdvancedPageLimit(totalPages, currentPage);
    if (this._maxPagesShown) {
      pageLimit = this.pagesShown;
    }
    return pageLimit;
  }

  getAdvancedPageLimit(totalPages: number, currentPage: number) {
    let pageLimit: number;
    const pageBinary =
      currentPage >= totalPages - Math.abs(6 - totalPages) || currentPage <= Math.abs(5 - totalPages);
    if (totalPages <= 6) {
      pageLimit = 5;
    }
    if (totalPages === 7) {
      pageLimit = pageBinary ? 5 : 6;
    }
    if (totalPages === 8) {
      if(currentPage === totalPages || currentPage === 1){
          pageLimit = 5;
      }
      else if(pageBinary){
          pageLimit = 6;
      }
      else {
        pageLimit = 7;
      }
    }
    return pageLimit;
  }

  paginate(currentPage: number, lastPage: number) {
    const offsets = this.getOffsets(lastPage, currentPage);
    let returnValue;

    if (currentPage <= offsets.offsetIf) {
      returnValue = this.getFirstIfPagination(lastPage, currentPage);
    } else if (currentPage >= lastPage - (offsets.offsetIf - 1)) {
      returnValue = this.getSecondIfPagination(lastPage, currentPage);
    } else {
      returnValue = this.multiEllipsesPaginate(lastPage, currentPage);
    }
    return returnValue;
  }

  getOffsets(totalPages: number, currentPage?: number) {
    let offsets;

    if (this.mobile) {
      offsets = {
        maxDisplayedPages: 5,
        offsetIf: 3,
        offsetFDot: 4,
        offsetSDot: 2,
        offsetPages: 2
      };
    } else if (this._maxPagesShown && this._advanced) {
      if (this._maxPagesShown <= 4) {
        offsets = {
          maxDisplayedPages: this.pagesShown,
          offsetIf: this.pagesShown - 2,
          offsetFDot: this.pagesShown - 1,
          offsetSDot: this.pagesShown - 3,
          offsetPages: this.pagesShown - 3
        };
      } else {
        offsets = {
          maxDisplayedPages: this.pagesShown,
          offsetIf: this.pagesShown - 3,
          offsetFDot: this.pagesShown - 1,
          offsetSDot: this.pagesShown - 4,
          offsetPages: this.pagesShown - 3
        };
      }
    } else if (this._advanced) {
      offsets = this.getAdvancedOffsets(totalPages, currentPage);
    } else {
      offsets = {
        maxDisplayedPages: 7,
        offsetIf: 4,
        offsetFDot: 6,
        offsetSDot: 3,
        offsetPages: 4
      };
    }

    return offsets;
  }

  getAdvancedOffsets(totalPages: number, currentPage: number) {
    let maxPages: number;
    let offset: number;
    if (totalPages <= 6) {
      maxPages = 5;
      offset = 2;
    } else if (totalPages === 7) {
      maxPages = currentPage >= totalPages - 1 || currentPage <= 2 ? 5 : 6;
      offset = currentPage >= totalPages - 1 || currentPage <= 2 ? 2 : 1;
    } else {
      const { maxCount, offsetCount } = this.getOffsetAndMaxPage(currentPage, totalPages);
      maxPages = maxCount;
      offset = offsetCount;
    }
    return {
      maxDisplayedPages: maxPages,
      offsetIf: 4 - offset,
      offsetFDot: 6 - offset,
      offsetSDot: 3 - offset,
      offsetPages: 4 - offset
    };
  }

  getOffsetAndMaxPage(currentPage, totalPages) {
    let maxPages = 0;
    let offset;
    if(currentPage >= totalPages - 1 || currentPage <= 2){
      maxPages = 5;
      offset = 2;
    }else if(currentPage >= totalPages - 2 || currentPage <= 3){
      maxPages = 6
      offset = 1
    }else{
      maxPages = 7
      offset = 0;
    }
    return {maxCount: maxPages, offsetCount: offset}
  }

  getFirstIfPagination(lastPage: number, currentPage: number) {
    const offsets = this.getOffsets(lastPage, currentPage);
    return {
      firstDotRange: Array.from(Array(lastPage - offsets.offsetFDot).keys(), x => x + offsets.offsetFDot),
      pages: Array.from(Array(offsets.maxDisplayedPages).keys(), x =>{
        let result;
        if(x <= offsets.offsetPages){
          result = x + 1;
        }else if( x + 1 === offsets.offsetFDot ){
          result = '...';
        }else {
          result = lastPage;
        }
        return result;
      }
      ),
      secondDotRange: ''
    };
  }

  getSecondIfPagination(lastPage: number, currentPage: number) {
    const offsets = this.getOffsets(lastPage, currentPage);
    return {
      firstDotRange: Array.from(Array(lastPage - offsets.offsetFDot).keys(), x => x + 2),
      pages: Array.from(Array(offsets.maxDisplayedPages).keys(), x =>
        x === 0 ? x + 1 : x + 1 === 2 ? '...' : lastPage - offsets.offsetFDot + x
      ),
      secondDotRange: ''
    };
  }

  multiEllipsesPaginate(lastPage, currentPage) {
    let returnValue;

    if (this._advanced && !this.mobile && !this._maxPagesShown) {
      returnValue = this.advancedPaginate(lastPage, currentPage);
    } else if (this._advanced && this._maxPagesShown && !this.mobile) {
      this.pagesShown = this.pagesShown % 2 === 0 ? this._maxPagesShown + 2 : this.pagesShown;
      returnValue = this.maxPagesShownPagination(lastPage, currentPage);
    } else {
      returnValue = this.getThirdIfPagination(lastPage, currentPage);
    }

    return returnValue;
  }

  advancedPaginate(lastPage, currentPage) {
    let advPages: number[];
    let returnValue;

    if (lastPage > 8 || lastPage <= 5) {
      return this.getThirdIfPagination(lastPage, currentPage);
    }
    if (lastPage === 6) {
      advPages = [1, 2, 3, 4, 5, 6];
    }
    if (lastPage === 7) {
      advPages = [1, 2, 3, 4, 5, 6, 7];
    }
    returnValue = {
      firstDotRange: '',
      pages: advPages,
      secondDotRange: ''
    };

    return returnValue;
  }

  getThirdIfPagination(lastPage: number, currentPage: number) {
    const offsets = this.getOffsets(lastPage);
    let returnPage;

    return {
      firstDotRange: Array.from(Array(currentPage - offsets.offsetSDot).keys(), x => x + 2),
      pages: Array.from(Array(offsets.maxDisplayedPages).keys(), x => {
        if (x === 0) {
          returnPage = x + 1;
        } else if (x === offsets.offsetFDot) {
          returnPage = lastPage;
        } else if (x === 1 || x === offsets.offsetPages + 1) {
          returnPage = '...';
        } else if (this.mobile) {
          returnPage = currentPage;
        } else {
          if( x === 2 ){
            returnPage = currentPage - 1;
          }else if( x === 3 ){
            returnPage = currentPage;
          }else{
            returnPage = currentPage + 1;
          }
        }

        return returnPage;
      }),
      secondDotRange: Array.from(
        Array(lastPage - currentPage - (offsets.offsetSDot - 1)).keys(),
        x => currentPage + (offsets.offsetSDot - 1) + x
      )
    };
  }

  maxPagesShownPagination(lastPage: number, currentPage: number) {
    const offsets = this.getOffsets(lastPage, currentPage);
    const maxArray = [];

    let i;
    for (i = 0; i < this.pagesShown; i++) {
      if (i === 0) {
        maxArray[0] = 1;
      } else if (i === offsets.offsetFDot) {
        maxArray[i] = lastPage;
      } else if (i === 1 || i === offsets.offsetPages + 1) {
        maxArray[i] = '...';
      } else if (i + 1 === Math.ceil(offsets.maxDisplayedPages / 2)) {
        maxArray[i] = currentPage;
      } else {
        maxArray[i] =
          i + 1 > Math.ceil(offsets.maxDisplayedPages / 2)
            ? currentPage + i - Math.ceil(offsets.maxDisplayedPages / 2) + 1
            : currentPage - (Math.ceil(offsets.maxDisplayedPages / 2) - (i + 1));
      }
    }
    return {
      firstDotRange: Array.from(Array(maxArray[2] - 2).keys(), x => x + 2),
      pages: maxArray,
      secondDotRange: Array.from(
        Array(maxArray[maxArray.length - 1] - maxArray[maxArray.length - 3] - 1).keys(),
        x => x + maxArray[maxArray.length - 3] + 1
      )
    };
  }

  evaluateLogic() {
    this.isBackArrowDisabled = this.pager.currentPage === 1 || this.pager.pages.length === 0;
    this.isForwardArrowDisabled = this.pager.currentPage === this.pager.pages[this.pager.pages.length - 1] || this.pager.pages.length === 0;
    this.isFirstLargeEllipses = this.pager.firstDotRange.length > 7;
    this.isSecondLargeEllipses = this.pager.secondDotRange.length > 7;
    this.isLastNumberClickable = [];

    this.pager.pages.forEach((page_, index) => {
      this.isCurrentPage[index] = this.pager.currentPage === page_;
      this.isNumber[index] = !isNaN(this.pager.pages[index]);

      if (
        !this._advanced ||
        (this.pager.pageArray.length !== page_) ||
        this.pager.currentPage >= this.pager.pageArray.length - (this._maxActive - 1) ||
        this.pager.pageArray.length <= 5
      ) {
        this.isLastNumberClickable[index] = true;
      }
      if (
        (this._advanced &&
          this.pager.pageArray.length === page_ &&
          !(this.pager.currentPage >= this.pager.pageArray.length - (this._maxActive - 1)) &&
          this.pager.pageArray.length > 5) ||
        (Math.abs(page_ - this.pager.currentPage) >= this._maxActive &&
          this._advanced &&
          this.pager.pageArray.length > 5)
      ) {
        this.isLastNumberClickable[index] = true;
      }
    });
  }


  isFirstEllipsesList(index: number) {
    if (!this.pager.secondDotRange || this.pager.secondDotRange === '' || index === 1) {
      return true;
    } else {
      return false;
    }
  }

  openSelector(el: ElementRef, visible: boolean, pageRange?) {
    if (!this.mobile) {
      const visibility = visible ? 'visible' : 'hidden';
      this.renderer.setStyle(el, 'visibility', visibility);
      if (visibility === 'visible') {
        this.renderer.setStyle(el, 'opacity', 1);
      } else {
        this.renderer.setStyle(el, 'opacity', 0);
      }
    }
  }

  itemPerPageChange(event) {
    this.itemsPerPage = Number(event.value);
    this.pager = this.getPager(Number(this.numberofRecords), 1, Number(this.itemsPerPage));
    this.paged = {
      pageNumber: this.pager.currentPage,
      pageSize: this.itemsPerPage,
      startIndex: this.pager.startIndex,
      endIndex: this.pager.endIndex,
      startItem: this.pager.startIndex + 1,
      endItem: this.pager.endIndex + 1,
      numberOfPages: this.pager.pageArray.length,
      pageName: this.pageName
    };
    this.dataservice.sendMessage({ pagination: this.paged }, 'eventEmitListen');
    this.emitPagedData.emit(this.paged);
    this.evaluateLogic();
  }

}
