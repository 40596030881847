import { CurrencyPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "unit",
})
export class UnitPipe implements PipeTransform {
  prevUnitValue: any = "";
  prevRatedPower;

  constructor(private currencyPipe: CurrencyPipe) {}

  transform(
    value: number,
    ratedPower?: number,
    unitOfConversion?: string,
    isDivide?: boolean,
    currencyCode?: any
  ): any {
    if (ratedPower && unitOfConversion) {
      if (isDivide) {
        return this.divideOperation(value, ratedPower, unitOfConversion, currencyCode);
      } else {
        return this.multiplyOperation(value, ratedPower, unitOfConversion, currencyCode);
      }
    } else {
      if (currencyCode) {
        return this.currencyPipe.transform(value, currencyCode);
      }
      return value ? Math.round(value) : 0;
    }
  }

  divideOperation(
    value: number,
    ratedPower?: number,
    unitOfConversion?: string,
    currencyCode?: any
  ) {
    switch (unitOfConversion) {
      case "KWhr":
        if (currencyCode) {
          return this.currencyPipe.transform(value / ratedPower, currencyCode);
        }
        return this.divideByPower(1, value, ratedPower);

      case "MWhr":
        if (currencyCode) {
          return this.currencyPipe.transform(
            value / (ratedPower / 1000),
            currencyCode
          );
        }
        return this.divideByPower(2, value, ratedPower);

      default:
        if (currencyCode) {
          return this.currencyPipe.transform(value, currencyCode);
        }
        return this.divideByPower(3, value, ratedPower);
    }
  }

  divideByPower(x, value, ratedPower) {
    if (x === 1) {
      return value ? Math.round(value / ratedPower) : 0;
    } else if (x === 2) {
      return value ? value / (ratedPower / 1000) : 0;
    } else {
      return value ? Math.round(value) : 0;
    }
  }

  multiplyOperation(
    value: number,
    ratedPower?: number,
    unitOfConversion?: string,
    currencyCode?: any
  ) {
    switch (unitOfConversion) {
      case "KWhr":
        if (currencyCode) {
          return this.currencyPipe.transform(value * ratedPower, currencyCode);
        }
        return this.multiplyByPower(1, value, ratedPower);

      case "MWhr":
        if (currencyCode) {
          return this.currencyPipe.transform(
            value * (ratedPower / 1000),
            currencyCode
          );
        }
        return this.multiplyByPower(2, value, ratedPower);

      default:
        if (currencyCode) {
          return this.currencyPipe.transform(value, currencyCode);
        }
        return this.multiplyByPower(3, value, ratedPower);
    }
  }

  multiplyByPower(x, value, ratedPower) {
    if (x === 1) {
      return value ? Math.round(value * ratedPower) : 0;
    } else if (x=== 2) {
      return value ? Math.round(value * (ratedPower / 1000)) : 0;
    } else {
      return value ? Math.round(value) : 0;
    }
  }


}
