import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent {

  @Input() heading: string;
  @Input() color: string;
  @Output() event = new EventEmitter();
  show = false;
  defaultColors: string[] = [
    '#DFE4E8',
    '#F2EBE9',
    '#DBEAFF',
    '#FFE478',
    '#FF8686',
    '#FF8000',
    '#5C6BEE',
    '#FFEBEC',
    '#FFCC11',
    '#9ECEF2',
    '#F0A4A4',
    '#65D3B9',
    '#EF9C70',
    '#BB8F8F',
    '#BEC2D2'
  ];



  toggleColors() {
    this.show = !this.show;
  }

  changeColor(color: string) {
    this.color = color;
    this.event.emit(this.color); // Return color
  }

}
