import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "environments/environment";

if (environment.production) {
  enableProdMode();
}
function updateAppKeyInScript() {
  const scriptTag = document.getElementById(
    "dynamic-config"
  ) as HTMLScriptElement;
  if (scriptTag) {
    scriptTag.text = `(function(config){
        config.appKey = '${environment.AppDynamicsKey}';
        config.adrumExtUrlHttp = "http://cdn.appdynamics.com";
        config.adrumExtUrlHttps = "https://cdn.appdynamics.com";
        config.beaconUrlHttp = "http://pdx-col.eum-appdynamics.com";
        config.beaconUrlHttps = "https://pdx-col.eum-appdynamics.com";
        config.useHTTPSAlways = true;
        config.resTiming = {"bufSize":200,"clearResTimingOnBeaconSend":true};
        config.maxUrlLength = 512;
        config.spa = {"spa2":true};
        config.isZonePromise = true;
        config.angular = true;
    }`;
  }
}

updateAppKeyInScript();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
