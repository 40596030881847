import { Component, OnInit } from '@angular/core';
import { DataService } from '@app/services/shared/storage/data.service';
import { AppInsightsService } from '@app/services/misc/App-Insights/app-insights.service';

@Component({
  selector: 'app-e404component',
  templateUrl: './e404.component.html',
  styleUrls: ['./e404.component.scss']
})
export class E404Component implements OnInit {


  constructor(private dataService: DataService,
    private appInsights: AppInsightsService) { 
    }

  ngOnInit() {
    this.dataService.closeFooter = true;
  }


}
