import { Injectable } from '@angular/core';
import { RestService } from '../rest/rest.service';
import { BehaviorSubject, EMPTY, Observable, forkJoin, of } from 'rxjs';
import { DataService } from '@app/services/shared/storage/data.service';
import * as ServiceMappings from '@app/services/shared/app-constants';
import { concatMap, delay, expand, map, mergeMap, single } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SubjectService } from '@app/services/data/subject.service';
enum EstimateStatus {
  None = 'None',
  Success = 'Success',
  Failure = 'Failure'
}
@Injectable({
  providedIn: 'root'
})
export class MultipleProductService {

  estimateSelections;
  mpeJobId;
  finalLoadedProducts = [];
  selectedWGData = [];
  totalHours: number = 0;
  totalDays: number = 0;
  progessIndicatorRef;
  reloadPopupRef;
  factorImportError = false;
  reloadPercentage = 0;
  reloadFactorsInProgress = false;
  factorCompleted = false;
  estimateCreateProgress = {
    estimateCreationInProgress: false,
    estimateCreated: false,
    factorImportInProgress: false,
    factorImportCompleted: false,
    evaluationInProgress: false,
    evaluationCompleted: false,
    calculationInProgress: false,
    calculationCompleted: false,
    factorListStatus: [],
    completionPercent: 0,
    dataMissing: false,
    reImportProgressPercent: 0,
    reImportListStatus: [],
    factorListHidden: false,
    reImportListHidden: false
  }
  productUploadStatus = [];
  planNumbers;
  components = [];
  componentsData = [];
  currencySelected: any;
  customer;
  currentRevisionStatusReload = false;
  fatorsImportStatusReload = false;
  reloadComplete: any;
  fatorsImportStatus: any;
  reloadPopupHidden = false;
  eventTypes = [
    { label: this.translate.instant('remove_and_install'), value: 'Remove & Install' },
    { label: this.translate.instant('scheduled_repair'), value: 'Scheduled Repair' },
    { label: this.translate.instant('unscheduled_repair'), value: 'Unscheduled Repair' },
    { label: this.translate.instant('maintenance'), value: 'Maintenance' },
    { label: this.translate.instant('miscellaneous'), value: 'Miscellaneous' }
  ];
  planStatusInfo = [];
  productSummary;
  unit;
  private pricingDataFlag = new BehaviorSubject<boolean>(true);
  private PricingDataPerproduct = new BehaviorSubject<any>({});
  private estimateEditResponse = new BehaviorSubject({});
  estimateEditApiResponse = this.estimateEditResponse.asObservable();
  WgLaborRates;
  usageInYear;
  usageInMonth;
  loadsummaryData;
  filteredLaborRate: any;
  corelationID: any;
  componentlevelAdjustmentData: any[];
  estimatlevelAdjustmentData: any;
  componentForAdjustment: any[];
  fullAdjustmentData: any;
  configuration: any;
  PricingAdjustmentFactorConfiguration: any;
  isEdit = false;
  estimateCopied = false;
  planNumber: any;
  factorsData: any;
  estimateDetails: any;
  estimateSummary: any;
  ratedPower;
  showOverrideDataPopup = false;
  factorsReloading = false;
  partsCostSources = [];
  partsSellSources = [];
  UndercarriageFactorsData: any;
  machineFactorsData: any;
  isCreateNew: any;
  pricingRefinementSettings: any;
  underCarriageFactors: any;
  machineFactors: any;
  setUnderCarriageVariability = null;
  customers=[];
  laborCodes: any;
  fluids: any;
  sellPricingSources = []
  costPricingSources = [];
  WgTravelLaborConfiguration;
  adjCorrelationID: any;
  initialBasicDataEntered: any;
  basicCustDetails: any;
  selectedSource: any;
  costValues: any;
  sellValues: any;
  startDate:any;

  constructor(
    private restService: RestService, 
    private dataService: DataService,
    private translate: TranslateService,
    public subjectService: SubjectService
  ) { }

  getProduct(prefix, ccr, wgId,type): Observable<any> {
    if (!prefix) {
      let queryParams = `?dataSource=${this.dataService.dataSource}&dataOrigin=${this.dataService.dataOrigin}&workGroupId=${this.dataService.basicDetailsWorkGroupId}&equipmentType=${type}&isPM=true`;
      return this.restService.httpGet(ServiceMappings.productSearchData + queryParams);
    }
    else if (!ccr) {
      let queryParams = `?dataSource=${this.dataService.dataSource}&prefix=${prefix}&dataOrigin=${this.dataService.dataOrigin}&useCustomCode=${this.dataService.useCustomcode}&workGroupId=${wgId}&isCCR=${ccr}&equipmentType=${type}&isPM=true`
      return this.restService.httpGet(ServiceMappings.productSearchData + queryParams);
    }
    else {
      let queryParams = `?dataSource=${this.dataService.dataSource}&prefix=${prefix}&dataOrigin=${this.dataService.dataOrigin}&useCustomCode=${this.dataService.useCustomcode}&isCCR=${ccr}&isPM=true`
      return this.restService.httpGet(ServiceMappings.productSearchData + queryParams);
    }
  }

  getBasicDetailsWorkGroup(id): Observable<any> {
    if (id == 5) {
      return this.restService.httpGet(ServiceMappings.basicDetailsWorkGroup +
        '?dataOrigin=ServiceOptionsCollaborator' +
        '&useCustomCode=' + this.dataService.useCustomcode);
    } else if (id == 1) {
      return this.restService.httpGet(ServiceMappings.basicDetailsWorkGroup +
        '?dataOrigin=ServiceOptionsCollaborator&dataSource=CATCanonical' +
        '&useCustomCode=' + this.dataService.useCustomcode);
    } else if (id == 3) {
      return this.restService.httpGet(ServiceMappings.basicDetailsWorkGroup +
        '?dataOrigin=ServiceOptionsCollaborator&dataSource=DealerOnly' +
        '&useCustomCode=' + this.dataService.useCustomcode);
    }
  }

  getManufacturerData(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.manufacturerData +
      '?dataSource=' + this.dataService.dataSource +
      '&useCustomCode=' + this.dataService.useCustomcode +
      '&dataOrigin=' + this.dataService.dataOrigin +
      '&workGroupId=' + this.dataService.basicDetailsWorkGroupId +
      '&isPM=' + true);
  }

  //Peek Api calls
  getPeekDefinitionData(params, isBuilderXMLDataSource): Observable<any> {
    if(isBuilderXMLDataSource) {
      return this.restService.httpPost(ServiceMappings.mpeBuilderXMLInitiatePeek, params);
    }
    return this.restService.httpPost(ServiceMappings.mpePeekCall, params);
  }

  getPeekStatus(id): Observable<any> {
    return this.restService.httpGet(ServiceMappings.mpePeekStatus + '?id=' + id).pipe(expand((res: any) => {
      return (res.OverallStatus != 'Completed' && res.OverallStatus != 'Failed' && res.OverallStatus != 'Timeout' && res.OverallStatus != 'Added') ? this.restService.httpGet(ServiceMappings.mpePeekStatus + '?id=' + id).pipe(delay(800)) : EMPTY
    }));
  }

  cancelPeekRequest(id): Observable<any> {
    return this.restService.httpPost(ServiceMappings.mpeCancelPeekRequest + '?id=' + id, '')
  }

  loadProductDetails(id): Observable<any> {
    return this.restService.httpGet(ServiceMappings.mpeLoadProducts + '?id=' + id)
  }

  mpeStartPlan(payload): Observable<any> {
    return this.restService.httpPost(ServiceMappings.mpeStartPlan, payload)
  }


  getMpeCheckStartPlanStatus(Id): Observable<any> {
    return this.restService.httpGet(ServiceMappings.mpeCheckStartPlanStatus + Id).pipe(expand((res: any) => {
      return res.OverallStatus !== 'Completed' && res.OverallStatus !== 'Timeout'  ? this.restService.httpGet(ServiceMappings.mpeCheckStartPlanStatus + Id).pipe(delay(800)) : EMPTY
    }));
  }

  getMpeStartPlanDetail(Id): Observable<any> {
    return this.restService.httpGet(ServiceMappings.mpeStartPlanDetail + Id)
  }

  initiateFactorImport(planNumber): Observable<any> {
    return this.restService.httpPost(ServiceMappings.mpeInitiateFactorImport + planNumber, {});
  }

  checkFactorsImportStatus(planNumber): Observable<any> {
    return this.pingFactorStatus(planNumber).pipe(
      mergeMap((factors: any) => {
        return this.checkFactorsInEstimate(planNumber, factors);
      })
    );
  }

  private pingFactorStatus(planNumber): Observable<any> {
    return this.restService.httpGet(ServiceMappings.factorImportStatus + '?PlanNumber=' + planNumber).pipe(
      expand((res: any) => {
        return res.Status === 'Ongoing' || res.Status === 'Created' || !res ?
          this.restService.httpGet(ServiceMappings.factorImportStatus + '?PlanNumber=' + planNumber).pipe(delay(800)) : EMPTY;
      }));
  }

  private checkFactorsInEstimate(planNumber, factors): Observable<any> {
    if (this.estimateCreateProgress.dataMissing) {
      this.estimateCreateProgress.evaluationInProgress = true;
      this.estimateCreateProgress.reImportProgressPercent = factors.CompletionPercent;
      this.estimateCreateProgress.reImportListStatus = factors.CheckListStatuses;
    } else {
      this.estimateCreateProgress.factorImportInProgress = true;
      this.estimateCreateProgress.completionPercent = factors.CompletionPercent;
      this.estimateCreateProgress.factorListStatus = factors.CheckListStatuses;
    }
    if (factors.Status === 'DoneAndReady' || factors.Status === 'WaitingForAdditionalFactors') {
      this.estimateCreateProgress.factorImportInProgress = false;
      this.estimateCreateProgress.factorImportCompleted = true;
      this.estimateCreateProgress.evaluationInProgress = true;
      if (this.estimateCreateProgress.dataMissing) {
        this.estimateCreateProgress.reImportListHidden = true;
      } else {
        this.estimateCreateProgress.factorListHidden = true;
      }
      return this.checkRevisionEvaulation(planNumber).pipe(
        delay(5000),
        mergeMap((evaluation: any) => {
          return this.checkAcceptableForOnboardingForFactors(evaluation, planNumber);
        })
      );
    } else {
      return of({ Status: factors.Status, completion: factors.CompletionPercent, CheckListStatuses: factors.CheckListStatuses });    // Factors import failed
    }
  }

  private checkRevisionEvaulation(planNumber): Observable<any> {
    return this.restService.httpGet(ServiceMappings.factorRevisionEvaluation + '?PlanNumber=' + planNumber);
  }

  checkAcceptableForOnboardingForFactors(evaluation: any, planNumber: any) {
    if (evaluation.AcceptableForOnboarding) {
      this.estimateCreateProgress.evaluationCompleted = true;
      this.estimateCreateProgress.evaluationInProgress = false;
      this.estimateCreateProgress.calculationInProgress = true;
      if (this.estimateCreateProgress.dataMissing) {
        this.estimateCreateProgress.reImportListHidden = true;
      } else {
        this.estimateCreateProgress.factorListHidden = true;
      }
      return this.fetchEstimate(planNumber).pipe(
        delay(5000),
        mergeMap((res: any) => {
          return this.checkEstimateStatus(planNumber);
        })
      );
    } else {
      this.estimateCreateProgress.dataMissing = true;
      return of({ Status: 'RevisionEvaluation Failed', Result: evaluation });
    }
  }

  fetchEstimate(planNumber): Observable<any> {
    return this.restService.httpPost(ServiceMappings.estimateApi + planNumber, {});
  }

  checkEstimateStatus(planNumber): Observable<any> {
    return this.pingEstimateStatus(planNumber).pipe(mergeMap((status: any) => {
      return this.compareEstimateStatus(status, planNumber)
    }));
  }

  pingEstimateStatus(planNumber): Observable<any> {
    return this.restService.httpGet(ServiceMappings.checkEstimateStatus + planNumber).pipe(
      expand((res: any) => {
        return res.OverallStatus !== 'Completed' ?
          this.restService.httpGet(ServiceMappings.checkEstimateStatus + planNumber).pipe(delay(800)) : EMPTY;
      }));
  }

  compareEstimateStatus(status,planNumber): Observable<any> {
    if (status.OverallStatus === 'Completed') { 
      this.loadSummary(planNumber).subscribe((response)=>{
         this.loadsummaryData = response;               
      });
      return this.loadSummary(planNumber);
    } else if(status.OverallStatus === 'InProgress') {
      return of({ Status: 'InProgress' })
    } else {
      return of({ Status: 'Failed' })
    }
  }

  customSaveClick(planNumber, params): Observable<any> {
     return this.restService.httpPost(ServiceMappings.customSaveGreif + '?PlanNumber=' + planNumber, params);
  }   

  loadSpecificPricing(planNumber,data){
    return this.restService.httpPost(ServiceMappings.mpeLoadSpecific + planNumber,data);
  }

  loadSummary(planNumber): Observable<any> {
    let planURL = '';
    planURL = ServiceMappings.loadSummary + planNumber;
    return this.restService.httpGet(planURL);
  }

  loadSpecific(planNumber, params): Observable<any> {
    let planURL = '';
    planURL = ServiceMappings.loadSpecific + planNumber;
    return this.restService.httpPost(planURL, params);
  }

  showmorePricingFlag(value: boolean){
    this.pricingDataFlag.next(value);
  }
  getshowmorePricingFlag(){
    return this.pricingDataFlag.asObservable();
  }

  showmorePricingData(value : any){
    this.PricingDataPerproduct.next(value);
  }
  getshowmorePricingData(){
    return this.PricingDataPerproduct.asObservable();
  }
  
  estimateAndApply(planNumber,payload?) : Observable<any>{
    return this.restService.httpPost(ServiceMappings.estimateAndApply + planNumber,payload);
  }

  lockAndLoad(planNumber) : Observable<any>{
   this.loadsummaryData = this.restService.httpPost(ServiceMappings.lockAndLoadSummary +planNumber,'');
   return this.loadsummaryData;
  }
  
  filterLabor(customerDetails, labor) {
    if (labor && labor.length > 0) {
      this.filteredLaborRate = labor.filter(elem => (elem.CustomerClass == customerDetails.Class && elem.CustomerNumber == customerDetails.Number)
        || (elem.CustomerClass == '' && elem.CustomerNumber == '') || ((elem.CustomerClass == '' || elem.CustomerClass == null) && elem.CustomerNumber == customerDetails.Number)
        || (elem.CustomerClass == customerDetails.Class && (elem.CustomerNumber == '' || elem.CustomerNumber == null)) || (elem.CustomerClass == null && elem.CustomerNumber == null));
    }
  }

  resetProgressFlags() {
    this.estimateCreateProgress = {
      estimateCreationInProgress: false,
      estimateCreated: false,
      factorImportInProgress: false,
      factorImportCompleted: false,
      evaluationInProgress: false,
      evaluationCompleted: false,
      calculationInProgress: false,
      calculationCompleted: false,
      factorListStatus: [],
      completionPercent: 0,
      dataMissing: false,
      reImportProgressPercent: 0,
      reImportListStatus : [],
      factorListHidden: false,
      reImportListHidden: false
    };
  }

  setEstimateDetails(isEdit, estimateCopied, factorsData, estimateDetails, estimateSummary, planNumber) {
    this.isEdit = isEdit;
    this.estimateCopied = estimateCopied;
    this.factorsData = factorsData;
    this.estimateDetails = estimateDetails;
    this.estimateSummary = estimateSummary;
    this.planNumber = planNumber;
  }
  editEstimateAndApply(planNumber, editJson): Observable<any> {
    return this.restService.httpPost(ServiceMappings.estimateAndApply + planNumber, editJson);
  }
  setEstimateEditResponse(data) {
    this.estimateEditResponse.next(data);
  }
  getFamilyData(manufacturerIdentifier, equipmentType): Observable<any> {
    return this.restService.httpGet(ServiceMappings.familyData +
      '?dataSource=' + this.dataService.dataSource +
      '&useCustomCode=' + this.dataService.useCustomcode +
      '&equipmentType=' + equipmentType +
      '&dataOrigin=' + this.dataService.dataOrigin +
      '&manufacturerCode=' + manufacturerIdentifier +
      '&workGroupId=' + this.dataService.basicDetailsWorkGroupId +
      '&isPM=' + true);
  }

  getModalData(manufacturerIdentifier, familyIdentifier, equipmentType): Observable<any> {
    return this.restService.httpGet(ServiceMappings.modalData +
      '?dataSource=' + this.dataService.dataSource +
      '&useCustomCode=' + this.dataService.useCustomcode +
      '&equipmentType=' + equipmentType +
      '&dataOrigin=' + this.dataService.dataOrigin +
      '&manufacturerCode=' + manufacturerIdentifier +
      '&familyCode=' + familyIdentifier +
      '&workGroupId=' + this.dataService.basicDetailsWorkGroupId +
      '&isPM=' + true);
  }

  getSerialNumberData(manufacturerIdentifier, familyIdentifier, model, equipmentType): Observable<any> {
    return this.restService.httpGet(ServiceMappings.serialNumberData +
      '?dataSource=' + this.dataService.dataSource +
      '&useCustomCode=' + this.dataService.useCustomcode +
      '&equipmentType=' + equipmentType +
      '&dataOrigin=' + this.dataService.dataOrigin +
      '&manufacturerCode=' + manufacturerIdentifier +
      '&familyCode=' + familyIdentifier +
      '&model=' + model +
      '&workGroupId=' + this.dataService.basicDetailsWorkGroupId +
      '&isCCR=' + this.dataService.isSocCCR + 
      '&isPM=' + true);
  }

  postEstimateFinalized(planNumber): Observable<any> {
    return this.restService.httpGet(ServiceMappings.estimateFinalize +
      '?planNumber=' + planNumber);
  }

  getPreviewReport(planNumber): Observable<any> {
    return this.restService.httpGet(ServiceMappings.getPreviewReportMpe + planNumber);
  }

  createReport(planNumber, req): Observable<any> {
    return this.restService.httpPost(ServiceMappings.createReport+planNumber, req);
  }

  checkReportStauts(planNumber): Observable<any> {
    return this.restService.httpGet(ServiceMappings.CheckReportStatus+planNumber).pipe(expand((res: any) => {
      return (res.OverallStatus != 'Completed' && res.OverallStatus != 'Failed' && res.OverallStatus != 'Timeout' && res.OverallStatus != 'Added') ? 
      this.restService.httpGet(ServiceMappings.CheckReportStatus+planNumber).pipe(delay(800)) : EMPTY
    }))
  }

  getUserSpecifiedReport(planNumber, type): Observable<any> {
    const contentType = 'application/octet-stream';
    return this.restService. httpGetTypeReport(ServiceMappings.downloadReportMPE + planNumber, contentType);
  }

  getInitialReportDetails(planNumber): Observable<any> {
    return this.restService.httpGet(ServiceMappings.listSupportedMPE + planNumber);
  }

  refreshFactorsImport(planNumber, params?): Observable<any> {
    return this.initiateSelectiveFactorsImport(planNumber, params).pipe(
      mergeMap((data: any) => {
        if (data.CheckoutResult && data.PlanExists) {
          return this.pingFactorStatus(planNumber).pipe(
            delay(2000),
            mergeMap((factors: any) => {
              return this.checkFactorRevisionInEstimate(factors, planNumber, data.RevisionId);
            })
          );
        } else {
          return of(data);
        }
      })
    );
  }

  private initiateSelectiveFactorsImport(planNumber, params): Observable<any> {
    const url = ServiceMappings.mpeInitiateSelectiveFactorImport;
    return this.restService.httpPost(url + planNumber, params);
  }

  private checkFactorRevisionInEstimate(factors, planNumber, revisionID, notPassFactors?): Observable<any> {
    if (factors.Status === 'DoneAndReady' || factors.Status === 'WaitingForAdditionalFactors') {
      return this.checkRevisionEvaulation(planNumber).pipe(
        mergeMap((evaluation: any) => {
          return this.checkAcceptableForOnboardingForFactorRevision(evaluation,planNumber);
        })
      );
    } else {
      return of({ Status: factors.Status, completion: factors.CompletionPercent });    // Factors import failed
    }
  }

  checkAcceptableForOnboardingForFactorRevision(evaluation: any, planNumber: any) {
    if (evaluation.AcceptableForOnboarding) {
      return this.fetchEstimate(planNumber).pipe(
        delay(5000),
        mergeMap((res: any) => {
          return this.checkEstimateStatusOnReload(planNumber);
        })
      );
    } else {
      return of({ Status: 'RevisionEvaluation Failed', Result: evaluation });
    }
  }

  checkFactorsImportAfterMissingData(planNumber): Observable<any> {
    this.fatorsImportStatusReload = true;
    return this.pingFactorStatus(planNumber).pipe(
      mergeMap((factors: any) => {
        return this.checkFactorRevisionInEstimate(factors, planNumber, null, true);
      })
    );
  }

  checkEstimateStatusOnReload(planNumber): Observable<any> {
    return this.pingEstimateStatus(planNumber);
  }

  uploadAndSummarizeSingleFile = (payload): Observable<any>  => {
    return this.restService.httpPost(ServiceMappings.mpeUploadAndSummarizeBuilderXML, payload);
  }

  getAllBuilderFileAPI = (multiProductFile) => {
    let allAPI = [];
    multiProductFile.map((singleProduct) => {
      let singleProductPayload = new FormData();
      singleProductPayload.append('multiProductFile', singleProduct);
      allAPI.push(this.uploadAndSummarizeSingleFile(singleProductPayload));
    });
    return allAPI;
  }
}
