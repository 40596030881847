import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'daysLeft'
})
export class DaysLeftPipe implements PipeTransform {
currentDate = new Date();
expectedDate;
  transform(value: unknown, args: any): unknown {

      this.expectedDate = new Date(value.toString());
      if (!args)
      {
        this.expectedDate.setDate(this.expectedDate.getDate() + 60);
      }
      else{
        this.expectedDate.setDate(this.expectedDate.getDate() + args);
      }
      const differnce = this.expectedDate.getTime() - this.currentDate.getTime();
      const diffDays = Math.round(differnce / (1000 * 3600 * 24));
      if (diffDays > 0){
        return diffDays;
      }
      else if (diffDays <= 0){
        return 0;
      }

    }
  }


