import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
})
export class SkeletonLoaderComponent {
  isLoadingFailed = false;
  isLoading = true;
  animate = true;
  primaryColor = '#EDEFF4';
  secondaryColor = '#DEF3ED'
  @Input() count: any;
}
