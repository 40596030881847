import { Platform } from '@angular/cdk/platform';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DataTableV2Component, NgbdSortableHeaderV2Directive } from '@app/common//data-table-v2/data-table-v2.component';
import { PaginationComponent } from '@app/common//pagination/pagination.component';
import { UnitPipe } from '@app/common//pipes/unit.pipe';
import { PrivacyPolicyComponent } from '@app/common//privacy-policy/privacy-policy.component';
import { ProgressIndicatorComponent } from '@app/common//progress-indicator/progress-indicator.component';
import { DataTableV3Component } from '@app/common/data-table-v3/data-table-v3.component';
import { LongPressDirective } from '@app/common/directives/long-press.directive';
import { LaborReloadTableComponent } from '@app/common/labor-reload-table/labor-reload-table.component';
import { LaborSelectionsDisplayComponent } from '@app/common/labor-selections-display/labor-selections-display.component';
import { LabourDataTableComponent } from '@app/common/labour-data-table/labour-data-table.component';
import { MyDateAdapter } from '@app/common/my-date-adapter';
import { DateDifferencePipe } from '@app/common/pipes/date-difference.pipe';
import { DaysLeftPipe } from '@app/common/pipes/days-left.pipe';
import { DropdownListPipe } from '@app/common/pipes/dropdown-list.pipe';
import { FormatCellPipe } from '@app/common/pipes/format-cell.pipe';
import { OrderByPipe } from '@app/common/pipes/order-by.pipe';
import { UnitConversionPipe } from '@app/common/pipes/unit-conversion.pipe';
import { ScrollTopComponent } from '@app/common/scroll-top/scroll-top.component';
import { CcInputFieldModule } from '@app/components/input-field/input-field.module';
import { GlobalUploadEnginePerformanceComponent } from '@app/global-settings/global-settings/global-upload-engine-performance/global-upload-engine-performance.component';
import { AddEditEnginePerformanceComponent } from '@app/settings/engine-performance/add-edit-engine-performance/add-edit-engine-performance.component';
import { AddEditExistingPDSComponent } from '@app/settings/engine-performance/add-edit-engine-performance/add-edit-existing-pds/add-edit-existing-pds.component';
import { AddEditHoursAdjstmentComponent } from '@app/settings/engine-performance/add-edit-engine-performance/add-edit-hours-adjstment/add-edit-hours-adjstment.component';
import { CreateNewPDSComponent } from '@app/settings/engine-performance/add-edit-engine-performance/create-new-pds/create-new-pds.component';
import { ProductSelectionComponent } from '@app/settings/engine-performance/add-edit-engine-performance/product-selection/product-selection.component';
import { EnginePerformanceComponent } from '@app/settings/engine-performance/engine-performance.component';
import { ExportAsComponent } from '@app/settings/engine-performance/export-as/export-as.component';
import { ViewEnginePerformanceComponent } from '@app/settings/engine-performance/view-engine-performance/view-engine-performance.component';
import { CcTableModule } from '@cat-digital-workspace/shared-ui-core/cc-table';
import { CcTooltipModule } from '@cat-digital-workspace/shared-ui-core/tooltip';
import { EnginePerformanceSkeletonLoaderComponent } from '@app/settings/engine-performance/engine-performance-skeleton-loader/engine-performance-skeleton-loader.component';
import { CcSkeletonLoaderModule } from '@cat-digital-workspace/shared-ui-core';
import { CcLoadersModule } from '@cat-digital-workspace/shared-ui-core/loaders';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ResizableModule } from 'angular-resizable-element';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DateAdapter, MAT_DATE_LOCALE, SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { DataTableComponent, NgbdSortableHeaderDirective } from '@app/common/data-table/data-table.component';
import { AddEditEnginePerformanceGlobalComponent } from '@app/settings/engine-performance/add-edit-engine-performance-global/add-edit-engine-performance-global.component';
import { DuplicateServiceOptionsComponent } from './duplicate-service-options/duplicate-service-options.component';
import { MatRadioModule } from '@angular/material/radio';
import { HoverComponent } from '@app/estimate/hover/hover.component';
import { DuplicateServiceOptionPopupComponent } from './duplicate-service-options/duplicate-service-option-popup/duplicate-service-option-popup.component';
import { IncludeCoolantComponent } from '@app/estimate/estimate-details/advanced-details/include-coolant/include-coolant.component';
import { FilterComponent } from '@app/estimate/estimate-details/service-details/filter/filter.component';
import { NumToYearPipe } from './pipes/num-to-year.pipe';
@NgModule({
  declarations: [DataTableComponent,
    NgbdSortableHeaderDirective,
    NgbdSortableHeaderV2Directive,
    ScrollTopComponent,
    FormatCellPipe,
    ViewEnginePerformanceComponent,
    PaginationComponent,
    PrivacyPolicyComponent,
    ProgressIndicatorComponent,
    DataTableV2Component,
    UnitPipe,
    OrderByPipe,
    LongPressDirective,
    DropdownListPipe,
    EnginePerformanceComponent,
    AddEditEnginePerformanceComponent,
    CreateNewPDSComponent,
    AddEditHoursAdjstmentComponent,
    AddEditExistingPDSComponent,
    ProductSelectionComponent,
    GlobalUploadEnginePerformanceComponent,
    DataTableV3Component,
    UnitConversionPipe,
    LaborSelectionsDisplayComponent,
    LabourDataTableComponent,
    LaborReloadTableComponent,
    ExportAsComponent,
    DaysLeftPipe,
    DateDifferencePipe,
    EnginePerformanceSkeletonLoaderComponent,
    AddEditEnginePerformanceGlobalComponent,
    DuplicateServiceOptionsComponent,
    HoverComponent,
    DuplicateServiceOptionPopupComponent,
    IncludeCoolantComponent,
    FilterComponent,
    NumToYearPipe
  ],
  imports: [
    CommonModule,
    NgbModule,
    NgxDropzoneModule,
    FormsModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    CcInputFieldModule,
    MatTableModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatProgressSpinnerModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatMenuModule,
    MatTooltipModule,
    MatDialogModule,
    ResizableModule,
    CcTableModule,
    CcTooltipModule,
    CcLoadersModule,
    CcSkeletonLoaderModule,
    MatRadioModule,
  ],
  exports: [
    DataTableComponent,
    NgbdSortableHeaderDirective,
    NgbdSortableHeaderV2Directive,
    ScrollTopComponent,
    ViewEnginePerformanceComponent,
    PaginationComponent,
    DataTableV2Component,
    FormatCellPipe,
    UnitPipe,
    OrderByPipe,
    LongPressDirective,
    DropdownListPipe,
    EnginePerformanceComponent,
    AddEditEnginePerformanceComponent,
    CreateNewPDSComponent,
    AddEditHoursAdjstmentComponent,
    AddEditExistingPDSComponent,
    ProductSelectionComponent,
    GlobalUploadEnginePerformanceComponent,
    DataTableV3Component,
    LaborSelectionsDisplayComponent,
    LabourDataTableComponent,
    LaborReloadTableComponent,
    ExportAsComponent,
    DaysLeftPipe,
    DateDifferencePipe,
    EnginePerformanceSkeletonLoaderComponent,
    AddEditEnginePerformanceGlobalComponent,
    HoverComponent,
    IncludeCoolantComponent,
    FilterComponent,
    NumToYearPipe
  ],
  providers: [ CurrencyPipe, FormatCellPipe, DatePipe, UnitPipe, {provide: DateAdapter, useClass: MyDateAdapter, deps: [MAT_DATE_LOCALE, Platform]}, UnitConversionPipe]
})
export class CommonComponentModule { }
