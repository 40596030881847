import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from '@app/services/rest/rest.service';
import * as ServiceMappings from '@app/services/shared/app-constants';
import { DataService } from '@app/services/shared/storage/data.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, EMPTY, forkJoin, Observable, of } from 'rxjs';
import { delay, expand, map, mergeMap } from 'rxjs/operators';
import { ApplicationVariability } from '../models/misc/general';
enum EstimateStatus {
  None = 'None',
  Success = 'Success',
  Failure = 'Failure'
}
@Injectable({
  providedIn: 'root'
})
export class EstimatePricingService {
  currentRevisionStatusReload = false;
  fatorsImportStatusReload = false;
  reloadComplete: any;
  includePriceEscalation: any;
  displayRiTab: boolean;
  constructor(private http: HttpClient, public restService: RestService, private dataService: DataService,
              private translate: TranslateService) { }

  estimateStage: number; // to determine the Estimate step number for user position indication in header
  startDate;
  currencySelected: any;
  isEdit = false;
  estimateCopied = false;
  planNumber: any;
  factorsData: any;
  estimateDetails: any;
  estimateSummary: any;
  planDetailsResponse: any;
  WgTravelLaborConfiguration;
  editResponse: any;
  includeLabor = false;
  includeMisc = false;
  includePerfomanceData = true;
  componentForAdjustment = [];
  estimatlevelAdjustmentData: any;
  componentlevelAdjustmentData: any;
  corelationID: any;
  fullAdjustmentData: any;
  isCreateNew: any;
  configuration: any;
  fetchVersionHistory = false;
  engineConfiguration: any;
  autoSaveEnabled = true;
  WgLaborRates;
  definitionType;
  unit;
  ratedPower;
  groupingEnabled = true;
  multiCurrencySelected;
  fatorsImportStatus: any;
  currentRevisionStatus: any;
  enableUndo = false;
  enableRedo = false;
  filteredLaborRate;
  notes = [];
  pricingRefinementSettings: any;
  underCarriageFactors: any;
  machineFactors: any;
  UndercarriageFactorsData: any;
  machineFactorsData: any;
  applicationVariability: ApplicationVariability;
  allRIVariabilityFactors: [string];
  setUnderCarriageVariability = null;
  reloadPopupHidden = false;
  reloadPopupRef;
  reloadFactorsInProgress = false;
  reloadPercentage = 0;
  factorCompleted = false;
  reloadBannerDisplayed = false;
  partsCostSources = [];
  partsSellSources = [];
  revisionId;
  showOverrideDataPopup = false;
  applyToPlanWhenComplete = false;
  factorsReloading = false;
  factorPendingForApply = false;
  factorImportNotCompleted = false;
  factorImportError = false;
  excludeLabor = false;
  partsPath;
  partnum;
  partdesc;
  estimateCreateProgress = {
    estimateCreationInProgress: false,
    estimateCreated: false,
    factorImportInProgress: false,
    factorImportCompleted: false,
    evaluationInProgress: false,
    evaluationCompleted: false,
    calculationInProgress: false,
    calculationCompleted: false,
    factorListStatus: [],
    completionPercent: 0,
    dataMissing: false,
    reImportProgressPercent: 0,
    reImportListStatus : [],
    factorListHidden: false,
    reImportListHidden: false
  };
  customers=[];

  eventTypes = [
    { label: this.translate.instant('remove_and_install'), value: 'Remove & Install' },
    { label: this.translate.instant('scheduled_repair'), value: 'Scheduled Repair' },
    { label: this.translate.instant('unscheduled_repair'), value: 'Unscheduled Repair' },
    { label: this.translate.instant('maintenance'), value: 'Maintenance' },
    { label: this.translate.instant('miscellaneous'), value: 'Miscellaneous' }
  ];

  components = [];
  fluids = [];
  laborCodes = [];
  compareVersionsData = [];
  versionApi = false;
  updatedParts = [];
  saveJSON = [];
  partsFullScreenDisplayed = false;
  estimateApplyJSON = [];
  planSelectionValues;
  searchEnabled = false;
  partsDatas;
  unitofMeasure1;
  genericPartData;
  sourceOfData = 0;
  private unitValue = new BehaviorSubject({});
  private unitSelection = new BehaviorSubject({});
  accordionData = new BehaviorSubject({});

  showStatus = false;
  public unitSelectionSub = new BehaviorSubject({});

  private createEstimateResponse = new BehaviorSubject({});
  createEstimateApiResponse = this.createEstimateResponse.asObservable();

  private estimateEditResponse = new BehaviorSubject({});
  estimateEditApiResponse = this.estimateEditResponse.asObservable();

  private estimateGriefResponse = new BehaviorSubject({});
  estimateGriefApiResponse = this.estimateGriefResponse.asObservable();

  private selectionValue = new BehaviorSubject({});
  selectionValueEnums = this.selectionValue.asObservable();

  setCreateEstimateResponseData(data) {
    this.createEstimateResponse.next(data);
  }

  setEstimateEditResponse(data) {
    this.estimateEditResponse.next(data);
  }

  setEstimateGriefResponse(data) {
    this.estimateGriefResponse.next(data);
  }

  setEstimateDetails(isEdit, estimateCopied, factorsData, estimateDetails, estimateSummary, planNumber) {
    this.isEdit = isEdit;
    this.estimateCopied = estimateCopied;
    this.factorsData = factorsData;
    this.estimateDetails = estimateDetails;
    this.estimateSummary = estimateSummary;
    this.planNumber = planNumber;
  }

  /* Request to upload BuilderXML file for peeking Service Data */
  uploadBuilderForPeek(file, equipmentType, currencyCode, definitionType, customGrouping, nonServiceable, zeroReplacement, variability): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(ServiceMappings.builderXml +
      '?currencyCode=' + currencyCode +
      '&definitionType=' + definitionType +
      '&equipmentType=' + equipmentType +
      '&groupingType=' + customGrouping +
      '&excludeNonServiceableParts=' + nonServiceable +
      '&excludeZeroReplacementParts=' + zeroReplacement +
      '&lookupVariabilityFactors=' + variability,
      formData);
  }

  partsExpertForPeek(equipmentType, currencyCode, definitionType, customGrouping, nonServiceable, zeroReplacement, builderId): Observable<any> {

    return this.http.post(ServiceMappings.builderPartsExpert +
      '?builderId=' + builderId +
      '&currencyCode=' + currencyCode +
      '&definitionType=' + definitionType +
      '&equipmentType=' + equipmentType +
      '&groupingType=' + customGrouping +
      '&excludeNonServiceableParts=' + nonServiceable +
      '&excludeZeroReplacementParts=' + zeroReplacement, '');
  }
  getComponentDetailsDetails(): Observable<any> {
    return this.restService.httpGet('./assets/mock-data/componentDetails.json');
  }


   getEstimateList(isArchive): Observable<any> {
    return this.restService.httpGet(ServiceMappings.getEstimate);
  }

  getEstimateDetails(planNumber, isEditable?): Observable<any> {
    let planURL = '';
    if (isEditable) {
      planURL = ServiceMappings.checkEstimateLock + planNumber;
    } else {
      planURL = ServiceMappings.estimateDetails + '?PlanNumber=' + planNumber;
    }
    return this.restService.httpGet(planURL);
  }

  startPlanBuilder(params): Observable<any> {
    return this.restService.httpPost(ServiceMappings.startPlan, params);
  }

  getManufacturerData(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.manufacturerData +
      '?dataSource=' + this.dataService.dataSource +
      '&useCustomCode=' + this.dataService.useCustomcode +
      '&dataOrigin=' + this.dataService.dataOrigin +
      '&workGroupId=' + this.dataService.basicDetailsWorkGroupId);
  }

  getFamilyData(manufacturerIdentifier, equipmentType): Observable<any> {
    return this.restService.httpGet(ServiceMappings.familyData +
      '?dataSource=' + this.dataService.dataSource +
      '&useCustomCode=' + this.dataService.useCustomcode +
      '&equipmentType=' + equipmentType +
      '&dataOrigin=' + this.dataService.dataOrigin +
      '&manufacturerCode=' + manufacturerIdentifier +
      '&workGroupId=' + this.dataService.basicDetailsWorkGroupId);
  }
  postEstimateFinalized(planNumber): Observable<any> {
    return this.restService.httpGet(ServiceMappings.estimateFinalize +
      '?planNumber=' + planNumber);
  }
  getModalData(manufacturerIdentifier, familyIdentifier, equipmentType): Observable<any> {
    return this.restService.httpGet(ServiceMappings.modalData +
      '?dataSource=' + this.dataService.dataSource +
      '&useCustomCode=' + this.dataService.useCustomcode +
      '&equipmentType=' + equipmentType +
      '&dataOrigin=' + this.dataService.dataOrigin +
      '&manufacturerCode=' + manufacturerIdentifier +
      '&familyCode=' + familyIdentifier +
      '&workGroupId=' + this.dataService.basicDetailsWorkGroupId);
  }
  getProductSearchData(prefix, isCatEquipment?): Observable<any> {
    let queryParams = `?dataSource=${this.dataService.dataSource}&prefix=${prefix}&dataOrigin=${this.dataService.dataOrigin}&useCustomCode=${this.dataService.useCustomcode}&workGroupId=${this.dataService.basicDetailsWorkGroupId}`
    if (!isCatEquipment) {
      return this.restService.httpGet(ServiceMappings.productSearchData +queryParams+ '&manufacturerCode=' + this.dataService.manufacturerCode + '&isCCR=' + this.dataService.isSocCCR);
    }
    else {
      if (isCatEquipment.isCatEquipment) {
        if(this.dataService.manufacturerCode){
        return this.restService.httpGet(ServiceMappings.productSearchData + queryParams +'&manufacturerCode=' + this.dataService.manufacturerCode + '&isCCR=' + this.dataService.isSocCCR);
      }
      else{
        return this.restService.httpGet(ServiceMappings.productSearchData + queryParams + '&isCCR=' + this.dataService.isSocCCR);
     
      }
    }
      else {
        return this.restService.httpGet(ServiceMappings.productSearchData + queryParams+ '&manufacturerCode=' + this.dataService.manufacturerCode + '&isCCR=' + this.dataService.isSocCCR);
      }
    }
  }
 getQEProductSearchData(prefix, isCatEquipment?): Observable<any> {
  let queryParams = `?dataSource=${this.dataService.dataSource}&prefix=${prefix}&dataOrigin=${this.dataService.dataOrigin}&useCustomCode=${this.dataService.useCustomcode}&workGroupId=${this.dataService.basicDetailsWorkGroupId}`
    if (!isCatEquipment) {
      return this.restService.httpGet(ServiceMappings.productSearchData + queryParams+ '&manufacturerCode=' + this.dataService.manufacturerCode);
    }
    else {
      if (isCatEquipment.isCatEquipment) {
        return this.restService.httpGet(ServiceMappings.productSearchData +queryParams );
      }
      else {
        return this.restService.httpGet(ServiceMappings.productSearchData + queryParams+ '&manufacturerCode=' + this.dataService.manufacturerCode);
      }
    }
  }
  getSerialNumberData(manufacturerIdentifier, familyIdentifier, model, equipmentType): Observable<any> {
    return this.restService.httpGet(ServiceMappings.serialNumberData +
      '?dataSource=' + this.dataService.dataSource +
      '&useCustomCode=' + this.dataService.useCustomcode +
      '&equipmentType=' + equipmentType +
      '&dataOrigin=' + this.dataService.dataOrigin +
      '&manufacturerCode=' + manufacturerIdentifier +
      '&familyCode=' + familyIdentifier +
      '&model=' + model +
      '&workGroupId=' + this.dataService.basicDetailsWorkGroupId +
      '&isCCR=' + this.dataService.isSocCCR);
  }

  getcommercialEngineData(prefix, low, high, equipmentType) {
    return this.restService.httpGet(ServiceMappings.commercialEngineData +
      '?prefix=' + prefix +
      '&low=' + low +
      '&high=' + high +
      '&dataOrigin=' + this.dataService.dataOrigin +
      '&equipmentType=' + equipmentType +
      '&useCustomCode=' + this.dataService.useCustomcode);
  }

  searchByPrefix(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.productSearchData);
  }

  restoreArchived(plannumber): Observable<any> {
    return this.restService.httpPost(ServiceMappings.restoreArchived + plannumber, {});
  }

  uploadPeekSocDetails(params, definitionType): Observable<any> {
    return this.restService.httpPost(ServiceMappings.peekSOC, params);
  }

  getPeekSocDetails(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.getpeekSOC);
  }

  updateArchive(plannumber): Observable<any> {
    return this.restService.httpPost(ServiceMappings.updateArchive + plannumber, {});
  }

  copyPlan(params): Observable<any> {
    return this.restService.httpPost(ServiceMappings.copyPlan, params);
  }

  copyPlanToWorkgroup(params): Observable<any> {
    return this.restService.httpPost(ServiceMappings.copyPlanToWorkgroup, params);
  }


  private pingFactorStatus(planNumber): Observable<any> {
    return this.restService.httpGet(ServiceMappings.factorImportStatus + '?PlanNumber=' + planNumber).pipe(
      expand((res: any) => {
        return res.Status === 'Ongoing' || res.Status === 'Created' || !res ?
          this.restService.httpGet(ServiceMappings.factorImportStatus + '?PlanNumber=' + planNumber).pipe(delay(800)) : EMPTY;
      }));
  }
  private checkFactorsInEstimate(planNumber, factors): Observable<any> {
    if (this.estimateCreateProgress.dataMissing) {
      this.estimateCreateProgress.evaluationInProgress = true;
      this.estimateCreateProgress.reImportProgressPercent = factors.CompletionPercent;
      this.estimateCreateProgress.reImportListStatus = factors.CheckListStatuses;
    } else {
      this.estimateCreateProgress.factorImportInProgress = true;
      this.estimateCreateProgress.completionPercent = factors.CompletionPercent;
      this.estimateCreateProgress.factorListStatus = factors.CheckListStatuses;
    }
    if (factors.Status === 'DoneAndReady' || factors.Status === 'WaitingForAdditionalFactors') {
      this.estimateCreateProgress.factorImportInProgress = false;
      this.estimateCreateProgress.factorImportCompleted = true;
      this.estimateCreateProgress.evaluationInProgress = true;
      if (this.estimateCreateProgress.dataMissing) {
        this.estimateCreateProgress.reImportListHidden = true;
      } else {
        this.estimateCreateProgress.factorListHidden = true;
      }
      this.fatorsImportStatus = factors;
      return this.checkRevisionEvaulation(planNumber).pipe(
        delay(5000),
        mergeMap((evaluation: any) => {
          return this.checkAcceptableForOnboardingForFactors(evaluation,planNumber);
        })
      );
    } else {
      return of({ Status: factors.Status, completion: factors.CompletionPercent, CheckListStatuses: factors.CheckListStatuses });    // Factors import failed
    }
  }

  checkAcceptableForOnboardingForFactors(evaluation: any, planNumber: any) {
    let count = 0;
    if (evaluation.AcceptableForOnboarding) {
      this.estimateCreateProgress.evaluationCompleted = true;
      this.estimateCreateProgress.evaluationInProgress = false;
      this.estimateCreateProgress.calculationInProgress = true;
      if (this.estimateCreateProgress.dataMissing) {
        this.estimateCreateProgress.reImportListHidden = true;
      } else {
        this.estimateCreateProgress.factorListHidden = true;
      }
      this.currentRevisionStatus = evaluation;
      return this.getEstimateDetails(planNumber).pipe(
        delay(5000),
        expand((details: any) => {
          details = details.PlanDetailView;
          //TODO remove comment after checking
          // this.estimateCreateProgress.calculationInProgress = false;
          // this.estimateCreateProgress.calculationCompleted = true;
          if (details.Estimate && details.Factors && details.Estimate.OverallStatus !== EstimateStatus.None) {
            return EMPTY;
          } else if(count !== 4) {
            count = count + 1;
            return this.getEstimateDetails(planNumber).pipe(delay(5000));
          } else {
            this.estimateCreateProgress.calculationInProgress = false;
            this.estimateCreateProgress.calculationCompleted = true;
            return EMPTY;
          }
        }),
        map((plan: any) => {
          plan = plan.PlanDetailView;
          return (plan.Estimate && plan.Factors &&
            plan.Estimate.OverallStatus !== EstimateStatus.None) ? plan : 
            { Status: 'Factors missing', Retrycount: count };
        })
      );
    } else {
      this.estimateCreateProgress.dataMissing = true;
      return of({ Status: 'RevisionEvaluation Failed', Result: evaluation });
    }
  }

  private initiateFactorsImport(planNumber, params): Observable<any> {
    const url = (params) ? ServiceMappings.initiateSelectFactorsImport : ServiceMappings.initiateFactorsImport;
    return this.restService.httpPost(url + '?PlanNumber=' + planNumber, params);
  }
  private checkRevisionEvaulation(planNumber): Observable<any> {
    return this.restService.httpGet(ServiceMappings.factorRevisionEvaluation + '?PlanNumber=' + planNumber);
  }

  applyFactorAPI(planNumber, revisionID) {
    return this.applyFactorsReload(planNumber).pipe(
      mergeMap((applyFactor: any) => {
        if (applyFactor.CheckoutResult) {
          return this.getEstimateDetails(planNumber).pipe(
            delay(1000),
            expand((plan: any) => {
              plan = plan.PlanDetailView;
              return (plan.Factors.RevisionId === revisionID &&
                plan.Estimate.OverallStatus !== EstimateStatus.None) ? EMPTY : this.getEstimateDetails(planNumber).pipe(
                  delay(1000));
            }),
            map((plans: any) => {
              plans = plans.PlanDetailView;
              return (plans.Factors.RevisionId === revisionID &&
                plans.Estimate.OverallStatus !== EstimateStatus.None) ? plans : { Status: 'RevisionID missing' };
            })
          );
        }
      })
    );
  }

  checkCurrentRevisionEvaluation(factors, planNumber, revisionID): Observable<any> {
    return this.checkRevisionEvaulation(planNumber).pipe(
      mergeMap((evaluation: any) => {
        if (evaluation.AcceptableForOnboarding) {
          return this.getEstimateDetails(planNumber).pipe(
            delay(1000),
            expand((plan: any) => {
              plan = plan.PlanDetailView;
              return (plan.Factors.RevisionId === revisionID &&
                plan.Estimate.OverallStatus !== EstimateStatus.None) ? EMPTY : this.getEstimateDetails(planNumber).pipe(
                  delay(1000));
            }),
            map((plans: any) => {
              plans = plans.PlanDetailView;
              return (plans.Factors.RevisionId === revisionID &&
                plans.Estimate.OverallStatus !== EstimateStatus.None) ? plans : { Status: 'RevisionID missing' };
            })
          );
        } else {
          return of({ Status: 'RevisionEvaluation Failed', Result: evaluation });
        }
      })
    );
  }

  applyFactorsReload(planNumber): Observable<any> {
    return this.restService.httpPost(ServiceMappings.applyFactors + '?PlanNumber=' + planNumber, null);
  }

  checkFactorsImportStatus(planNumber): Observable<any> {
    return this.pingFactorStatus(planNumber).pipe(
      mergeMap((factors: any) => {
        return this.checkFactorsInEstimate(planNumber, factors);
      })
    );
  }

  editEstimateAndApply(planNumber, editJson): Observable<any> {
    return this.restService.httpPost(ServiceMappings.estimateApply + '?PlanNumber=' + planNumber, editJson);
  }
  restoreVersion(planNumber, savePoint): Observable<any> {
    return this.restService.httpPost(ServiceMappings.restoreVersionAPI + '?planNumber=' + planNumber + '&savePointName=' + savePoint, {});
  }

  resolveVersion(planNumber, savePoint): Observable<any> {
    return this.restService.httpGet(ServiceMappings.resolveVersionAPI + '?planNumber=' + planNumber + '&savePointName=' + savePoint);
  }
  releasePlan(planNumber): Observable<any> {
    return this.restService.httpPost(ServiceMappings.releasePlan + '?PlanNumber=' + planNumber, {});
  }

  // MVP phase report services
  getInitialReportDetails(planNumber): Observable<any> {
    return this.restService.httpGet(ServiceMappings.listSupported + planNumber);
  }
  getPreviewReport(planNumber, req): Observable<any> {
    return this.restService.httpPost(ServiceMappings.getPreviewReport + planNumber, req);
  }
  getUserSpecifiedReport(planNumber, req, type): Observable<any> {
    const contentType = 'application/octet-stream';
    if (type == 'pdf') {
      return this.restService.httpPostTypeReportPdf(ServiceMappings.downloadReport + planNumber, req, contentType);
    } else {
      return this.restService. httpPostTypeReport(ServiceMappings.downloadReport + planNumber, req, contentType);
    }
  }
   // Services For Estimate Versioning

  savePointClick(planNumber, name): Observable<any> {
    const query = 'planNumber=' + planNumber;
    const Name ={Name : name}
    return this.restService.httpPost(ServiceMappings.savePoint + query , Name);
  }

  estimateVersion(planNumber): Observable<any> {
    const query = 'planNumber=' + planNumber;
    return this.restService.httpGet(ServiceMappings.estVersion + query);
  }

  // Service for Grief Process
  populateComponentData(): Observable<any> {
    return this.restService.httpGet('./assets/mock-data/grief-parts.json');
  }

  populateJobData(): Observable<any> {
    return this.restService.httpGet('./assets/mock-data/grief-parts.json');
  }

  populateMockData(): Observable<any> {
    return this.restService.httpGet('./assets/mock-data/grief-parts.json');
  }

  populatePartsData(): Observable<any> {
    return this.restService.httpGet('./assets/mock-data/grief-parts.json');
  }

  customSaveClick(planNumber, params): Observable<any> {
    return this.restService.httpPost(ServiceMappings.customSaveGreif + '?PlanNumber=' + planNumber, params);
  }
  generateEventSchedule(jsonData): Observable<any> {
    return this.restService.httpPost(ServiceMappings.generateEvent, jsonData);
  }
  getPmWizardDetailsBeforeStartPlan(params): Observable<any> {
    return this.restService.httpPost(ServiceMappings.pmWizardDetailsBeforeStartPlan, params);
  }

  getFluidDetails(params, planNumber): Observable<any> {
    return this.restService.httpPost(ServiceMappings.fetchFluidDetails + '?PlanNumber=' + planNumber, params);
  }

  calculateOilUsage(params, planNumber): Observable<any> {
    return this.restService.httpPost(ServiceMappings.calculateOilUsage + '?PlanNumber=' + planNumber, params);
  }

  calculateFuelUsage(params, planNumber): Observable<any> {
    return this.restService.httpPost(ServiceMappings.calculateFuelUsage + '?PlanNumber=' + planNumber, params);
  }

  deleteEstimate(planNumber): Observable<any> {
    return this.restService.httpPost(ServiceMappings.deleteEstimate + '?PlanNumber=' + planNumber, {});
  }
  calculatecoolantUsage(params, planNumber): Observable<any> {
    return this.restService.httpPost(ServiceMappings.calculatecoolantUsage + planNumber, params);
  }
  getSelectionValue(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.selectionValues);
  }

  setSelectionValue(data: any) {
    this.selectionValue.next(data);
  }

  undoEstimateChange(planNumber): Observable<any> {
    return this.restService.httpPost(ServiceMappings.undoEstimateChange + '?PlanNumber=' + planNumber, {});
  }

  redoEstimateChange(planNumber): Observable<any> {
    return this.restService.httpPost(ServiceMappings.redoEstimateChange + '?PlanNumber=' + planNumber, {});
  }

  getUndoRedoAvailability(planNumber): Observable<any> {
    return this.restService.httpGet(ServiceMappings.undoRedoAvailability + '?PlanNumber=' + planNumber);
  }
  filterLabor(customerDetails, labor) {
    if (labor && labor.length > 0) {
      this.filteredLaborRate = labor.filter(elem => (elem.CustomerClass == customerDetails.Class && elem.CustomerNumber == customerDetails.Number)
        || (elem.CustomerClass == '' && elem.CustomerNumber == '') || ((elem.CustomerClass == '' || elem.CustomerClass == null) && elem.CustomerNumber == customerDetails.Number)
        || (elem.CustomerClass == customerDetails.Class && (elem.CustomerNumber == '' || elem.CustomerNumber == null)) || (elem.CustomerClass == null && elem.CustomerNumber == null));
    }
  }
  getBasicDetailsWorkGroup(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.basicDetailsWorkGroup +
      '?dataOrigin=ServiceOptionsCollaborator' +
      '&useCustomCode=' + this.dataService.useCustomcode);
  }
  getBasicDetailsWorkGroupforSOCcat(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.basicDetailsWorkGroup +
      '?dataOrigin=ServiceOptionsCollaborator&dataSource=CATCanonical' +
      '&useCustomCode=' + this.dataService.useCustomcode);
  }
  getBasicDetailsWorkGroupforSOCdealer(): Observable<any> { 
    return this.getBasicDetailsWorkGroupDealer();
  }
  getBasicDetailsWorkGroupDealer(): Observable<any> {
    return this.restService.httpGet(ServiceMappings.basicDetailsWorkGroup +
      '?dataOrigin=ServiceOptionsCollaborator&dataSource=DealerOnly' +
      '&useCustomCode=' + this.dataService.useCustomcode);
  }

  saveNote(planNumber, params): Observable<any> {
    return this.restService.httpPost(ServiceMappings.saveNotes + '?PlanNumber=' + planNumber, params);
  }

  previewRefreshSOCData(planNumber): Observable<any> {
    return this.restService.httpGet(ServiceMappings.previewRefreshSOCData + '?PlanNumber=' + planNumber);
  }

  applyRefreshSOCData(planNumber, params): Observable<any> {
    return this.restService.httpPost(ServiceMappings.applyRefreshSOCData + '?PlanNumber=' + planNumber, params);
  }

  loadTrainingData(content, multipleData?): Observable<any> {
    if (multipleData){
      let data = '';
      content.forEach((elem, index) => {
        if (index == content.length - 1){
          data = data + 'ids=' + elem.Name;
        } else {
          data = data + 'ids=' + elem.Name + '&';
        }
      });
      return this.restService.httpGet(ServiceMappings.loadTrainingContent + data);
    } else {
      return this.restService.httpGet(ServiceMappings.loadTrainingContent + 'ids=' + content);
    }
  }

  saveTrainingData(content): Observable<any> {
    return this.restService.httpPost(ServiceMappings.saveTrainingContent, content);
  }
  processStuff(inputObject) {
    const observableBatch = [];

    inputObject.forEach((componentarray, key) => {
      observableBatch.push(this.restService.httpGet(ServiceMappings.loadTrainingContent + '?id=' + componentarray.Name));
    });

    return forkJoin(observableBatch);
  }


  // Service for reload factors import

  refreshFactorsImport(planNumber, params?): Observable<any> {
    return this.initiateFactorsImport(planNumber, params).pipe(
      mergeMap((data: any) => {
        if (data.CheckoutResult && data.PlanExists) {
          this.revisionId = data.RevisionId;
          this.fatorsImportStatusReload = true;
          return this.pingFactorStatus(planNumber).pipe(
            delay(2000),
            mergeMap((factors: any) => {
              return this.checkFactorRevisionInEstimate(factors, planNumber, data.RevisionId);
            })
          );
        } else {
          return of(data);
        }
      })
    );
  }

  private checkFactorRevisionInEstimate(factors, planNumber, revisionID, notPassFactors?): Observable<any> {
    if (!notPassFactors) {
      this.reloadComplete = factors;
    }
    if (factors.Status === 'DoneAndReady' || factors.Status === 'WaitingForAdditionalFactors') {
      return this.checkRevisionEvaulation(planNumber).pipe(
        mergeMap((evaluation: any) => {
          return this.checkAcceptableForOnboardingForFactorRevision(evaluation,planNumber);
        })
      );
    } else {
      return of({ Status: factors.Status, completion: factors.CompletionPercent });    // Factors import failed
    }
  }

  checkAcceptableForOnboardingForFactorRevision(evaluation: any, planNumber: any) {
    if (evaluation.AcceptableForOnboarding) {
      return this.getEstimateDetails(planNumber).pipe(
        delay(1000),
        expand((plan: any) => {
          plan = plan.PlanDetailView;
          return (plan.Estimate.OverallStatus !== EstimateStatus.None) ? EMPTY : this.getEstimateDetails(planNumber).pipe(
                delay(1000));
        }),
        map((plans: any) => {
          plans = plans.PlanDetailView;
          return (plans.Estimate.OverallStatus !== EstimateStatus.None) ? plans : { Status: 'RevisionID missing' };
        })
      );
    } else {
      return of({ Status: 'RevisionEvaluation Failed', Result: evaluation });
    }
  }

  applyFactors(planNumber) {
    return this.applyFactorsReload(planNumber).pipe(
      mergeMap((applyFactor: any) => {
        if (applyFactor.CheckoutResult) {
          return this.getEstimateDetails(planNumber).pipe(
            delay(1000),
            expand((details: any) => {
              details = details.PlanDetailView;
              return (details.Estimate && details.Factors &&
                details.Estimate.OverallStatus !== EstimateStatus.None) ? EMPTY :
                this.getEstimateDetails(planNumber).pipe(delay(1000));
            }),
            map((plan: any) => {
              plan = plan.PlanDetailView;
              return (plan.Estimate && plan.Factors &&
                plan.Estimate.OverallStatus !== EstimateStatus.None) ? plan : { Status: 'Factors missing' };
            })
          );
        }
      })
    );
  }

  checkPendingFactorsImportStatus(planNumber): Observable<any> {
    return this.pingFactorStatus(planNumber).pipe(
      mergeMap((factors: any) => {
        return this.checkPendingFactorsInEstimate(planNumber, factors);
      })
    );
  }

  private checkPendingFactorsInEstimate(planNumber, factors): Observable<any> {
    if (factors.Status === 'DoneAndReady' || factors.Status === 'WaitingForAdditionalFactors') {
      this.fatorsImportStatus = factors;
      return this.checkRevisionEvaulation(planNumber).pipe(
        mergeMap((evaluation: any) => {
          if (evaluation.AcceptableForOnboarding) {
            this.currentRevisionStatus = evaluation;
            return this.applyFactorPendingAPI(planNumber);
          } else {
            return of({ Status: 'RevisionEvaluation Failed', Result: evaluation });
          }
        })
      );
    } else {
      return of({ Status: factors.Status, completion: factors.CompletionPercent, CheckListStatuses: factors.CheckListStatuses });    // Factors import failed
    }
  }

  applyFactorPendingAPI(planNumber) {
  return this.applyFactors(planNumber);
  }

  handleFactorImportFailed(planNumber, params) {
    return this.checkFactorsImportStatus(planNumber);
  }

  checkFactorsImportAfterMissingData(planNumber): Observable<any> {
    this.fatorsImportStatusReload = true;
    return this.pingFactorStatus(planNumber).pipe(
      mergeMap((factors: any) => {
        return this.checkFactorRevisionInEstimate(factors, planNumber, this.revisionId, true);
      })
    );
  }

  comparisonView(planNumbers): Observable<any> {
    let planNum = '';
    planNumbers.forEach((elem, index) => {
      if (index == planNumbers.length - 1){
      planNum = planNum + 'planNumbers=' + elem.PlanNumber;
      } else {
        planNum = planNum + 'planNumbers=' + elem.PlanNumber + '&';
      }
    });
    return this.restService.httpGet(ServiceMappings.comparisonView + planNum);
  }

  comparisonEdit(planNumbers): Observable<any> {
    let planNum = '';
    planNumbers.forEach((elem, index) => {
      if (index == planNumbers.length - 1){
      planNum = planNum + 'planNumbers=' + elem.PlanNumber;
      } else {
        planNum = planNum + 'planNumbers=' + elem.PlanNumber + '&';
      }
    });
    return this.restService.httpGet(ServiceMappings.comparisonEdit + planNum);
  }

  getReportDetails(planNumbers): Observable<any>{
    let planNum = '';
    planNumbers.forEach((elem, index) => {
      if (index == planNumbers.length - 1){
      planNum = planNum + 'planNumbers=' + elem.PlanNumber;
      } else {
        planNum = planNum + 'planNumbers=' + elem.PlanNumber + '&';
      }
    });
    return this.restService.httpGet(ServiceMappings.listSupportedAggregateReports + planNum);
  }
  createReportPreview(planNumbers, data): Observable<any>{
    let planNum = '';
    const contentType = 'application/octet-stream';
    planNumbers.forEach((elem, index) => {
      if (index == planNumbers.length - 1){
      planNum = planNum + 'planNumbers=' + elem.PlanNumber;
      } else {
        planNum = planNum + 'planNumbers=' + elem.PlanNumber + '&';
      }
    });
    return this.restService.httpPost(ServiceMappings. createAggregateReportPreview  + planNum , data, contentType);
  }
  downloadReport(planNumbers, data): Observable<any>{
    let planNum = '';
    const contentType = 'application/octet-stream';
    planNumbers.forEach((elem, index) => {
      if (index == planNumbers.length - 1){
      planNum = planNum + 'planNumbers=' + elem.PlanNumber;
      } else {
        planNum = planNum + 'planNumbers=' + elem.PlanNumber + '&';
      }
    });
    return this.restService.httpPostTypeHtml(ServiceMappings. downloadAggregateReport  + planNum, data, contentType);
  }

  restoreEstimate(planNumber): Observable<any> {
    return this.restService.httpPost(ServiceMappings.restoreEstimate + '?planNumber=' + planNumber, '');
  }
  downloadAdminReport(data): Observable<any>{
    const contentType = 'application/octet-stream';
    return this.restService.httpPostTypeHtml(ServiceMappings.downloadAdminReport, data, contentType);
  }

  resetProgressFlags() {
    this.estimateCreateProgress = {
      estimateCreationInProgress: false,
      estimateCreated: false,
      factorImportInProgress: false,
      factorImportCompleted: false,
      evaluationInProgress: false,
      evaluationCompleted: false,
      calculationInProgress: false,
      calculationCompleted: false,
      factorListStatus: [],
      completionPercent: 0,
      dataMissing: false,
      reImportProgressPercent: 0,
      reImportListStatus : [],
      factorListHidden: false,
      reImportListHidden: false
    };
  }

  initiateRepricing(planNumbers): Observable<any>{
    let planNum =''
    planNumbers.forEach((elem, index) => {
      if (index == planNumbers.length - 1){
      planNum = planNum + 'planNumbers=' + planNumbers[index];
      } else {
        planNum = planNum + 'planNumbers=' + planNumbers[index] + '&';
      }
    });
    return this.restService.httpPost(ServiceMappings.initiateRepricing + planNum, planNumbers);
  }
  
  cancelRepricing(id): Observable<any>{
    return this.restService.httpPost(ServiceMappings.cancelRepricing + id, id);
  } 

  onGoingRevisions():Observable<any>{
    return this.restService.httpGet(ServiceMappings.onGoingRevisions);
  }

  factorImport(id):Observable<any>{
    return this.restService.httpGet(ServiceMappings.factorImport + id);
  }
  
  sentclick() {
    this.unitValue.next({})
  }
  getclick(): Observable<any> {
    return this.unitValue.asObservable();
  }
  sendUnitvalue(data) {
   this.unitSelection.next(data)
  }

  getUnitclick(): Observable<any> {
  return this.unitSelection.asObservable();

  }

  checkForStatusOfFactorsImport(planNumber): Observable<any> {
    return this.pingFactorStatus(planNumber).pipe(
      mergeMap((factors: any) => {
        return this.checkFactorStatusAndProceed(planNumber, factors);
      })
    );
  }

  checkFactorStatusAndProceed(planNumber, factors) {
    if (factors.Status === 'DoneAndReady' || factors.Status === 'WaitingForAdditionalFactors') {
      this.fatorsImportStatus = factors;
      return this.checkRevisionEvaulation(planNumber).pipe(
        mergeMap((evaluation: any) => {
          return this.checkIfAcceptableForOnboardingFactors(evaluation,planNumber);
        })
      );
    } else {
      return of({ Status: factors.Status, completion: factors.CompletionPercent, CheckListStatuses: factors.CheckListStatuses });    // Factors import failed
    }
  }

  checkIfAcceptableForOnboardingFactors(evaluation: any, planNumber: any) {
    if (evaluation.AcceptableForOnboarding) {
      this.currentRevisionStatus = evaluation;
      return this.getEstimateDetails(planNumber, true).pipe(
        delay(1000),
        map((plan: any) => {
          return plan;
        })
      );
    } else {
      this.estimateCreateProgress.dataMissing = true;
      return of({ Status: 'RevisionEvaluation Failed', Result: evaluation });
    }
  }

  getRefreshSOCDataForPreview(planNumber):Observable<any> {
    return this.restService.httpGet(ServiceMappings.previewDataForSOCRefresh + '?PlanNumber=' + planNumber);
  }

  applySOCDataRefresh(planNumber, data):Observable<any> {
    return this.restService.httpPost(ServiceMappings.applySOCDataRefresh + '?PlanNumber=' + planNumber, data);
  }

  checkFactorsImportStatusForSOCRefresh(planNumber): Observable<any> {
    return this.pingFactorStatus(planNumber).pipe(
      mergeMap((factors: any) => {
        return this.checkFactorsInSOCRefresh(planNumber, factors);
      })
    );
  }

  private checkFactorsInSOCRefresh(planNumber, factors): Observable<any> {
    if (factors.Status === 'DoneAndReady' || factors.Status === 'WaitingForAdditionalFactors') {
      this.estimateCreateProgress.factorImportInProgress = false;
      this.estimateCreateProgress.factorImportCompleted = true;
      this.estimateCreateProgress.evaluationInProgress = true;
      if (this.estimateCreateProgress.dataMissing) {
        this.estimateCreateProgress.reImportListHidden = true;
      } else {
        this.estimateCreateProgress.factorListHidden = true;
      }
      this.fatorsImportStatus = factors;
      return this.checkRevisionEvaulation(planNumber).pipe(
        delay(5000),
        mergeMap((evaluation: any) => {
          return this.checkAcceptableForOnboardingSOCRefresh(evaluation,planNumber);
        })
      );
    } else {
      return of({ Status: factors.Status, completion: factors.CompletionPercent, CheckListStatuses: factors.CheckListStatuses });    // Factors import failed
    }
  }

  checkAcceptableForOnboardingSOCRefresh(evaluation: any, planNumber: any) {
    if (evaluation.AcceptableForOnboarding) {
      this.currentRevisionStatus = evaluation;
      return this.getEstimateDetails(planNumber).pipe(
        delay(1000),
        expand((details: any) => {
          details = details.PlanDetailView;
          if((details.Estimate && details.Factors &&
            details.Estimate.OverallStatus !== EstimateStatus.None) || (details.Configuration && !details.Estimate && !details.Factors)){
              return EMPTY;
            } else {
              this.getEstimateDetails(planNumber).pipe(delay(1000))
            }
        }),
        map((plan: any) => {
          plan = plan.PlanDetailView;
          if((plan.Estimate && plan.Factors && plan.Estimate.OverallStatus !== EstimateStatus.None)  ||
            (plan.Configuration && !plan.Estimate && !plan.Factors)) {
              return plan;
            } else {
              return { Status: 'Factors missing' };
            }
        })
      );
    } else {
      return of({ Status: 'RevisionEvaluation Failed', Result: evaluation });
  
    }
  }
}
