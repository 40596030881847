import { AfterContentInit, Component, OnInit } from '@angular/core';
import { EnginePerformanceService } from '@app/services/workgroup/engineperformance.service';
import { WorkgroupService } from '@app/services/workgroup/workgroup.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-view-engine-performance',
  templateUrl: './view-engine-performance.component.html',
  styleUrls: ['./view-engine-performance.component.scss'],
})
export class ViewEnginePerformanceComponent
  implements OnInit, AfterContentInit
{
  dataHeader: any;
  datasource: any;
  engineModifierEnum = [];
  isFromGlobal = false;
  viewEnginePerformance: any;
  viewCustom: any;
  private enumSubscribe: any;
  constructor(
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
    public enginePerformanceService: EnginePerformanceService,
    private workgroupService: WorkgroupService
  ) {}

  ngOnInit() {
    this.viewCustom = this.translate.instant('lbl_view_custom');
    this.viewEnginePerformance = this.translate.instant(
      'viewEnginePerformance'
    );
    if (this.dataHeader !== undefined) {
      if (this.dataHeader.IsCustom) {
        this.datasource = this.translate.instant('customData');
      } else {
        this.datasource = this.translate.instant('catData');
      }
    }
    this.enumSubscribe =
      this.workgroupService.workgroupSelectionEnums.subscribe((data: any) => {
        if (data && data.enums && data.enums.KeyValues) {
          this.engineModifierEnum = data.enums.KeyValues.EngineModifierEnum;
          this.dataHeader.FuelOverhauls.forEach((el) => {
            if (el.ModifierCode === this.engineModifierEnum[0]) {
              this.dataHeader.modifedcode0 = el.Consumption;
            } else if (el.ModifierCode === this.engineModifierEnum[1]) {
              this.dataHeader.modifedcode1 = el.Consumption;
            } else if (el.ModifierCode === this.engineModifierEnum[2]) {
              this.dataHeader.modifedcode2 = el.Consumption;
            }
          });
        }
      });
  }

  ngAfterContentInit() {
    const canclBtnElmt: any = document.getElementsByClassName('cancel-button');
    setTimeout(() => {
      if (canclBtnElmt && canclBtnElmt.length > 0) {
        canclBtnElmt[0].blur();
      }
    }, 200);
  }
  close() {
    this.activeModal.close();
  }
}
