import { Component } from '@angular/core';

@Component({
  selector: 'app-parts-expert',
  templateUrl: './parts-expert.component.html',
  styleUrls: ['./parts-expert.component.scss']
})
export class PartsExpertComponent {


}
