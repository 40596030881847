import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertButton } from '@app/modals/alert-button';
import { DefaultUserWGPreferences, UserService } from '@app/services/misc/user.service';
import { DataService } from '@app/services/shared/storage/data.service';
import { SubjectService } from '@app/services/data/subject.service';

@Component({
  selector: 'app-parts-expert-workgroup',
  templateUrl: './parts-expert-workgroup.component.html',
  styleUrls: ['./parts-expert-workgroup.component.scss']
})
export class PartsExpertWorkgroupComponent {

  @Input() alertMessage: string;
  @Input() buttons?: (AlertButton | string)[];
  @Input() workGroupList: any;
  selectedWorkGroup: any;
  isLoading = false;
  prefernceDataRequest: any;
  isError = false;

  constructor(
    public activeModal: MatDialogRef<PartsExpertWorkgroupComponent>,
    public ref: ChangeDetectorRef,
    public dataService: DataService,
    private userService: UserService, 
    public subjectService: SubjectService) {
  }



  wgValueChange(event) {
    this.isError = false;
    this.prefernceDataRequest = {
      Name: DefaultUserWGPreferences,
      Settings: [{ Name: DefaultUserWGPreferences, Value: event.value.WorkGroupId }]
    };
    this.dataService.defaultOrgWG = event.value;
    this.dataService.defaultWGRole = event.value.Role;
    this.subjectService.sendMessage(event.value.Role,'defaultWGRoleChanged');
  }

  close() {
    this.activeModal.close();
  }
  save() {
    this.isError = false;
    this.isLoading = true;
    const sub = this.userService.saveUserPreferences(this.prefernceDataRequest).subscribe(() => {
      sub.unsubscribe();
      this.isLoading = false;
      this.activeModal.close();
    }, err => {
      this.isLoading = false;
      this.isError = true;
    });
  }

}
