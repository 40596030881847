import { CurrencyPipe, getCurrencySymbol } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";

import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";

import { UnitPipe } from "@app/common/pipes/unit.pipe";
import { SubjectService } from "@app/services/data/subject.service";
import { EstimatePricingService } from "@app/services/estimate-pricing/estimate-pricing.service";
import { DataService } from "@app/services/shared/storage/data.service";
import { ConfirmAlertComponent } from "@app/components/confirm-alert/confirm-alert.component";
import { Utility } from "@app/common/utility/utils";

@Component({
  selector: "app-include-coolant",
  templateUrl: "./include-coolant.component.html",
  styleUrls: ["./include-coolant.component.scss"],
})
export class IncludeCoolantComponent implements OnInit {
  includecoolantForm: FormGroup;
  @Input() factorsData;
  @Input() planNumber;
  @Input() correlationId;
  @Input() estimateHeader;
  @Input() estimateSummary;

  save = true;
  isLoading = false;
  coolantList = [];
  laborList = [];
  currencyCode = "";
  fluidtypeCorrelationId = "";
  coolantTypeLoading = false;
  coolantCurrencySymbol: any;
  coolantData: any;
  oilSellkwHrConsumed = "";
  oilCostkwHrConsumed: any;
  showButtons = false;
  coolantValue;
  laborValue;
  selectedFuel: any;
  sellError = false;
  costError = false;
  selectedLabor: any;
  coolantusage = "";
  coolantSell = "";
  coolantCost = "";
  coolantSellkwHrConsumed = "";
  coolantCostkwHrConsumed = "";
  sellperHour = "";
  costperHour = "";
  selectedCoolant: any;
  fuelUsageCalculating: boolean;
  submit = false;
  protected onDestroy = new Subject<boolean>();
  coolantValueChanged: Subject<object> = new Subject();
  savingIndicator: any = false;
  displaySaveButton: any = true;
  coolantSellLabel;
  coolantCostLabel;
  usStandardUnit = false;
  selectedLabour: any;
  unitOfMeasure;
  capacityWithoutRounding;
  sellWithoutRounding;
  costWithoutRounding;
  usageWithoutRounding;
  isUserOrgIntermediate: boolean = false;
  utility = new Utility();

  constructor(
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    private translate: TranslateService,
    public estimateService: EstimatePricingService,
    public unitPipe: UnitPipe,
    public currencyPipe: CurrencyPipe,
    private snackBar: MatSnackBar,
    private dataService: DataService,
    private modalService: NgbModal,
    private subjectService: SubjectService
  ) {
    this.isUserOrgIntermediate = this.dataService.isUserOrgIntermediate;
    this.includecoolantForm = this.fb.group({
      changeInterval: [null, [Validators.required]],
      capacity: [null, [Validators.required]],
      coolantType: [null, [Validators.required]],
      labor: [null, [Validators.required]],
      sell: [null, [Validators.required]],
      cost: [null, [Validators.required]],
    });

    if (this.isUserOrgIntermediate) {
      this.includecoolantForm = this.utility.removeFormsMandatoryFields(
        this.includecoolantForm,
        ["cost"]
      );
    }
  }

  ngOnInit() {
    this.extractValue();
    this.unitOfMeasure = this.dataService.unitSelected;
    this.setUnitOfMeasure(this.unitOfMeasure);
    this.coolantSet();
    this.setSellAndCostLabels();

    this.includecoolantForm.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this.includecoolantForm.valid) {
          this.fuelUsageCalculating = true;
          this.save = false;
        } else {
          this.fuelUsageCalculating = false;
          this.isLoading = false;
          this.save = true;
        }
      });
    this.coolantValueChanged.pipe(debounceTime(500)).subscribe((data: any) => {
      if (data && data.isCoolantChanged) {
        this.submitForm();
      }
    });
  }

  extractValue() {
    this.coolantList = [];
    this.laborList = [];
    if (
      this.factorsData.GeneralFactors &&
      this.factorsData.GeneralFactors.FluidPrices.length > 0
    ) {
      this.factorsData.GeneralFactors.FluidPrices.forEach((element) => {
        if (element.FluidType === "Coolant") {
          const temp = {
            Number: element.Number,
            SourceOfSupply: element.SourceOfSupply,
            PartTypeID: element.PartTypeID,
            CustomerCode: element.CustomerCode,
            FluidType: element.FluidType,
            MarketingOrganization: element.MarketingOrganization,
            SalesArea: element.SalesArea,
            CoreelationId: element.CorrelationId,
          };
          this.coolantList.push(temp);
          this.coolantCurrencySymbol = getCurrencySymbol(
            element.Pricing.Sell.CurrencyCode,
            "wide"
          );
          this.currencyCode = element.Pricing.Sell.CurrencyCode;
        }
      });
    }

    if (this.estimateService.filteredLaborRate.length > 0) {
      this.estimateService.filteredLaborRate.forEach((element) => {
        const temp = {
          LaborCode: element.LaborCode,
          StoreCode: element.StoreCode,
          CustomerNumber: element.CustomerNumber,
          CustomerClass: element.CustomerClass,
          CostCenter: element.CostCenter,
          LaborRate: element.LaborRate,
        };
        this.laborList.push(temp);
      });
    }
  }
  coolantSet() {
    if (this.estimateHeader.CoolantUsage.CoolantType != null) {
      const labor = {
        LaborCode: this.estimateHeader.CoolantUsage.Labor.LaborCode,
        StoreCode: this.estimateHeader.CoolantUsage.Labor.StoreCode,
        CustomerNumber: this.estimateHeader.CoolantUsage.Labor.CustomerNumber,
        CustomerClass: this.estimateHeader.CoolantUsage.Labor.CustomerClass,
        CostCenter: this.estimateHeader.CoolantUsage.Labor.CostCenter,
      };
      this.includecoolantForm.controls.changeInterval.setValue(
        this.estimateHeader.CoolantUsage.ChangeInterval
      );
      this.includecoolantForm.controls.capacity.setValue(
        this.estimateHeader.CoolantUsage.Capacity
      );
      this.selectedLabor = _.find(this.laborList, labor);
      if (this.selectedLabor) {
        this.includecoolantForm.controls.labor.setValue(this.selectedLabor);
        this.laborValue = this.selectedLabor;
      } else {
        this.selectedLabor = this.laborList[0];
        this.includecoolantForm.controls.labor.setValue(this.selectedLabor);
        this.laborValue = this.selectedLabor;
      }
      const coolant = {
        Number: this.estimateHeader.CoolantUsage.CoolantType.Number,
        SourceOfSupply:
          this.estimateHeader.CoolantUsage.CoolantType.SourceOfSupply,
        PartTypeID: this.estimateHeader.CoolantUsage.CoolantType.PartTypeID,
        CustomerCode: this.estimateHeader.CoolantUsage.CoolantType.CustomerCode,
        FluidType: this.estimateHeader.CoolantUsage.CoolantType.FluidType,
        MarketingOrganization:
          this.estimateHeader.CoolantUsage.CoolantType.MarketingOrganization,
        SalesArea: this.estimateHeader.CoolantUsage.CoolantType.SalesArea,
        CoreelationId: 0,
      };
      this.selectedCoolant = _.find(this.coolantList, coolant);
      this.includecoolantForm.controls.coolantType.setValue(
        this.selectedCoolant
      );
      if (
        this.estimateHeader.FactorOverrides &&
        this.estimateHeader.FactorOverrides.FluidPartPrices.FluidPartPriceItems
          .length > 0
      ) {
        const coolantData =
          this.estimateHeader.FactorOverrides.FluidPartPrices.FluidPartPriceItems.filter(
            (item) => item.FluidType == "Coolant"
          );
        this.selectedFuel = coolantData[0];
        this.includecoolantForm.controls.sell.setValue(
          coolantData[0].Pricing.Sell.Amount
        );
        this.includecoolantForm.controls.cost.setValue(
          coolantData[0].Pricing.Cost.Amount
        );
        this.currencyCode = coolantData[0].Pricing.Cost.CurrencyCode;
        this.coolantCurrencySymbol = getCurrencySymbol(
          this.currencyCode,
          "wide"
        );
      }
      if (this.estimateSummary.CoolantUsage != null) {
        this.coolantusage =
          this.estimateSummary.CoolantUsage.FilledQuantityInLiters;
        this.coolantSell = this.currencyPipe.transform(
          this.estimateSummary.CoolantUsage.FillPrice.Sell.Amount,
          this.estimateSummary.CoolantUsage.FillPrice.Sell.CurrencyCode
        );
        this.coolantCost = this.currencyPipe.transform(
          this.estimateSummary.CoolantUsage.FillPrice.Cost.Amount,
          this.estimateSummary.CoolantUsage.FillPrice.Cost.CurrencyCode
        );
        this.coolantSellkwHrConsumed = this.unitPipe.transform(
          this.estimateSummary.CoolantUsage.HourlyFillPrice.Sell.Amount,
          this.estimateService.ratedPower,
          "KWhr",
          true,
          this.estimateSummary.CoolantUsage.HourlyFillPrice.Sell.CurrencyCode
        );
        this.coolantCostkwHrConsumed = this.unitPipe.transform(
          this.estimateSummary.CoolantUsage.HourlyFillPrice.Cost.Amount,
          this.estimateService.ratedPower,
          "KWhr",
          true,
          this.estimateSummary.CoolantUsage.HourlyFillPrice.Cost.CurrencyCode
        );
        this.sellperHour = this.currencyPipe.transform(
          this.estimateSummary.CoolantUsage.HourlyFillPrice.Sell.Amount,
          this.estimateSummary.CoolantUsage.HourlyFillPrice.Sell.CurrencyCode
        );
        this.costperHour = this.currencyPipe.transform(
          this.estimateSummary.CoolantUsage.HourlyFillPrice.Cost.Amount,
          this.estimateSummary.CoolantUsage.HourlyFillPrice.Cost.CurrencyCode
        );
        this.fuelUsageCalculating = true;
      }
    }
    if (!this.selectedLabor) {
      this.selectedLabor = this.laborList[0];
      this.includecoolantForm.controls.labor.setValue(this.selectedLabor);
      this.laborValue = this.selectedLabor;
    }
  }

  valueChange(event) {
    if (this.includecoolantForm.valid) {
      this.coolantValueChanged.next({ isCoolantChanged: true });
    }
  }

  capacityChange(event) {
    this.capacityWithoutRounding = event.value;
    if (this.includecoolantForm.valid) {
      this.coolantValueChanged.next({ isCoolantChanged: true });
    }
  }

  laborChange(event) {
    if (this.includecoolantForm.valid) {
      this.coolantValueChanged.next({ isCoolantChanged: true });
    }
    this.laborValue = event.value;
  }

  coolantChange(event) {
    this.coolantTypeLoading = true;
    this.save = true;
    this.coolantValue = event.value;
    this.fluidtypeCorrelationId = event.value.CorrelationId;
    this.estimateService
      .getFluidDetails(this.coolantValue, this.planNumber)
      .subscribe(
        (response) => {
          if (response && response.FluidPartPriceItem && response.IsSuccess) {
            let data = response.FluidPartPriceItem;
            this.selectedFuel = data;
            this.coolantTypeLoading = false;
            this.sellWithoutRounding = data.Pricing.Sell.Amount;
            this.includecoolantForm.controls.sell.setValue(
              data.Pricing.Sell.Amount
            );
            this.currencyCode = data.Pricing.Sell.CurrencyCode;
            this.costWithoutRounding = data.Pricing.Cost.Amount;
            this.includecoolantForm.controls.cost.setValue(
              data.Pricing.Cost.Amount
            );
            this.coolantCurrencySymbol = getCurrencySymbol(
              this.currencyCode,
              "wide"
            );
            if (this.usStandardUnit) {
              this.setCoolantSellValue();
              this.setCoolantCostValue();
            }
            this.setSellAndCostLabels();
            if (this.includecoolantForm.valid) {
              this.save = false;
              this.coolantValueChanged.next({ isCoolantChanged: true });
            }
          } else {
            this.handleErrorMessage(response);
          }
        },
        (err) => {
          this.coolantTypeLoading = false;
        }
      );
  }
  sellOrCostValueChange(event, field) {
    if (field === "cost") {
      this.costWithoutRounding = event.value;
    } else {
      this.sellWithoutRounding = event.value;
    }
    if (this.includecoolantForm.valid) {
      this.coolantValueChanged.next({ isCoolantChanged: true });
    }
  }

  close() {
    this.activeModal.close({ data: { isSaveClicked: false } });
  }
  inputJsonformat() {
    return {
      UsageData: {
        CorrelationId: this.fluidtypeCorrelationId,
        IncludeCoolant: true,
        ChangeInterval: this.includecoolantForm.value.changeInterval,
        Capacity: this.usStandardUnit
          ? Number(this.capacityWithoutRounding) * 3.785
          : Number(this.capacityWithoutRounding),
        CoolantType: {
          Number: this.includecoolantForm.value.coolantType.Number,
          SourceOfSupply:
            this.includecoolantForm.value.coolantType.SourceOfSupply,
          PartTypeID: this.includecoolantForm.value.coolantType.PartTypeID,
        },
        Labor: {
          LaborCode: this.includecoolantForm.value.labor.LaborCode,
          StoreCode: this.includecoolantForm.value.labor.LaborCode,
        },
      },
      FluidType: "Coolant",
      Price: {
        Number: this.coolantValue.Number,
        SourceOfSupply: this.coolantValue.SourceOfSupply,
        MarketingOrganization: this.coolantValue.MarketingOrganization,
        SalesArea: this.coolantValue.SalesArea,
        CustomerCode: this.coolantValue.CustomerCode,
        PartTypeID: this.coolantValue.PartTypeID,
        Pricing: {
          Cost: {
            Amount: this.usStandardUnit
              ? Number(this.costWithoutRounding) / 3.785
              : Number(this.costWithoutRounding),
            CurrencyCode: this.currencyCode,
          },
          Sell: {
            Amount: this.usStandardUnit
              ? Number(this.sellWithoutRounding) / 3.785
              : Number(this.sellWithoutRounding),
            CurrencyCode: this.currencyCode,
          },
        },
      },
    };
  }
  submitForm() {
    this.save = true;
    if (this.includecoolantForm.valid) {
      const objDiv = document.getElementById("coolant-container");
      objDiv.scrollTop = objDiv.scrollHeight;
      this.isLoading = true;
      this.fuelUsageCalculating = true;
      this.estimateService
        .calculatecoolantUsage(this.inputJsonformat(), this.planNumber)
        .subscribe(
          (data) => {
            if (data && data.IsSuccess) {
              this.showButtons = true;
              this.save = false;

              this.coolantData = data;
              this.estimateService.setEstimateEditResponse(data);
              this.coolantusage = data.FilledQuantityInLiters;
              this.usageWithoutRounding = data.FilledQuantityInLiters;
              if (this.usStandardUnit) {
                this.setCoolantUsage();
              }
              this.coolantSell = this.currencyPipe.transform(
                data.FillPrice.Sell.Amount,
                data.FillPrice.Sell.CurrencyCode
              );
              this.coolantCost = this.currencyPipe.transform(
                data.FillPrice.Cost.Amount,
                data.FillPrice.Cost.CurrencyCode
              );
              this.sellperHour = this.currencyPipe.transform(
                data.HourlyFillPrice.Sell.Amount,
                data.HourlyFillPrice.Sell.CurrencyCode
              );
              this.costperHour = this.currencyPipe.transform(
                data.HourlyFillPrice.Cost.Amount,
                data.HourlyFillPrice.Sell.CurrencyCode
              );
              this.coolantSellkwHrConsumed = this.unitPipe.transform(
                data.HourlyFillPrice.Sell.Amount,
                this.estimateService.ratedPower,
                "KWhr",
                true,
                data.HourlyFillPrice.Sell.CurrencyCode
              );
              this.coolantCostkwHrConsumed = this.unitPipe.transform(
                data.HourlyFillPrice.Cost.Amount,
                this.estimateService.ratedPower,
                "KWhr",
                true,
                data.HourlyFillPrice.Cost.CurrencyCode
              );
              this.fuelUsageCalculating = true;
              this.isLoading = false;
            } else {
              this.handleErrorMessage(data);
            }
          },
          (err) => {
            this.isLoading = false;
          }
        );
    }
  }
  submitCoolant() {
    this.submit = true;
    const value = {};
    const formValidObj = {
      formName: "Include Coolant - Pricing Screen",
      formContent: this.includecoolantForm.value.Capacity,
      formContent2: null,
      formStatus: "success",
    };
    if (this.includecoolantForm.valid) {
      this.isLoading = true;
      this.savingIndicator = true;
      this.displaySaveButton = false;
      this.submit = false;
      this.includeCoolant(value);
      formValidObj.formContent2 = value;
      this.estimateService
        .editEstimateAndApply(this.planNumber, this.submitcoolantPayload())
        .subscribe(
          (data) => {
            this.savingIndicator = false;
            this.displaySaveButton = true;
            this.estimateService.setEstimateEditResponse(data);
            this.activeModal.close({
              data: { isSaveClicked: true, responseData: data },
            });
            this.snackBar.open(
              this.translate.instant("estimateEditSuccess"),
              "",
              {
                duration: 4000,
                panelClass: ["status-snack-bar"],
              }
            );
          },
          (err) => {
            this.submitCoolantError();
          }
        );
      this.subjectService.setFormChangesStream({
        isFormSubmitted: true,
        formValue: formValidObj,
      });
    }
  }

  submitcoolantPayload() {
    const fluidOverride = JSON.parse(
      JSON.stringify(this.estimateHeader.FactorOverrides.FluidPartPrices)
    );
    fluidOverride.FluidPartPriceItems.forEach((el, index, array) => {
      if (el.FluidType === "Coolant") {
        array.splice(index, 1);
      }
    });
    const overrideFluid = JSON.parse(
      JSON.stringify(_.omit(this.selectedFuel, "CorrelationId"))
    );
    overrideFluid.Pricing = {
      Sell: {
        Amount: this.usStandardUnit
          ? Number(this.sellWithoutRounding) / 3.785
          : Number(this.sellWithoutRounding),
        CurrencyCode: this.selectedFuel.Pricing.Sell.CurrencyCode,
      },
      Cost: {
        Amount: this.usStandardUnit
          ? Number(this.costWithoutRounding) / 3.785
          : Number(this.costWithoutRounding),
        CurrencyCode: !this.isUserOrgIntermediate
          ? this.selectedFuel.Pricing.Cost.CurrencyCode
          : this.selectedFuel.Pricing.Sell.CurrencyCode,
      },
    };
    if (this.isUserOrgIntermediate) {
      this.laborValue.LaborRate.Cost.CurrencyCode =
        this.laborValue.LaborRate.Sell.CurrencyCode;
    }
    fluidOverride.FluidPartPriceItems.push(overrideFluid);
    return [
      {
        Capacity: this.usStandardUnit
          ? Number(this.capacityWithoutRounding) * 3.785
          : Number(this.capacityWithoutRounding),
        ChangeInterval: Number(this.includecoolantForm.value.changeInterval),
        CoolantType: {
          Number: this.includecoolantForm.value.coolantType.Number,
          SourceOfSupply:
            this.includecoolantForm.value.coolantType.SourceOfSupply,
          PartTypeID: this.includecoolantForm.value.coolantType.PartTypeID,
          FluidType: this.coolantValue.FluidType,
        },
        CorrelationId: this.correlationId,
        IncludeCoolant: true,
        Labor: {
          LaborCode: this.laborValue.LaborCode,
          StoreCode: this.laborValue.StoreCode,
          CustomerNumber: this.laborValue.CustomerNumber,
          CustomerClass: this.laborValue.CustomerClass,
          CostCenter: this.laborValue.CostCenter,
          LaborRate: this.laborValue.LaborRate,
        },
      },
      fluidOverride,
    ];
  }
  displayTable() {
    if (this.fuelUsageCalculating) {
      return true;
    }
  }
  ngOnDestroy() {
    this.onDestroy.next(true);
    this.onDestroy.complete();
  }

  unitOfMeasureChange(e) {
    this.setUnitOfMeasure(e);
    this.setSellAndCostLabels();
    this.setCapacity();
    this.setCoolantCostValue();
    this.setCoolantSellValue();
    this.setCoolantUsage();
  }

  setUnitOfMeasure(unit) {
    if (unit === "Metric") {
      this.usStandardUnit = false;
    } else {
      this.usStandardUnit = true;
    }
  }

  setSellAndCostLabels() {
    if (this.coolantCurrencySymbol) {
      if (this.usStandardUnit) {
        this.coolantSellLabel =
          this.translate.instant("coolant_sell_per_gallon") +
          " (" +
          this.coolantCurrencySymbol +
          ")";
        this.coolantCostLabel =
          this.translate.instant("coolant_cost_per_gallon") +
          " (" +
          this.coolantCurrencySymbol +
          ")";
      } else {
        this.coolantSellLabel =
          this.translate.instant("coolant_sell_per_liter") +
          " (" +
          this.coolantCurrencySymbol +
          ")";
        this.coolantCostLabel =
          this.translate.instant("coolant_cost_per_liter") +
          " (" +
          this.coolantCurrencySymbol +
          ")";
      }
    } else {
      if (this.usStandardUnit) {
        this.coolantSellLabel = this.translate.instant(
          "coolant_sell_per_gallon"
        );
        this.coolantCostLabel = this.translate.instant(
          "coolant_cost_per_gallon"
        );
      } else {
        this.coolantSellLabel = this.translate.instant(
          "coolant_sell_per_liter"
        );
        this.coolantCostLabel = this.translate.instant(
          "coolant_cost_per_liter"
        );
      }
    }
  }

  setCapacity() {
    if (this.includecoolantForm.controls.capacity.errors) {
      return;
    }
    if (this.usStandardUnit) {
      this.capacityWithoutRounding = Number(
        this.capacityWithoutRounding / 3.785
      );
      this.includecoolantForm.controls.capacity.setValue(
        this.capacityWithoutRounding.toFixed(2)
      );
    } else {
      this.capacityWithoutRounding = Number(
        this.capacityWithoutRounding * 3.785
      );
      this.includecoolantForm.controls.capacity.setValue(
        this.capacityWithoutRounding.toFixed(2)
      );
    }
  }

  setCoolantSellValue() {
    if (!this.includecoolantForm.controls.sell.errors) {
      if (this.usStandardUnit) {
        this.sellWithoutRounding = Number(this.sellWithoutRounding) * 3.785;
        this.includecoolantForm.controls.sell.setValue(
          this.sellWithoutRounding.toFixed(2).toString()
        );
      } else {
        this.sellWithoutRounding = Number(this.sellWithoutRounding) / 3.785;
        this.includecoolantForm.controls.sell.setValue(
          this.sellWithoutRounding.toFixed(2).toString()
        );
      }
    }
  }

  setCoolantCostValue() {
    if (!this.includecoolantForm.controls.cost.errors) {
      if (this.usStandardUnit) {
        this.costWithoutRounding = Number(this.costWithoutRounding) * 3.785;
        this.includecoolantForm.controls.cost.setValue(
          this.costWithoutRounding.toFixed(2).toString()
        );
      } else {
        this.costWithoutRounding = Number(this.costWithoutRounding) / 3.785;
        this.includecoolantForm.controls.cost.setValue(
          this.costWithoutRounding.toFixed(2).toString()
        );
      }
    }
  }

  setCoolantUsage() {
    if (this.usStandardUnit) {
      this.usageWithoutRounding = this.usageWithoutRounding / 3.785;
      this.coolantusage = Number(this.usageWithoutRounding)
        .toFixed(2)
        .toString();
    } else {
      this.usageWithoutRounding = this.usageWithoutRounding * 3.785;
      this.coolantusage = Number(this.usageWithoutRounding)
        .toFixed(2)
        .toString();
    }
  }

  laborDataSelected(event) {
    this.includecoolantForm.controls.labor.setValue(event.value);
    this.laborChange(event);
  }
  submitCoolantError() {
    this.isLoading = false;
    this.savingIndicator = false;
    this.displaySaveButton = true;

    this.snackBar.open(this.translate.instant("serverErr"), "", {
      duration: 4000,
      panelClass: ["status-snack-bar"],
    });
  }
  includeCoolant(value) {
    Object.keys(this.includecoolantForm.value).forEach((key, index, array) => {
      if (this.includecoolantForm.value[key] !== null) {
        if (key == "Capacity") {
          value =
            index < array.length - 1
              ? value + this.includecoolantForm.value[key] + " | "
              : value + this.includecoolantForm.value[key];
        } else {
          value =
            index < array.length - 1
              ? value + "User Entered Value | "
              : value + "User Entered Value";
        }
      }
    });
  }

  handleErrorMessage = (response) => {
    let hasMultipleErrors = response.FeedbackItems.length > 1;
    if (hasMultipleErrors) {
      let errorMessage = this.translate.instant("serverErr");
      this.showSnackBar(errorMessage);
    } else {
      let errorMessage = response.FeedbackItems[0].Message;
      this.showErrorMsg(errorMessage);
    }
  };

  showErrorMsg = (msg) => {
    let modalProps: {} = {
      centered: true,
      windowClass: "confirm-modal",
      backdropClass: "confirm-modal-backdrop",
      backdrop: "static",
      keyboard: false,
    };
    const modalRef = this.modalService.open(ConfirmAlertComponent, modalProps);
    modalRef.componentInstance.isMessage = true;
    modalRef.componentInstance.noCloseIcon = true;
    modalRef.componentInstance.timeFrame = true;
    modalRef.componentInstance.alertMessage = msg;
    modalRef.componentInstance.buttons = [
      {
        text: this.translate.instant("confirmOk"),
        cssClass: ["cat-btn-primary"],
        handler: (modal) => {
          modal.close(false);
        },
      },
    ];
  };

  showSnackBar = (message) => {
    this.snackBar.open(message, "", {
      duration: 4000,
      panelClass: ["status-snack-bar"],
    });
  };
}
