import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(value: any, propertyName?: any, arg2?: any): any {
    if (value){
      if (propertyName){
        return value.sort((a, b) => {
          let x = this.toUpperCaseIfNotNumberOrBoolean(a,propertyName);
          let y = this.toUpperCaseIfNotNumberOrBoolean(b,propertyName);
          const pattern = /^\d*(\.\d+)?$/;
          x = this.changeToNumber(x,pattern,arg2);
          y = this.changeToNumber(y,pattern,arg2);
          return this.checkTypeOf(x, y);
        });
      }
      else{
        return value.sort((a, b) => {
          let x = this.toUpperCaseIfNotNumberOrBoolean(a);
          let y = this.toUpperCaseIfNotNumberOrBoolean(b);
          const pattern = /^[1-9]\d*(\.\d+)?$/;
          x = this.changeToNumber(x,pattern,arg2);
          y = this.changeToNumber(y,pattern,arg2);
          return this.checkTypeOf(x, y);
        });
      }
    }
  }

  checkTypeOf(x, y) {
    if (typeof x === 'number' && typeof y !== 'number'){
      return -1;
    }
    if (typeof y === 'number' && typeof x !== 'number'){
      return 1;
    }
    if (x > y) {
      return 1;
    }
    if (x < y) {
      return -1;
    }
    return 0;
  }

  toUpperCaseIfNotNumberOrBoolean(element: any,propertyName?: any) {
    if(propertyName) {
      return ((typeof element[propertyName] !== 'number') && (typeof element[propertyName] !== 'boolean')) ? element[propertyName].toUpperCase() : element[propertyName];
    } 
    else {
      return ((typeof element !== 'number') && (typeof element !== 'boolean')) ? element.toUpperCase() : element;
    }
  }

  changeToNumber(element: any, pattern: RegExp, arg2: any) {
    if (element.match(pattern) && !arg2){
      element = Number(element);
    }
    return element;
  }

}
