import { ScrollStrategy } from '@angular/cdk/overlay';
import { ComponentFactoryResolver, ViewContainerRef } from '@angular/core';

/**
 * Modal's Position
 */
export interface ModalPosition {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
}

export class CcModalConfig<D = any> {
  /**
   * Where the attached component should live in Angular's *logical* component tree.
   * This affects what is available for injection and the change detection order for the
   * component instantiated inside of the dialog. This does not affect where the dialog
   * content will be rendered.
   */

  viewContainerRef?: ViewContainerRef;


  /**custom modal class    */
  panelClass ? = 'cc-modal-panel';

  type ? = 'modal';

  /** Enable / Disable close modal on backdrop click */
  hasBackdrop ? = true;

  /** Custom class for the backdrop  */
  backdropClass ? = 'cc-modal-backdrop';

  /** Enable / Disable close modal on Esc button click   */
  closeOnEsc ? = false;

  /** custom id for modal */
  id?: string;

  /** role of the modal element  */
  role?: 'modal';

  /** with of the modal  */
  width ? = '';

  /** height of the modal   */
  height ? = '';

  /** min-width of the modal */
  minWidth?: number | string;

  /** min-height of the modal */
  minHeight?: number | string;

  /** max-width of the modal */
  maxWidth?: number | string = '80vw';

  /** max-height of the modal  */
  maxHeight?: number | string;

  /** position for the  modal  */
  position?: ModalPosition;

  /** custome data to bind the modal window  */
  data?: D | null = null;

  /**  */
  scrollStrategy?: ScrollStrategy;

  /**  */
  hasScroll ? = true;

  /**  */
  closeOnNavigation ? = true;

  /**  */
  autoFocus ? = true;

  /** */
  onTimeout ? = 3000;

  /**  */
  componentFactoryResolver?: ComponentFactoryResolver;
}
