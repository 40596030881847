import {
  Directive,
  HostListener,
  ElementRef,
  ChangeDetectorRef,
  ComponentFactoryResolver,
  Injector,
  OnDestroy,
  EventEmitter,
  Output,
  Input
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CcInputField } from '@app/components/input-field/input-field.component';
import { FocusMonitor } from '@angular/cdk/a11y';
import { UP_ARROW, DOWN_ARROW, DELETE, ZERO, NINE, TAB } from '@angular/cdk/keycodes';
export class CcInputChange {
  source: CcInputField;
  value: string;
}
const keyCode = { period: 190, thirtyone: 31 };
@Directive({
  selector: '[input-field]',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: CCInputFieldDirective, multi: true }]
})
export class CCInputFieldDirective implements ControlValueAccessor, OnDestroy {
  private inputFieldComponent = this.componentFactoryResolver.resolveComponentFactory(CcInputField);
  private inputFieldComponentRef = this.inputFieldComponent.create(this.injector);
  @Input() disabled;
  @Input() inputtype;
  @Input() limitTo;

  @Output()
  valueChange: EventEmitter<CcInputChange> = new EventEmitter<CcInputChange>();

  constructor(
    private _elementRef: ElementRef,
    private injector: Injector,
    public cd: ChangeDetectorRef,
    public focusMonitor: FocusMonitor,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.focusMonitor.monitor(this._elementRef.nativeElement, true).subscribe(response => {
      if (response && (response === 'mouse' || response === 'keyboard') && this.disabled !== true) {
        this.onInputFocus(this._elementRef.nativeElement);
      }
    });
  }

  protected registerOnChangefn = (_: any) => { };
  protected registerOnTouchfn = () => { };
  @HostListener('change', ['$event'])
  @HostListener('keyup', ['$event'])
  onValueChange($event) {
    let fieldvalue;
    if (this.disabled === true || $event.keyCode === UP_ARROW || $event.keyCode === DOWN_ARROW || $event.keyCode === TAB) {
      $event.preventDefault();
      $event.stopImmediatePropagation();
      return;
    }
    fieldvalue = $event.target.value;
    if (fieldvalue !== undefined) {
      this.inputFieldComponentRef.instance.inputValue = fieldvalue;
      this.registerOnChangefn(this.inputFieldComponentRef.instance.inputValue);
      const event = new CcInputChange();
      event.value = this.inputFieldComponentRef.instance.inputValue;
      this.valueChange.emit(event);
    }

  }

 

  @HostListener('touchstart', ['$event']) @HostListener('focus', ['$event']) onInputFocus($event) {
    if (this.disabled === true) {
      $event.preventDefault();
      $event.stopImmediatePropagation();
      return;
    }
    const FocusElement: HTMLElement = this._getHostElement();
    FocusElement.classList.remove('cc-text-present');
    FocusElement.classList.add('cc-floating-label');
    this.cd.markForCheck();
  }
  @HostListener('focusout', ['$event']) onInputBlur(value) {
    const FocusElement: HTMLElement = this._getHostElement();
    FocusElement.classList.remove('cc-floating-label');
    if (this.inputFieldComponentRef.instance.inputValue || this.inputFieldComponentRef.instance.inputValue === 0) {
      FocusElement.classList.add('cc-text-present');
      this.cd.markForCheck();

      return;
    } else {
      FocusElement.classList.remove('cc-text-present');
    }
    this.registerOnTouchfn();
    this.cd.markForCheck();
  }
  @HostListener('keydown', ['$event'])
  @HostListener('keypress', ['$event'])
  operationsOnNumbers($event) {
    const exclude = /Backspace|Enter|Tab|Delete|Del|ArrowUp|Up|ArrowDown|Down|ArrowLeft|Left|ArrowRight|Right/;
    if (!exclude.test($event.key) && $event.target.value !== undefined) {
      if (document.getSelection().toString() === '') {
        if ($event.target.value.length === this.limitTo) { $event.preventDefault(); }
      }
    }
    if (this.inputtype === 'number') {
      if ($event.keyCode === DOWN_ARROW) {
        $event.preventDefault();
      }
      if ($event.keyCode === UP_ARROW) {
        $event.preventDefault();
      }
      const charCode = $event.which ? $event.which : $event.keyCode;
      if (
        charCode === keyCode.period &&
        charCode !== DELETE &&
        charCode > keyCode.thirtyone &&
        (charCode < ZERO || charCode > NINE)
      ) {
        return false;
      }
      if (!exclude.test($event.key) && charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)) {
        return false;
      }
      return true;
    }
    const FocusElement: HTMLElement = this._getHostElement();
    FocusElement.classList.remove('cc-text-present');
    FocusElement.classList.add('cc-floating-label');
    this.cd.markForCheck();
  }

  ngOnDestroy() {
    this.focusMonitor.stopMonitoring(this._elementRef.nativeElement);
  }

  decrement() {
    --this.inputFieldComponentRef.instance.inputValue;
    this.registerOnChangefn(this.inputFieldComponentRef.instance.inputValue);
  }
  increment() {
    ++this.inputFieldComponentRef.instance.inputValue;
    this.registerOnChangefn(this.inputFieldComponentRef.instance.inputValue);
  }

  writeValue(val: string) {
    this.inputFieldComponentRef.instance.inputValue = val;
    this.cd.markForCheck();
  }

  registerOnChange(fn) {
    this.registerOnChangefn = fn;
  }

  registerOnTouched(fn) {
    this.registerOnTouchfn = fn;
  }
  _getHostElement(): HTMLElement {
    return this._elementRef.nativeElement.children[0];
  }
}
