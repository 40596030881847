import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CCInputFieldDirective } from '@app/components/input-field/input-field.directive';
import { CcInputField } from '@app/components/input-field/input-field.component';
import { CcInputFieldRequiredValidator } from '@app/components/input-field/input-field-required-validator';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TextFieldModule, TranslateModule],
  declarations: [CcInputField, CCInputFieldDirective, CcInputFieldRequiredValidator],
  exports: [CcInputField, CCInputFieldDirective],
  entryComponents: [CcInputField],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CcInputFieldModule {}
