import { ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubjectService } from '@app/services/data/subject.service';
import { EstimatePricingService } from '@app/services/estimate-pricing/estimate-pricing.service';
import { EnginePerformanceService } from '@app/services/workgroup/engineperformance.service';
import { WorkgroupService } from '@app/services/workgroup/workgroup.service';
import { DataService } from '@app/services/shared/storage/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AddEditEnginePerformanceComponent } from '../add-edit-engine-performance/add-edit-engine-performance.component';
import { ProductDetailsViewComponent } from '@app/estimate/product/product-details-view/product-details-view.component';
import * as constants from '@app/services/shared/app-constants';
import _ from 'lodash';
import { ProductDetails } from '@app/services/models/misc/general';
import { Subscription } from 'rxjs';
import { SelectEngineSpecificationComponent } from './select-engine-specification/select-engine-specification.component';
import { CreateNewPDSComponent } from '../add-edit-engine-performance/create-new-pds/create-new-pds.component';
import { takeUntil } from 'rxjs/operators';
import { log } from 'console';


@Component({
  selector: 'app-add-edit-engine-performance-global',
  templateUrl: './add-edit-engine-performance-global.component.html',
  styleUrls: ['../add-edit-engine-performance/add-edit-engine-performance.component.scss','./add-edit-engine-performance-global.component.scss']
})
/** 
 * This component is a child component of AddEditEnginePerformanceComponent. 
 */
export class AddEditEnginePerformanceGlobalComponent extends AddEditEnginePerformanceComponent {
  productDetails: ProductDetails = new ProductDetails();
  @Input() pdsIdListData1;
  data: ProductDetails = new ProductDetails();
  uploadPeekSOCSubscribe: Subscription;
  showPeakError: boolean;
  peakErrorMsg: string;
  disableEngSpecInputs = {
    aggmtNo: true,
    power: true,
    priceAggnt: true,
    speed: true,
    ot: true
  };
  isProductDetailsLoading: boolean;
  public compFilterCtrl: FormControl = new FormControl();
  isProductDetailsAPIError: boolean;
  isProductDetailsNotFound: boolean;
  isCommercialEngineEmpty: boolean;
  tmiData;
  retrieveTMIData;
  noRecords = false;
  wgDisplayedColumns1: string[] = ['Engine Speed','Engine BKW','Generator Efficiency(%)','BSFC G/Kw-Hr','Fuel Rate L/Hr','Engine EKW'];
  pdsIdList1: any[];
  retrieveTmiTableData = [];
  selectedPDSData: any;
  isRefresh: boolean;
  pdsIdAdded = false;
  /** 
   * This component is a child component of AddEditEnginePerformanceComponent. 
   */
  constructor(public dialogRef: MatDialogRef<AddEditEnginePerformanceGlobalComponent>, @Inject(MAT_DIALOG_DATA) public permData,
  public fb: FormBuilder, public ref: ChangeDetectorRef, public subjectService: SubjectService, public dialog: MatDialog,
  public workgroupService: WorkgroupService, public translate: TranslateService,
  public estimatePricingService: EstimatePricingService, public snackBar: MatSnackBar,
  public enginePerformanceService: EnginePerformanceService, public dataService: DataService,
  public modalService: NgbModal) {
    
    
    super(dialogRef,permData,fb,ref,subjectService,dialog,workgroupService,translate,estimatePricingService,snackBar,enginePerformanceService,dataService,modalService);
   
  }
  ngAfterViewInit() {
    // initialization logic here
  
    this.pdsIdList1=this.pdsIdListData1
    this.compFilterCtrl.valueChanges
    .pipe(takeUntil(this.onDestroy))
    .subscribe(() => {
      this.filterMyOptions();
    }); 
  }

  
  filterMyOptions() {
    const search = this.compFilterCtrl.value.toLowerCase();
    const res = [];
    if (!search) {
      this.pdsIdListData1 = this.pdsIdList1;
    } else {
      this.pdsIdList  = this.pdsIdList1;
      let componentsTemp = [];
      let componentsTempNew = [];
      componentsTemp = this.pdsIdList;
      componentsTempNew = componentsTemp.filter((cws) => (cws.toLowerCase().includes(search)) );
    this.pdsIdListData1 = componentsTempNew;
     }

  }
  
  productSearchChange() {
    this.isProductDetailsAPIError = false;
    this.showSearchError = false;
    this.isProductDetailsNotFound = false;

    const val = this.searchText;
    if (val === '' || val === null) {
      this.prefixError = true;
    } else {
      if (String(val).match(constants.numberAplhabetsRegex)) {
        this.prefixError = false;
      } else {
        this.prefixError = true;
      }
    }
    this.searchError();
  }
 // this method currently removed manufacturername from if condition to make disableSearch false
  searchError() {
    if (!this.searchText) {
      this.disableSearch = true;
    }
    else if (this.prefixError) {
      this.disableSearch = true;
    }
    else if ((this.searchText.length === 3 || this.searchText.length === 8)) {
      this.disableSearch = false;
    }
    else {
      this.disableSearch = true;
    }
  }

  changeProduct(keepSearchText?: boolean) {
    this.cancelSearch(keepSearchText);
    this.permcDataForm.controls.aggmtNo.reset();
    this.permcDataForm.controls.power.reset();
    this.permcDataForm.controls.priceAggnt.reset();
    this.permcDataForm.controls.speed.reset();
    this.permcDataForm.controls.ot.reset();
    this.permcDataForm.controls.model.reset();
    this.permcDataForm.controls.manufacturer.reset();
    this.permcDataForm.controls.serialPrefix.reset();
  }

  cancelSearch(keepSearchText?: boolean) {
    this.productDetails.flag = false;
    this.data = new ProductDetails();
    this.productDetails = new ProductDetails();
    this.isProductDetailsNotFound = false;
    this.isCommercialEngineEmpty = false;
    this.isProductDetailsAPIError = false;
    if(this.prefixAPISubscribe) {
      this.prefixAPISubscribe.unsubscribe();
    }
    this.isProductDetailsLoading = false;
    this.disableSearch = false;
    this.placeHolderValue = this.translate.instant('lbl_search_prefix');
    if(!keepSearchText) {
      this.searchText = '';
    }
    this.searchError();
    if(!keepSearchText) {
      this.searchText = null;
    }
  }

  search(event) {
    this.changeProduct(true);
    this.isProductDetailsLoading = true;
    this.isProductDetailsAPIError = false;
    this.showSearchError = false;
    this.isProductDetailsNotFound = false;
    this.isCommercialEngineEmpty = false;
    event.preventDefault();

    const isCatEquipment: any = {
      isCatEquipment:
        this.selectedManufacturer.Identifier === 'AA' ? true : false,
      selectedManufacturerCode: this.selectedManufacturer.Identifier,
    };
    this.prefixAPISubscribe = this.estimatePricingService.getProductSearchData(this.searchText, isCatEquipment).subscribe((data) => {
      this.dataCheckCall(data);
    }, err => {
      if (err.status === 500 || err.status === 503) {
        this.isProductDetailsNotFound = true;
      } else {
        this.isProductDetailsAPIError = true;
      }
      this.isProductDetailsLoading = false;
    });
  }

  dataCheckCall(data){
    if (data?.ProductInfo?.length > 0 && data?.ProductInfo[0].CommercialEngines?.length > 0) {
      this.productInfoCall(data);
    } else {
      this.isProductDetailsNotFound = true;
      this.isProductDetailsLoading = false;
      this.showSearchError = true;
      this.failureList = [];
      this.failureList = data.Failures;
     // this.handleErrorMessage(data);
    }
  }

  productInfoCall(data){
    if (data?.ProductInfo.length > 1) {
      data = data.ProductInfo;
      this.productPopup(data);
    }
    else {
      data = data.ProductInfo[0];
      this.checkForErrorAndAssignFormValues(data);
    }
  }

  async checkForErrorAndAssignFormValues(data: any) {
    
    if (data.Manufacturer === null && data.Family === null && data.Model === null &&
      data.PrefixAndSerialNumbers === null) {
      this.isProductDetailsNotFound = true;
      this.isProductDetailsLoading = false;
      this.isProductDetailsAPIError = false;
      return;
    }
    const tempRange = [];
    this.isProductDetailsAPIError = false;
    this.isProductDetailsLoading = false;
    if (data.CommercialEngines.length > 0) {
      this.setFormValues('model',data.Model.SalesModel);
      this.setFormValues('serialPrefix',data.PrefixAndSerialNumbers.Prefix);
      this.setFormValues('manufacturer',{ Identifier: data.Manufacturer.Identifier, Name: data.Manufacturer.Name });
      tempRange.push(data.PrefixAndSerialNumbers.Range.Low + '-' + data.PrefixAndSerialNumbers.Range.High);
      this.productDetails = {
        manufacturer: { Identifier: data.Manufacturer.Identifier, Name: data.Manufacturer.Name },
        family: { Identifier: data.Family.Identifier, Name: data.Family.Name },
        model: data.Model.SalesModel,
        serialPrefix: data.PrefixAndSerialNumbers.Prefix,
        serialRange: data.PrefixAndSerialNumbers.Range.Low + '-' + data.PrefixAndSerialNumbers.Range.High,
        rangeList: tempRange,
        flag: true,
        serialNumber: this.searchText,
        commercialEngine: null,
        commercialEngineData: data.CommercialEngines,
        CCRTypes: data.PrefixAndSerialNumbers.CCRTypes
      };
      this.data = _.cloneDeep(this.productDetails);
      data.CommercialEngines.forEach((engine) => {
        engine.PowerRatingKW = engine.PowerRating + 'KW';
        engine.SpeedRatingRPM = engine.SpeedRating + 'RPM';
      });
      this.isCommercialEngineEmpty = false;
        
        this.setFormValues('aggmtNo',data.CommercialEngines[0].Arrangement);
        this.setFormValues('power',data.CommercialEngines[0].PowerRating);
        this.setFormValues('priceAggnt',data.CommercialEngines[0].PriceList);
        this.setFormValues('speed',data.CommercialEngines[0].SpeedRating);
        this.setFormValues('ot',data.CommercialEngines[0].TestSpecification);
      
      
      const modalRef = this.activeDrawerAddNew = this.modalService.open(SelectEngineSpecificationComponent, 
        { 
          centered: true,
          backdrop: "static",
          keyboard: false,
          windowClass: 'engine-spec-view-popup',
          backdropClass: 'engine-spec-view-backdrop'
        } 
      );
      modalRef.componentInstance.data = data.CommercialEngines;
      await modalRef.result.then(
        (engineSpecification) => {
          this.setFormValues('aggmtNo',engineSpecification.Arrangement);
          this.setFormValues('power',engineSpecification.PowerRating);
          this.setFormValues('priceAggnt',engineSpecification.PriceList);
          this.setFormValues('speed',engineSpecification.SpeedRating);
          this.setFormValues('ot',engineSpecification.TestSpecification);
         // let pdsData = [];
          this.tmiTableData = engineSpecification.engineData.EngineBaseData;
          // engineSpecification.engineData.EngineBaseData.forEach(a=>{
          //   pdsData.push(a.DataHeader.PdsID);
          // })
          // this.pdsIdListData1 = pdsData;
          
          // this.pdsIdList1.forEach(a=>{
          //   if(this.permcDataForm.controls.pdsId.value == a.value){
          //     this.valueAdded = true;
          //   }
          //   else{
          //     this.valueAdded = false;
          //   }
          // })
        }
      ).catch(() => {
        // engine specifications not selected
      });
    }
    else {
      this.isCommercialEngineEmpty = true;
    }
  }

  async productPopup(data) {
    const modalRef = this.productDetailsActiveModal = this.modalService.open(ProductDetailsViewComponent, { centered: true, windowClass: 'product-view-popup', backdrop: 'static', backdropClass: 'engine-spec-view-backdrop' });
    modalRef.componentInstance.productData = data;
    modalRef.result.then(async data => {
      this.cancelSearch();
      if (data) {
        this.checkForErrorAndAssignFormValues(data);
      }
    });
  }

  setFormValues(formControlName: string, formControlValue: any) {
    switch (formControlName) {
      case 'aggmtNo':
        this.permcDataForm.controls.aggmtNo.setValue(formControlValue);
        this.checkForInvalidFormControl(formControlName);
        break;        
      case 'power':
        this.permcDataForm.controls.power.setValue(formControlValue);
        this.checkForInvalidFormControl(formControlName);
        break;      
      case 'priceAggnt':
        this.permcDataForm.controls.priceAggnt.setValue(formControlValue);
        this.checkForInvalidFormControl(formControlName);
        break;      
      case 'speed':
        this.permcDataForm.controls.speed.setValue(formControlValue);
        this.checkForInvalidFormControl(formControlName);
        break;      
      case 'ot':
        this.permcDataForm.controls.ot.setValue(formControlValue);
        this.checkForInvalidFormControl(formControlName);
        break;      
      case 'model':
        this.permcDataForm.controls.model.setValue(formControlValue);
        this.checkForInvalidFormControl(formControlName);
        break;      
      case 'manufacturer':
        this.permcDataForm.controls.manufacturer.setValue(formControlValue);
        this.checkForInvalidFormControl(formControlName);
        break;      
      case 'serialPrefix':
        this.permcDataForm.controls.serialPrefix.setValue(formControlValue);
        this.checkForInvalidFormControl(formControlName);
        break;        
    }
  }

  checkForInvalidFormControl(formControlName: string) {
    if(this.permcDataForm.controls[formControlName].invalid) {
      this.permcDataForm.controls[formControlName].reset();
      this.ref.detectChanges();
    }
  }

  checkSerial(): boolean { 
   if(this.permcDataForm.status == "VALID"){
    return false;
   }
   else{
    return true;
   }
  }
  tmi(){
    this.additionDatacheck();
    if(!this.isconsumptionError) {
    this.tmiData = true;
    let value = this.permcDataForm.controls.pdsId.value;
     this.selectedPDSData = this.tmiTableData.filter(a=>{
      return a.DataHeader.PdsID == value
    })
   }
  }

  retrieveTMI(){
    if(this.selectedPDSData?.length){
    this.retrieveTMIData = true;
    this.retrieveTmiTableData = this.selectedPDSData[0].EnginePerformances;
    this.existingPDSDetails = _.cloneDeep(this.retrieveTmiTableData);
    this.subjectService.sendMessage(this.retrieveTmiTableData,'tmiData');
    }
    else{
      this.retrieveTMIData = true;
      this.retrieveTmiTableData = [];
    }
  }
  tmiSave(){
    this.close();
  }
  refreshData(){
    this.isRefresh = true;
    let id = this.permcDataForm.controls.pdsId.value;
    this.selectedPDSData = this.tmiTableData.filter(b =>{
      return b.DataHeader.PdsID == id;
    })
    this.retrieveTmiTableData = this.selectedPDSData[0].EnginePerformances;
  }

  confirmRefresh(){
    let len = this.existingPDSDetails.length;
  this.existingPDSDetails.splice(0,len,...this.retrieveTmiTableData);
  this.snackBar.open(this.translate.instant('editEngineperformanceSuccess'), '', {
    duration: 4000,
    panelClass: ['status-snack-bar']
  });
    this.isRefresh = false;
    this.ref.detectChanges();
  }
  discard(){
    this.isRefresh = false;
  }
  addNewPdS() {
    const obj = {
      isEdit: true,
      pdsIdList: JSON.parse(JSON.stringify(this.pdsIdListData1))
    };
    const dialogRef = this.dialog.open(CreateNewPDSComponent, {
      panelClass: 'custom-mat-dialog',
      data: obj,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
       // this.pdsIdList1.push(result);
       this.existingPDSDetails = [];
       this.pdsIdAdded = result.flag;
        this.pdsIdListData1.unshift(result.value);
        if (this.pdsIdListData1.indexOf(result.value) < 0) {
          this.pdsIdListData1.unshift(result.value);
        }
        this.selectedPdsid = result.value;
        this.permcDataForm.controls.pdsId.setValue(result.value);
        this.IsEditablePDS = true;
      }
    });
  }
}

