import { Component, Input } from '@angular/core';
import { AlertButton } from '@app/modals/alert-button';
import { TableDescription } from '@app/services/models/misc/table-description';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-alert',
  templateUrl: './confirm-alert.component.html',
  styleUrls: ['./confirm-alert.component.scss']
})

export class ConfirmAlertComponent {

  @Input() alertMessage: string;
  @Input() alertHeader: string;
  @Input() alertMessage1: string;
  @Input() alertMessage2: string;
  @Input() boldMessage : string;
  @Input() reloadmsg = false;
  @Input() buttons?: (AlertButton | string)[];
  @Input() isConfirm = false;
  @Input() isMessage = false;
  @Input() noCloseIcon = false;
  @Input() delete = false;
  @Input() estimateRelease = false;
  @Input() factorImport = false;
  @Input() regionSettingsPopup = false;
  @Input() dealerPopup = false;
  @Input() notAuthorized = false;
  @Input() compareEstimate = false;
  @Input() compareData;
  @Input() compareError = false;
  @Input() compareErrorText;
  @Input() canCompare = false;
  @Input() compareText;
  @Input() timeFrame = false;
  @Input() isRepriceEstimate: boolean = false;
  @Input() repriceData;
  @Input() displayPleaseTryAgainLaterMsg: boolean = false;
  @Input() fieldError = false;
  @Input() messageList: any[] = [];
  @Input() failureMessage='';
  langChangeLocal;
  showSection=false;
  lineError = false;
  hide = false;
  canIncludeRIVaraibility: boolean = false;

  constructor(public activeModal: NgbActiveModal) { }

  close() {
    this.activeModal.close(false);
  }
  
  showPeakErrorClick() {
    this.showSection = true;
    this.lineError = false;
    this.hide = !this.hide;
  }
  hideDetails() {
    this.showSection = false;
    this.hide = !this.hide;
  }
  getConfirmAlertTableDesc = () => {
    let tableDesc = TableDescription.ConfirmAlert;
    return tableDesc;
  }

  ngOnInit(){
    this.langChangeLocal = localStorage.getItem('locale');
  }

}
