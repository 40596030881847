import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuickEstimateServiceNew } from '@app/upload-pricing/quick-estimator-new-services/quick-estimate-data-share.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-upload-pricing',
  templateUrl: './upload-pricing.component.html',
  styleUrls: ['./upload-pricing.component.scss'],

})
export class UploadPricingComponent {

  isFileSelected = false;
  multipleFileError = false;
  fileTypeError = false;
  fileSizeError = false;
  pricingFile = [];
  loading = false;
  serverError = false;
  noncatpriceClear = false;
  catpriceClear = false;
  noncatpriceReload = false;
  catpriceReload = false;
  showclearcacheError: boolean;
  showreloadcacheError: boolean;
  isLoading = false;
  isreLoading = false;
  submit = false;
  clearcacheError = '';
  reloadcacheError = '';
  uploadError = '';
  constructor(private quickEstimateService: QuickEstimateServiceNew, public snackBar: MatSnackBar, public translate: TranslateService,
  ) { }

  onSelect(event) {
    this.pricingFile = [];
    this.multipleFileError = false;
    this.fileTypeError = false;
    this.fileSizeError = false;
    this.isFileSelected = false;
    if (_.find(event.rejectedFiles, { reason: 'no_multiple' })) {
      this.multipleFileError = true;
      this.fileTypeError = false;
      this.fileSizeError = false;
      this.isFileSelected = false;
    } else if (_.find(event.rejectedFiles, { reason: 'type' })) {
      this.isFileSelected = false;
      this.fileTypeError = true;
      this.fileSizeError = false;
      this.multipleFileError = false;
    } else if (_.find(event.rejectedFiles, { reason: 'size' })) {
      this.isFileSelected = false;
      this.fileTypeError = false;
      this.fileSizeError = true;
      this.multipleFileError = false;
    } else {
      this.pricingFile.push(...event.addedFiles);
      this.showclearcacheError = false;
      this.showreloadcacheError = false;
      this.clearcacheError = '';
      this.reloadcacheError = '';

      if (this.findExtention(this.pricingFile[0].name).toUpperCase() === 'dlr'.toUpperCase()) {
        this.isFileSelected = true;
      } else {
        this.isFileSelected = false;
        this.fileTypeError = true;
        this.fileSizeError = false;
        this.multipleFileError = false;
      }
    }
  }

  onRemove(event) {
    this.pricingFile.splice(this.pricingFile.indexOf(event), 1);
    this.isFileSelected = false;
    this.fileTypeError = false;
    this.fileSizeError = false;
    this.multipleFileError = false;
    this.clearcacheError = '';
    this.reloadcacheError = '';
    this.uploadError = '';
  }

  findExtention(str) {
    const file = str.split('/').pop();
    return file.substr(0, file.lastIndexOf('.')), file.substr(file.lastIndexOf('.') + 1, file.length);
  }

  uploadPricingFile() {
    this.showclearcacheError = false;
    this.showreloadcacheError = false;
    this.clearcacheError = '';
    this.reloadcacheError = '';
    this.loading = true;
    this.quickEstimateService.priceFileUpload(this.pricingFile[0]).subscribe(res => {
      this.loading = false;
      if (res.DatabaseUpdateResult.Success && res.DatabaseUpdateResult.Message) {
        this.pricingFile = [];
        this.uploadError = '';
        this.isFileSelected = false;
        this.fileTypeError = false;
        this.fileSizeError = false;
        this.multipleFileError = false;
        this.snackBar.open(this.translate.instant(res.DatabaseUpdateResult.Message), '', {
          duration: 4000,
          panelClass: ['status-snack-bar']
        });
      } else if (!res.DatabaseUpdateResult.Success && res.DatabaseUpdateResult.Message) {
        this.uploadError = res.DatabaseUpdateResult.Message;
      }
    }, err => {
      this.loading = false;
      this.snackBar.open(this.translate.instant('serverErr'), '', {
        duration: 4000,
        panelClass: ['status-snack-bar']
      });
    });
  }
  clearCache() {
    this.reloadcacheError = '';
    this.uploadError = '';
    this.isLoading = true;
    this.submit = true;
    this.showreloadcacheError = false;
    this.clearcacheError = '';
    if (!this.catpriceClear && !this.noncatpriceClear) {
      this.showclearcacheError = true;
      this.isLoading = false;
      this.submit = false;
    } else {
      this.showclearcacheError = false;
      this.quickEstimateService.regionalpriceClearCache(this.generateJson('cache')).subscribe(data => {
        if (data.DatabaseUpdateResult.Success) {
          this.submit = false;
          this.clearcacheError = '';
          this.isLoading = false;
          this.snackBar.open(this.translate.instant('clear_cache_success'), '', {
            duration: 4000,
            panelClass: ['status-snack-bar']
          });
        } else if (!data.DatabaseUpdateResult.Success && data.DatabaseUpdateResult.Message) {
          this.clearcacheError = data.DatabaseUpdateResult.Message;
          this.submit = false;
          this.isLoading = false;
        }
      }, err => {
        this.submit = false;
        this.isLoading = false;
        this.snackBar.open(this.translate.instant('serverErr'), '', {
          duration: 4000,
          panelClass: ['status-snack-bar']
        });

      });
    }
  }

  reloadCache() {
    this.clearcacheError = '';
    this.uploadError = '';
    this.reloadcacheError = '';
    this.isreLoading = true;
    this.submit = true;
    this.showclearcacheError = false;
    if (!this.catpriceReload && !this.noncatpriceReload) {
      this.showreloadcacheError = true;
      this.isreLoading = false;
      this.submit = false;
    } else {
      this.showreloadcacheError = false;
      this.quickEstimateService.regionalpriceReloadCache(this.generateJson('reload')).subscribe(data => {
        if (data.DatabaseUpdateResult.Success) {
          this.isreLoading = false;
          this.reloadcacheError = '';
          this.submit = false;
          this.snackBar.open(this.translate.instant('reload_cache_success'), '', {
            duration: 4000,
            panelClass: ['status-snack-bar']
          });
        } else if (!data.DatabaseUpdateResult.Success) {
          this.isreLoading = false;
          this.submit = false;
          this.reloadcacheError = data.DatabaseUpdateResult.Message;
        }

      }, err => {
        this.submit = false;
        this.isreLoading = false;
        this.snackBar.open(this.translate.instant('serverErr'), '', {
          duration: 4000,
          panelClass: ['status-snack-bar']
        });
      });
    }
  }

  generateJson(type) {
    if (type === 'cache') {
      return {
        ClearCache: {
          CatPrices: this.catpriceClear,
          NonCatPrices: this.noncatpriceClear
        },
        ReloadCache: null,
        DatabaseUpdateResult: null
      };
    } else {
      return {
        ClearCache: null,
        ReloadCache: {
          CatPrices: this.catpriceReload,
          NonCatPrices: this.noncatpriceReload
        },
        DatabaseUpdateResult: null
      };
    }
  }



}
