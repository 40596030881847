import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { UserService } from '@app/services/misc/user.service';
import * as ServiceConstants from '@app/services/shared/app-constants';
import { DataService } from '@app/services/shared/storage/data.service';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public isAuthenticating = false;
  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private userService: UserService
  ) { }

  initLogin() {
    try {
        const authUrl = environment.authUrl + environment.codeEndPoint + environment.codeEndPointNonProd + '&response_type=' 
          + environment.responseType + '&client_id=' + environment.client_id + '&scope=' + environment.scope + environment.client_id 
          + '&redirect_uri=' + environment.callbackUrl;
        window.location.href = authUrl;

    } catch (e) {
    }
  }

  getAccessTokenFromAuthServer(accessCode: string): Observable<any> {
    this.isAuthenticating = true;
    // Fallback method in case this CWS implementation does not work with Fedlogin
    const url = ServiceConstants.accessTokenUrl + '?tokencode=' + accessCode;
    return this.http
      .get(url).pipe(
        mergeMap((resultsToken: any) => {
          if (resultsToken.AccessToken && resultsToken.RefreshToken) {
            this.isAuthenticating = false;
            this.saveTokens(resultsToken);
            return this.userService.getPermissions(true);
          }
        })
      );
  }
  saveTokens(data: any) {
    try {
      localStorage.setItem('AccessToken', data.AccessToken);
      localStorage.setItem('RefreshToken', data.RefreshToken);
    } catch (e) {
    }
  }
  public getAccessToken(): string {
    return localStorage.getItem('AccessToken');
  }
  public getRefreshToken(): string {
    return localStorage.getItem('RefreshToken');
  }
  public removeAuthToken() {
    if (this.getAccessToken() && this.getRefreshToken()) {
      localStorage.removeItem('AccessToken');
      localStorage.removeItem('RefreshToken');
    }
  }
  getRefreshTokenFromAuthorizationServer() {

    this.isAuthenticating = true;

    const headers = new HttpHeaders().set('Refresh-Token', this.getRefreshToken()) .set('Accept', 'application/json');
    return this.http.get(ServiceConstants.refreshTokenUrl, { headers })
      .pipe(map((res: any) => {
        this.isAuthenticating = false;
        if (res.code === 1015 || res.code === 1012) {
          return false;
        } else if (res.AccessToken && res.RefreshToken) {
          this.saveTokens(res);
          return true;
        } else {
          return false;
        }
      }), catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          this.removeAuthToken();
        }
        return throwError(err);
      })
      );

  }

  logout() {

    this.http.get(ServiceConstants.logoutUrl).subscribe(res => {
      // logout successful
    }, error => {
      throw error;
    });
    this.removeAuthToken();
    window.location.href = environment.logoutUrl + '?post_logout_redirect_uri=' + environment.callbackUrl + '&client-request-id=' + environment.client_id;

  }
  logoutFromAuthorizationServer(): Observable<any> {
    const headers = new HttpHeaders().set('Refresh-Token', this.getRefreshToken()) .set('Accept', 'application/json');
    this.removeAuthToken();
    return this.http.get(ServiceConstants.logoutUrl, { headers })
      .pipe(map(res => {
        return res;
      }, error => {
        throw error;
      }));
  }

  isAuthenticated(): boolean {
    if (this.getAccessToken() && this.getRefreshToken()) {
      return true;
    } else {
      return false;
    }
  }
}
