import { Injectable } from '@angular/core';
import { RestService } from '@app/services/rest/rest.service';
import * as serviceConstants from '@app/services/shared/app-constants';
import { BehaviorSubject, iif, Observable, of, Subject, throwError } from 'rxjs';
import { concatMap, delay, retryWhen } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QuickEstimateServiceNew {
  estimateDetails: any = '';
  activeTabQuickEstimator: any = 'estimateDetails';
  previousTabQuickEstimator: any = '';
  nextTabQuickEstimator: any = '';
  disableButton: any = true;
  progressBarLabels: any;
  constructor(private restService: RestService) { }

  private estimateDetailsFlag = new BehaviorSubject('');
  currentEstimateDetailsFlag = this.estimateDetailsFlag.asObservable();

  productDetailsFormData = new BehaviorSubject({});
  currentProductDetailsFormData = this.productDetailsFormData.asObservable();

  engineDetailsFormData = new BehaviorSubject({});
  currentEngineDetailsFormData = this.engineDetailsFormData.asObservable();

  private productFormFlag = new BehaviorSubject(0);
  currentproductFormFlag = this.productFormFlag.asObservable();

  private pricingFormData = new BehaviorSubject('');
  currentPricingFormData = this.pricingFormData.asObservable();

  pricingFormFlag = new BehaviorSubject('');
  currentpricingFormFlag = this.pricingFormFlag.asObservable();

  dataSource = new BehaviorSubject({});
  currentData = this.dataSource.asObservable();

  pricingScreenLoading = new BehaviorSubject(false);

  completeEstimate = new BehaviorSubject(null);
  currentcompleteEstimate = this.completeEstimate.asObservable();

  dataRefresh = new Subject<string>();

  reportGenerationParams = new BehaviorSubject(null);

  productType = new BehaviorSubject('');
  currentProductType = this.productType.asObservable();

  updatecurrentcompleteEstimate(data) {
    this.completeEstimate.next(data);
  }

  refreshData() {
    this.dataRefresh.next();
  }

  changeData(data: any) {
    this.dataSource.next(data);
  }

  changeEstimateDetailsFlag(detailsFlag: any) {
    this.estimateDetailsFlag.next(detailsFlag);
  }

  changeProductDetailsFormData(data: any) {
    this.productDetailsFormData.next(data);
  }

  changeEngineDetailsFormData(data: any) {
    this.engineDetailsFormData.next(data);
  }

  changeProductFormFlag(data: any) {
    this.productFormFlag.next(data);
  }
  changePricingFormData(data: any) {
    this.pricingFormData.next(data);
  }

  changePricingFormFlag(data: any) {
    this.pricingFormFlag.next(data);
  }

  continueClicked(data: any){
    this.estimateDetails = data;
  }

  getEstimateValue(){
    return this.estimateDetails;
  }

  getManufacturerDetails(): Observable<any> {
    return this.restService.httpGet(serviceConstants.manufacturerDetails).pipe(this.retryAPI());
  }

  getFamilyDetails(manufacturer): Observable<any> {
    return this.restService.httpGet(serviceConstants.familyDetails +
      '?manufacturerCode=' + manufacturer.Code +
      '&equipmentType=All'
    );
  }

  getProductDetails(formData): Observable<any> {
    return this.restService.httpGet(
      serviceConstants.productDetails +
      '?manufacturerCode=' + formData.manufacturer.Code +
      '&familyCode=' + formData.family.FamilyCode +
      '&equipmentType=All'
    );
  }

  getSerialNumbers(formData): Observable<any> {
    return this.restService.httpGet(serviceConstants.serialNumbers +
      '?manufacturerCode=' + formData.manufacturer.Code +
      '&familyCode=' + formData.family.FamilyCode +
      '&model=' + formData.model.Name +
      '&equipmentType=All'
    );
  }

  searchByPrefix(prefix): Observable<any> {
    return this.restService.httpGet(serviceConstants.searchPrefix +
      '?prefix=' + prefix +
      '&timeStamp=' + new Date().toISOString()
    ).pipe(this.retryAPI());
  }


  calculateEstimate(submitData): Observable<any> {
    return this.restService.httpPost(serviceConstants.estimateCalculation + '?timeStamp=' + new Date().toISOString(), submitData)
      .pipe(this.retryAPI());
  }

  priceFileUpload(file): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.restService.httpPost(serviceConstants.priceFileUpload, formData)
    .pipe(this.retryAPI());
  }

  regionalpriceClearCache(data): Observable<any> {
    return this.restService.httpPost(serviceConstants.clearCache, data).pipe(this.retryAPI());
  }

  regionalpriceReloadCache(data): Observable<any> {
    return this.restService.httpPost(serviceConstants.reloadCache, data).pipe(this.retryAPI());
  }
  changeProductType(data: any){
this.productType.next(data);
  }
  retryAPI(){
    return retryWhen(errors => errors
    .pipe(
    concatMap((e, i) =>
    iif(
     () => (!(e.status !== 401 && e.status !== 403 &&  i < 2)),
    throwError(e),
    of(e).pipe(delay(0))
     )
     )
     )
     );
     }

}
