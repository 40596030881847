import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-engine-performance-skeleton-loader',
  templateUrl: './engine-performance-skeleton-loader.component.html',
  styleUrls: ['./engine-performance-skeleton-loader.component.scss']
})
export class EnginePerformanceSkeletonLoaderComponent {
  isLoadingFailed = false;
  isLoading = true;
  animate = true;
  primaryColor = '#EDEFF4';
  secondaryColor = '#DEF3ED';
  @Input() count: any;
}
