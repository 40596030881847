import { CdkPortalOutlet, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  Inject,
  OnDestroy,
  Optional,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { FocusTrap, FocusTrapFactory } from '@angular/cdk/a11y';
import { DOCUMENT } from '@angular/common';
import { CcModalConfig } from '@app/components/modal/modal.component.model';

@Component({
  selector: 'cc-modal-container',
  templateUrl: 'modal.component.container.html',
  styleUrls: ['./_modal.theme.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'cc-modal-container',
    tabindex: '-1',
    'aria-modal': 'true',
    '[attr.id]': '_id'
  }
})
export class CcModalContainerComponent implements OnDestroy {
  @ViewChild(CdkPortalOutlet, { static: true }) _portalOutlet: CdkPortalOutlet;

  _id: string;

  private _focusTrap: FocusTrap;

  constructor(
    private _elementRef: ElementRef,
    @Optional() @Inject(DOCUMENT) private _document: any,
    public _config: CcModalConfig,
    private _focusTrapFactory: FocusTrapFactory
  ) {
    if (this._config.autoFocus) {
      this._focusTrap = this._focusTrapFactory.create(
        this._elementRef.nativeElement
      );
      this._focusTrap.focusInitialElement();
    }
  }

  ngOnDestroy() {
    if (this._config.autoFocus && this._focusTrap) {
      this._focusTrap.destroy();
    }
  }
  /**
   *  Attach the componnet modal
   *
   * @param portal <Component>
   */
  attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    return this._portalOutlet.attachComponentPortal(portal);
  }

  /**
   * Attach the template modal
   *
   * @param portal <Template>
   */
  attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
    return this._portalOutlet.attachTemplatePortal(portal);
  }

}
