import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpContextToken, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
export const CONTENT_CONTEXT_TOKEN = new HttpContextToken<string>(() => 'application/json');

@Injectable({
  providedIn: 'root'
})

export class RestService {

  constructor(private http: HttpClient) { }

  httpGet(url) {
    return this.http.get(url).pipe(
      map((res: Response) => {
        return res;
      })
      , catchError((error: any) => {
        throw (error);
      }));
  }
  // MVP phase getwith Params
  httpGetParam(url, params) {
    return this.http.get(url, params)
      .pipe(tap(res => {
        return this.processResponse(res);
      }, error => {
        throw error;
      }));
  }

  httpPost(url, params, contentType?: string) {
    let httpContext: HttpContext;
    if (contentType) {
      httpContext = new HttpContext().set(CONTENT_CONTEXT_TOKEN, contentType);
    }
    return this.http.post(url, params, { context: httpContext })
      .pipe(tap(res => {
        return this.processResponse(res);
      }, error => {
        throw error;
      }));
  }
  httpPostTypeHtml(url, params, contentType?: string) {
    let httpContext: HttpContext;
    if (contentType) {
      httpContext = new HttpContext().set(CONTENT_CONTEXT_TOKEN, contentType);
    }
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'text/plain, */*',
        'Content-Type': 'application/json' // We send JSON
      }),
      responseType: 'blob' as 'json',  // We accept plain text as response.
      context: httpContext
    };
    return this.http.post(url, params, httpOptions)
     .pipe(tap(res => {
       return this.processResponse(res);
       }, error => {
        throw error;
      }));
  }
  httpPostTypeZip(url, params, contentType?: string) {
    let httpContext: HttpContext;
    if (contentType) {
      httpContext = new HttpContext().set(CONTENT_CONTEXT_TOKEN, contentType);
    }
    const headers = new HttpHeaders({
      Accept: 'text/html',
      'Content-Type': 'application/zip'
    });
    return this.http.post(url, params, {
      headers,
      responseType: 'arraybuffer',
      context: httpContext
    })
      .pipe(tap(res => {
        return this.processResponse(res);
      }, error => {
        throw error;
      }));
  }
  httpPostTypePdf(url, params, contentType?: string) {
    let httpContext: HttpContext;
    if (contentType) {
      httpContext = new HttpContext().set(CONTENT_CONTEXT_TOKEN, contentType);
    }
    const headers = new HttpHeaders({
      Accept: 'text/html',
      'Content-Type': 'application/json'
    });
    return this.http.post(url, params, {
      headers,
      responseType: 'arraybuffer',
      context: httpContext
    })
      .pipe(tap(res => {
        return this.processResponse(res);
      }, error => {
        throw error;
      }));
  }

  processResponse(res) {
   if (res) {
       return res;
      } else {
      return false;
    }
  }
  httpDelete(url) {
    return this.http.delete(url).pipe(
      map((res: Response) => {
        return res;
      })
      , catchError((error: any) => {
        throw (error);
      }));
  }
  httpPostTypeHTML(url, params) {
    const headers = new HttpHeaders({
      Accept: 'text/plain',
      'Content-Type': 'application/html'
    });
    return this.http.post(url, params, {
      headers, responseType: 'json'
    })
      .pipe(tap(res => {
        return this.processResponse(res);
      }, error => {
        throw error;
      }));
  }
  httpPostTypeReport(url, params, contentType?: string) {
    let httpContext: HttpContext;
    if (contentType) {
      httpContext = new HttpContext().set(CONTENT_CONTEXT_TOKEN, contentType);
    }
    const httpOptions: any = {
      headers: new HttpHeaders({
        Accept: 'text/plain, */*',
        'Content-Type': 'application/json' // We send JSON
      }),
      responseType: 'blob' as 'json' ,
      // We accept plain text as response.
      observe: 'response',
      context: httpContext
    };
    return this.http.post(url, params, httpOptions)
      .pipe(tap(res => {
        return this.processResponse(res);
      }, error => {
        throw error;
      }));
  }
  httpPostTypeReportPdf(url, params, contentType?: string) {
    let httpContext: HttpContext;
    if (contentType) {
      httpContext = new HttpContext().set(CONTENT_CONTEXT_TOKEN, contentType);
    }
    const headers = new HttpHeaders({
      Accept: 'text/html',
      'Content-Type': 'application/json'
    });
    return this.http.post(url, params, {
      headers,
      responseType: 'arraybuffer',
      observe : 'response',
      context: httpContext
      })
      .pipe(tap(res => {
        return this.processResponse(res);
      }, error => {
        throw error;
      }));
  }

  httpGetTypeReport(url, contentType?: string) {
    let httpContext: HttpContext;
    if (contentType) {
      httpContext = new HttpContext().set(CONTENT_CONTEXT_TOKEN, contentType);
    }
    const httpOptions: any = {
      headers: new HttpHeaders({
        Accept: 'text/plain, */*',
        'Content-Type': 'application/json' // We send JSON
      }),
      responseType: 'blob' as 'json' ,
      // We accept plain text as response.
      observe: 'response',
      context: httpContext
    };
    return this.http.get(url, httpOptions)
      .pipe(tap(res => {
        return this.processResponse(res);
      }, error => {
        throw error;
      }));
  }

  }
