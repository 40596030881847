import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONTENT_CONTEXT_TOKEN, RestService } from '@app/services/rest/rest.service';
import * as ServiceMappings from '@app/services/shared/app-constants';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class GloabalSettingsService {

  constructor(private restService: RestService,
              private http: HttpClient) { }

  uploadEngineperformance(file, type): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    return this.restService.httpPost(ServiceMappings.uploadEngineperformance + '=' + type,
      formData);
  }

  exportEngineperformance(type): Observable<any> {
    const contentType = 'application/octet-stream';
    const httpContext: HttpContext = new HttpContext().set(CONTENT_CONTEXT_TOKEN, contentType);
    return this.http.post(ServiceMappings.exportEngineperformance + '=' + type, {responseType: 'arraybuffer'}, { context: httpContext });
  }
}
