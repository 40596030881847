import { Component } from '@angular/core';

@Component({
  selector: 'app-e401component',
  templateUrl: './e401.component.html',
  styleUrls: ['./e401.component.scss']
})
export class E401Component {





}
