import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-duplicate-service-option-popup',
  templateUrl: './duplicate-service-option-popup.component.html',
  styleUrls: ['./duplicate-service-option-popup.component.scss']
})
export class DuplicateServiceOptionPopupComponent {
  @Input() popupContent;
  @Input() popupLabelList: [];

  constructor(public translate: TranslateService) { }

  getLabel = (currentLabel) => {
    return this.translate.instant(currentLabel);
  }

  getSmcsComponentCode = () => {
    return this.popupContent.SMCSComponent;
  }

  getSmcsComponentDescription = () => {
    return this.popupContent.SMCSComponentDescription;
  }

  getModifierCode = (currentLabel) => {
    switch(currentLabel) {
      case 'cabType' : {
        return this.popupContent.SMCSCabType;
      }
      case 'jobCondition' : {
        return this.popupContent.SMCSJobCondition;
      }
      case 'jobLocation' : {
        return this.popupContent.SMCSJobLocation;
      }
      case 'modifier' : {
        return this.popupContent.SMCSModifier;
      }
      case 'smcsQty' : {
        return this.popupContent.SMCSQuantity;
      }
      case 'workApplication' : {
        return this.popupContent.SMCSWorkApplication;
      }
      default: {
        return '';
      }
    }
  }

  getModifierDesc = (currentLabel) => {
    switch(currentLabel) {
      case 'cabType' : {
        return this.popupContent.SMCSCabTypeDescription;
      }
      case 'jobCondition' : {
        return this.popupContent.SMCSJobConditionDescription;
      }
      case 'jobLocation' : {
        return this.popupContent.SMCSJobLocationDescription;
      }
      case 'modifier' : {
        return this.popupContent.SMCSModifierDescription;
      }
      case 'smcsQty' : {
        return this.popupContent.SMCSQuantityDescription;
      }
      case 'workApplication' : {
        return this.popupContent.SMCSWorkApplicationDescription;
      }
      default: {
        return '';
      }
    }
  }
}
