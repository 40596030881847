import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubjectService } from '@app/services/data/subject.service';
import { DataService } from '@app/services/shared/storage/data.service';
import { WorkgroupService } from '@app/services/workgroup/workgroup.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-add-edit-customer',
  templateUrl: './add-edit-customer.component.html',
  styleUrls: ['./add-edit-customer.component.scss'],
})
export class AddEditCustomerComponent implements OnInit {
  @Input() isEdit: boolean;
  @Input() customer: any;
  @Input() customerData: any;

  customerForm: FormGroup;
  submit = false;
  selectedCustomerClass: any;
  customerClasses = ['A', 'B', 'C'];
  isLoading = false;
  networkError = false;
  networkErrorMessage: any;
  saveButtonDisable = false;
  flag = false;
  fieldError = false;
  showSection = false;
  hide = false;
  lineError: any = false;
  messageList: any[] = [];
  customerNumAlreadyExistsMsg = '';
  constructor(
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    private workgroupService: WorkgroupService,
    public snackBar: MatSnackBar,
    public translate: TranslateService,
    private dataService: DataService,
    private subjectService: SubjectService
  ) {
    this.customerForm = this.fb.group({
      customerName: [null, [Validators.pattern('^[a-zA-Z0-9_ ]*$'), Validators.maxLength(50)]],
      customerNumber: [
        null,
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_]*$'),
          Validators.maxLength(30),
        ],
      ],

      customerClass: [
        null,
        [Validators.pattern('^[a-zA-Z0-9]*$'), Validators.maxLength(30)],
      ],
    });
  }

  ngOnInit() {
    if (this.isEdit) {
      this.saveButtonDisable = true;
      this.addFormValue();
    } else if (!this.isEdit) {
      this.saveButtonDisable = false;
    }
  }

  close() {
    this.activeModal.close();
  }

  addFormValue() {
    this.customerForm.controls.customerName.setValue(
      this.customer.CustomerName
    );
    this.customerForm.controls.customerNumber.setValue(
      this.customer.CustomerNumber
    );

    this.customerForm.controls.customerClass.setValue(
      this.customer.CustomerClass
    );
  }

  valueChange(event) {
    this.customerNumAlreadyExistsMsg = ''
    this.saveButtonDisable = false;
  }

  checkForAdd(data) {
    this.flag = false;
    this.customerData.forEach((cust) => {
      this.checkForDuplication(cust, data);
    });
    return this.flag;
  }

  addNewCustomer(customerData) {
    if (!this.checkForAdd(customerData)) {
      this.workgroupService.addCustomerData(customerData).subscribe(
        (response) => {
          this.isLoading = false;

          if (response.EvaluationMessages) {
            this.isLoading = false;
            this.fieldError = true;
            this.messageList = response.EvaluationMessages;
          } else {
            this.isLoading = false;
            if (response.IsSuccess) {
              customerData.SystemUniqueId = response.SystemUniqueId;
            }
            this.activeModal.close(customerData);
          }
        },
        (err) => {
          this.showServerError();
        }
      );
    } else {
      this.isLoading = false;
      this.customerNumAlreadyExistsMsg =
        this.translate.instant('customerExistsMsg');
    }
  }
  checkForEdit(data) {
    this.flag = false;
    const dataArray = _.clone(this.customerData);

    dataArray.splice(dataArray.indexOf(this.customer), 1);
    dataArray.forEach((cust) => {
      this.checkForDuplication(cust, data);
    });
    return this.flag;
  }
  editCustomer(customerData) {
    if (!this.checkForEdit(customerData)) {
      this.workgroupService.editCustomerData(customerData).subscribe(
        (response) => {
          if (response.EvaluationMessages != null) {
            this.isLoading = false;
            this.fieldError = true;
            this.messageList = response.EvaluationMessages;
          } else {
            this.isLoading = false;
            this.activeModal.close(customerData);
          }
        },
        (err) => {
          this.showServerError();
        }
      );
    } else {
      this.isLoading = false;
      this.customerNumAlreadyExistsMsg =
        this.translate.instant('customerExistsMsg');
    }
  }

  generateJson() {
    if (this.isEdit) {
      return {
        SystemUniqueId: this.customer.SystemUniqueId,
        CustomerNumber: this.customerForm.value.customerNumber,
        CustomerName: this.customerForm.value.customerName,
        CustomerClass: this.customerForm.value.customerClass,
      };
    } else {
      return {
        CustomerNumber: this.customerForm.value.customerNumber,
        CustomerName: this.customerForm.value.customerName,
        CustomerClass: this.customerForm.value.customerClass,
      };
    }
  }

  submitForm(event) {
    let value = '';
    this.customerNumAlreadyExistsMsg = '';
    const formValidObj = {
      formName: 'Add/Edit Customer - Settings',
      formContent: 'User Entered Value',
      formContent2: null,
      formStatus: 'success',
    };
    Object.keys(this.customerForm.value).forEach((key, index, array) => {
      if (this.customerForm.value[key] !== null) {
        value = index < array.length - 1
          ? (value + 'User Entered Value | ')
          : (value + 'User Entered Value');
      }
    });
    formValidObj.formContent2 = value;

    if (this.customerForm.valid) {
      this.isLoading = true;
      const customerData = this.generateJson();
      if (this.isEdit) {
        this.editCustomer(customerData);
      } else {
        this.addNewCustomer(customerData);
      }
    } else {
      this.submit = true;
    }
    this.subjectService.setFormChangesStream({
      isFormSubmitted: true,
      formValue: formValidObj,
    });
  }

  showServerError() {
    this.isLoading = false;
    this.networkError = true;
    this.networkErrorMessage = this.translate.instant('serverErr');
  }

  checkForDuplication(cust, data) {
    if (
      cust.CustomerNumber.toLowerCase() === data.CustomerNumber.toLowerCase()
    ) {
      this.flag = true;
    }
  }

  showPeakErrorClick() {
    this.showSection = true;
    this.lineError = false;
    this.saveButtonDisable = true;
    this.hide = !this.hide;
  }
  hideDetails() {
    this.showSection = false;
    this.hide = !this.hide;
  }
}
