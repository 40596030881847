import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from '@app/services/common/common.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-labor-reload-table',
  templateUrl: './labor-reload-table.component.html',
  styleUrls: ['./labor-reload-table.component.scss']
})
export class LaborReloadTableComponent implements OnInit {
  @Input() tableData;
  @Input() columns: any[];
  @Input() selectedData;
  @Input() hideHeader = false;
  @Output() selectedLaborEmitter = new EventEmitter;
  @Input() status;
  isModified = true;
  includeLaborData = true;
  selectedConfigurationIndex = 0;
  displayedColumns = [];
  selectedProductIndex = 0;
  selectedProduct;
  check = false;
  sumArray;
  @Output() checkboxHandler = new EventEmitter<any>();
  selectAllValues;
  protected onDestroy = new Subject<boolean>();
  @Input() jsonKey: any;

  constructor(public activeModal: NgbActiveModal, private commonService: CommonService) { }

  ngOnInit(): void {
    if(this.status !== 'Existing' ){
      this.displayedColumns.push('radio');
    }

  

    this.setDisplayedColumns();
    this.initListenChange();

  }
  setDisplayedColumns(){
    this.columns.forEach((column, index) => {

      this.displayedColumns.push(column.value);
    });
  }


   handleCheckBox(element, i, event){
    element.selected = event.target.checked;
    this.checkForSelectAll();

    this.checkboxHandler.emit({selectedRow: element, index: i, isSelectAllClicked: false, jsonKey: this.jsonKey});
   }
  selectAll(event){
   this.tableData.forEach((row) => {
    row.selected = event.target.checked;

   });
   this.selectAllValues = event.target.checked;
   this.checkboxHandler.emit({selectAll: event, isSelectAllClicked: true, tableData: this.tableData, jsonKey: this.jsonKey});
  }
  checkForSelectAll(){
   const temp = this.tableData.filter((row) => row.selected).length == this.tableData.length ? true : false;
   this.selectAllValues = temp;
  }

  initListenChange(){
    this.commonService.discardChangesObservable.pipe(takeUntil(this.onDestroy)).subscribe((data) => {
      if (data){
        if (this.tableData && this.tableData.length > 0){
          this.selectAllValues = false;
          this.tableData.forEach((row) => {
            row.selected = false;
           });
      }
    }
    });
    this.commonService.acceptAllChangesObservable.pipe(takeUntil(this.onDestroy)).subscribe((data) => {
      if (data){
        const event = {target: {checked: true}};
        this.selectAll(event);
      }
    });
  }
  checkifIsPM(element, key){
  const value = _.get(element, key);
  return value;

  }
  ngOnDestroy(){
    this.onDestroy.next(true);
    this.onDestroy.complete();
  }

}
