import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertButton } from '@app/modals/alert-button';

@Component({
  selector: 'app-peak-error',
  templateUrl: './peak-error.component.html',
  styleUrls: ['./peak-error.component.scss']
})
export class PeakErrorComponent {

  @Input() alertMessage: string;
  @Input() alertHeader: string;
  @Input() buttons?: (AlertButton | string)[];
  @Input() isConfirm = false;
  @Input() isMessage = false;
  @Input() noCloseIcon = false;
  @Input() erroList: [];
  @Input() apiError : object;

  constructor(public activeModal: NgbActiveModal) { }


  close() {
    this.activeModal.close(false);
  }

}
