import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-components',
  templateUrl: './footer-components.component.html',
  styleUrls: ['./footer-components.component.scss']
})
export class FooterComponentsComponent  {




}
