import { Component, Input } from '@angular/core';
import { AlertButton } from '@app/modals/alert-button';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-product-info-error',
  templateUrl: './product-info-error.component.html',
  styleUrls: ['./product-info-error.component.scss']
})
export class ProductInfoErrorComponent {

  @Input() alertMessage: string;
  @Input() buttons?: (AlertButton | string)[];
  @Input() noCloseIcon = false;
  @Input() erroList: [];

  constructor(public activeModal: NgbActiveModal) { }

  close() {
    this.activeModal.close(false);
  }

}
