import { Injectable } from '@angular/core';
import { DefinitionTypes } from '@app/services/models/misc/general';
import { RestService } from '@app/services/rest/rest.service';
import * as serviceConstants from '@app/services/shared/app-constants';
import { boolean } from '@storybook/addon-knobs';
import { BehaviorSubject, iif, Observable, of, Subject, throwError } from 'rxjs';
import { concatMap, delay, retryWhen } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QuickEstimateServiceNew {
  estimateDetails: any = '';
  activeTabQuickEstimator: any = 'estimateDetails';
  previousTabQuickEstimator: any = '';
  nextTabQuickEstimator: any = '';
  disableButton: any = true;
  progressBarLabels: any;
  workgroupDetails: any = [];
  selectedBaseCurrency: any;
  definitionType = DefinitionTypes.Standard;
  contentId: any = '';
  originalPmData: any = [];
  disableStartOver = true;
  validForms;
  disableReport = true;
  engineDataEmpty = false;
  constructor(private restService: RestService) { }

  private estimateDetailsFlag = new BehaviorSubject('');
  currentEstimateDetailsFlag = this.estimateDetailsFlag.asObservable();

  productDetailsFormData = new BehaviorSubject({});
  currentProductDetailsFormData = this.productDetailsFormData.asObservable();

  engineDetailsFormData = new BehaviorSubject({});
  currentEngineDetailsFormData = this.engineDetailsFormData.asObservable();

  private productFormFlag = new BehaviorSubject(0);
  currentproductFormFlag = this.productFormFlag.asObservable();

  private pricingFormData = new BehaviorSubject('');
  currentPricingFormData = this.pricingFormData.asObservable();

  pricingFormFlag = new BehaviorSubject('');
  currentpricingFormFlag = this.pricingFormFlag.asObservable();

  dataSource = new BehaviorSubject({});
  currentData = this.dataSource.asObservable();

  pricingScreenLoading = new BehaviorSubject(false);

  completeEstimate = new BehaviorSubject(null);
  currentcompleteEstimate = this.completeEstimate.asObservable();

  dataRefresh = new Subject<string>();

  reportGenerationParams = new BehaviorSubject(null);

  productType = new BehaviorSubject('');
  currentProductType = this.productType.asObservable();

  performanceData = new BehaviorSubject(null);
  currentPerformanceData = this.performanceData.asObservable();

  nextClick = new BehaviorSubject(boolean);
  currentNext = this.nextClick.asObservable();

  pmWizardData = new BehaviorSubject(null);
  currentPmWizardData = this.pmWizardData.asObservable();

  selectedPerformanceData = new BehaviorSubject('');
  currentSelectedPerformance = this.selectedPerformanceData.asObservable();

  scheduleToggle = false;

  scheduleData = new BehaviorSubject(null);
  currentscheduleData = this.scheduleData.asObservable();

  scheduleDate = new BehaviorSubject(null);
  currentscheduleDate = this.scheduleDate.asObservable();

  allFormsValid = new BehaviorSubject(boolean);
  allFormsValidObservable = this.allFormsValid.asObservable();

  previousButtonDisable: any = false;
  startOverChange = new BehaviorSubject(boolean);
  currentStartOverChange = this.startOverChange.asObservable();

  pricingScreenError = new BehaviorSubject(null);
  isPricingScreenError = this.pricingScreenError.asObservable();

  engineSpecSelectionData = new BehaviorSubject('');
  currentEngineSpecFormData = this.engineSpecSelectionData.asObservable();

  selectedServiceOptions = new BehaviorSubject([]);
  selectedDuplivateServiceOptsion = this.selectedServiceOptions.asObservable();

  updatecurrentcompleteEstimate(data) {
    this.completeEstimate.next(data);
  }

  refreshData() {
    this.dataRefresh.next();
  }

  changeData(data: any) {
    this.dataSource.next(data);
  }

  changeEstimateDetailsFlag(detailsFlag: any) {
    this.estimateDetailsFlag.next(detailsFlag);
  }

  changeProductDetailsFormData(data: any) {
    this.productDetailsFormData.next(data);
  }
  changeEngineDetailsFormData(data: any) {
    this.engineDetailsFormData.next(data);
  }

  changeProductFormFlag(data: any) {
    this.productFormFlag.next(data);
  }
  changePricingFormData(data: any) {
    this.pricingFormData.next(data);
  }

  changePricingFormFlag(data: any) {
    this.pricingFormFlag.next(data);
  }
  updatePricingScreenError(data) {
    this.pricingScreenError.next(data);
  }

  continueClicked(data: any) {
    this.estimateDetails = data;
  }

  getEstimateValue() {
    return this.estimateDetails;
  }

  getManufacturerDetails(): Observable<any> {
    return this.restService.httpGet(serviceConstants.manufacturerDetails).pipe(this.retryAPI());
  }

  getFamilyDetails(manufacturer): Observable<any> {
    return this.restService.httpGet(serviceConstants.familyDetails +
      '?manufacturerCode=' + manufacturer.Code +
      '&equipmentType=All'
    );
  }

  getProductDetails(formData): Observable<any> {
    return this.restService.httpGet(
      serviceConstants.productDetails +
      '?manufacturerCode=' + formData.manufacturer.Code +
      '&familyCode=' + formData.family.FamilyCode +
      '&equipmentType=All'
    );
  }

  getSerialNumbers(formData): Observable<any> {
    return this.restService.httpGet(serviceConstants.serialNumbers +
      '?manufacturerCode=' + formData.manufacturer.Code +
      '&familyCode=' + formData.family.FamilyCode +
      '&model=' + formData.model.Name +
      '&equipmentType=All'
    );
  }

  searchByPrefix(prefix): Observable<any> {
    return this.restService.httpGet(serviceConstants.searchPrefix +
      '?prefix=' + prefix +
      '&timeStamp=' + new Date().toISOString()
    ).pipe(this.retryAPI());
  }


  calculateEstimate(submitData): Observable<any> {
    return this.restService.httpPost(serviceConstants.estimateCalculation + '?timeStamp=' + new Date().toISOString(), submitData)
      .pipe(this.retryAPI());
  }

  priceFileUpload(file): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.restService.httpPost(serviceConstants.priceFileUpload, formData)
      .pipe(this.retryAPI());
  }

  regionalpriceClearCache(data): Observable<any> {
    return this.restService.httpPost(serviceConstants.clearCache, data).pipe(this.retryAPI());
  }

  regionalpriceReloadCache(data): Observable<any> {
    return this.restService.httpPost(serviceConstants.reloadCache, data).pipe(this.retryAPI());
  }
  changeProductType(data: any) {
    this.productType.next(data);
  }
  retryAPI() {
    return retryWhen(errors => errors
      .pipe(
        concatMap((e, i) =>
          iif(
            () => (!(e.status !== 401 && e.status !== 403 && i < 2)),
            throwError(e),
            of(e).pipe(delay(0))
          )
        )
      )
    );
  }

  quickEstimatorPeekSOC(params: any): Observable<any> {
    return this.restService.httpPost(serviceConstants.quickPeekSoc +
       '?definitionType=' + DefinitionTypes.Standard, params).pipe(this.retryAPI());
  }

  quickEstimatorAttemptEst(params: any): Observable<any> {
    return this.restService.httpPost(serviceConstants.quickAttemptEstimate +
       '?definitionType=' + DefinitionTypes.Standard, params).pipe(this.retryAPI());
  }

  updateCurrentPerfomanceData(data) {
    this.performanceData.next(data);
  }
  nextClicked(data) {
    this.nextClick.next(data);
  }
  updatePmWizardData(data) {
    this.pmWizardData.next(data);
  }
  updateCurrentSelectedPerformanceData(data) {
    this.selectedPerformanceData.next(data);
  }
  updateScheduleToggle(data) {
    this.scheduleToggle = data;
  }
  updateScheduleData(data) {
    this.scheduleData.next(data);
  }
  sendStartOverEvent(data) {
    this.startOverChange.next(data);
  }
  updateScheduleDate(data) {
    this.scheduleDate.next(data);
  }
  formValidChanges(data) {
    this.allFormsValid.next(data);
  }
  quickEstimatorDownloadReport(params: any, type): Observable<any> {
    const contentType = 'application/octet-stream';
    if (type.toString() === 'Pdf') {
      return this.restService.httpPostTypePdf(serviceConstants.quickEstimateDownloadReport, params, contentType);
    }
    else {
      return this.restService.httpPostTypeHtml(serviceConstants.quickEstimateDownloadReport, params, contentType);
    }

  }
  
  getEnginespecSelection(data:any){
   this.engineSpecSelectionData.next(data);
  }

  getSelectedDuplicateServiceOptios = (data) => {
    this.selectedServiceOptions.next(data);
  }
}
