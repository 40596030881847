import { Injectable } from "@angular/core";

import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DataService {
  userAffiliation = [];
  systemRole: any; // Holds the logged in user - System Role
  orgRole: any; // Organization role of logged in user
  orgName: any; // Selected or Logged in Organization Name
  defaultWGRole: any; // WG role of selected Workgroup under an organization.
  defaultOrg: any; // Organization type and ID of logged in User
  defaultOrgWG: any; // Workgroup name and role of logged in User
  workgroups = []; // List of WG under an Organizaiton
  canAccessCalc = false; // For Advanced Calculator user or Basic User access (QC).

  isUserAdmin = false; // to know whether user is Admin and Non Admin
  isUserOrgAdmin = false; // know user has a role of Admin in any Organizations (multiple)
  isUserWGAdmin = false; // know user has a role of Admin in any of Workgroups (multiple)
  showLoadingLoader = true;
  showLoading = true;

  tempOrg: any; // temporary organization id & type to get WG associated.

  isUserActive = false;
  displayName: string;
  userName: string;
  cwsId: string;
  CatRecId: string;
  email: string;
  marketingOrg = {
    Code: "",
    Description: "",
  };
  salesArea = {
    Code: "",
    Description: "",
  };

  cwsAffiliation: string;
  phoneNumber: string;
  isSocCCR = false;
  ccrType;
  isUserLoggedIn = false;
  canAccess = false;
  dataSource: string;
  dealerCode: string;
  dataOrigin: string;
  equipmentType: string;
  currencyChange: string;
  displayNonZeroPercentParts: boolean;
  isDealer: boolean;
  userDealercode: string;
  organizationCode: string;
  basicDetailsWorkGroupId = "";
  closeFooter = false;
  autoSaveEnabeld: boolean;
  userDateFormat = "yyyy/MM/dd, hh:mm a";
  currencyList = [];
  useCustomcode = false;
  isQe = false;
  isAdmin = false;
  navigateToElevatedAccess = null;
  navigateToQERequest = null;
  unitSelected;
  popupElevatedAccess = null;
  popupQERequest = null;
  notificationTimings = 5;
  alertsApiCallInterval = 5; // Interval in minutes
  manufacturerCode: string;
  checkSOE = false;
  continueErp = true;

  userPrefenceData = [];
  ssoAffilitation: any = "";
  languages = [
    {
      label: "English - US",
      countryCode: "en-US",
      locale: "en",
      translated: "",
    },
    {
      label: "Chinese",
      countryCode: "zh-CN",
      locale: "zh",
      translated: "中文",
    },
    {
      label: "French",
      countryCode: "fr-FR",
      locale: "fr",
      translated: "Français",
    },
    {
      label: "German",
      countryCode: "de-DE",
      locale: "de",
      translated: "Deutsch",
    },
    {
      label: "Indonesian",
      countryCode: "id-ID",
      locale: "id",
      translated: "Bahasa Indonesia",
    },
    {
      label: "Italian",
      countryCode: "it-IT",
      locale: "it",
      translated: "Italiano",
    },
    {
      label: "Japanese",
      countryCode: "ja-JP",
      locale: "ja",
      translated: "日本語",
    },
    {
      label: "Korean",
      countryCode: "ko-KO",
      locale: "ko",
      translated: "한국어",
    },
    {
      label: "Portuguese",
      countryCode: "pt-BR",
      locale: "pt",
      translated: "Português",
    },
    {
      label: "Russian",
      countryCode: "ru-RU",
      locale: "ru",
      translated: "Русский",
    },
    {
      label: "Spanish",
      countryCode: "es-ES",
      locale: "es",
      translated: "Español",
    },
    {
      label: "Turkish",
      countryCode: "tr-TR",
      locale: "tr",
      translated: "Türkçe",
    },
  ];
  selectionValues;
  formValidObjects = [];
  ccrTypeValue: any;
  isCCrToggle = false;

  isQESettingCrossRef: boolean = false;
  isDefaultPartNumSelected = false;
  qeSettingPartNumSelected = "";
  private subject = new Subject<any>();
  isUserOrgIntermediate: boolean = false;

  constructor() {
    this.autoSaveEnabeld = true;
    this.displayNonZeroPercentParts = true;
  }
  setCurrencyList(data: any) {
    const currency = [];
    if (data?.length > 0) {
      currency.push(data[0]);
      const value = data.splice(1).sort();
      value.forEach((val) => {
        currency.push(val);
      });
    }
    data = currency;
    this.currencyList = data;
  }
  getWGCurencyList() {
    return this.currencyList;
  }
  setUserAffiliation(data: any) {
    this.userAffiliation = data;
  }
  getUserAffiliation() {
    return this.userAffiliation;
  }

  discardProfileChanges() {
    this.subject.next();
  }

  reloadprofile(): Observable<any> {
    return this.subject.asObservable();
  }
}
