import { Injectable } from '@angular/core';
import { FormatCellPipe } from '@app/common/pipes/format-cell.pipe';
import { SubjectService } from '@app/services/data/subject.service';
import { RestService } from '@app/services/rest/rest.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  nestedData;
  children;
  partsData;

  singleDdlDetails: any = {
    this: '',
    data: ''
  };
  jsonChanges: any[] = [];
  adv = false;
  discardChanges = new BehaviorSubject(false);
  discardChangesObservable = this.discardChanges.asObservable();

  acceptAllChanges = new BehaviorSubject(false);
  acceptAllChangesObservable = this.acceptAllChanges.asObservable();


  constructor(private subjectService: SubjectService, public restService: RestService, private formatPipe: FormatCellPipe, public translate: TranslateService) { }

  initializeDDL(parentClass?: any) {
    let x: any;
    let i: any;
    let j: any;
    let selElmnt: any;
    let a: any;
    let b: any;
    let c: any;
    const that = this;
    /*look for any elements with the class "custom-select":*/
    if (parentClass) {
      x = document.getElementsByClassName(parentClass);
    } else {
      x = document.getElementsByClassName('custom-select-ddl');
    }
    for (i = 0; i < x.length; i++) {
      selElmnt = x[i].getElementsByTagName('select')[0];
      /*for each element, create a new DIV that will act as the selected item:*/
      a = document.createElement('DIV');
      a.setAttribute('class', 'select-selected floating-select pLFix');
      x[i].appendChild(a);
      /*for each element, create a new DIV that will contain the option list:*/
      b = document.createElement('DIV');
      b.setAttribute('class', 'select-items select-hide');
      for (j = 1; j < selElmnt.length; j++) {
        /*for each option in the original select element,
        create a new DIV that will act as an option item:*/
        c = document.createElement('DIV');
        c.innerHTML = selElmnt.options[j].innerHTML;
        c.addEventListener('click', function(e) {
          /*when an item is clicked, update the original select box,
          and the selected item:*/
          let y: any;
          let i: any;
          let k: any;
          let s: any;
          let h: any;
          s = this.parentNode.parentNode.getElementsByTagName('select')[0];
          h = this.parentNode.previousSibling;
          that.singleDdlDetails.this = this;
          that.singleDdlDetails.data = e.target.innerText;
          this.removeClassAttributeOfParentNode(this,i,s,h,y,k);
          h.click();
          that.subjectService.sendMessage(that.singleDdlDetails, 'singleDdlChange');
        });
        b.appendChild(c);
      }
      x[i].appendChild(b);
      a.addEventListener('click', function(e) {
        /*when the select box is clicked, close any other select boxes,
        and open/close the current select box:*/
        e.stopPropagation();
        that.closeAllSelect(this);
        this.nextSibling.classList.toggle('select-hide');
        this.classList.toggle('select-arrow-active');
      });
    }
    document.addEventListener('click', this.closeAllSelect);
  }

  removeClassAttributeOfParentNode(this: any, i: any, s: any, h: any, y: any, k: any) {
    for (let i = 0; i < s.length; i++) {
      // tslint:disable-next-line: triple-equals
      if (s.options[i].innerHTML == this.innerHTML) {
        s.selectedIndex = i;
        h.innerHTML = this.innerHTML;
        y = this.parentNode.getElementsByClassName('same-as-selected');
        for (k = 0; k < y.length; k++) {
          y[k].removeAttribute('class');
        }
        this.setAttribute('class', 'same-as-selected');
        break;
      }
    }
  }

  closeAllSelect(elmnt: any) {
    /*a function that will close all select boxes in the document,
    except the current select box:*/
    let x: any;
    let y: any;
    let i: any;
    const arrNo = [];
    x = document.getElementsByClassName('select-items');
    y = document.getElementsByClassName('select-selected floating-select');
    for (i = 0; i < y.length; i++) {
      // tslint:disable-next-line: triple-equals
      if (elmnt == y[i]) {
        arrNo.push(i);
      } else {
        y[i].classList.remove('select-arrow-active');
      }
    }
    for (i = 0; i < x.length; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add('select-hide');
      }
    }
  }
  // ---------------------compareJson---Starts------------------
  compareJson(data: any, data2: any) {
    const that = this;
    this.jsonChanges = [];
    data.forEach((firstChild, index) => {
      const secondChild = data2[index];
      _.reduce(firstChild, function(result, value, key) {
        if (typeof (firstChild[key]) === 'object') {
          that.compareServiceIntervals(firstChild[key], secondChild[key]);
        }
        else {
          if (firstChild[key] != secondChild[key]) {
            const obj = {
              key,
              value,
              parentIndex: '',
              childIndex: ''
            };
            that.jsonChanges.push(obj);
          }
        }
      });
    });
    that.subjectService.sendMessage(this.jsonChanges, 'jsonCompareResult');
  }

  compareServiceIntervals(data: any, data2?: any) {
    const that = this;
    if (!data) {
      return;
    }
    data.forEach((firstChild, index) => {
      const secondChild = data2[index];
      const i = index;
      _.reduce(firstChild, function(result, value, key) {
        if (typeof (firstChild[key]) === 'object' && key === 'Jobs') {
          that.compareJobs(firstChild[key], secondChild[key], i);
        }
        else {
          if (firstChild[key] != secondChild[key]) {
            const obj = {
              key,
              value,
              parentIndex: index,
              childIndex: ''
            };
            that.jsonChanges.push(obj);
          }
        }
      });


    });
  }
  compareJobs(data: any, data2: any, parentIndex: any) {
    const that = this;
    if (!data) {
      return;
    }
    data.forEach((firstChild, index) => {
      const secondChild = data2[index];
      _.reduce(firstChild, function(result, value, key) {
        if (typeof (firstChild[key]) !== 'object') {
          if (firstChild[key] != secondChild[key]) {
            const obj = {
              key,
              value,
              index: parentIndex,
              childIndex: index
            };
            that.jsonChanges.push(obj);
          }
        }
      });


    });
  }
  // ---------------------compareJson---Ends------------------


  // generate parts nested JSON from flat JSON

  fetchFlatJSON() {
    this.partsData = this.restService.httpGet('./assets/mock-data/partsData.json');
  }

  initProcessingFlatJson() {
    let currentPartNo = null;
    const rootItem = this.partsData.Parts.filter(item => item.GroupNumber === null);
    this.nestedData = rootItem[0];
    currentPartNo = rootItem[0].PartNumber;
    this.appendChildren(currentPartNo, this.nestedData);
    this.generateNestedData();
  }

  generateNestedData() {
      if (this.nestedData && this.nestedData.hasOwnProperty('data')) {
        for (const j in this.children) {
          if (this.children[j]) {
            this.appendChildren(this.children[j].PartNumber, this.children[j]);
          }
        }
      }
    
  }

  appendChildren(currentPartNo, obj) {
    for (const item of this.partsData.Parts) {
      if (item.GroupNumber === currentPartNo) {
        if (!obj.data) {
          obj.data = [];
        }
        if (!obj.data.includes(item)) {
          obj.data.push(item);
        }
      }
    }
    if (obj.data) {
      this.children = obj.data;
    }
  }

  // generate parts nested JSON from flat JSON -- END

  // search inside table data -- start
  matTableSearch(searchTerm, tableData, columnHeaders) {
    if (!searchTerm) {
      return tableData;
    } else {
      const filteredData = [];
      tableData.forEach(row => {
        columnHeaders.forEach((column) => {
          if (column.searchEnabled) {
            let transformedData = this.formatPipe.transform(undefined, column, row);
            transformedData = this.matTableSearchOnTransformedData(transformedData,column,searchTerm);
            this.matTableSearchOnTransformedDataIfNotEqualsNotAvailable(transformedData,searchTerm,filteredData,row);
          }
        });
      });
      return filteredData;
    }
  }

  matTableSearchOnTransformedData(transformedData: any, column: any, searchTerm: any) {
    if (transformedData) {
      if (column.translate) {
        transformedData = this.translate.instant(transformedData);
      }
      if (column.currency) {
        if (!searchTerm.includes(',')) {
          transformedData = transformedData.replace(',', '');
        }
      }
    }
    return transformedData;
  }

  matTableSearchOnTransformedDataIfNotEqualsNotAvailable(transformedData: any, searchTerm: any, filteredData: any[], row: any) {
    if (transformedData && transformedData !== 'not available') {
      const processedData = ((typeof transformedData) === 'string') ?
        transformedData.toLowerCase() :
        JSON.stringify(transformedData);
      if (processedData.includes(searchTerm.toLowerCase())) {
        if (!filteredData.includes(row)) {
          filteredData.push(row);
        }
      }
    }
  }

  // search inside table data -- end


}
