import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { SubjectService } from '@app/services/data/subject.service';

@Component({
  selector: 'app-add-edit-hours-adjstment',
  templateUrl: './add-edit-hours-adjstment.component.html',
  styleUrls: ['./add-edit-hours-adjstment.component.scss'],
})
export class AddEditHoursAdjstmentComponent implements OnInit {
  hoursAdjmtForm: FormGroup;
  isLoading = false;
  isEdit = true;
  saveButtonDisable = false;
  submit = false;
  index = -1;

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public hoursAdjmtData,
    private dialogRef: MatDialogRef<AddEditHoursAdjstmentComponent>,
    public fb: FormBuilder,
    private subjectService: SubjectService
  ) {}

  ngOnInit() {
    this.isEdit = this.hoursAdjmtData.isEdit;
    this.index = this.hoursAdjmtData.index;
    this.hoursAdjmtForm = this.fb.group({
      EngineModel: this.hoursAdjmtData.model,
      EngineSpeed: [
        null,
        [Validators.required, Validators.pattern(/^[0-9]*(\.[0-9]+)?$/)],
      ],
      AverageLoadFactor: [
        null,
        [Validators.required, Validators.pattern(/^[0-9]*(\.[0-9]+)?$/)],
      ],
      AdjustmentFactor: [
        null,
        [Validators.required, Validators.pattern(/^[0-9]*(\.[0-9]+)?$/)],
      ],
    });
    if (this.isEdit) {
      this.setValues();
    }
  }

  setValues() {
    this.hoursAdjmtForm.controls.EngineSpeed.setValue(
      this.hoursAdjmtData.rowData.EngineSpeed
    );
    this.hoursAdjmtForm.controls.AverageLoadFactor.setValue(
      this.hoursAdjmtData.rowData.AverageLoadFactor
    );
    this.hoursAdjmtForm.controls.AdjustmentFactor.setValue(
      this.hoursAdjmtData.rowData.AdjustmentFactor
    );
  }
  close() {
    this.dialogRef.close();
  }
  saveHoursAdjmt() {
    let value = '';

    const formValidObj = {
      formName: 'Add/Edit Hours Adjustment',
      formContent: this.hoursAdjmtForm.value.EngineModel,
      formContent2: null,
      formStatus: 'success',
    };

    Object.keys(this.hoursAdjmtForm.value).forEach((key, index, array) => {
      if (this.hoursAdjmtForm.value[key] !== null) {
        value = index < array.length - 1
          ? (value + this.hoursAdjmtForm.value[key] + ' | ')
          : (value + this.hoursAdjmtForm.value[key]);
      }
    });
    formValidObj.formContent2 = value;
    this.submit = true;
    if (this.hoursAdjmtForm.valid && !this.hoursAdjmtData.isEdit) {
      this.subjectService.setFormChangesStream({
        isFormSubmitted: true,
        formValue: formValidObj,
      });
      this.dialogRef.close(this.hoursAdjmtForm.value);
    } else if (this.hoursAdjmtForm.valid && this.hoursAdjmtData.isEdit) {
      this.hoursAdjmtData.fulldata.splice(
        this.index,
        1,
        this.hoursAdjmtForm.value
      );
      this.subjectService.setFormChangesStream({
        isFormSubmitted: true,
        formValue: formValidObj,
      });
      this.dialogRef.close(this.hoursAdjmtData.fulldata);
    }
  }
}
